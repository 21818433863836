import { createSelector } from 'reselect'
import createDataModule from 'utils/moduleCreator'

const {
  api: { cap },
} = __CONFIG__

export type UmrDevice = {
  id: string
  application_name: string
  activation_code: string
  mac: string
  name?: string
}

export const umrDevicesDataKey = 'UmrDevices'

const dataModule = createDataModule<any>(
  umrDevicesDataKey,
  cap.paths.devices,
  cap.base
)

export const {
  fetch: fetchUmrRDevices,
  selectData,
  rootSaga: umrDevicesRootSaga,
  reducer: umrDevicesReducer,
  selectIsFetchLoading: selectIsFetchingUmrDevices,
} = dataModule

export const umrDevices = createSelector(selectData, (umrData) => {
  if (umrData.data)
    return umrData.data.filter(
      (console: UmrDevice) => console.application_name === 'umr'
    )
  return []
})
