import React, { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { CANCEL_SUBSCRIPTION_EXT_CLOUD_CONSOLE_MODAL_ID } from 'pages/subscriptions/ExternalCancelSubscriptionCloudConsoleModal'
import { RoundFlag } from 'components/RoundFlag'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'

import {
  SubscriptionCard,
  SubscriptionCharge,
  SubscriptionMorePopover,
} from '../components'
import {
  CardCell,
  Cell,
  CellColumnLabel,
  CellColumns,
  CellContainer,
  ChargeCell,
  ChargeLabelCell,
  Container,
  LabelCell,
  Row,
  RowWarningIcon,
} from '../Styles'
import { FullSubscription } from '../../../fullSubscriptions/fullSubscriptions'
import { DateCell } from '../../cells/DateCell'
import { getSubscriptionBillingDate } from '../../utils'
import getNumberOfDevices from './utils'

interface Props {
  fullSubscriptions: FullSubscription[]
  mixedRegions: boolean
}

export const CloudConsoleSubscriptionList: React.FC<Readonly<Props>> = ({
  fullSubscriptions,
  mixedRegions,
}) => {
  const { isMaxXSmall } = useContext(MediaSizingContext)
  const intl = useIntl()

  const [cardPopoverOpen, setCardPopoverOpen] = useState<number | null>(null)
  const [morePopoverOpen, setMorePopoverOpen] = useState<number | null>(null)

  if (!fullSubscriptions.length) return <></>

  const shouldRenderSubscriptionItems = (fullSub: FullSubscription) =>
    fullSub.items.data.length > 1

  return (
    <Container>
      <Row>
        <CellColumnLabel $mixedRegions={mixedRegions}>
          {mixedRegions && <LabelCell />}
          <LabelCell>
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_COLUMN_LABEL_UNIFI_DEVICES" />
          </LabelCell>
          {!isMaxXSmall && (
            <>
              <LabelCell>
                <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_COLUMN_LABEL_QUANTITY" />
              </LabelCell>
              <LabelCell>
                <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_COLUMN_LABEL_BILLING_DATE" />
              </LabelCell>
              <LabelCell>
                <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_COLUMN_LABEL_PAYMENT_METHOD" />
              </LabelCell>
            </>
          )}
          <ChargeLabelCell>
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_COLUMN_LABEL_CHARGE" />
          </ChargeLabelCell>
        </CellColumnLabel>
      </Row>
      {fullSubscriptions.map(
        (fullSubscription: FullSubscription, i: number) => {
          const { quantity, region, status, items, cancelAtPeriodEnd } =
            fullSubscription
          const hidePopoverToggle = status === 'canceled' && !isMaxXSmall
          if (shouldRenderSubscriptionItems(fullSubscription)) {
            return (
              <>
                {items.data.map((item, j) => (
                  <Row key={i + j}>
                    <CellColumns
                      $mixedRegions={mixedRegions}
                      $cancelAtPeriodEnd={cancelAtPeriodEnd}
                    >
                      {cancelAtPeriodEnd && (
                        <CellContainer>
                          <RowWarningIcon
                            width={20}
                            height={20}
                            variant="fill"
                          />
                        </CellContainer>
                      )}
                      {mixedRegions && (
                        <CellContainer>
                          <Cell>
                            <RoundFlag countryCode={region} noMargin />
                          </Cell>
                        </CellContainer>
                      )}
                      <CellContainer>
                        <Cell>{getNumberOfDevices(item)}</Cell>
                      </CellContainer>
                      {!isMaxXSmall && (
                        <>
                          <CellContainer>
                            <Cell>{item.quantity ?? 1}</Cell>
                          </CellContainer>
                          <CellContainer>
                            <Cell>
                              <DateCell
                                dt={getSubscriptionBillingDate(
                                  fullSubscription
                                )}
                              />
                            </Cell>
                          </CellContainer>
                          <CellContainer>
                            <CardCell>
                              <SubscriptionCard
                                fullSubscription={fullSubscription}
                                cardPopoverOpen={cardPopoverOpen}
                                setCardPopoverOpen={setCardPopoverOpen}
                                id={i + j}
                              />
                            </CardCell>
                          </CellContainer>
                        </>
                      )}
                      <CellContainer>
                        <ChargeCell>
                          {cancelAtPeriodEnd ? (
                            intl.formatMessage({
                              id: 'SETTINGS_SUBSCRIPTIONS_CANCELED_AT_MONTH_END_ROW',
                            })
                          ) : (
                            <SubscriptionCharge
                              fullSubscription={fullSubscription}
                            />
                          )}
                        </ChargeCell>
                      </CellContainer>
                      <CellContainer>
                        {!hidePopoverToggle && (
                          <SubscriptionMorePopover
                            fullSubscription={fullSubscription}
                            morePopoverOpen={morePopoverOpen}
                            setCardPopoverOpen={setCardPopoverOpen}
                            setMorePopoverOpen={setMorePopoverOpen}
                            cardPopoverOpen={cardPopoverOpen}
                            modalId={
                              CANCEL_SUBSCRIPTION_EXT_CLOUD_CONSOLE_MODAL_ID
                            }
                            id={i + j}
                          />
                        )}
                      </CellContainer>
                    </CellColumns>
                  </Row>
                ))}
              </>
            )
          }

          return (
            <Row key={i}>
              <CellColumns $mixedRegions={mixedRegions}>
                {mixedRegions && (
                  <CellContainer>
                    <Cell>
                      <RoundFlag countryCode={region} noMargin />
                    </Cell>
                  </CellContainer>
                )}
                <CellContainer>
                  <Cell>{getNumberOfDevices(fullSubscription)}</Cell>
                </CellContainer>
                {!isMaxXSmall && (
                  <>
                    <CellContainer>
                      <Cell>{quantity ?? 1}</Cell>
                    </CellContainer>
                    <CellContainer>
                      <Cell>
                        <DateCell
                          dt={getSubscriptionBillingDate(fullSubscription)}
                        />
                      </Cell>
                    </CellContainer>
                    <CellContainer>
                      <CardCell>
                        <SubscriptionCard
                          fullSubscription={fullSubscription}
                          cardPopoverOpen={cardPopoverOpen}
                          setCardPopoverOpen={setCardPopoverOpen}
                          id={i}
                        />
                      </CardCell>
                    </CellContainer>
                  </>
                )}
                <CellContainer>
                  <ChargeCell>
                    {cancelAtPeriodEnd ? (
                      intl.formatMessage({
                        id: 'SETTINGS_SUBSCRIPTIONS_CANCELED_AT_MONTH_END_ROW',
                      })
                    ) : (
                      <SubscriptionCharge fullSubscription={fullSubscription} />
                    )}
                  </ChargeCell>
                </CellContainer>
                <CellContainer>
                  {!hidePopoverToggle && (
                    <SubscriptionMorePopover
                      fullSubscription={fullSubscription}
                      morePopoverOpen={morePopoverOpen}
                      setCardPopoverOpen={setCardPopoverOpen}
                      setMorePopoverOpen={setMorePopoverOpen}
                      cardPopoverOpen={cardPopoverOpen}
                      modalId={CANCEL_SUBSCRIPTION_EXT_CLOUD_CONSOLE_MODAL_ID}
                      id={i}
                    />
                  )}
                </CellContainer>
              </CellColumns>
            </Row>
          )
        }
      )}
    </Container>
  )
}

export default CloudConsoleSubscriptionList
