import React from 'react'
import { UcoreDevice } from 'features/devices/types'
import { UidbImage_Console } from '@ubnt/ui-components/aria/UidbImage'
import { ZDFormValueType } from './types'

export const getConsoleZdId = (shortname?: string): string => {
  if (!shortname) {
    return ''
  }

  return `uosfrm_cons_${shortname.toLowerCase()}`
}

export const parseNcaDevices = (
  ncaDevices: UcoreDevice[]
): ZDFormValueType[] => {
  return ncaDevices.map((device) => {
    const { shortname } = device.shadow.state.reported.hardware

    return {
      id: getConsoleZdId(shortname),
      label: shortname,
      icon: <UidbImage_Console shortname={shortname} size="60px" />,
    }
  })
}
