import { all, put, select, spawn, takeEvery } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { api } from 'api'

import { MFAType } from 'modules/mfa'
import createDataModule from 'utils/moduleCreator'
import { SSOAPIError } from 'types/types'
import { MFAAuthenticatorStatus } from 'enums'
import { setVisibleModal } from 'modules/modals'

import { PUSH_QR_CODE_MODAL_ID } from '..'
import { verifyMFAPush } from './verifyPush'

const {
  api: { sso },
} = __CONFIG__

export const initiatePushDataKey = 'MFAInitiatePush'

const initiatePushActions = {
  create: `${initiatePushDataKey}/CREATE`,
}

type InitiatePushState =
  | {
      setup_uri: string
      status: MFAAuthenticatorStatus
      last_success: string | null
      id: string
      created: string
      type: MFAType.push
    }
  | Record<string, never>

const dataModule = createDataModule<InitiatePushState, SSOAPIError>(
  initiatePushDataKey,
  `${sso.paths.mfa}/push`,
  api.ssoBase
)

const {
  selectData,
  selectIsLoading,
  selectErrors,
  selectIsError,
  resetData,
  CREATE_DONE,
  CREATE,
} = dataModule

export {
  selectIsLoading as selectInitiatePushIsLoading,
  selectErrors as selectInitiatePushErrors,
  selectIsError as selectInitiatePushIsError,
  resetData as resetInitiatePushData,
}

export const selectInitiatePushData = createSelector(
  selectData,
  (data) => data ?? {} //data might be undefined, override it to be empty object to allow destructuring
)

export const initiateMFAPush = (name?: string) => {
  return { type: initiatePushActions.create, payload: { name } }
}

function* initiatePushSaga() {
  yield put(setVisibleModal(PUSH_QR_CODE_MODAL_ID))
}

function* initiatePushDoneSaga() {
  const { id } = yield select(selectData)
  yield put(verifyMFAPush(id))
}

function* subscribeToInitiatePushSaga() {
  yield takeEvery(CREATE, initiatePushSaga)
}

function* subscribeToInitiatePushDoneSaga() {
  yield takeEvery(CREATE_DONE, initiatePushDoneSaga)
}

export function* initiatePushRootSaga() {
  yield all([
    spawn(dataModule.rootSaga),
    spawn(subscribeToInitiatePushSaga),
    spawn(subscribeToInitiatePushDoneSaga),
  ])
}

export const inititatePushReducer = dataModule.reducer
