import React, { useContext } from 'react'

import ProtectedPage from 'pages/ProtectedPage'
import { Activities } from 'pages/activity/ui/Activities'
import { useAppView } from 'utils/useAppView'
import { MobileNavigation } from 'components/navigation/MobileNavigation'
import { ProfileCard } from 'components/profileCard/ProfileCard'
import { WrappedAddMFAPrompt } from 'components/addMfaPrompt/AddMFAPrompt'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'

import Cards from './components/Cards'
import Hero from './components/Hero'
import { useShowAddMFAPrompt } from './useShowAddMFAPrompt'

const Home: React.FC = () => {
  const [isAppView] = useAppView()
  const { isMaxMediumV2 } = useContext(MediaSizingContext)

  useShowAddMFAPrompt()

  if (isAppView && isMaxMediumV2) {
    return (
      <ProtectedPage>
        <ProfileCard />
        <MobileNavigation />
        <Activities numberOfItems={5} />
        <WrappedAddMFAPrompt />
      </ProtectedPage>
    )
  }

  return (
    <ProtectedPage>
      <Hero />
      <Cards />
      <Activities numberOfItems={5} />
      <WrappedAddMFAPrompt />
    </ProtectedPage>
  )
}

export default Home
