import React from 'react'
import { useSelector } from 'react-redux'
import { WrappedComponentProps, injectIntl } from 'react-intl'

import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { selectMFASetup } from 'modules/mfaSetup'
import { MFAType } from 'modules/mfa'

import { GenericConfirmRemoveMFAModal } from '../RemoveModal/GenericConfirmRemoveMFAModal'

export const SMS_REMOVE_MODAL_ID = 'SMS_REMOVE_MODAL_ID'

const SMSRemoveModal: React.FC<ModalProps & WrappedComponentProps> = ({
  intl,
  ...restProps
}) => {
  const { removeSMS } = useSelector(selectMFASetup)

  return (
    <GenericConfirmRemoveMFAModal
      title={intl.formatMessage({
        id: 'SETTINGS_MFA_SMS_REMOVE_MODAL_TITLE',
      })}
      removeId={removeSMS.id}
      type={MFAType.sms}
      {...restProps}
    />
  )
}

const SMSRemoveModalConnected = injectIntl(SMSRemoveModal)

export const WrappedSMSRemoveModal = () => (
  <ModalWrapper modalId={SMS_REMOVE_MODAL_ID}>
    <SMSRemoveModalConnected />
  </ModalWrapper>
)
