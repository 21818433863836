import { all, put, spawn, takeEvery } from 'redux-saga/effects'
import type Stripe from 'stripe'
import { createSelector } from 'reselect'

import createDataModule from 'utils/moduleCreator'
import { StripeData } from 'types/types'

import { fetchCards } from './fetchCards'
import { CardData } from './types'

const {
  api: { billing },
} = __CONFIG__

export const dataKey = 'removeCard'

const getUrl = (url: string, card: CardData) =>
  `${url}/${card.id}?region=${card.region}`

const dataModule = createDataModule<StripeData<Stripe.Card>>(
  dataKey,
  billing.paths.cards,
  billing.base,
  undefined,
  getUrl
)

export const {
  remove: removeCard,
  REMOVE_DONE: REMOVE_CARD_DONE,
  selectIsLoading: selectIsRemoveCardLoading,
  resetErrors: resetRemoveCardErrors,
  reducer: removeCardReducer,
} = dataModule

export const selectRemoveCardApiError = createSelector(
  dataModule.selectErrors,
  (errors) => (errors.message ? errors.message : null)
)

function* cardDoneSaga() {
  yield put(fetchCards())
}

function* subscribeToRemoveCardDoneSaga() {
  yield takeEvery([dataModule.REMOVE_DONE], cardDoneSaga)
}

export function* removeCardRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToRemoveCardDoneSaga)])
}
