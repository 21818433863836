import React from 'react'
import { FormattedMessage } from 'react-intl'

import { FullSubscription } from 'features/subscriptions/fullSubscriptions/fullSubscriptions'
import styled from 'theme/styled'
import { space, typography } from 'theme'
import { ProductName } from 'types/enums'

export const InvoiceIdentifier: React.FC<{
  subscription?: FullSubscription
}> = ({ subscription }) => {
  if (!subscription) {
    return null
  }

  // TODO: Add case for UniFi Talk, UniFi Mobile Routing, Official UniFi Hosting
  const { controller, site } = subscription.metadata
  switch (subscription.productLine) {
    case ProductName.ULTE:
      if (!controller) return null
      const siteName = site !== 'Default' ? ` - ${site}` : ''
      return (
        <LteWrapper>
          <Title>
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_INVOICE_HOST" />
          </Title>
          <Content>{`${controller}${siteName}`}</Content>
        </LteWrapper>
      )
    default:
      return null
  }
}

const Title = styled.div`
  font: ${typography['desktop-body']};
  text-align: left;
  &.margin-top {
    margin-top: ${space['desktop-spacing-base-04']};
  }
`

const Content = styled(Title)`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.text0};
`

const LteWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
