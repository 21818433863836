import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Button, Tabs } from '@ubnt/ui-components'

import styled from 'theme/styled'
import { typography } from 'theme'
import { FlexWrapper } from 'components/SharedComponents'
import { selectTotalOpenTickets } from 'modules/zendesk/openUserTickets'
import { selectTotalClosedTickets } from 'modules/zendesk/closedUserTickets'
import { selectTotalCcdTickets } from 'modules/zendesk/ccdUserTickets'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'

import { RequestsGrid } from './RequestsGrid'
import { RequestsContext } from './RequestsContext'
import { RequestsPagination } from './RequestsPagination'
import { RequestsSearch } from './RequestsSearch'
import { HeaderTabLabel } from './HeaderTabLabel'
import { HeaderTab } from './types'

export const RequestsContainer: React.FC = () => {
  const {
    statusToShow,
    handleChangeStatusToShow,
    hasMultiplePages,
    ticketsError,
    searchData,
  } = useContext(RequestsContext)

  const { isMaxMobileXLarge } = useContext(MediaSizingContext)

  const totalOpenTickets = useSelector(selectTotalOpenTickets)
  const totalClosedTickets = useSelector(selectTotalClosedTickets)
  const totalCcdTickets = useSelector(selectTotalCcdTickets)

  const isSearchActive = !!searchData.value.length

  const renderSearch = (hasMultiplePages && !ticketsError) || isSearchActive

  return (
    <Container>
      <Wrapper>
        <Title>
          <FormattedMessage id="REQUESTS_HEADER_TITLE" />
        </Title>
        <Link to="/supportform">
          <Button variant="primary" size="small">
            <FormattedMessage id="REQUEST_CREATE_NEW" />
          </Button>
        </Link>
      </Wrapper>
      <Header $omitPaddingBottom={hasMultiplePages || isSearchActive}>
        <StyledTabs
          tabs={[
            {
              id: 'open',
              label: (
                <HeaderTabLabel
                  label="SUPPORT_MY_REQUESTS_TAB_OPEN"
                  totalTickets={totalOpenTickets}
                />
              ),
            },
            {
              id: 'closed',
              label: (
                <HeaderTabLabel
                  label="SUPPORT_MY_REQUESTS_TAB_CLOSED"
                  totalTickets={totalClosedTickets}
                />
              ),
            },
            {
              id: 'ccd',
              label: (
                <HeaderTabLabel
                  label="SUPPORT_MY_REQUESTS_TAB_CCD"
                  totalTickets={totalCcdTickets}
                />
              ),
            },
          ]}
          selectedTabBold
          fullHeightTabs
          tabClassName="requests-tab"
          onSelectTab={({ id }) => handleChangeStatusToShow?.(id as HeaderTab)}
          selectedTab={statusToShow}
          variant="capsule"
        />
        {!isMaxMobileXLarge && renderSearch && (
          <RequestsSearch isSmallScreen={isMaxMobileXLarge} />
        )}
      </Header>
      {isMaxMobileXLarge && renderSearch && (
        <RequestsSearch isSmallScreen={isMaxMobileXLarge} />
      )}
      <FlexWrapper column width="100%">
        <RequestsGrid />
      </FlexWrapper>
      {hasMultiplePages && !ticketsError && <RequestsPagination />}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  padding: 16px;
  border: ${({ theme }) => `1px solid ${theme.neutral03}`};
  border-radius: 8px;
  margin-bottom: 100px;
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Title = styled.span`
  font: ${typography['desktop-typography-heading-large']};
  color: ${({ theme }) => theme.text1};
`

const Header = styled.div<{ $omitPaddingBottom: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
`

const StyledTabs = styled(Tabs)`
  justify-content: flex-start;

  div {
    margin: 0;
  }

  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    div {
      width: 100%;
    }
  }
`
