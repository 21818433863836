import { Button } from '@ubnt/ui-components'
import { typography } from '@ubnt/ui-components/styles/designToken'

import UserImage from 'components/avatar/UserImage'
import styled from 'theme/styled'
import ExternalLink from 'components/ExternalLink'
import { MegaphoneIcon } from '@ubnt/icons'

const StyledMegaphoneIcon = styled(MegaphoneIcon)`
  color: ${({ theme }) => theme.neutral08};
`

const GreyButton = styled(Button)`
  color: ${({ theme }) => theme.text3};
  :hover {
    color: ${({ theme }) => theme.text4};
  }
`

const PopoverOption = styled(ExternalLink)`
  font: ${typography['desktop-body']};
  @media (max-width: ${({ theme }) => theme.media.small}) {
    font: ${typography['desktop-caption']};
  }
`

const SignOutButton = styled(Button)`
  color: ${({ theme }) => theme.neutral08};
  height: 32px;
  margin: 8px 0;
`

export {
  UserImage,
  PopoverOption,
  SignOutButton,
  GreyButton,
  StyledMegaphoneIcon,
}
