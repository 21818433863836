import { Dispatch } from 'redux'
import { createSelector } from 'reselect'
import axios from 'axios'
import createDataModule from 'utils/moduleCreator'
import { ZendeskTicket, ZendeskTicketsResponse } from 'pages/requests/types'

const {
  api: { accountBE },
} = __CONFIG__

export const openTicketsDataKey = 'zendeskOpenTickets'

const dataModule = createDataModule<ZendeskTicketsResponse>(
  openTicketsDataKey,
  accountBE.paths.zendeskOpenTickets,
  accountBE.base
)

export const {
  fetch: fetchOpenTickets,
  selectData,
  setIsFetchLoading,
  FETCH_FAILED,
  FETCH_DONE,
  selectIsDone: selectOpenTicketsFetched,
  selectErrors: selectOpenTicketsErrors,
  selectIsLoading: selectOpenTicketsAreLoading,
  rootSaga: openTicketsRootSaga,
  reducer: openTicketsReducer,
} = dataModule

export const selectOpenTickets = createSelector(
  selectData,
  ({ tickets }: ZendeskTicketsResponse): ZendeskTicket[] => tickets ?? []
)

export const selectHasMoreOpenTickets = createSelector(
  selectData,
  ({ tickets, totalTickets }: ZendeskTicketsResponse): boolean =>
    tickets?.length < totalTickets
)

export const selectTotalOpenTickets = createSelector(
  selectData,
  ({ totalTickets }: ZendeskTicketsResponse): number => totalTickets
)

export const fetchExtraOpenTickets =
  (page: number, fetchedTickets: ZendeskTicket[]) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsFetchLoading())
    const pathWithParams = `${accountBE.base}/${accountBE.paths.zendeskOpenTickets}?page=${page}`
    try {
      const response = await axios.get(pathWithParams, {
        withCredentials: true,
      })
      const tickets = [
        ...(fetchedTickets ?? []),
        ...(response?.data?.tickets ?? []),
      ]
      const { totalTickets } = response.data
      dispatch({ type: FETCH_DONE, payload: { tickets, totalTickets } })
    } catch (e) {
      dispatch({ type: FETCH_FAILED, payload: { error: e } })
    }
  }
