import { all, put, spawn, takeEvery } from 'redux-saga/effects'
import { api } from 'api'

import { fetchMFA } from 'modules/mfa'
import createDataModule from 'utils/moduleCreator'
import { SSOAPIError } from 'types/types'
import { closeVisibleModal } from 'modules/modals'

const {
  api: { sso },
} = __CONFIG__

export const removePushDataKey = 'MFARemovePush'

const removePushActions = {
  remove: `${removePushDataKey}/REMOVE`,
}

type RemovePushState = Record<string, never>

const dataModule = createDataModule<RemovePushState, SSOAPIError>(
  removePushDataKey,
  `${sso.paths.mfa}/push`,
  api.ssoBase
)

export const {
  REMOVE_DONE,
  selectErrors: selectRemovePushErrors,
  resetErrors: resetRemovePushErrors,
} = dataModule

export const removeMFAPush = (id: string) => {
  return {
    type: removePushActions.remove,
    urlPath: `/${id}`,
  }
}

function* closeModalSaga() {
  yield put(fetchMFA())
  yield put(closeVisibleModal())
}

function* subscribeToRemovedPushDoneSaga() {
  yield takeEvery(REMOVE_DONE, closeModalSaga)
}

export function* removePushRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToRemovedPushDoneSaga)])
}

export const removePushReducer = dataModule.reducer
