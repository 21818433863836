import { Button } from '@ubnt/ui-components'
import { media, radius, space, typography } from 'theme'
import styled from 'theme/styled'

const Wrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.ublue06};
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TextWrap = styled.div`
  width: 100%;

  @media (max-width: ${media.mobileLarge}) {
    margin-bottom: 12px;
  }
`

const EmptyOption = styled.div`
  border-radius: ${radius['desktop-radius-medium']};
  border: 1px solid ${({ theme }) => theme.neutral03};
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-height: 100%;
  transition: background-color 0.2s linear;
  width: 100%;
  will-change: background-color;

  @media (max-width: ${media.mobileXLarge}) {
    height: 80px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.neutral01};
  }
`

const Container = styled.div<{ $isExpanded?: boolean }>`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  max-height: ${({ $isExpanded }) => ($isExpanded ? 'auto' : '181px')};
  max-width: 100%;
  min-height: 160px;
  overflow: hidden;
  margin-bottom: 16px;

  @media (max-width: ${media.mediumV2}) {
    grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
  }

  @media (max-width: ${media.mobileXLarge}) {
    grid-template-columns: 1fr;
    max-height: ${({ $isExpanded }) => ($isExpanded ? 'auto' : '478px')};
  }
`

const Text = styled.p`
  font-weight: ${typography['desktop-font-weight-bold']};
  font: ${typography['desktop-body']};
  margin: 0;
  width: 100%;
`

const StyledButton = styled(Button, {
  shouldForwardProp: (props) => props !== '$isExpanded',
})<{ $isExpanded: boolean }>`
  font: ${typography['desktop-typography-btn-label-regular']} !important;

  svg {
    margin-right: 4px;
    transform: ${({ $isExpanded }) =>
      $isExpanded ? 'rotate(180deg)' : 'none'};
  }
`

const TitleSection = styled.div<{ isVat?: boolean }>`
  align-items: center;
  display: flex;
  gap: ${({ isVat }) => (isVat ? space['desktop-spacing-base-02'] : '0')};
  justify-content: ${({ isVat }) => (isVat ? 'flex-start' : 'space-between')};
  margin-bottom: 16px;
  margin-top: ${({ isVat }) =>
    isVat ? space['desktop-spacing-base-12'] : '0'};

  @media (max-width: ${media.mobileLarge}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.neutral03};
  margin: 20px 0 32px 0;
`

export {
  Container,
  Divider,
  EmptyOption,
  StyledButton,
  Text,
  TextWrap,
  TitleSection,
  Wrapper,
}
