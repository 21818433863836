import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import styled from 'theme/styled'
import { closeVisibleModal, setVisibleModal } from 'modules/modals'

import { GenericMFAModal } from '../GenericMFAModal'
import { PASSKEY_ADD_KEY_NAME_MODAL_ID } from './AddKeyNameModal'
import PasskeyIcon from '@ubnt/icons/PasskeyIcon'
import ExclamationMarkIcon from '@ubnt/icons/ExclamationMarkIcon'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`

const Description = styled.div`
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: ${(p) => p.theme.neutral08};
  margin: 16px 0;
`

export const PASSKEY_FAILED_MODAL_ID = 'PASSKEY_FAILED_MODAL_ID'

export const PasskeyFailedModal: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  return (
    <GenericMFAModal
      modalId={PASSKEY_FAILED_MODAL_ID}
      title={intl.formatMessage({
        id: 'SETTINGS_MFA_PASSKEY_MODAL_TITLE',
      })}
      actions={[
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_CLOSE' }),
          variant: 'tertiary',
          onClick: () => dispatch(closeVisibleModal()),
        },
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_TRY_AGAIN' }),
          variant: 'primary',
          onClick: () =>
            dispatch(setVisibleModal(PASSKEY_ADD_KEY_NAME_MODAL_ID)),
        },
      ]}
      size="small"
      overrideFullScreen
    >
      <Wrapper>
        <Container>
          <PasskeyIcon variant="twoTone" isActive size={32} />
          <WarningIcon variant="fill" size="original" />
        </Container>
        <Description>
          {intl.formatMessage({
            id: 'SETTINGS_MFA_PASSKEY_FAILED_MODAL_DESCRIPTION',
          })}
        </Description>
      </Wrapper>
    </GenericMFAModal>
  )
}

const Container = styled.div`
  width: 48px;
  height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ theme }) => `1px solid ${theme.neutral04}`};
  border-radius: 8px;
`

const WarningIcon = styled(ExclamationMarkIcon)`
  color: ${({ theme }) => theme.orange06};
  position: absolute;
  bottom: -4px;
  right: -6px;
`
