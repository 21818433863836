import { ReduxAction, RootState } from 'types/types'
import { all, call, put, spawn, takeEvery } from 'redux-saga/effects'
import { api } from 'api'
import axios from 'axios'

import { closeVisibleModal, setVisibleModal } from 'modules/modals'
import { VERIFY_EMAIL_CODE_MODAL_ID } from './VerifyEmailModal'

export const dataKey = 'changeEmail'

export interface ChangeEmailState {
  error: unknown
  isLoading: boolean
}

const REQUEST_EMAIL_VERIFICATION = `${dataKey}/REQUEST_EMAIL_VERIFICATION`
export const requestEmailVerification = (newEmail: string) => {
  return {
    type: REQUEST_EMAIL_VERIFICATION,
    payload: newEmail,
  }
}

const REQUEST_EMAIL_VERIFICATION_FAILED = `${dataKey}/REQUEST_EMAIL_VERIFICATION_FAILED`
export const requestEmailVerificationFailed = (data: unknown) => {
  return {
    type: REQUEST_EMAIL_VERIFICATION_FAILED,
    payload: data,
  }
}

const REQUEST_EMAIL_VERIFICATION_RESET = `${dataKey}/REQUEST_EMAIL_VERIFICATION_RESET`
export const requestEmailVerificationReset = () => ({
  type: REQUEST_EMAIL_VERIFICATION_RESET,
})

const selectData = (state: RootState) => state[dataKey]

export const selectEmailRequestErrors = (state: RootState) =>
  selectData(state).error

export const selectEmailRequestIsLoading = (state: RootState) =>
  selectData(state).isLoading

export function* requestVerifyEmailSaga({
  payload: email,
}: ReduxAction<string>) {
  try {
    yield call(api.requestVerificationEmail, email)
    yield put(requestEmailVerificationReset())
    yield put(closeVisibleModal())
    yield put(setVisibleModal(VERIFY_EMAIL_CODE_MODAL_ID, { email }))
  } catch (e) {
    if (axios.isAxiosError(e) && e.response?.status === 400) {
      yield put(requestEmailVerificationFailed(e.response.data))
      return
    } else {
      if (axios.isAxiosError(e) && e.response) {
        yield put(requestEmailVerificationFailed(e.response.statusText))
      }
      return
    }
  }
}

export function* subscribeToRequestVerifyEmail() {
  yield takeEvery(REQUEST_EMAIL_VERIFICATION, requestVerifyEmailSaga)
}

export function* changeEmailRootSaga() {
  yield all([spawn(subscribeToRequestVerifyEmail)])
}

const initialState: ChangeEmailState = {
  isLoading: false,
  error: null,
}

export const requestEmailVerificationReducer = (
  state = initialState,
  action: ReduxAction
) => {
  const { type, payload } = action

  switch (type) {
    case REQUEST_EMAIL_VERIFICATION:
      return {
        isLoading: true,
        error: null,
      }
    case REQUEST_EMAIL_VERIFICATION_FAILED:
      return {
        isLoading: false,
        error: payload,
      }
    case REQUEST_EMAIL_VERIFICATION_RESET:
      return {
        isLoading: false,
        error: null,
      }
    default:
      return state
  }
}
