import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Button } from '@ubnt/ui-components'
import PasskeyIcon from '@ubnt/icons/PasskeyIcon'

import styled from 'theme/styled'
import { space, typography } from 'theme'
import {
  MFAAuthenticator,
  MFAAuthenticatorEmail,
  MFAAuthenticatorPush,
  MFAAuthenticatorSMS,
  MFAAuthenticatorTOTP,
  MFAType,
} from 'modules/mfa'
import UIVerify from 'assets/images/uiVerify.png'
import { MFAOption } from 'components/mfa-options-modal/MFAOption'
import { closeVisibleModal, setVisibleModal } from 'modules/modals'
import { MFA_LOST_ACCESS_MODAL_ID } from 'components/mfa-options-modal/LostAccessModal'
import AuthIcon from '@ubnt/icons/AuthIcon'
import MailsCloseIcon from '@ubnt/icons/MailsCloseIcon'
import RepliesIcon from '@ubnt/icons/RepliesIcon'

interface Props {
  authenticators?: MFAAuthenticator[] | null
  setCurrentAuthenticator: Dispatch<
    SetStateAction<MFAAuthenticator | undefined>
  >
  currentAuthenticator: MFAAuthenticator | undefined
}

export const LoginMFAOptions: React.FC<Props> = ({
  authenticators,
  setCurrentAuthenticator,
  currentAuthenticator,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const preparedData = (
    authenticator:
      | MFAAuthenticatorTOTP
      | MFAAuthenticatorEmail
      | MFAAuthenticatorSMS
      | MFAAuthenticatorPush
  ) => {
    switch (authenticator.type) {
      case MFAType.push:
        return {
          iconLink: UIVerify,
          title: intl.formatMessage({ id: 'SETTINGS_MFA_METHOD_PUSH' }),
          description: <Description>{authenticator.name}</Description>,
        }
      case MFAType.totp:
        return {
          icon: <AuthIcon variant="twoTone" isActive size={32} />,
          title: intl.formatMessage({ id: 'SETTINGS_MFA_METHOD_TOTP' }),
          description: <Description>{authenticator.name}</Description>,
        }
      case MFAType.email:
        return {
          icon: <MailsCloseIcon variant="twoTone" isActive size={32} />,
          title: intl.formatMessage({ id: 'SETTINGS_MFA_METHOD_EMAIL' }),
          description: <Description>{authenticator.email}</Description>,
        }
      case MFAType.sms:
        return {
          icon: <RepliesIcon variant="twoTone" isActive size={32} />,
          title: intl.formatMessage({ id: 'SETTINGS_MFA_METHOD_SMS' }),
          description: <Description>{authenticator.phone_number}</Description>,
        }
    }
  }

  const backups = authenticators?.find(
    (authenticator) => authenticator.type === MFAType.backupCodes
  )

  const passkeyNames = (
    <div>
      {authenticators?.map((authenticator, i) => {
        if (authenticator.type === MFAType.webAuthn) {
          return (
            <Description key={i.toString()}>{authenticator.name}</Description>
          )
        }
      })}
    </div>
  )

  const otherPasskey = useMemo(() => {
    return authenticators?.find(
      (authenticator) =>
        authenticator !== currentAuthenticator &&
        authenticator.type === MFAType.webAuthn
    )
  }, [authenticators, currentAuthenticator])

  return (
    <Wrapper>
      <OptionsDescription>
        <FormattedMessage id="LOGIN_MFA_OPTIONS_DESCRIPTION" />
      </OptionsDescription>
      {authenticators?.map((authenticator, i, arr) => {
        if (
          authenticator.id === currentAuthenticator?.id ||
          authenticator.type === MFAType.backupCodes ||
          authenticator.type === MFAType.webAuthn
        )
          return
        const { iconLink, title, description, icon } =
          preparedData(authenticator)
        return (
          <MFAOption
            key={i.toString()}
            iconLink={iconLink}
            icon={icon}
            iconBorder={authenticator.type === MFAType.push ? false : true}
            title={title}
            description={description}
            onClick={() => {
              dispatch(closeVisibleModal())
              setCurrentAuthenticator(authenticator)
            }}
            showBorder={arr.length + 1 === i ? false : true}
          />
        )
      })}

      {otherPasskey && currentAuthenticator?.type !== MFAType.webAuthn && (
        <MFAOption
          key={'passkey'}
          icon={<PasskeyIcon variant="twoTone" isActive size={32} />}
          title={intl.formatMessage({
            id: 'SETTINGS_MFA_METHOD_PASSKEY',
          })}
          description={passkeyNames}
          onClick={() => {
            dispatch(closeVisibleModal())
            setCurrentAuthenticator(otherPasskey)
          }}
          showBorder={false}
        />
      )}

      <OtherMethodsContainer>
        <Button
          disabled={!backups}
          variant="inline"
          onClick={() => {
            if (backups) {
              dispatch(closeVisibleModal())
              setCurrentAuthenticator(backups)
            }
          }}
        >
          <FormattedMessage id="LOGIN_MFA_BACKUP_CODES" />
        </Button>
        <Button
          variant="inline"
          onClick={() => dispatch(setVisibleModal(MFA_LOST_ACCESS_MODAL_ID))}
        >
          <FormattedMessage id="LOGIN_MFA_OPTIONS_LOST_ACCESS" />
        </Button>
      </OtherMethodsContainer>
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  width: 100%;
  font-size: ${typography['desktop-body']};
`

const OptionsDescription = styled.div`
  text-align: left;
  color: ${({ theme }) => theme.text2};
  margin-bottom: ${space['desktop-spacing-base-05']};
`

const OtherMethodsContainer = styled.div`
  color: ${({ theme }) => theme.text3};
  margin-top: ${space['desktop-spacing-base-04']};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Description = styled.div`
  color: ${(props) => props.theme.neutral08};
  margin-top: ${space['desktop-spacing-base-01']};
  text-align: left;
`
