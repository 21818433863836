import React from 'react'
import { useMediaQuery } from 'react-responsive'

import { media } from 'theme'

type MediaSizingContext = {
  isMaxMobileXLarge: boolean
  isMaxTabletLarge: boolean
  isMaxMobileLarge: boolean
  isMaxMediumV2: boolean
  isMaxXSmall: boolean
  isMaxSmall: boolean
}

export const MediaSizingContext = React.createContext<MediaSizingContext>({
  isMaxMobileXLarge: false,
  isMaxTabletLarge: false,
  isMaxMobileLarge: false,
  isMaxMediumV2: false,
  isMaxXSmall: false,
  isMaxSmall: false,
})

export const MediaSizingProvider: React.FC = ({ children }) => {
  const isMaxMobileXLarge = useMediaQuery({
    query: `(max-width: ${media.mobileXLarge})`,
  })
  const isMaxTabletLarge = useMediaQuery({
    query: `(max-width: ${media.tabletLarge})`,
  })
  const isMaxMobileLarge = useMediaQuery({
    query: `(max-width: ${media.mobileLarge})`,
  })
  const isMaxMediumV2 = useMediaQuery({
    query: `(max-width: ${media.mediumV2})`,
  })
  const isMaxXSmall = useMediaQuery({
    query: `(max-width: ${media.xSmall})`,
  })
  const isMaxSmall = useMediaQuery({
    query: `(max-width: ${media.small})`,
  })

  return (
    <MediaSizingContext.Provider
      value={{
        isMaxMobileXLarge,
        isMaxTabletLarge,
        isMaxMobileLarge,
        isMaxMediumV2,
        isMaxXSmall,
        isMaxSmall,
      }}
    >
      {children}
    </MediaSizingContext.Provider>
  )
}
