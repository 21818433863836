import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import styled from 'theme/styled'
import { MFAType, selectMFAAuthenticators } from 'modules/mfa'
import ShowOtherButton from 'components/showOtherButton/ShowOtherButton'
import HideOptions from 'components/hideOptions/HideOptions'

import { useGetMFAOptions } from 'features/auth/ui/useGetMFAOptions'

export const MFAOptions: React.FC = () => {
  const [showOtherMethods, setShowOtherMethods] = useState(false)
  const [hideButton, setHideButton] = useState(false)

  const authenticators = useSelector(selectMFAAuthenticators)

  const hasActivePush = useMemo(() => {
    const hasActivePush = authenticators?.find(
      (auth) => auth.type === MFAType.push && auth.status === 'active'
    )
    if (hasActivePush) {
      setShowOtherMethods(true)
      setHideButton(true)
    }
    return hasActivePush
  }, [authenticators])

  const hasActiveTOTP = useMemo(() => {
    return !!authenticators?.some(
      ({ type, status }) => type === MFAType.totp && status === 'active'
    )
  }, [authenticators])

  const mfaOptions = useGetMFAOptions(!!hasActivePush, !!hasActiveTOTP)

  return (
    <Wrapper>
      {mfaOptions.featured}
      <HideOptions isShown={showOtherMethods}>{mfaOptions.other}</HideOptions>
      {hideButton ? (
        <></>
      ) : (
        <ButtonWrapper>
          <ShowOtherButton
            handleClick={() => setShowOtherMethods(!showOtherMethods)}
            isOpen={showOtherMethods}
          />
        </ButtonWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > div:not(:last-child) {
    margin-bottom: 8px;
  }
`

const ButtonWrapper = styled.div`
  text-align: left;
  margin-left: 10px;
`
