import { all, put, spawn, takeEvery } from 'redux-saga/effects'

import createDataModule from 'utils/moduleCreator'

import type { StripeRegionCode } from '../ui/types'
import { GlobalCustomerData, fetchCustomer } from './stripeCustomer'

const {
  api: { billing },
} = __CONFIG__

export const updateCustomerDataKey = 'updatedStripeCustomer'

const dataModule = createDataModule<GlobalCustomerData | undefined>(
  updateCustomerDataKey,
  billing.paths.customer,
  billing.base
)

export const {
  selectIsLoading: selectIsCustomerUpdateLoading,
  selectData: selectUpdateCustomerData,
  UPDATE_DONE: UPDATED_CARD,
  reducer: updateStripeCustomerReducer,
} = dataModule

export const updateCustomer = (
  data: Record<string, unknown>,
  region: StripeRegionCode
) => {
  return {
    type: `${updateCustomerDataKey}/UPDATE`,
    payload: data,
    urlPath: `?region=${region}`,
  }
}

function* refreshCustomerSaga() {
  yield put(fetchCustomer())
}

function* subscribeToRefreshCustomerSaga() {
  yield takeEvery(UPDATED_CARD, refreshCustomerSaga)
}

export function* updateStripeCustomerRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToRefreshCustomerSaga)])
}
