import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import { FormattedMessage, useIntl } from 'react-intl'
import { isEmpty } from 'lodash-es'
import { FormikProps, Form as ImportedForm, withFormik } from 'formik'

import { CheckmarkIcon } from '@ubnt/icons'
import Loader from '@ubnt/ui-components/Loader/Loader'
import ValidationMessage from '@ubnt/ui-components/ValidationMessage/ValidationMessage'
import Button from '@ubnt/ui-components/Button/Button'
import { Checkbox } from '@ubnt/ui-components'
import designToken from '@ubnt/ui-components/styles/designToken'
import { api } from 'api'

import Yup from 'validators/yupLocaleConfig'
import { RootState } from 'types/types'
import { LegalInfo, selectIsLegalLoading, selectLegalInfo } from 'modules/legal'
import authorizedRedirect from 'components/authorizedRedirect'
import PublicPage from 'pages/PublicPage'
import styled from 'theme/styled'
import usePrevious from 'utils/usePrevious'
import { PASSWORD_MIN_LENGTH } from 'utils/passwordContants'
import { CheckboxReCaptcha } from 'features/RecaptchaCheckbox/CheckboxReCaptcha'
import { SsoErrorCode } from 'features/RecaptchaCheckbox/types'
import { useReCaptcha } from 'components/ReCaptcha'
import { Input, ValidationError } from 'components/Input'
import { isAxiosError } from 'utils/isAxiosError'

import {
  createAccount,
  resetApiErrors,
  selectCreateAccountErrors,
  selectIsCreateAccountLoading,
} from '../modules/createAccount'
import PasswordFeedback, {
  PasswordFeedbackData,
  passwordScoreBarWidth,
  passwordScoreColor,
  passwordScoreText,
} from './PasswordFeedback'

interface Props {
  isLoading?: boolean
  legalInfo: LegalInfo
  handleSubmit: typeof createAccount
  createAccountErrors: ReturnType<typeof selectCreateAccountErrors>
}

interface FormValues {
  username?: string
  email?: string
  password?: string
  password_confirm?: string
  phone?: string
  curr_terms_rev?: string
  curr_privacy_rev?: string
  curr_terms_aircrm_rev?: string
  mailinglist?: boolean
  site_key?: string
  agreedToTerms?: boolean
}

const { GOOGLE_RECAPTCHA_SECRET_SCORE, GOOGLE_RECAPTCHA_SECRET_CHALLENGE } =
  __CONFIG__

const CheckLoading = () => <Loader size="tiny" type="spinner" />

const RegisterForm: React.FC<Props & FormikProps<FormValues>> = ({
  errors,
  values,
  touched,
  isLoading,
  handleBlur,
  handleChange,
  handleSubmit,
  createAccountErrors,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const { ref: recaptchaRef } = useReCaptcha()
  const [isCaptchaLoading, setCaptchaLoading] = useState(false)
  const [usernameChecking, setUsernameChecking] = useState(false)
  const [passwordFocused, setPasswordFocused] = useState(false)
  const [usernameError, setUsernameError] = useState<string | null>(null)
  const [emailChecking, setEmailChecking] = useState(false)
  const [emailError, setEmailError] = useState<string | null>(null)
  const [passwordData, setPasswordData] = useState<PasswordFeedbackData | null>(
    null
  )
  const [passwordConfirmError, setPasswordConfirmError] =
    useState<boolean>(false)
  const [passwordCheckLoading, setPasswordCheckLoading] =
    useState<boolean>(false)

  const prevPassword = usePrevious(values.password)

  const apiErrors = useMemo(
    () =>
      createAccountErrors.error_code ===
      SsoErrorCode.CHALLENGE_RECAPTCHA_TOKEN_REQUIRED
        ? ''
        : createAccountErrors,
    [createAccountErrors]
  )

  const checkBoxCaptchaIsVisible = useMemo(
    () =>
      createAccountErrors.error_code ===
      SsoErrorCode.CHALLENGE_RECAPTCHA_TOKEN_REQUIRED,
    [createAccountErrors]
  )

  const confirmPasswordSame = useMemo(() => {
    return (
      (touched.password_confirm || !!values.password_confirm?.length) &&
      !errors.password_confirm &&
      values.password_confirm === values.password
    )
  }, [touched, values, errors])

  const getError = useMemo(() => {
    if (
      !isEmpty(apiErrors) &&
      !apiErrors.username &&
      !apiErrors.email &&
      !apiErrors.password
    ) {
      let errorMessage = intl.formatMessage({ id: 'REGISTER_FALLBACK_ERROR' })
      if (apiErrors.detail === 'invalid captcha') {
        errorMessage = intl.formatMessage({
          id: 'COMMON_AUTH_REGISTER_RECAPTCHA_ERROR',
        })
      }
      if (apiErrors.fields_non_unique?.length) {
        errorMessage = intl.formatMessage(
          {
            id: 'COMMON_AUTH_REGISTER_FIELDS_ERROR',
          },
          { field: apiErrors.fields_non_unique[0] }
        )
      }
      return (
        <ValidationErrorWrapper>
          <ValidationError>{errorMessage}</ValidationError>
        </ValidationErrorWrapper>
      )
    }
  }, [apiErrors, intl])

  const checkUsername = useDebouncedCallback(async () => {
    if (!values.username) return
    setUsernameChecking(true)
    try {
      await api.checkUsername(values.username)
      setUsernameError(
        intl.formatMessage({
          id: 'COMMON_AUTH_REGISTER_USERNAME_TAKEN',
        })
      )
    } catch (e) {
      setUsernameError(null)
    }
    setUsernameChecking(false)
  }, 200)

  const checkEmail = useDebouncedCallback(async () => {
    const validator = Yup.string().email()
    if (!values.email || !validator.isValidSync(values.email)) return
    setEmailChecking(true)
    try {
      await api.checkEmail(encodeURIComponent(values.email!))
      setEmailError(
        intl.formatMessage({
          id: 'COMMON_AUTH_REGISTER_EMAIL_TAKEN',
        })
      )
    } catch (e) {
      setEmailError(null)
    }
    setEmailChecking(false)
  }, 200)

  const checkPassword = useDebouncedCallback(async () => {
    const validator = Yup.string()
    if (
      !values.password ||
      !validator.isValidSync(values.password) ||
      values.password.length < 12
    )
      return
    try {
      const userInputs: string[] = [
        values?.username,
        values?.email,
        values?.mailinglist?.toString(),
        values?.agreedToTerms?.toString(),
      ].filter((userInput): userInput is string => !!userInput)

      const { is_acceptable_password, suggestions, warning, score } =
        await api.checkPassword(values.password, userInputs)
      setPasswordCheckLoading(false)
      warning && suggestions.unshift(warning)
      score !== undefined &&
        setPasswordData({
          is_acceptable_password: is_acceptable_password ?? null,
          suggestions: suggestions ?? null,
          score: score,
          barWidth: passwordScoreBarWidth(score),
          feedbackColor: passwordScoreColor(score),
          text: (
            <FormattedMessage
              id={passwordScoreText(score)}
              values={{
                b: (text: string) => <b className="intl-message">{text}</b>,
              }}
            />
          ),
          error: null,
        })
    } catch (e) {
      if (isAxiosError(e)) {
        setPasswordCheckLoading(false)
        setPasswordData({
          is_acceptable_password: null,
          suggestions: null,
          score: null,
          barWidth: null,
          feedbackColor: null,
          text: null,
          error: intl.formatMessage({
            id: 'COMMON_AUTH_PASSWORD_CHANGE_FAILED',
          }),
        })
      }
    }
  }, 200)

  const checkPasswordConfirmError = useCallback(() => {
    if (
      values?.password_confirm?.length &&
      values.password_confirm !== values.password
    ) {
      setPasswordConfirmError(true)
    } else {
      setPasswordConfirmError(false)
    }
  }, [values.password, values.password_confirm])

  useEffect(() => {
    if (apiErrors.username) {
      setUsernameError(
        intl.formatMessage({
          id: 'COMMON_AUTH_REGISTER_USERNAME_GENERIC_ERROR',
        })
      )
    }
    if (apiErrors.email) {
      setEmailError(
        intl.formatMessage({
          id: 'COMMON_AUTH_REGISTER_EMAIL_GENERIC_ERROR',
        })
      )
    }
  }, [
    setUsernameError,
    setEmailError,
    apiErrors.email,
    apiErrors.username,
    intl,
  ])

  useEffect(() => {
    checkUsername()
  }, [checkUsername, values?.username])

  useEffect(() => {
    checkEmail()
  }, [checkEmail, values?.email])

  useEffect(() => {
    if (passwordFocused && values.password !== prevPassword) {
      checkPassword()
    }
  }, [checkPassword, passwordFocused, prevPassword, values.password])

  useEffect(() => {
    if (values?.password && values?.password.length >= PASSWORD_MIN_LENGTH) {
      setPasswordCheckLoading(true)
    }
  }, [checkPassword, values?.password])

  useEffect(() => {
    checkPasswordConfirmError()
  }, [checkPasswordConfirmError, values.password])

  useEffect(() => {
    if (apiErrors && Object.keys(apiErrors).length) {
      recaptchaRef?.reset()
    }
  }, [apiErrors, recaptchaRef])

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null
    if (isCaptchaLoading) {
      // make sure that captcha stops loading in case the challenge window has been closed
      timer = setTimeout(
        () => isCaptchaLoading && setCaptchaLoading(false),
        1000
      )
    }
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [isCaptchaLoading, setCaptchaLoading])

  const handleSubmitWithRecaptcha = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      if (Object.keys(errors).length || !recaptchaRef) {
        return handleSubmit(e)
      }
      setCaptchaLoading(true)
      const token = await recaptchaRef?.executeAsync()
      setCaptchaLoading(false)
      if (token) {
        return dispatch(createAccount({ ...values, captcha: token }))
      }
    },
    [errors, recaptchaRef, handleSubmit, dispatch, values]
  )

  const handleCheckboxRecaptcha = (token: string) => {
    dispatch(
      createAccount({
        ...values,
        site_key: GOOGLE_RECAPTCHA_SECRET_CHALLENGE,
        captcha: token,
      })
    )
  }

  const handleChangeWithReset = useCallback(
    (e: React.ChangeEvent<any>) => {
      if (Object.keys(apiErrors).length) {
        dispatch(resetApiErrors())
      }
      handleChange(e)
    },
    [apiErrors, dispatch, handleChange]
  )

  const termsNotChecked = useMemo(
    () => !!(touched.agreedToTerms && errors.agreedToTerms),
    [touched, errors]
  )

  const passwordLongEnough = useMemo(() => {
    if (values.password) {
      if (values.password?.length >= PASSWORD_MIN_LENGTH) return true
      return false
    }
  }, [values.password])

  const invalidMessage = useMemo(() => {
    if (touched.password && errors.password) {
      return errors.password
    }
    if (passwordData?.error) {
      return passwordData.error
    }
  }, [errors.password, passwordData, touched.password])

  return (
    <PublicPage
      title={intl.formatMessage({
        id: 'COMMON_AUTH_REGISTER_HEADER',
      })}
      registerPage
    >
      <Form
        data-testid="registerForm"
        id="register-form"
        onSubmit={handleSubmitWithRecaptcha}
      >
        <StyledInput
          data-testid="username"
          label={intl.formatMessage({ id: 'COMMON_LABEL_USERNAME' })}
          name="username"
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
          invalid={
            (touched.username && errors.username) ||
            (!usernameChecking && usernameError) ||
            undefined
          }
          iconAfter={usernameChecking ? CheckLoading : undefined}
          autoCapitalize="none"
          autoCorrect="off"
          full
          variant="secondary"
        />
        <StyledInput
          data-testid="email"
          label={intl.formatMessage({ id: 'COMMON_LABEL_EMAIL' })}
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          invalid={
            (touched.email && errors.email) ||
            (!emailChecking && emailError) ||
            undefined
          }
          iconAfter={emailChecking ? CheckLoading : undefined}
          full
          variant="secondary"
        />
        <StyledInput
          data-testid="password"
          type="password"
          name="password"
          label={intl.formatMessage({ id: 'COMMON_LABEL_PASSWORD' })}
          value={values.password}
          onChange={handleChangeWithReset}
          onFocus={() => setPasswordFocused(true)}
          helperMessage={
            !passwordLongEnough ? (
              intl.formatMessage(
                {
                  id: 'COMMON_AUTH_REGISTER_PASSWORD_LABEL_HELPER',
                },
                { passwordLength: PASSWORD_MIN_LENGTH }
              )
            ) : (
              <PasswordFeedback
                passwordLongEnough={passwordLongEnough ?? false}
                passwordCheckLoading={passwordCheckLoading}
                passwordData={passwordData}
                apiErrors={apiErrors}
              />
            )
          }
          onBlur={handleBlur}
          invalid={invalidMessage}
          passwordToggle
          full
          variant="secondary"
        />

        <StyledInput
          data-testid="confirmPassword"
          type="password"
          name="password_confirm"
          label={intl.formatMessage({ id: 'COMMON_LABEL_PASSWORD_CONFIRM' })}
          value={values.password_confirm}
          onChange={(e) => {
            setPasswordConfirmError(false)
            handleChangeWithReset(e)
          }}
          onBlur={(e) => {
            checkPasswordConfirmError()
            handleBlur(e)
          }}
          invalid={
            (touched.password_confirm && errors.password_confirm) ||
            (passwordConfirmError &&
              intl.formatMessage({
                id: 'COMMON_AUTH_REGISTER_PASSWORD_NOT_SAME',
              }))
          }
          contentAfter={
            confirmPasswordSame && (
              <StyledCheckIcon variant="twoTone" isActive />
            )
          }
          passwordToggle
          full
          variant="secondary"
        />
        <StyledCheckbox
          data-testid="agreeToTerms"
          id="agreedToTerms"
          checked={!!values.agreedToTerms}
          onChange={handleChange}
        >
          <CheckDescription>
            <FormattedMessage
              id="COMMON_AUTH_REGISTER_LABEL_TERMS"
              values={{
                termsOfConditions: (
                  <a
                    href="https://www.ui.com/legal/termsofservice/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FormattedMessage id="COMMON_LABEL_TERMS_OF_SERVICE" />
                  </a>
                ),
                privacyPolicy: (
                  <a
                    href="https://www.ui.com/legal/privacypolicy/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FormattedMessage id="COMMON_LABEL_PRIVACY_POLICY" />
                  </a>
                ),
              }}
            />
          </CheckDescription>
        </StyledCheckbox>
        <StyledCheckbox
          data-testid="subscribeToMailingList"
          id="mailinglist"
          checked={!!values.mailinglist}
          onChange={handleChange}
        >
          <CheckDescription>
            <FormattedMessage id="COMMON_AUTH_REGISTER_LABEL_NEWSLETTER" />
          </CheckDescription>
        </StyledCheckbox>
        {getError}
        {termsNotChecked && (
          <ValidationMessage>{errors.agreedToTerms}</ValidationMessage>
        )}

        <StyledButton
          data-testid="createAccountButton"
          disabled={
            isLoading ||
            !!Object.keys(errors).length ||
            !!usernameError ||
            !!emailError ||
            passwordCheckLoading ||
            !passwordData?.score ||
            passwordData.score < 3 ||
            passwordConfirmError ||
            !confirmPasswordSame ||
            !passwordLongEnough ||
            checkBoxCaptchaIsVisible
          }
          type="submit"
          variant="primary"
          loader={isLoading || isCaptchaLoading ? 'dots' : undefined}
          full
        >
          {intl.formatMessage({
            id: 'COMMON_AUTH_REGISTER_LABEL_REGISTER',
          })}
        </StyledButton>
        {checkBoxCaptchaIsVisible && (
          <CheckboxReCaptcha onVerify={handleCheckboxRecaptcha} />
        )}
      </Form>
      <LoginWrapper>
        <LoginText>
          {intl.formatMessage({
            id: 'COMMON_SSO_LABEL_ALREADY_HAVE_ACCOUNT',
          })}
          &nbsp;
          <LoginLink to="/login">
            {intl.formatMessage({ id: 'COMMON_AUTH_ACTION_SIGN_IN' })}
          </LoginLink>
        </LoginText>
      </LoginWrapper>
    </PublicPage>
  )
}

const enchance = withFormik<Props, FormValues>({
  mapPropsToValues: ({ legalInfo }) => ({
    username: '',
    email: '',
    password: '',
    password_confirm: '',
    mailinglist: false,
    curr_terms_rev: legalInfo.rev_terms,
    curr_privacy_rev: legalInfo.rev_privacy,
    curr_terms_aircrm_rev: legalInfo.rev_terms_aircrm,
    agreedToTerms: false,
    site_key: GOOGLE_RECAPTCHA_SECRET_SCORE,
  }),
  enableReinitialize: true,
  handleSubmit: (values, { props: { handleSubmit } }) => handleSubmit(values),
  validationSchema: Yup.object().shape({
    username: Yup.string()
      .required()
      .min(1)
      .max(30)
      .username()
      .label('COMMON_LABEL_USERNAME'),
    email: Yup.string().required().email().label('COMMON_LABEL_EMAIL'),
    password: Yup.string()
      .required()
      .min(PASSWORD_MIN_LENGTH)
      .label('COMMON_LABEL_PASSWORD'),
    password_confirm: Yup.string()
      .required()
      .label('COMMON_LABEL_PASSWORD_CONFIRM'),
    agreedToTerms: Yup.boolean()
      .oneOf([true], 'Please agree to the terms and conditions.')
      .required(),
    captcha: Yup.string().recaptcha(),
  }),
})

const mapsStateToProps = (state: RootState) => ({
  isLoading: selectIsCreateAccountLoading(state) || selectIsLegalLoading(state),
  legalInfo: selectLegalInfo(state),
  createAccountErrors: selectCreateAccountErrors(state),
})

const mapDispatchToProps = {
  handleSubmit: createAccount,
}

export default authorizedRedirect(
  connect(mapsStateToProps, mapDispatchToProps)(enchance(RegisterForm))
)

const ValidationErrorWrapper = styled.div`
  margin: 20px 0;
`

const StyledInput = styled(Input)`
  height: 48px;
  margin-bottom: ${designToken['desktop-spacing-base-03']};
  > div {
    > span {
      font: ${designToken['desktop-typography-caption']};
    }
  }
`

const Form = styled(ImportedForm)`
  display: flex;
  flex-direction: column;
  > div {
    margin: 0;
  }
`

const CheckDescription = styled.div`
  font-size: 12px;
  line-height: 20px;
`

const StyledButton = styled(Button)`
  height: 40px;
`

const LoginWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  background-color: ${designToken['desktop-color-neutral-01-light']};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  justify-self: flex-end;
`

const LoginLink = styled(Link)`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.ublue06};
`

const LoginText = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
  text-align: center;
`

const StyledCheckIcon = styled(CheckmarkIcon)`
  position: absolute;
  transform: translate(-10%, -10%);
`

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 24px;
`
