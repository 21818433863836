import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import Dropdown from '@ubnt/ui-components/Dropdown/Dropdown'

import {
  SupportedLanguages,
  changeLanguageAction,
  selectLanguage,
  selectLanguageOptions,
} from 'modules/locale'
import { selectProfileIsLoading } from 'modules/profile'
import { GridWrapper, InputLabel } from 'pages/profile/Profile.styles'

const SelectLanguage = () => {
  const dispatch = useDispatch()
  const selectedLanguage = useSelector(selectLanguage)
  const isLoading = useSelector(selectProfileIsLoading)
  const languageOptions = useSelector(selectLanguageOptions)

  const intl = useIntl()

  const onLanguageChange = useCallback(
    (language: SupportedLanguages) => {
      dispatch(changeLanguageAction(language))
    },
    [dispatch]
  )

  return (
    <>
      <GridWrapper>
        <InputLabel>
          {intl.formatMessage({ id: 'COMMON_LABEL_LANGUAGE' })}
        </InputLabel>
        <Dropdown
          variant="secondary"
          id="language"
          onChange={(language: any) => onLanguageChange(language.value)}
          value={selectedLanguage}
          disabled={isLoading}
          options={languageOptions}
          width="100%"
        />
      </GridWrapper>
    </>
  )
}

export default SelectLanguage
