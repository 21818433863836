import React, { Dispatch, SetStateAction } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { Tooltip } from '@ubnt/ui-components'
import { AddWithCircleIcon } from '@ubnt/icons'

import { selectCustomerCards } from 'features/payment-methods/modules/fetchCards'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/fullSubscriptions'
import styled from 'theme/styled'

import CardsPopover from '../../CardsPopover'
import { useSubscriptionPaymentMethods } from '../../hooks/useSubscriptionPaymentMethod'
import { AddWrapper, StyledTextSmall as TextSmall } from '../Styles'

export const SubscriptionCard: React.FC<{
  fullSubscription: FullSubscription
  cardPopoverOpen: null | number
  setCardPopoverOpen: Dispatch<SetStateAction<number | null>>
  id: number
  isTalk?: boolean
}> = ({
  fullSubscription,
  cardPopoverOpen,
  setCardPopoverOpen,
  id,
  isTalk,
}) => {
  const { addPaymentMethod } = useSubscriptionPaymentMethods()
  const cards = useSelector(selectCustomerCards)

  return (
    <>
      {cards.length > 0 ? (
        <CardsPopover
          subscription={fullSubscription}
          popoverOpen={cardPopoverOpen}
          setPopoverOpen={setCardPopoverOpen}
          id={id}
          isTalk={isTalk}
        />
      ) : (
        <Tooltip
          style={{ zIndex: 100 }}
          description={
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_NO_METHOD_POPOVER" />
          }
          width={250}
        >
          <AddWrapper onClick={() => addPaymentMethod(fullSubscription.region)}>
            <AddWithCircleIcon width={16} isActive />
            <StyledTextSmall>
              <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_POPOVER_ADD" />
            </StyledTextSmall>
          </AddWrapper>
        </Tooltip>
      )}
    </>
  )
}

const StyledTextSmall = styled(TextSmall)`
  color: ${({ theme }) => theme.ublue06};
`
