import React, { Fragment, useEffect, useMemo, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import { ExternalLink } from 'utils/ExternalLink'
import { BubbleWrapper } from 'components/support/Wrappers'

import { Description } from '../support.styles'
import { BubbleProps } from './types'
import { Country, OrderIssue, StoreRegionFaqMap } from './config/types'

export const InfoBubble: React.FC<BubbleProps> = ({ values, shouldScroll }) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll, values.orderIssue])

  const orderReturnText = useMemo(() => {
    return values.country === Country.usa
      ? 'SUPPORT_STORE_FORM_RETURN_USA'
      : 'SUPPORT_STORE_FORM_RETURN'
  }, [values.country])

  const faqText = useMemo(() => {
    return values.country === Country.usa
      ? 'SUPPORT_STORE_FORM_FAQ_USA'
      : 'SUPPORT_STORE_FORM_FAQ'
  }, [values.country])

  const createExternalLink = (link: string, label: string) => {
    return () => <ExternalLink link={link}>{label}</ExternalLink>
  }

  return (
    <BubbleWrapper $isVisible ref={ref}>
      <Description>
        {values.orderIssue === OrderIssue.productAvailability && (
          <Fragment>
            <FormattedMessage
              id="SUPPORT_STORE_FORM_INVENTORY"
              values={{ b: (text: string) => <b>{text}</b> }}
            />
            <br />
            <br />
            <FormattedMessage id="SUPPORT_STORE_FORM_RESTOCK" />
          </Fragment>
        )}
        {values.orderIssue === OrderIssue.returnOrder && (
          <Fragment>
            <FormattedMessage
              id={orderReturnText}
              values={{
                a: createExternalLink(
                  'https://store.ui.com/account',
                  'store.ui.com/account'
                ),
              }}
            />
            <br />
            <br />
            <FormattedMessage
              id={faqText}
              values={{
                a: createExternalLink(
                  StoreRegionFaqMap[values.country as Country],
                  'FAQ page'
                ),
              }}
            />
          </Fragment>
        )}
        {values.orderIssue === OrderIssue.taxExemption && (
          <FormattedMessage id="SUPPORT_STORE_FORM_TAX_EXEMPTION" />
        )}
      </Description>
    </BubbleWrapper>
  )
}
