import React from 'react'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'

import {
  DEFAULT_LANGUAGE,
  selectLanguage,
  selectMessages,
} from 'modules/locale'
import { RootState } from 'types/types'

const AppIntlProvider: React.FC<any> = (props) => {
  return <IntlProvider {...props} />
}

const mapStateToProps = (state: RootState) => ({
  locale: selectLanguage(state),
  messages: selectMessages(state),
  defaultLocale: DEFAULT_LANGUAGE,
  textComponent: React.Fragment,
})

export default connect(mapStateToProps)(AppIntlProvider)
