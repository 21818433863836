import React from 'react'
import { Button, Text as TextBase } from '@ubnt/ui-components'
import { FormattedMessage } from 'react-intl'
import {
  ContentBlock,
  ContentWrapper,
  DisclaimerContainer,
  Header,
  IssueContainer,
  StyledLink,
  StyledRedditLogo,
  Text,
  Wrapper,
} from './styles'
import { IQuickSupportContentProps, PlatformType } from './types'
import { CommunityPlaceholder } from './CommunityPlaceholder'
import { XLogo } from './XLogo'
import { getPlatformTitle } from './utils'
import { FacebookLogo } from './FacebookLogo'

export const ICON_MAP = {
  [PlatformType.Reddit]: <StyledRedditLogo />,
  [PlatformType.Facebook]: <FacebookLogo />,
  [PlatformType.X]: <XLogo />,
  [PlatformType.UICommunity]: <CommunityPlaceholder />,
} satisfies Record<PlatformType, React.ReactNode>

export const QuickSupportContent: React.FC<IQuickSupportContentProps> = ({
  data,
  isLoading,
  onSubmit,
}) => {
  return (
    <Wrapper>
      <ContentWrapper>
        <ContentBlock>
          {ICON_MAP[data.platform]}
          <Header>
            <FormattedMessage
              id="SUPPORT_QUICK_LINK_HEADER_TEXT"
              values={{
                platform: getPlatformTitle(data.platform),
              }}
            />
          </Header>
        </ContentBlock>

        <ContentBlock>
          <IssueContainer>
            <TextBase size="header-xs" weight="bold" color="primary">
              {data.title}
            </TextBase>
            <TextBase size="body" color="secondary">
              {data.description}
            </TextBase>
            <StyledLink
              href={data.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data.link}
            </StyledLink>
          </IssueContainer>
        </ContentBlock>

        <ContentBlock>
          <DisclaimerContainer>
            <Text secondary>
              <FormattedMessage id="SUPPORT_QUICK_LINK_DISCLAIMER" />
            </Text>
          </DisclaimerContainer>
        </ContentBlock>
        <Button
          loader={isLoading ? 'dots' : undefined}
          variant="primary"
          onClick={onSubmit}
        >
          <FormattedMessage id="COMMON_ACTION_SUBMIT" />
        </Button>
      </ContentWrapper>
    </Wrapper>
  )
}
