import React, { useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { FormikProps, withFormik } from 'formik'
import { History } from 'history'

import {
  AuthError,
  loginMFA,
  selectErrors,
  selectIsLoading,
} from 'features/auth/modules/auth'
import { LoginMFAData, RootState } from 'types/types'
import authorizedRedirect from 'components/authorizedRedirect'
import styled from 'theme/styled'
import { size, space, typography } from 'theme'
import awaitHand from 'assets/images/pushHandAwait.png'
import deniedHand from 'assets/images/pushHandDenied.png'
import Button from '@ubnt/ui-components/Button/Button'
import ButtonLink from '@ubnt/ui-components/Button/ButtonLink'
import { Link as ImportedLink } from 'react-router-dom'
import logo from 'assets/svgs/uiIconRoundedNew.svg'
import {
  pushLoginCancel,
  resetLoginPushErrors,
  selectLoginPushErrors,
} from 'pages/security/components/Push/modules/loginPush'
import { TokenInput } from 'components/TokenInput'

import { AuthForm } from '../styles'

interface Props {
  pushErrors?: { ERRCODE: string; detail: string }
  apiErrors?: AuthError
  handleLogin: typeof loginMFA
  history: History
  handleResend: () => void
  isLoading: boolean
}
type FormValues = LoginMFAData
const LoginMFAPush: React.FC<Props & FormikProps<FormValues>> = ({
  pushErrors,
  children,
  handleResend,
  values,
  setFieldValue,
  submitForm,
  apiErrors,
  isLoading,
}) => {
  const dispatch = useDispatch()
  const [input, setInput] = useState<'prompt' | 'manual'>('prompt')
  const [resendTimeLeft, setResendTimeLeft] = useState<number>(10)
  const [resendTimer, setResendTimer] = useState<
    ReturnType<typeof setTimeout> | undefined
  >()
  const [manualError, setManualError] = useState<undefined | string>()

  useEffect(() => {
    setInput('prompt')
    dispatch(resetLoginPushErrors())
  }, [dispatch])

  const renderHandImage = useMemo(() => {
    switch (pushErrors?.ERRCODE) {
      case 'MFA_PUSH_LOGIN_APPROVAL_DENIED':
      case 'NOT_FOUND':
      case 'BAD_REQUEST':
        return deniedHand
      default:
        return awaitHand
    }
  }, [pushErrors])

  useEffect(() => {
    return () => resendTimer && clearTimeout(resendTimer)
  }, [resendTimer])

  useEffect(() => {
    return () => {
      dispatch(pushLoginCancel())
    }
  }, [dispatch])

  useEffect(() => {
    if (resendTimeLeft > 0) {
      const timer = setTimeout(
        () => setResendTimeLeft(resendTimeLeft - 1),
        1000
      )
      setResendTimer(timer)
    }
  }, [dispatch, resendTimeLeft])

  const description = useMemo(() => {
    switch (pushErrors?.ERRCODE) {
      case 'MFA_PUSH_LOGIN_APPROVAL_DENIED':
        return `LOGIN_MFA_PUSH_APPROVAL_DENIED`
      case 'NOT_FOUND':
        return `LOGIN_MFA_PUSH_NOT_FOUND`
      case 'BAD_REQUEST':
        return `LOGIN_MFA_PUSH_BAD_REQUEST`
      default:
        return 'LOGIN_MFA_PUSH_DESCRIPTION_PROMPT'
    }
  }, [pushErrors])

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setResendTimeLeft(10)
    dispatch(pushLoginCancel())
    handleResend()
  }

  const toggleInput = () => {
    if (input === 'manual' && resendTimeLeft <= 0) {
      resendTimer && clearTimeout(resendTimer)
      setResendTimeLeft(10)
      dispatch(pushLoginCancel())
      handleResend()
    }
    setInput(input === 'prompt' ? 'manual' : 'prompt')
  }

  useEffect(() => {
    setManualError(apiErrors?.detail)
  }, [apiErrors?.detail])

  useEffect(() => {
    if (input === 'manual' && values.token.length === 6) {
      submitForm()
    }
  }, [input, submitForm, values.token])

  return (
    <>
      <AuthForm id="login-mfa-form" buttonAlign>
        {input === 'prompt' ? (
          <>
            <Description>
              <FormattedMessage id={description} />
            </Description>
            <ImageWrapper>
              <img
                src={renderHandImage}
                alt="UI Verify app"
                draggable="false"
              />
            </ImageWrapper>
            <StyledButton
              variant="primary"
              onClick={handleClick}
              disabled={
                resendTimeLeft > 0 || pushErrors?.ERRCODE === 'BAD_REQUEST'
              }
            >
              {resendTimeLeft > 0 ? (
                <FormattedMessage
                  id="SETTINGS_MFA_OPTIONS_RESEND_PUSH_TIMER"
                  values={{ seconds: resendTimeLeft }}
                />
              ) : (
                <FormattedMessage id="SETTINGS_MFA_OPTIONS_RESEND_PUSH" />
              )}
            </StyledButton>
          </>
        ) : (
          <>
            <Link to="/">
              <UiLogo src={logo} />
            </Link>
            <Description style={{ marginBottom: '40px' }}>
              <FormattedMessage
                id={pushErrors?.ERRCODE ?? 'LOGIN_MFA_PUSH_DESCRIPTION_MANUAL'}
              />
            </Description>
            <TokenInput
              currentError={manualError}
              setCurrentError={setManualError}
              disabled={values.token.length === 6}
              setFieldValue={setFieldValue}
            />
            <StyledButton
              variant="primary"
              loader={isLoading ? 'dots' : undefined}
              disabled={values.token.length < 6}
            >
              <FormattedMessage id="COMMON_ACTION_CONFIRM" />
            </StyledButton>
          </>
        )}

        {children}
      </AuthForm>
      <StyledButtonLink onClick={toggleInput}>
        {input === 'prompt' ? (
          <FormattedMessage id="LOGIN_MFA_PUSH_MANUAL" />
        ) : (
          <FormattedMessage id="LOGIN_MFA_PUSH_PROMPT" />
        )}
      </StyledButtonLink>
    </>
  )
}
const enchance = withFormik<Props, FormValues>({
  handleSubmit: (values, { props: { handleLogin, history } }) =>
    handleLogin(values, { history }),
  mapPropsToValues: () => ({
    token: '',
  }),
})
const mapStateToProps = (state: RootState) => ({
  apiErrors: selectErrors(state),
  pushErrors: selectLoginPushErrors(state),
  isLoading: selectIsLoading(state),
})
const mapDispatchToProps = {
  handleLogin: loginMFA,
}

export default authorizedRedirect(
  connect(mapStateToProps, mapDispatchToProps)(enchance(LoginMFAPush))
)

const ImageWrapper = styled.div`
  width: 144px;
  height: 266px;
  display: flex;
  justify-content: center;
  margin: ${space['desktop-spacing-base-06']} auto 0;
`

const Link = styled(ImportedLink)`
  display: flex;
  justify-content: center;
  margin-bottom: ${space['desktop-spacing-base-12']};
`

const UiLogo = styled.img`
  height: ${size['desktop-sizing-base-16']};
  width: ${size['desktop-sizing-base-16']};
`

const Description = styled.span`
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.text2};
  display: flex;
  justify-content: center;
`

const StyledButtonLink = styled(ButtonLink)`
  margin: ${space['desktop-spacing-base-06']} 0
    ${space['desktop-spacing-base-02']};
  font: ${typography['desktop-body']} !important;
`

const StyledButton = styled(Button)`
  height: ${size['desktop-sizing-base-10']};
  margin-top: 10px;
`
