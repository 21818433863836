import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentSessionLocation } from 'modules/session'

import { validStripeCountryMap } from './regions'
import {
  StripeCountryCode,
  StripeRegionCode,
  StripeRegionContextType,
} from './types'

export const StripeRegionContext = React.createContext<StripeRegionContextType>(
  {
    region: StripeRegionCode.US,
    setRegion: undefined,
    country: 'us',
    setCountry: undefined,
  }
)

const getRegion = (countryCode: StripeCountryCode): StripeRegionCode | null =>
  validStripeCountryMap.get(countryCode)?.region ?? null

const DEFAULT_REGION_CODE = StripeRegionCode.US

export const StripeRegionProvider: React.FC = ({ children }) => {
  const sessionLocation = useSelector(selectCurrentSessionLocation)

  const [country, setCountry] = useState<string>(sessionLocation)
  const [region, setRegion] = useState<StripeRegionCode>(
    getRegion(sessionLocation as StripeCountryCode) || DEFAULT_REGION_CODE
  )

  useEffect(() => {
    setCountry(sessionLocation)
    setRegion(
      getRegion(sessionLocation as StripeCountryCode) || DEFAULT_REGION_CODE
    )
  }, [sessionLocation])

  return (
    <StripeRegionContext.Provider
      value={{ region, setRegion, country, setCountry }}
    >
      {children}
    </StripeRegionContext.Provider>
  )
}
