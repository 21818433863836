import { spawn } from 'redux-saga/effects'
import { createSelector } from 'reselect'

import createDataModule from 'utils/moduleCreator'

const {
  api: { megaphone },
} = __CONFIG__

export const announcementsDataKey = 'announcements'

interface AnnouncementResponse {
  announcementId: string
  title: string
  description: string
  externalLink: string
  isRead: boolean
  media: { image?: string }
}

interface AnnouncementData {
  data: AnnouncementResponse[]
}

const dataModule = createDataModule<AnnouncementData>(
  announcementsDataKey,
  megaphone.paths.announcements,
  megaphone.base
)

export const {
  fetch: fetchAnnouncements,
  selectData,
  selectIsDone: fetchAnnouncementsDone,
  reducer: announcementsReducer,
  setData: setAnnouncementData,
} = dataModule

export const selectUnreadAnnouncements = createSelector(
  selectData,
  ({ data: announcements }: AnnouncementData) => {
    if (!announcements?.length) return []
    return announcements
      .filter((announcement: AnnouncementResponse) => !announcement.isRead)
      .map((announcement) => ({
        ...announcement,
        id: announcement.announcementId,
      }))
  }
)

export function* announcementsRootSaga() {
  yield spawn(dataModule.rootSaga)
}
