import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@ubnt/ui-components/Button'

import styled from 'theme/styled'
import { MFAAuthenticator, MFAType } from 'modules/mfa'
import { selectProfileData, updateProfileData } from 'modules/profile'

import { prepareData } from '../MultiFactor/prepareData'

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  margin-top: 30px;
`

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
`

const IconTypeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border: 1px solid ${(props) => props.theme.neutral04};
  border-radius: 8px;
  margin-right: 16px;
`

const Type = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: ${(props) => props.theme.text1};
`

const Description = styled.div`
  font-size: 13px;
  line-height: 16px;
  text-align: left;
  color: ${(props) => props.theme.neutral08};
`

const Primary = styled.div`
  color: ${(props) => props.theme.neutral08};
  padding: 0 5px;
  font-size: 13px;
  text-align: left;
`

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const TempEmailBadge = styled.div`
  font-size: 10px;
  color: white;
  background-color: ${(props) => props.theme.neutral05};
  border-radius: 10px;
  margin: auto 5px;
  padding: 1px 4px;
  @media (max-width: ${(p) => p.theme.media.mobileLarge}) {
    margin: 2px 0;
    width: 55px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: left;
  @media (max-width: ${(p) => p.theme.media.mobileLarge}) {
    flex-direction: column;
  }
`

const StyledImg = styled('img')<{ size?: string }>`
  width: ${(p) => p.size ?? '30px'};
  height: ${(p) => p.size ?? '30px'};
`

interface Props {
  authenticator: MFAAuthenticator
  defaultMFAId?: string | null
}

export const MultiFactorMethod: React.FC<Props> = ({
  authenticator,
  defaultMFAId,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const profileData = useSelector(selectProfileData)

  const preparedData = prepareData(authenticator, intl, dispatch)

  const isTemporaryEmail = () => {
    if (authenticator.type === 'email' && profileData?.email) {
      return authenticator.email === profileData.email
    } else {
      return false
    }
  }

  const setPrimary = useCallback(() => {
    const data = {
      default_mfa: authenticator.id,
    }
    dispatch(updateProfileData(data))
  }, [authenticator.id, dispatch])

  const renderAuthenticatorAction = useMemo(() => {
    if (authenticator.id === defaultMFAId)
      return (
        <Primary>
          {intl.formatMessage({ id: 'SETTINGS_MFA_PRIMARY_METHOD' })}
        </Primary>
      )
    if (
      authenticator.type === 'email' &&
      authenticator.email === profileData?.email
    )
      return (
        <Primary>
          {intl.formatMessage({ id: 'SETTINGS_MFA_TEMPORARY_METHOD' })}
        </Primary>
      )
    return (
      <Button variant="link" onClick={setPrimary}>
        {intl.formatMessage({ id: 'SETTINGS_MFA_SET_PRIMARY_METHOD' })}
      </Button>
    )
  }, [authenticator, defaultMFAId, intl, profileData?.email, setPrimary])

  if (!preparedData) {
    return <></>
  }

  const { iconLink, typeText, description, action, icon } = preparedData

  if (action.verify) {
    return <></>
  }
  return (
    <Wrapper>
      <InnerWrapper>
        <IconTypeWrapper>
          {iconLink && (
            <StyledImg
              src={iconLink}
              alt={typeText}
              size={authenticator.type === MFAType.push ? '48px' : undefined}
            />
          )}
          {icon && icon}
        </IconTypeWrapper>
        <OuterWrapper>
          <TitleWrapper>
            <Type>{typeText}</Type>
            {isTemporaryEmail() && (
              <TempEmailBadge>
                {intl.formatMessage({ id: 'SETTINGS_MFA_METHOD_EMAIL_TEMP' })}
              </TempEmailBadge>
            )}
          </TitleWrapper>
          <Description>{description}</Description>
        </OuterWrapper>
      </InnerWrapper>
      <InnerWrapper>{renderAuthenticatorAction}</InnerWrapper>
    </Wrapper>
  )
}
