import { FullSubscription } from 'features/subscriptions/fullSubscriptions/fullSubscriptions'

export const getDataLimit = (fullSubscription: FullSubscription) => {
  if (
    fullSubscription?.plan?.metadata?.quota &&
    fullSubscription?.plan?.metadata?.unit
  ) {
    return `${fullSubscription?.plan?.metadata?.quota} ${fullSubscription?.plan?.metadata?.unit}`
  }
  if (fullSubscription?.plan?.nickname?.includes('MONTHLY_')) {
    return fullSubscription?.plan?.nickname?.replace('MONTHLY_', '')
  }
  return fullSubscription?.plan?.nickname
}
