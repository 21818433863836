import React, { useContext, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@ubnt/ui-components/Button'
import DownloadIcon from '@ubnt/icons/DownloadIcon'

import { setVisibleModal } from 'modules/modals'
import usePrevious from 'utils/usePrevious'

import {
  Cell,
  CellContainer,
  CellWithRightMargin,
  Columns,
  LabelCell,
  Labels,
  Row,
  StyledCheckbox,
  Wrapper,
} from './Backups.styles'
import { BackupDetail } from './types'
import { BackupsContext } from './BackupsContext'
import { getBackupsForTable } from './utils'
import { ManageButton } from './ManageButton'
import { PASSWORD_MODAL_ID } from './SubmitPasswordModal'
import { selectDeletingBackups } from './module/backups'
import { useBackupsGroupItem } from './hooks/useBackupsGroupItem'
import { useToasts } from './useToasts'

interface Props {
  backups: BackupDetail[]
  deviceId: string
}

export const BackupsGroupItem: React.FC<Props> = ({ backups, deviceId }) => {
  const {
    isManageActive,
    setIsManageActive,
    checkedBackups,
    openDeleteBackupModal,
    handleCheckBackup,
    isAllChecked,
    handleCheckAllBackups,
    clearAllChecked,
    tableItems,
  } = useBackupsGroupItem(getBackupsForTable(backups, deviceId))

  const isDeleting = useSelector(selectDeletingBackups)

  const intl = useIntl()
  const dispatch = useDispatch()
  const { backupsDeleteSuccess } = useToasts()
  const { deletedBackups, downloadLoading } = useContext(BackupsContext)
  const hasDeleted = usePrevious(isDeleting)
  const supportsDownload: boolean =
    document.createElement('a').download !== 'undefined'

  useEffect(() => {
    if (hasDeleted && !isDeleting) {
      backupsDeleteSuccess(deletedBackups)
    }
  }, [deletedBackups, hasDeleted, intl, isDeleting, backupsDeleteSuccess])

  return (
    <Wrapper>
      <Row>
        <Labels>
          <LabelCell>
            <FormattedMessage id="SETTINGS_BACKUPS_TABLE_COLUMN_DATE_TIME" />
          </LabelCell>
          <LabelCell>
            <ManageButton
              checkedBackups={checkedBackups}
              isManageActive={isManageActive}
              setIsManageActive={setIsManageActive}
              setCheckedBackups={handleCheckAllBackups}
              clearAllChecked={clearAllChecked}
              isChecked={isAllChecked}
              handleDelete={openDeleteBackupModal}
            />
          </LabelCell>
        </Labels>
      </Row>
      {tableItems.map((item, index) => (
        <Row key={`${item.id}-${index}`}>
          <Columns>
            <CellContainer>
              <Button
                variant="inline"
                disabled={!supportsDownload || downloadLoading !== undefined}
                loader={
                  downloadLoading && downloadLoading === item.id
                    ? 'dots'
                    : undefined
                }
                onClick={() =>
                  dispatch(setVisibleModal(PASSWORD_MODAL_ID, { item }))
                }
              >
                <CellWithRightMargin>{item.time}</CellWithRightMargin>
                <DownloadIcon />
              </Button>
            </CellContainer>
            <Cell>
              {isManageActive ? (
                <StyledCheckbox
                  reversed
                  id={item.id}
                  checked={checkedBackups.includes(item.id)}
                  onChange={() => handleCheckBackup(item.id)}
                  labelClassName="backupsCheckboxLabel"
                />
              ) : (
                <></>
              )}
            </Cell>
          </Columns>
        </Row>
      ))}
    </Wrapper>
  )
}
