import { all, put, select, spawn, takeEvery } from 'redux-saga/effects'
import { api } from 'api'

import {
  MFAAuthenticator,
  fetchMFA,
  selectMFAAuthenticators,
} from 'modules/mfa'
import createDataModule from 'utils/moduleCreator'
import { SSOAPIError } from 'types/types'
import { closeVisibleModal } from 'modules/modals'
import { pushAdded } from 'modules/mfaAdded'
import { checkAuth } from 'features/auth/modules/auth'
import {
  resetMFADeactivatedPush,
  selectMFADeactivatedPush,
} from 'modules/mfaSetup'

import { resetInitiatePushData } from './initiatePush'
import { removeMFAPush } from './removePush'

const {
  api: { sso },
} = __CONFIG__

export const verifyPushDataKey = 'MFAVerifyPush'

const MFAPushActions = {
  fetch: `${verifyPushDataKey}/FETCH`,
}

type VerifyPushState = Record<string, never>

const dataModule = createDataModule<VerifyPushState, SSOAPIError>(
  verifyPushDataKey,
  `${sso.paths.mfa}/push`,
  api.ssoBase,
  undefined,
  undefined,
  undefined,
  true
)

const { fetchCancel, selectErrors, FETCH_DONE, FETCH_FAILED } = dataModule

export {
  fetchCancel as pushVerifyCancel,
  selectErrors as selectVerifyPushErrors,
}

export const verifyMFAPush = (id: string) => ({
  type: MFAPushActions.fetch,
  urlPath: `/${id}/poll-setup`,
})

function* pushDoneSaga() {
  const deactivatedPush: { id: string } = yield select(selectMFADeactivatedPush)
  const authenticators: MFAAuthenticator[] = yield select(
    selectMFAAuthenticators
  )
  const active = authenticators.filter((auth) => auth.status === 'active')
  if (active.length) {
    yield put(pushAdded(false))
  } else {
    yield put(pushAdded(true))
  }
  if (deactivatedPush.id) {
    yield put(removeMFAPush(deactivatedPush.id))
    yield put(resetMFADeactivatedPush())
  }
  yield put(closeVisibleModal())
  yield put(resetInitiatePushData())
  yield put(fetchMFA())
  yield put(checkAuth())
}

function* pushFailSaga() {
  yield put(resetInitiatePushData())
}

function* subscribeToPushDoneSaga() {
  yield takeEvery(FETCH_DONE, pushDoneSaga)
}

function* subscribeToPushFailedSaga() {
  yield takeEvery(FETCH_FAILED, pushFailSaga)
}

export function* verifyPushRootSaga() {
  yield all([
    spawn(dataModule.rootSaga),
    spawn(subscribeToPushDoneSaga),
    spawn(subscribeToPushFailedSaga),
  ])
}

export const verifyPushReducer = dataModule.reducer
