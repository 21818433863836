import React from 'react'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { FormikProps, withFormik } from 'formik'

import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { withConfirmation } from 'utils/withConfirmation'
import { ApiUpdateError, RootState } from 'types/types'
import { selectProfileErrors, selectProfileIsLoading } from 'modules/profile'
import { Input } from 'components/Input'
import { ModalForm } from 'components/ModalForm'
import Yup from 'validators/yupLocaleConfig'
import { InputGroup } from 'components/form'
import styled from 'theme/styled'
import { GenericModal } from 'components/generic-modal/GenericModal'

interface MappedProps {
  apiErrors: ApiUpdateError
}

interface Props extends ModalProps, MappedProps {
  isLoading?: boolean
  onConfirm?(currentPassword: string): void
}

interface FormValues {
  password?: string
}

const PasswordModal: React.FC<
  Props & FormikProps<FormValues> & WrappedComponentProps
> = ({
  apiErrors,
  isOpen,
  isLoading,
  onClose,
  intl,
  values,
  handleSubmit,
  handleBlur,
  handleChange,
  touched,
  errors,
}) => {
  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={<FormattedMessage id="COMMON_LABEL_CURRENT_PASSWORD" />}
      size="small"
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CANCEL" />,
          onClick: onClose,
          variant: 'default',
        },
        {
          text: <FormattedMessage id="COMMON_ACTION_SAVE" />,
          variant: 'primary',
          onClick: handleSubmit as any,
          disabled: !values.password || isLoading,
          type: 'submit',
        },
      ]}
    >
      <ModalForm onSubmit={handleSubmit} style={{ height: '100%' }}>
        <Wrapper>
          <InputGroupStyled full>
            <Input
              type="password"
              name="password"
              label={intl.formatMessage({
                id: 'COMMON_LABEL_CURRENT_PASSWORD',
              })}
              value={values.password || ''}
              disabled={isLoading}
              invalid={
                touched.password && (errors.password || apiErrors.detail)
              }
              onChange={handleChange}
              onBlur={handleBlur}
              passwordToggle
              focus
              full
            />
          </InputGroupStyled>
        </Wrapper>
      </ModalForm>
    </GenericModal>
  )
}

const enchance = withFormik<Props, FormValues>({
  handleSubmit: (values, { props: { onConfirm } }) =>
    values.password && onConfirm && onConfirm(values.password),
  validationSchema: Yup.object().shape({
    password: Yup.string().required().label('COMMON_LABEL_CURRENT_PASSWORD'),
  }),
})

const PasswordModalEnchanced = enchance(injectIntl(PasswordModal))

export const PASSWORD_MODAL_ID = 'PASSWORD_MODAL_ID'

const mapStateToProps = (state: RootState) => ({
  apiErrors: selectProfileErrors(state),
  isLoading: selectProfileIsLoading(state),
})

export const WrappedPasswordModal: any = connect(mapStateToProps)(
  ({ apiErrors, isLoading }) => (
    <ModalWrapper modalId={PASSWORD_MODAL_ID}>
      <PasswordModalEnchanced apiErrors={apiErrors} isLoading={isLoading} />
    </ModalWrapper>
  )
)

export const submitWithPasswordConfirmation = (formData: any, onConfirm: any) =>
  withConfirmation(
    PASSWORD_MODAL_ID,
    (currentPassword: string) =>
      onConfirm({ ...formData, old_password: currentPassword }),
    {},
    false
  )

const InputGroupStyled = styled(InputGroup)`
  span {
    top: 4px;
  }
`

const Wrapper = styled('div')`
  padding: 32px 0 48px 0;
  height: 100%;
  border-bottom: ${(p) => `1px solid ${p.theme.neutral03}`};
`
