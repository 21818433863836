import { all, put, race, spawn, take, takeEvery } from 'redux-saga/effects'

import createDataModule from 'utils/moduleCreator'
import { closeVisibleModal } from 'modules/modals'

import { fetchSubscriptions } from './subscriptions'
import { fetchInvoices } from './invoices'

const {
  api: { lte },
} = __CONFIG__

export const dataKey = 'deviceLte'

const getUrl = (url: string, item: { id?: string }) =>
  item && item.id ? `${url}/${item.id}` : url

const dataModule = createDataModule(
  dataKey,
  lte.paths.subscriptions,
  lte.base,
  undefined,
  getUrl
)

export const {
  remove: removeSubscription,

  selectIsLoading: selectIsLteLoading,
  selectErrors: selectLteErrors,
  resetErrors: resetLteErrors,

  reducer: deviceLteReducer,
} = dataModule

export function* removeSubscriptionSaga() {
  const { error } = yield race({
    success: take(dataModule.REMOVE_DONE),
    error: take(dataModule.REMOVE_FAILED),
  })

  if (error) {
    return
  }
  yield put(fetchSubscriptions())
  yield put(fetchInvoices())
  yield put(closeVisibleModal())
}

export function* subscribeToRemoveSubscriptionSaga() {
  yield takeEvery(dataModule.REMOVE, removeSubscriptionSaga)
}

export function* deviceLteRootSaga() {
  yield all([
    spawn(dataModule.rootSaga),
    spawn(subscribeToRemoveSubscriptionSaga),
  ])
}
