import React, { useCallback, useEffect, useRef, useState } from 'react'
import { isEqual, pick } from 'lodash-es'
import Text from '@ubnt/ui-components/SkeletonLoader/Skeleton/Text'
import { SkeletonLoader } from '@ubnt/ui-components/SkeletonLoader'
import { DropdownOption } from '@ubnt/ui-components/Dropdown'

import { Bubble } from 'components/support/Bubble'
import { BubbleWrapper, TilesWrapper } from 'components/support/Wrappers'
import { DeviceTile } from 'components/support/DeviceTile'
import { TilesLoader } from 'components/support/TilesLoader'
import usePrevious from 'utils/usePrevious'
import { ExtraSelector } from 'components/support/ExtraSelector'
import { DeviceDropdown } from 'components/support/DeviceDropdown'
import useGetDevices from 'components/support/useGetDevices'
import { formatMac } from 'utils/formatMac'

import { DROPDOWN_BREAKPOINT, ELEMENT_COUNT } from '../constants'
import {
  BubbleProps,
  FormSections,
  FormValue,
  ParsedNCAConsole,
  SupportConsole,
} from './types'
import { extraConsoles, otherConsole } from './config/ConfigOptions'
import { Assistance, Console, UniFiStateValues } from './config/types'
import { parseQueryValues } from './utils/parseQueryData'
import { getNextSection } from './handleClicks'
import { parseNCAdata } from './utils/parseNCAdata'

const isConsole = (value: FormValue): value is SupportConsole => {
  return (value as SupportConsole)?.id !== undefined
}

export const ConsoleBubble: React.FC<BubbleProps> = ({
  handleChange,
  values,
  shouldScroll,
  config,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll, values.assistance, values.trouble, values.adoptionTrouble])

  const [isOtherSelected, setIsOtherSelected] = useState(false)
  const [shouldRenderComponent, setShouldRenderComponent] = useState(true)

  const { consoles, isConsolesLoading } = useGetDevices()

  const userConsoles = parseNCAdata(consoles, config.console.options || [])

  const previousValues = usePrevious(values)
  const hasPageBeenRendered = useRef(false)

  useEffect(() => {
    if (
      !values.console &&
      !isEqual(
        pick(previousValues, [
          UniFiStateValues.ASSISTANCE,
          UniFiStateValues.ADOPTION_TROUBLE,
          values.assistance === Assistance.hwFailure
            ? ''
            : UniFiStateValues.TROUBLE,
        ]),
        pick(values, [
          UniFiStateValues.ASSISTANCE,
          UniFiStateValues.ADOPTION_TROUBLE,
          values.assistance === Assistance.hwFailure
            ? ''
            : UniFiStateValues.TROUBLE,
        ])
      )
    ) {
      setIsOtherSelected(false)
    }
  }, [previousValues, values, values.console])

  const { consoleId } = parseQueryValues(window.location.href)

  const handleClickOther = useCallback(
    (value: FormValue, section?: FormSections) => {
      const isHwFailure = values.assistance === Assistance.hwFailure

      if (isHwFailure) {
        handleChange(UniFiStateValues.CONSOLE, value, section)
      }

      if (values.console && !isHwFailure) {
        handleChange(UniFiStateValues.CONSOLE, undefined)
      }
      setIsOtherSelected(true)
    },
    [handleChange, values.assistance, values.console]
  )

  const onChange = (option: DropdownOption) => {
    const selectedConsole = userConsoles.find(
      ({ mac }) => formatMac(mac) === option.additionalInfo
    )
    if (selectedConsole) {
      return handleClick(selectedConsole)
    }
    return handleClick({ id: Console.other, ...option })
  }

  const handleClick = useCallback(
    (value: FormValue) => {
      const section = getNextSection(
        config,
        value,
        UniFiStateValues.CONSOLE,
        values
      )
      if (!isConsole(value))
        return handleChange(UniFiStateValues.CONSOLE, undefined, section)

      if (value.id === Console.other) {
        return handleClickOther(value, section)
      }

      const isExtraOption = extraConsoles.some(
        (option) => option.id === value.id
      )
      !isExtraOption && setIsOtherSelected(false)
      handleChange(UniFiStateValues.CONSOLE, value, section)
    },
    [config, handleChange, handleClickOther, values]
  )

  useEffect(() => {
    if (consoleId && !hasPageBeenRendered.current) {
      const preSelectedConsole = userConsoles.find(
        (console) => console.deviceId === consoleId
      )
      preSelectedConsole && handleClick(preSelectedConsole)
      hasPageBeenRendered.current = !!preSelectedConsole
    }
  }, [consoleId, handleClick, userConsoles])

  useEffect(() => {
    if (
      !isConsolesLoading &&
      !consoles.length &&
      shouldRenderComponent &&
      values.assistance === Assistance.hwFailure
    ) {
      setShouldRenderComponent(false)
      handleClick(undefined)
    }
    if (values.assistance !== Assistance.hwFailure && !shouldRenderComponent) {
      setShouldRenderComponent(true)
    }
  }, [
    consoles.length,
    handleClick,
    isConsolesLoading,
    shouldRenderComponent,
    values.assistance,
  ])

  if (isConsolesLoading) {
    return (
      <BubbleWrapper $isVisible ref={ref}>
        <SkeletonLoader>
          <Text width={220} />
        </SkeletonLoader>
        <TilesLoader numberOfTiles={ELEMENT_COUNT} />
      </BubbleWrapper>
    )
  }

  if (!shouldRenderComponent) return null

  const renderDevices = () => {
    if (!userConsoles.length && values.assistance !== Assistance.hwFailure) {
      return (
        <TilesWrapper>
          {extraConsoles.map((el) => (
            <DeviceTile
              key={el.id}
              label={el.label}
              icon={el.icon}
              onClick={() => handleClick(el)}
              isActive={el.id === values.console?.id}
            />
          ))}
        </TilesWrapper>
      )
    }
    const allConsoleOptions: ParsedNCAConsole[] = [
      ...userConsoles,
      otherConsole,
    ]
    if (allConsoleOptions.length > DROPDOWN_BREAKPOINT) {
      return (
        <DeviceDropdown
          userConsoles={userConsoles}
          otherOption={otherConsole}
          handleChange={onChange}
        />
      )
    }

    return (
      <TilesWrapper>
        {allConsoleOptions.map((el) => (
          <DeviceTile
            key={`${el.id}-${el.mac}`}
            icon={el.icon}
            label={el.label}
            onClick={() => handleClick(el)}
            deviceName={el.deviceName}
            isActive={
              el.id === Console.other
                ? isOtherSelected
                : el.mac === values.console?.mac
            }
          />
        ))}
      </TilesWrapper>
    )
  }

  const extraSelectorOptions = extraConsoles.map(({ id, label }) => ({
    id,
    children: label,
    key: id,
    onChange: () => handleClick({ id }),
    checked: values.console?.id === id,
  }))

  return (
    <Bubble question={config.console.fieldTitle} ref={ref}>
      {renderDevices()}
      {isOtherSelected && values.assistance !== Assistance.hwFailure && (
        <ExtraSelector items={extraSelectorOptions} />
      )}
    </Bubble>
  )
}
