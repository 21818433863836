import { billingSpecificService, console, userType } from './ConfigOptions'
import { FormConfig } from './types'

export const config: FormConfig = {
  billingSpecificService: {
    fieldId: 6179932795543,
    fieldTitle: 'SUPPORT_BILLING_SUBSCRIPTION_SERVICE',
    options: billingSpecificService,
    nextSection: { section: 'console' },
  },
  console: {
    fieldId: 1500011190041,
    fieldTitle: 'SUPPORT_BILLING_CONSOLE',
    options: console,
    nextSection: { section: 'userType' },
  },
  userType: {
    fieldId: 9383100357271,
    fieldTitle: 'SUPPORT_UNIFI_USER_TYPE',
    options: userType,
    nextSection: { section: 'description' },
  },
  description: {
    fieldId: 0,
    fieldTitle: 'SUPPORT_BILLING_DESCRIPTION',
    options: null,
    nextSection: null,
  },
  attachments: {
    fieldId: 10138128074775,
    fieldTitle: 'SUPPORT_ATTACHMENT_UPLOAD_RELEVANT',
    options: null,
    nextSection: null,
  },
  totalDevices: {
    fieldId: 18787629510807,
    fieldTitle: null,
    options: null,
    nextSection: null,
    hide: true,
  },
  mac: {
    fieldId: 1500011311782,
    fieldTitle: null,
    options: null,
    nextSection: null,
    hide: true,
  },
  version: {
    fieldId: 1500011311842,
    fieldTitle: null,
    options: null,
    nextSection: null,
    hide: true,
  },
  fwVersion: {
    fieldId: 10005292168215,
    fieldTitle: null,
    options: null,
    nextSection: null,
    hide: true,
  },
  releaseChannel: {
    fieldId: 1900002967245,
    fieldTitle: null,
    options: null,
    nextSection: null,
    hide: true,
  },
  totalConsoles: {
    fieldId: 10442648931991,
    fieldTitle: null,
    options: null,
    nextSection: null,
    hide: true,
  },
  rawData: {
    fieldId: 1500011191181,
    fieldTitle: null,
    options: null,
    nextSection: null,
    hide: true,
  },
}
