import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { BasicToast, Dropdown, Input, ToastContext } from '@ubnt/ui-components'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { useDebouncedCallback } from 'use-debounce'

import Yup from 'validators/yupLocaleConfig'
import {
  editCard,
  selectIsUpdateCardLoading,
} from 'features/payment-methods/modules/updateCard'
import { selectIsCustomerLoading } from 'features/stripe/modules/stripeCustomer'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { selectFetchAddressIsLoading } from 'features/addresses/module/addresses'
import { RoundFlag } from 'components/RoundFlag'
import { StripeElements } from 'features/stripe/ui/StripeElements'

import { StripeCountryCode } from 'features/stripe/ui/types'
import {
  validStripeCountries,
  validStripeCountryMap,
  validStripeRegionMap,
} from 'features/stripe/ui/regions'
import { getFormattedAddress } from 'utils/getFormattedAddress'
import { useAutocomplete } from 'utils/useAutocomplete'
import { ValidationError } from 'components/Input'
import { MotifContext } from 'motif/MotifProvider'
import { useOutsideClick } from 'utils/useOutsideClick'

import { omit } from 'lodash-es'
import { regionsWithMultipleCountries } from 'features/stripe/config'
import { CardType } from '../modules/types'
import { selectStripeCardError } from '../modules/addCard'
import {
  checkAddress,
  handleCaughtAddressError,
  parseMonth,
  parseYear,
  stripeStyles,
  stripeStylesDark,
} from './utils'
import {
  AddressResult,
  AddressResultsContainer,
  AddressResultsInnerContainer,
  Country,
  HalfFields,
  InputWrapper,
  Label,
  SectionWrapper,
  StripeInputWrapper,
  StyledCardCVCElement,
  StyledCardNumberElement,
  Title,
  Wrapper,
} from './Components'

import CardProviderOverlay from './CardProviderOverlay'
import { ExpiryDate } from './ExpiryDate'

interface Props {
  card?: CardType
  cardRegion?: string
}

const EditPaymentMethodForm: React.FC<
  Props & ModalProps & WrappedComponentProps
> = ({ isOpen, onClose, intl, card }) => {
  const dispatch = useDispatch()
  const isUpdateCardLoading = useSelector(selectIsUpdateCardLoading)
  const isCustomerLoading = useSelector(selectIsCustomerLoading)
  const addressIsLoading = useSelector(selectFetchAddressIsLoading)
  const stripeError = useSelector(selectStripeCardError)

  const { createNotification, removeNotification } = useContext(ToastContext)

  const cardData = card?.card

  const isLoading = isUpdateCardLoading || isCustomerLoading || addressIsLoading

  const [editError, setEditError] = useState<string | null>(null)
  const [expiryDateError, setExpiryDateError] = useState<boolean>(false)
  const [addressError, setAddressError] = useState<{
    [key: string]: string | string[]
  } | null>(null)
  const [active, setActive] = useState<number>(0)
  const { motif } = useContext(MotifContext)

  const addressData = card?.owner?.address

  const selectedCountry = validStripeCountryMap.get(
    addressData?.country?.toLowerCase() as StripeCountryCode
  )

  const countryRegion = card?.region
    ? validStripeRegionMap.get(card.region)
    : undefined

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: card?.owner?.name || '',
      country: addressData?.country || 'us',
      line1: addressData?.line1 || '',
      line2: addressData?.line2 || '',
      city: addressData?.city || '',
      state: addressData?.state || '',
      postal_code: addressData?.postal_code || '',
      exp_month: parseMonth(cardData?.exp_month.toString()), // month requires parsing on initial value only
      exp_year: cardData?.exp_year.toString(),
    },
    onSubmit: async (values) => {
      const {
        name,
        country,
        line1,
        line2,
        city,
        state,
        postal_code,
        exp_month,
        exp_year,
      } = values

      const addressValues = omit(values, 'exp_month', 'exp_year')

      try {
        await checkAddress(addressValues)
      } catch (error) {
        handleCaughtAddressError(error, intl, setAddressError)
        return
      }

      if (card?.id) {
        dispatch(
          editCard({
            id: card.id,
            owner: {
              name,
              address: {
                country,
                line1,
                line2,
                city,
                state,
                postal_code,
              },
            },
            card: {
              exp_month,
              exp_year,
            },
            region: card.region,
          })
        )
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required().label('SETTINGS_PAYMENTS_NAME_ON_CARD'),
      country: Yup.string().required().max(2).label('COMMON_LABEL_COUNTRY'),
      line1: Yup.string().required().label('COMMON_LABEL_STREET_ADDRESS'),
      city: Yup.string().required().label('COMMON_LABEL_CITY'),
      state: Yup.string().required().label('COMMON_LABEL_STATE'),
      postal_code: Yup.string().required().label('COMMON_LABEL_POSTAL_CODE'),
    }),
  })

  const [addressResultsVisible, setAddressResultsVisible] = useState(false)

  const { placesService, placePredictions, getPlacePredictions } =
    useAutocomplete()

  useEffect(() => {
    if (placePredictions?.length && formik.values.country === 'US') {
      setAddressResultsVisible(true)
    } else {
      setAddressResultsVisible(false)
      setActive(0)
    }
  }, [formik.values.country, placePredictions])

  const checkAddressDebounced = useDebouncedCallback(async () => {
    try {
      await checkAddress(formik.values)
      setAddressError(null)
    } catch (error) {
      handleCaughtAddressError(error, intl, setAddressError)
    }
  }, 200)

  useEffect(() => {
    checkAddressDebounced()
  }, [checkAddressDebounced, formik.values])

  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, () => {
    setAddressResultsVisible(false)
    setActive(0)
  })

  const handleAddressSelected = useCallback(
    (addressDetails: google.maps.places.PlaceResult) => {
      formik.setValues({
        ...formik.values,
        ...getFormattedAddress(addressDetails),
      })
      formik.setFieldTouched('postal_code', false)
    },
    [formik]
  )

  const handleKeyDown = useCallback(
    (event) => {
      if (!event?.key) {
        return
      } else if (event.key === 'Tab') {
        setAddressResultsVisible(false)
        setActive(0)
      } else if (event.key === 'Enter') {
        event.preventDefault()
        setAddressResultsVisible(false)
        setActive(0)
        placesService?.getDetails(
          {
            placeId: placePredictions[active]?.place_id,
            fields: ['address_component'],
          },
          (addressDetails) => {
            addressDetails && handleAddressSelected(addressDetails)
          }
        )
      } else if (event.key === 'ArrowDown') {
        setActive(
          active < placePredictions.length - 1
            ? active + 1
            : placePredictions.length - 1
        )
      } else if (event.key === 'ArrowUp') {
        setActive(active > 0 ? active - 1 : 0)
      }
    },
    [active, handleAddressSelected, placePredictions, placesService]
  )

  useEffect(() => {
    if (stripeError) {
      return setEditError(stripeError)
    }
  }, [stripeError])

  useEffect(() => {
    if (editError) {
      createNotification(
        <BasicToast
          stateIndicator="danger"
          title={<FormattedMessage id="GENERIC_ERROR_MESSAGE" />}
          details={editError}
        />
      )
    }
  }, [editError, createNotification, removeNotification])

  const isButtonDisabled = useMemo(() => {
    const hasAddressError =
      !!addressError && !!Object.values(addressError).length
    const hasFormikError = !!Object.values(formik.errors).length
    const hasAnyError = hasAddressError || hasFormikError || expiryDateError

    if (isLoading || formik.isSubmitting || hasAnyError) {
      return true
    }
    return false
  }, [
    addressError,
    expiryDateError,
    formik.errors,
    formik.isSubmitting,
    isLoading,
  ])
  const hasRegionMultipleCountries = useMemo(() => {
    if (!countryRegion?.code || !selectedCountry) {
      return false
    }
    return regionsWithMultipleCountries.includes(countryRegion.code)
  }, [countryRegion?.code, selectedCountry])

  if (!card) return null

  const customStripeStyles = motif === 'dark' ? stripeStylesDark : stripeStyles

  return (
    <GenericModal
      size="small"
      isOpen={isOpen}
      onRequestClose={onClose}
      title={
        <FormattedMessage
          id="SETTINGS_PAYMENTS_MODAL_TITLE_EDIT"
          tagName="div"
        />
      }
      actions={[
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_CANCEL' }),
          onClick: onClose,
          variant: 'default',
          disabled: isLoading || formik.isSubmitting,
        },
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_SUBMIT' }),
          type: 'submit',
          variant: 'primary',
          onClick: formik.handleSubmit as any,
          disabled: isButtonDisabled,
          loader:
            isOpen && (isLoading || formik.isSubmitting)
              ? 'spinner'
              : undefined,
          style: { outline: 'none' },
        },
      ]}
    >
      {formik && (
        <form onSubmit={formik.handleSubmit}>
          <Wrapper>
            <SectionWrapper>
              <Title>
                <FormattedMessage id="SETTINGS_PAYMENTS_CARD_REGION" />
              </Title>
              {countryRegion && (
                <InputWrapper>
                  <Country disabled>
                    <div>
                      <RoundFlag
                        countryCode={countryRegion.code}
                        size="small"
                        noMargin
                      />
                      <span className="country-name">{countryRegion.name}</span>
                    </div>
                  </Country>
                </InputWrapper>
              )}
            </SectionWrapper>
            <SectionWrapper>
              <Title>
                <FormattedMessage id="SETTINGS_PAYMENTS_CARD_INFORMATION" />
              </Title>
              <StripeInputWrapper>
                <Label>
                  <FormattedMessage id="SETTINGS_PAYMENTS_LABEL_CARD_NUMBER" />
                </Label>
                <CardProviderOverlay
                  cardBrand={cardData?.brand}
                  invalidCard={false}
                  disabled
                >
                  <StyledCardNumberElement
                    options={{
                      style: customStripeStyles,
                      placeholder: `\u2022\u2022\u2022\u2022 \u2022\u2022\u2022\u2022 \u2022\u2022\u2022\u2022 ${cardData?.last4}`,
                      disabled: true,
                    }}
                    disabled
                  />
                </CardProviderOverlay>
              </StripeInputWrapper>

              <HalfFields>
                <ExpiryDate
                  value={{
                    expMonth: formik.values.exp_month,
                    expYear: formik.values.exp_year,
                  }}
                  onChange={formik.setFieldValue}
                  placeholder={{
                    expMonth: parseMonth(cardData?.exp_month),
                    expYear: parseYear(cardData?.exp_year),
                  }}
                  expiryDateError={expiryDateError}
                  setExpiryDateError={setExpiryDateError}
                />
                <StripeInputWrapper>
                  <Label>
                    <FormattedMessage id="SETTINGS_PAYMENTS_LABEL_SECURITY_CODE" />
                  </Label>
                  <StyledCardCVCElement
                    options={{
                      style: customStripeStyles,
                      placeholder: '\u2022\u2022\u2022',
                      disabled: true,
                    }}
                    disabled
                  />
                </StripeInputWrapper>
              </HalfFields>
              <Input
                id="name"
                name="name"
                variant="secondary"
                label={intl.formatMessage({
                  id: 'SETTINGS_PAYMENTS_NAME_ON_CARD',
                })}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
                autoComplete="new-name"
                full
                invalid={!!editError}
              />
            </SectionWrapper>
            <SectionWrapper>
              <Title>
                <FormattedMessage id="SETTINGS_PAYMENTS_BILLING_ADDRESS" />
              </Title>
              {hasRegionMultipleCountries && (
                <InputWrapper>
                  <Dropdown
                    label="Country"
                    value={selectedCountry?.code}
                    onChange={(option) => {
                      formik.setValues({
                        ...formik.values,
                        country: option.value.toUpperCase(),
                      })
                    }}
                    options={validStripeCountries
                      .filter(
                        (country) => country.region === countryRegion?.code
                      )
                      .map((country) => ({
                        label: country.name,
                        value: country.code,
                        image: <RoundFlag countryCode={country.code} />,
                      }))}
                    searchable
                    variant="secondary"
                    minHeight="214px"
                    optionContainerClassName="change-region__dropdown"
                    disabled
                  />
                </InputWrapper>
              )}
              <InputWrapper>
                <Input
                  onKeyDown={handleKeyDown}
                  name="line1"
                  variant="secondary"
                  label={intl.formatMessage({
                    id: 'COMMON_LABEL_STREET_ADDRESS',
                  })}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.values.country === 'US' &&
                      getPlacePredictions({ input: e.target.value })
                    formik.handleChange(e)
                  }}
                  invalid={
                    (formik.touched.line1 && formik.errors.line1) ||
                    addressError?.['line1']
                  }
                  value={formik.values.line1}
                  autoComplete="new-line1"
                  full
                />
                <AddressResultsContainer
                  ref={wrapperRef}
                  visible={addressResultsVisible}
                >
                  <AddressResultsInnerContainer>
                    {placePredictions?.length &&
                      placePredictions.map(
                        (
                          place: google.maps.places.AutocompletePrediction,
                          i: number
                        ) => (
                          <AddressResult
                            active={active === i}
                            key={i.toString()}
                            onClick={() => {
                              setAddressResultsVisible(false)
                              setActive(0)
                              placesService?.getDetails(
                                {
                                  placeId: place?.place_id,
                                  fields: ['address_component'],
                                },
                                (addressDetails) => {
                                  addressDetails &&
                                    handleAddressSelected(addressDetails)
                                }
                              )
                            }}
                          >
                            {place?.description}
                          </AddressResult>
                        )
                      )}
                  </AddressResultsInnerContainer>
                </AddressResultsContainer>
              </InputWrapper>
              <InputWrapper>
                <Input
                  name="city"
                  variant="secondary"
                  label={intl.formatMessage({ id: 'COMMON_LABEL_CITY' })}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  invalid={
                    (formik.touched.city && formik.errors.city) ||
                    addressError?.['city']
                  }
                  value={formik.values.city}
                  autoComplete="new-city"
                  full
                />
              </InputWrapper>
              <InputWrapper>
                <Input
                  variant="secondary"
                  name="state"
                  label={intl.formatMessage({ id: 'COMMON_LABEL_STATE' })}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  invalid={
                    (formik.touched.state && formik.errors.state) ||
                    addressError?.['state']
                  }
                  value={formik.values.state}
                  autoComplete="new-state"
                  full
                />
              </InputWrapper>
              <InputWrapper>
                <Input
                  name="postal_code"
                  variant="secondary"
                  label={intl.formatMessage({ id: 'COMMON_LABEL_POSTAL_CODE' })}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  invalid={
                    (formik.touched.postal_code && formik.errors.postal_code) ||
                    addressError?.['postal_code'] ||
                    !!editError
                  }
                  value={formik.values.postal_code}
                  autoComplete="new-postal-code"
                  full
                />
              </InputWrapper>
            </SectionWrapper>
          </Wrapper>
          {addressError?.['detail'] ? (
            <ValidationError>
              <FormattedMessage id="SETTINGS_PAYMENTS_ADDRESS_VALIDATION_FAILED" />
            </ValidationError>
          ) : null}
        </form>
      )}
    </GenericModal>
  )
}

const EditPaymentMethodModalConnected = injectIntl(EditPaymentMethodForm)

export const EDIT_PAYMENT_METHOD_MODAL_ID = 'EDIT_PAYMENT_METHOD_MODAL_ID'

const StripeWrappedModal: React.FC<Props & ModalProps> = (props) => {
  return (
    <StripeElements region={props.cardRegion}>
      <EditPaymentMethodModalConnected {...props} />
    </StripeElements>
  )
}

export default () => (
  <ModalWrapper modalId={EDIT_PAYMENT_METHOD_MODAL_ID}>
    <StripeWrappedModal />
  </ModalWrapper>
)
