import React from 'react'
import { useIntl } from 'react-intl'

import { GenericCompletedMFAModal } from '../GenericCompletedMFAModal'

export const PASSKEY_COMPLETED_MODAL_ID = 'PASSKEY_COMPLETED_MODAL_ID'

export const PasskeyCompletedModal: React.FC = () => {
  const intl = useIntl()

  return (
    <GenericCompletedMFAModal
      modalId={PASSKEY_COMPLETED_MODAL_ID}
      title={intl.formatMessage({
        id: 'SETTINGS_MFA_PASSKEY_MODAL_TITLE',
      })}
      description={intl.formatMessage({
        id: 'SETTINGS_MFA_PASSKEY_COMPLETED_DESCRIPTION',
      })}
    />
  )
}
