import React from 'react'
import { FormattedMessage } from 'react-intl'
import { SortAscendingIcon, SortDescendingIcon } from '@ubnt/icons'

import { SortType } from './types'

export const getSortOptions = (selectedSort: SortType) => [
  {
    label: (
      <span className={selectedSort === 'desc-date' ? 'selected' : ''}>
        <SortDescendingIcon
          isActive
          size="navigation"
          style={{ marginRight: 8 }}
        />
        <FormattedMessage id="SETTINGS_BACKUPS_DESCENDING_BY_DATE" />
      </span>
    ),
    value: 'desc-date',
  },
  {
    label: (
      <span className={selectedSort === 'asc-date' ? 'selected' : ''}>
        <SortAscendingIcon
          isActive
          size="navigation"
          style={{ marginRight: 8 }}
        />
        <FormattedMessage id="SETTINGS_BACKUPS_ASCENDING_BY_DATE" />
      </span>
    ),
    value: 'asc-date',
  },
  {
    label: (
      <span className={selectedSort === 'desc-name' ? 'selected' : ''}>
        <SortDescendingIcon
          isActive
          size="navigation"
          style={{ marginRight: 8 }}
        />
        <FormattedMessage id="SETTINGS_BACKUPS_DESCENDING_BY_NAME" />
      </span>
    ),
    value: 'desc-name',
  },
  {
    label: (
      <span className={selectedSort === 'asc-name' ? 'selected' : ''}>
        <SortAscendingIcon
          isActive
          size="navigation"
          style={{ marginRight: 8 }}
        />
        <FormattedMessage id="SETTINGS_BACKUPS_ASCENDING_BY_NAME" />
      </span>
    ),
    value: 'asc-name',
  },
]
