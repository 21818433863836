import { all, put, spawn, takeEvery } from 'redux-saga/effects'

import { api } from 'api'
import createDataModule from 'utils/moduleCreator'
import { SSOAPIError } from 'types/types'
import { closeVisibleModal } from 'modules/modals'
import { fetchMFA } from 'modules/mfa'

const {
  api: { sso },
} = __CONFIG__

export const removeMFASMSDataKey = 'removeMFASMS'

const RemoveMFASMSActions = {
  remove: `${removeMFASMSDataKey}/REMOVE`,
}

type RemoveMFASMSState = Record<string, never>

const dataModule = createDataModule<RemoveMFASMSState, SSOAPIError>(
  removeMFASMSDataKey,
  `${sso.paths.mfa}/sms`,
  api.ssoBase
)

export const {
  REMOVE_DONE,
  selectErrors: selectRemoveSMSErrors,
  resetErrors: resetRemoveSMSErrors,
} = dataModule

export const removeMFASMS = (id: string, token?: string) => {
  return {
    type: RemoveMFASMSActions.remove,
    payload: { oneTimePassword: token },
    urlPath: `/${id}`,
  }
}

function* removeSMSDoneSaga() {
  yield put(fetchMFA())
  yield put(closeVisibleModal())
}

function* subscribeToRemoveSMSDoneSaga() {
  yield takeEvery(REMOVE_DONE, removeSMSDoneSaga)
}

export function* removeMFASMSRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToRemoveSMSDoneSaga)])
}

export const removeMFASMSReducer = dataModule.reducer
