import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@ubnt/ui-components/Button'

import styled from 'theme/styled'
import {
  removeTaxId,
  selectIsRemoveTaxIdLoading,
} from 'features/payment-methods/modules/taxId/removeTaxId'
import { TaxIdType } from 'features/stripe/ui/types'

type Props = {
  taxIdValue: string
  taxIdType?: TaxIdType
  clearErrors: () => void
}

const RemoveButton: React.FC<Props> = ({
  taxIdValue,
  taxIdType,
  clearErrors,
}) => {
  const isAddingTaxIdLoading = useSelector(selectIsRemoveTaxIdLoading)

  const dispatch = useDispatch()

  const handleClick = () => {
    if (!taxIdType) return
    clearErrors()
    dispatch(removeTaxId({ taxIdValue, taxIdType }))
  }

  return (
    <StyledButton
      color="danger"
      variant="inline"
      onClick={handleClick}
      loader={isAddingTaxIdLoading ? 'dots' : undefined}
    >
      <FormattedMessage id="COMMON_ACTION_REMOVE" />
    </StyledButton>
  )
}

export default RemoveButton

const StyledButton = styled(Button)`
  align-self: flex-end;
`
