import {
  resetAddTaxIdErrors,
  selectAddTaxIdErrors,
} from 'features/payment-methods/modules/taxId/addTaxId'
import {
  resetRemoveTaxIdErrors,
  selectRemoveTaxIdErrors,
} from 'features/payment-methods/modules/taxId/removeTaxId'
import {
  resetUpdateTaxIdErrors,
  selectUpdateTaxIdErrors,
} from 'features/payment-methods/modules/taxId/updateTaxId'
import { useDispatch, useSelector } from 'react-redux'

const useTaxIdErrors = () => {
  const dispatch = useDispatch()

  const addErrors = useSelector(selectAddTaxIdErrors)
  const updateErrors = useSelector(selectUpdateTaxIdErrors)
  const removeErrors = useSelector(selectRemoveTaxIdErrors)

  const hasError =
    !!addErrors.message || !!updateErrors.message || !!removeErrors.message

  const getErrorMessage = (): string | undefined => {
    if (addErrors.message) return 'add'
    if (updateErrors.message) return 'update'
    if (removeErrors.message) return 'remove'
    return undefined
  }

  const clearAllErrors = () => {
    !!addErrors.message && dispatch(resetAddTaxIdErrors())
    !!updateErrors.message && dispatch(resetUpdateTaxIdErrors())
    !!removeErrors.message && dispatch(resetRemoveTaxIdErrors())
  }

  return { hasError, errorMessage: getErrorMessage(), clearAllErrors }
}

export default useTaxIdErrors
