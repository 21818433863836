import { all, put, spawn, takeEvery } from 'redux-saga/effects'
import createDataModule from 'utils/moduleCreator'

import { fetchSubscriptions } from './subscriptions'
import { fetchInvoices } from './invoices'

const {
  api: { billing },
} = __CONFIG__

export interface InvoicesUpdate {
  id?: string
  source?: string
  region?: string
}

const getUrl = (url: string, { id, region }: InvoicesUpdate) => {
  return `${url.replace('{invoiceId}', id || '')}?region=${region}`
}

export const dataKey = 'invoicesUpdate'

export const dataModule = createDataModule<InvoicesUpdate>(
  dataKey,
  billing.paths.invoicePay,
  billing.base,
  undefined,
  getUrl
)

export const {
  reducer: invoicesUpdateReducer,
  create: payInvoice,
  CREATE_DONE: RETRY_PAYMENT_SUCCESS,
} = dataModule

function* subscriptionUpdatedSaga() {
  yield put(fetchSubscriptions())
  yield put(fetchInvoices())
}

function* subscribeToSubscriptionUpdatedSaga() {
  yield takeEvery(RETRY_PAYMENT_SUCCESS, subscriptionUpdatedSaga)
}

export function* invoicesUpdateRootSaga() {
  yield all([
    spawn(dataModule.rootSaga),
    spawn(subscribeToSubscriptionUpdatedSaga),
  ])
}
