import { RootState } from '../types/types'
import { MFAType } from './mfa'

export const mfaAddedDataKey = 'mfaAdded'

export interface MFAAddedMethodState {
  method: string | null
  firstMethod: boolean
}

const initialState: MFAAddedMethodState = {
  method: null,
  firstMethod: false,
}

export enum MFAAddedActionTypes {
  MFA_METHOD_ADDED = 'MFA_METHOD_ADDED',
  MFA_RESET_SETUP_DATA = 'MFA_RESET_SETUP_DATA',
}

type Action =
  | {
      type: MFAAddedActionTypes.MFA_METHOD_ADDED
      payload: {
        method: string
        firstMethod: boolean
      }
    }
  | {
      type: MFAAddedActionTypes.MFA_RESET_SETUP_DATA
    }

export const mfaAddedReducer = (state = initialState, action: Action) => {
  let newState: MFAAddedMethodState

  switch (action.type) {
    case MFAAddedActionTypes.MFA_METHOD_ADDED:
      newState = {
        ...action.payload,
      }
      return newState
    case MFAAddedActionTypes.MFA_RESET_SETUP_DATA:
      return initialState
    default:
      return state
  }
}

/**
 * Actions
 */

export const resetMFASetupData = (): Action => ({
  type: MFAAddedActionTypes.MFA_RESET_SETUP_DATA,
})

export const pushAdded = (firstMethod: boolean): Action => ({
  type: MFAAddedActionTypes.MFA_METHOD_ADDED,
  payload: {
    method: MFAType.push,
    firstMethod,
  },
})
export const emailAdded = (firstMethod: boolean): Action => ({
  type: MFAAddedActionTypes.MFA_METHOD_ADDED,
  payload: {
    method: MFAType.email,
    firstMethod,
  },
})
export const smsAdded = (firstMethod: boolean): Action => ({
  type: MFAAddedActionTypes.MFA_METHOD_ADDED,
  payload: {
    method: MFAType.sms,
    firstMethod,
  },
})
export const totpAdded = (firstMethod: boolean): Action => ({
  type: MFAAddedActionTypes.MFA_METHOD_ADDED,
  payload: {
    method: MFAType.totp,
    firstMethod,
  },
})
export const passkeyAdded = (firstMethod: boolean): Action => ({
  type: MFAAddedActionTypes.MFA_METHOD_ADDED,
  payload: {
    method: MFAType.webAuthn,
    firstMethod,
  },
})

/**
 * Selectors
 */

export const selectMFAAddedMethod = (state: RootState) => state[mfaAddedDataKey]
