import React from 'react'
import { FormattedMessage } from 'react-intl'
import isMobile from 'is-mobile'

import styled from 'theme/styled'
import LanguageSelector from 'components/LanguageSelector'

const AuthFooter = () => {
  const isMobileDevice = isMobile()

  return (
    <Container isMobileDevice={isMobileDevice}>
      <FooterLink href="https://www.ui.com/legal/termsofservice/">
        <FormattedMessage id="COMMON_LABEL_TERMS_OF_SERVICE" />
      </FooterLink>
      <FooterLink href="https://www.ui.com/legal/privacypolicy">
        <FormattedMessage id="COMMON_LABEL_PRIVACY_POLICY" />
      </FooterLink>
      <LanguageSelector />
    </Container>
  )
}

export default AuthFooter

const Container = styled.div<{ isMobileDevice: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 276px;
  margin: auto auto 0;
  padding: 0 0 12px 0;

  @media (max-height: 560px) {
    padding-bottom: 15px;
  }
`

const FooterLink = styled.a`
  font-size: 12px;
  color: #000000;
  max-width: 34%;
  overflow-wrap: anywhere;
  :hover {
    color: #000000d9;
  }
`
