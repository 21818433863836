import { createSelector } from 'reselect'
import createDataModule from 'utils/moduleCreator'
import {
  ZendeskCCdTicketsResponse,
  ZendeskTicket,
  ZendeskTicketsResponse,
} from 'pages/requests/types'

const {
  api: { accountBE },
} = __CONFIG__

export const ccdTicketsDataKey = 'zendeskCcdTickets'

const dataModule = createDataModule<ZendeskTicketsResponse>(
  ccdTicketsDataKey,
  accountBE.paths.zendeskCcdTickets,
  accountBE.base
)

export const {
  fetch: fetchCcdTickets,
  selectData,
  selectIsDone: selectCcdTicketsFetched,
  selectErrors: selectCcdTicketsErrors,
  selectIsLoading: selectCcdTicketsAreLoading,
  rootSaga: ccdTicketsRootSaga,
  reducer: ccdTicketsReducer,
} = dataModule

export const selectCcdTickets = createSelector(
  selectData,
  ({ tickets }: ZendeskCCdTicketsResponse): ZendeskTicket[] => tickets ?? []
)

export const selectTotalCcdTickets = createSelector(
  selectData,
  ({ totalTickets }: ZendeskTicketsResponse): number => totalTickets
)

export const selectFetchedCcdTickets = createSelector(
  selectData,
  ({ tickets }: ZendeskCCdTicketsResponse): number => tickets?.length || 0
)
