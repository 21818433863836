import React from 'react'
import { Form, FormikConsumer } from 'formik'

import { BasicActionPanel } from './BasicActionPanel'
import { Page } from './Page'

interface Props {
  name: string
  children?: React.ReactNode
  isLoading?: boolean
  renderFooter?: any
  onCancelClick?(): void
  inline?: boolean
  disabled?: boolean
}

export const FormPage: React.FC<
  Props & React.HTMLAttributes<HTMLFormElement>
> = ({
  name,
  children,
  isLoading,
  renderFooter,
  inline,
  onCancelClick,
  disabled,
  ...rest
}) => {
  return (
    <FormikConsumer>
      {(formikBag) => {
        if (!formikBag.values) {
          console.error(
            'Error: FormPage does not have a formik context. Did you remember to apply withFormik to your FormPage? Returning null.'
          )
          return null
        }

        const { dirty, handleReset, errors } = formikBag
        const hasErrors = !!Object.keys(errors).length

        const panel = () => (
          <BasicActionPanel
            name={name}
            onCancelClick={onCancelClick || handleReset}
            visible={dirty}
            disabled={hasErrors}
            isLoading={isLoading}
          />
        )

        return (
          <Form {...rest}>
            <Page
              overflow
              noCenter
              renderFooter={disabled ? undefined : panel}
              inline={inline}
            >
              {children}
            </Page>
            {renderFooter ? renderFooter() : null}
          </Form>
        )
      }}
    </FormikConsumer>
  )
}
