import React, { SetStateAction, useCallback, useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import Button from '@ubnt/ui-components/Button/Button'
import CheckmarkIcon from '@ubnt/icons/CheckmarkIcon'
import { useMotif } from '@ubnt/ui-components'
import { BasicToast, ToastContext } from '@ubnt/ui-components/Toast'
import axios from 'axios'

import { typography } from 'theme'
import styled from 'theme/styled'
import { fetchOpenTickets } from 'modules/zendesk/openUserTickets'
import { fetchClosedTickets } from 'modules/zendesk/closedUserTickets'
import { StatusPill } from 'components/RequestsStatusPill'
import { FlexWrapper } from 'components/SharedComponents'

import { getDaysToClose, getFormattedTime } from '../requests/utils'
import { TicketStatus, ZendeskTicketWithComments } from '../requests/types'
import { SecondaryText } from './styles'

const {
  api: { accountBE },
} = __CONFIG__

export const RequestTitle: React.FC<
  ZendeskTicketWithComments & {
    setTicket: React.Dispatch<
      SetStateAction<{
        loading: boolean
        ticket?: ZendeskTicketWithComments
      }>
    >
  }
> = ({
  status,
  id,
  created_at,
  description,
  is_pending,
  updated_at,
  setTicket,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { createNotification } = useContext(ToastContext)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const { motif } = useMotif()
  const isDarkTheme = motif === 'dark'

  const createGenericErrorNotfication = useCallback(() => {
    createNotification(
      <BasicToast
        stateIndicator="danger"
        title={<FormattedMessage id="SUPPORT_SUBMIT_FAILED_TOAST_TITLE" />}
        details={<FormattedMessage id="REQUEST_COMMENT_ERROR" />}
      />
    )
  }, [createNotification])

  const handleMarkAsClosed = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      setIsSubmitLoading(true)
      const url = `${accountBE.base}/${accountBE.paths.zendeskMarkTicketAsClosed}/${id}`
      await axios
        .put(url, {}, { withCredentials: true })
        .then(({ data }) => {
          setTicket(() => ({
            loading: false,
            ticket: data?.ticket,
          }))
          dispatch(fetchOpenTickets())
          dispatch(fetchClosedTickets())
          setIsSubmitLoading(false)
        })
        .catch(() => {
          setIsSubmitLoading(false)
          createGenericErrorNotfication()
        })
    },
    [id, dispatch, createGenericErrorNotfication, setTicket]
  )

  return (
    <FlexWrapper column>
      <FlexWrapper justify-content="space-between" gap={20}>
        <TicketTitle title={description}>{description}</TicketTitle>
        <StatusPill $isDarkTheme={isDarkTheme} className={status.toLowerCase()}>
          {status}
        </StatusPill>
      </FlexWrapper>
      <SecondaryText>
        #{id}
        {is_pending && (
          <span className="pending">
            <FormattedMessage id="SUPPORT_MY_REQUESTS_AWAITING" />
          </span>
        )}
      </SecondaryText>

      <SecondaryText>
        <FormattedMessage
          id="REQUEST_CREATED_AT"
          values={{ date: getFormattedTime(created_at, intl, true) }}
        />
      </SecondaryText>

      {status === TicketStatus.Answered && (
        <Container $isDarkTheme={isDarkTheme}>
          <InfoWrapper $isDarkTheme={isDarkTheme}>
            <StyledCheckmarkIcon variant="fill" size="original" />
            <FormattedMessage
              id="REQUEST_WILL_CLOSE"
              values={{ number: getDaysToClose(updated_at) }}
            />
          </InfoWrapper>
          <Button
            variant="primary"
            size="small"
            onClick={handleMarkAsClosed}
            loader={isSubmitLoading ? 'dots' : undefined}
          >
            <FormattedMessage id="REQUEST_CLOSE_NOW" />
          </Button>
        </Container>
      )}
    </FlexWrapper>
  )
}

const TicketTitle = styled.span`
  width: 100%;
  font: ${typography['desktop-heading-large']};
  color: ${({ theme }) => theme.text1};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Container = styled.div<{ $isDarkTheme: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 12px;
  background-color: ${({ theme, $isDarkTheme }) =>
    $isDarkTheme ? theme.green09 : theme.green01};
  border-radius: 4px;
  margin-top: 16px;
`

const InfoWrapper = styled.div<{ $isDarkTheme: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  font: ${typography['desktop-caption']};
  color: ${({ theme, $isDarkTheme }) =>
    $isDarkTheme ? theme.text1 : theme.text2};
`

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  color: ${({ theme }) => theme.green06};
`
