import { useDispatch, useSelector } from 'react-redux'

import {
  fetchCcdTickets,
  selectCcdTicketsFetched,
  selectTotalCcdTickets,
} from 'modules/zendesk/ccdUserTickets'
import {
  fetchClosedTickets,
  selectClosedTicketsFetched,
  selectTotalClosedTickets,
} from 'modules/zendesk/closedUserTickets'
import {
  fetchOpenTickets,
  selectOpenTicketsFetched,
  selectTotalOpenTickets,
} from 'modules/zendesk/openUserTickets'

export const useFetchAll = () => {
  const dispatch = useDispatch()

  const haveOpenFetched = useSelector(selectOpenTicketsFetched)
  const haveClosedFetched = useSelector(selectClosedTicketsFetched)
  const haveCcdFetched = useSelector(selectCcdTicketsFetched)

  const totalOpenTickets = useSelector(selectTotalOpenTickets)
  const totalClosedTickets = useSelector(selectTotalClosedTickets)
  const totalCcdTickets = useSelector(selectTotalCcdTickets)

  const totalTicketCount =
    totalOpenTickets + totalClosedTickets + totalCcdTickets || 0

  const haveAllTicketsFetched =
    haveOpenFetched && haveClosedFetched && haveCcdFetched

  const fetchAllTickets = () => {
    dispatch(fetchOpenTickets())
    dispatch(fetchClosedTickets())
    dispatch(fetchCcdTickets())
  }

  return {
    fetchAllTickets,
    haveAllTicketsFetched,
    totalTicketCount,
  }
}
