import React, { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { api } from 'api'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import styled from 'theme/styled'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/fullSubscriptions'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { closeVisibleModal } from 'modules/modals'
import { fetchSubscriptions } from 'features/subscriptions/module/subscriptions'
import { fetchInvoices } from 'features/subscriptions/module/invoices'

interface Props extends ModalProps {
  fullSubscription?: FullSubscription
}

const CancelSubscriptionEnhancedThreatModal: React.FC<Props> = ({
  isOpen,
  onClose,
  fullSubscription,
}) => {
  const history = useHistory()
  const [hasCancelError, setHasCancelError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const error = useMemo(() => {
    if (hasCancelError) {
      return <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_ERROR" />
    } else if (!fullSubscription) {
      return <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_FETCH_ERROR" />
    } else {
      return null
    }
  }, [hasCancelError, fullSubscription])

  const onConfirm = async () => {
    setIsLoading(true)
    if (error) {
      return history.push('/supportform')
    }

    if (!fullSubscription?.id) {
      return setHasCancelError(true)
    }

    // 'rule-set-pro' is necessary as a fallback to cancel subscriptions made before the subscribeItem was added to the metadata in Stripe
    const subscribeItem =
      fullSubscription?.metadata.subscribeItem ?? 'rule_set_pro'

    try {
      await api.cancelEnhancedSubscription(subscribeItem, fullSubscription.id)
      dispatch(fetchSubscriptions())
      dispatch(fetchInvoices())
      dispatch(closeVisibleModal())
    } catch (e) {
      setHasCancelError(true)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={() => {
        onClose?.()
      }}
      title={<Title />}
      size="small"
      onAfterClose={() => setHasCancelError(false)}
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CANCEL" />,
          onClick: onClose,
        },
        {
          text: error ? (
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CONTACT_SUPPORT" />
          ) : (
            <FormattedMessage id="COMMON_ACTION_CONFIRM" />
          ),
          variant: 'primary',
          onClick: onConfirm,
          loader: isLoading ? 'dots' : undefined,
          type: 'submit',
          disabled: isLoading,
        },
      ]}
    >
      {error ? (
        <ErrorMessage>{error}</ErrorMessage>
      ) : (
        <FormattedMessage
          id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_MESSAGE"
          values={{ name: fullSubscription?.name }}
          tagName="div"
        />
      )}
    </GenericModal>
  )
}

export const CANCEL_SUBSCRIPTION_ETU_MODAL_ID =
  'CANCEL_SUBSCRIPTION_ETU_MODAL_ID'

const WrappedCancelSubscriptionEnhancedThreatUpdatesModal = () => (
  <ModalWrapper modalId={CANCEL_SUBSCRIPTION_ETU_MODAL_ID}>
    <CancelSubscriptionEnhancedThreatModal />
  </ModalWrapper>
)

export default WrappedCancelSubscriptionEnhancedThreatUpdatesModal

const Title = () => (
  <TitleWrapper>
    <FormattedMessage
      id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION"
      tagName="div"
    />
  </TitleWrapper>
)

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.red06};
`
