import { createSelector } from 'reselect'
import type Stripe from 'stripe'

import createDataModule from 'utils/moduleCreator'
import { CustomerProducts } from './types'

const {
  api: { billing },
} = __CONFIG__

const getUrl = (url: string) => `${url}?limit=100`

export const dataKey = 'products'

export const dataModule = createDataModule<CustomerProducts>(
  dataKey,
  billing.paths.products,
  billing.base,
  undefined,
  getUrl
)

export const {
  fetch: fetchProducts,
  selectData: selectProductsData,
  reducer: productsReducer,
  rootSaga: productsRootSaga,
} = dataModule

export const selectProducts = createSelector(selectProductsData, (data) => {
  const productRegions = Object.keys(data)

  if (!productRegions.length) {
    return []
  }

  const userProducts = productRegions
    .map((region) => {
      return (
        data[region as keyof CustomerProducts]?.data.map((product) => {
          return { ...product, region }
        }) ?? []
      )
    })
    .flat()

  return userProducts
})

export const selectProduct = (
  products: Stripe.Product[],
  productId: string
): Stripe.Product | undefined => products.find(({ id }) => id === productId)
