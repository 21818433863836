import React, { useCallback, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import {
  FormattedMessage,
  IntlShape,
  WrappedComponentProps,
  injectIntl,
} from 'react-intl'
import { FormikProps, withFormik } from 'formik'
import { Checkbox, Slider } from '@ubnt/ui-components'

import { ProfileData, RootState } from 'types/types'
import {
  selectIsEmployeeAccount,
  selectProfileData,
  selectProfileIsLoading,
  updateProfileData,
} from 'modules/profile'
import { FormPage } from 'components/FormPage'
import styled from 'theme/styled'
import { selectAssuranceLevel } from 'features/auth/modules/assuranceLevel'
import { InputLabel } from 'pages/profile/Profile.styles'

const ONE_MIN_IN_SECONDS = 60
const FIVE_MIN_IN_SECONDS = 300
const ONE_DAY_IN_SECONDS = 86400
const SEVEN_DAYS_IN_SECONDS = 604800
const THIRTY_DAYS_IN_SECONDS = 2592000

const DEFAULT_STEP = 2

const getOptions = (isEmployee: boolean) => {
  return isEmployee
    ? [ONE_MIN_IN_SECONDS, FIVE_MIN_IN_SECONDS, ONE_DAY_IN_SECONDS]
    : [ONE_DAY_IN_SECONDS, SEVEN_DAYS_IN_SECONDS, THIRTY_DAYS_IN_SECONDS]
}

const getLabels = (isEmployee: boolean, intl: IntlShape) => {
  return isEmployee
    ? [
        intl.formatMessage({ id: 'SETTINGS_SECURITY_TTL_ONE_MIN' }),
        intl.formatMessage({ id: 'SETTINGS_SECURITY_TTL_FIVE_MIN' }),
        intl.formatMessage({ id: 'SETTINGS_SECURITY_TTL_ONE_DAY' }),
      ]
    : [
        intl.formatMessage({ id: 'SETTINGS_SECURITY_TTL_ONE_DAY' }),
        intl.formatMessage({ id: 'SETTINGS_SECURITY_TTL_ONE_WEEK' }),
        intl.formatMessage({ id: 'SETTINGS_SECURITY_TTL_ONE_MONTH' }),
      ]
}

type FormValues = ProfileData

interface FormProps {
  handleSubmit(data: FormValues): void
  session_ttl_seconds?: number
  isLoading?: boolean
}

export const SessionForm: React.FC<
  FormProps & FormikProps<FormValues> & WrappedComponentProps
> = ({ setFieldValue, isLoading, intl, session_ttl_seconds }) => {
  const assuranceLevel = useSelector(selectAssuranceLevel)
  const isEmployee = useSelector(selectIsEmployeeAccount)
  const options = getOptions(!!isEmployee)
  const labels = getLabels(!!isEmployee, intl)

  const sliderLength = options.length - 1

  const initialStep = useMemo(() => {
    return session_ttl_seconds
      ? options.indexOf(session_ttl_seconds)
      : DEFAULT_STEP
  }, [session_ttl_seconds, options])

  const [step, setStep] = useState(initialStep)

  const [isAuto, setIsAuto] = useState(step === DEFAULT_STEP)

  const setNewValue = useCallback(
    (index: number) => {
      setFieldValue('session_ttl_seconds', options[index])
      setStep(index)
    },
    [options, setFieldValue]
  )

  const toggleAuto = useCallback(() => {
    if (!isAuto) {
      setIsAuto(true)
      setNewValue(DEFAULT_STEP)
    } else {
      setIsAuto(false)
      setNewValue(initialStep)
    }
  }, [isAuto, initialStep, setNewValue])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sliderValue = Number(e.target.value)
    setNewValue(sliderValue)
  }

  const Slider = () => (
    <StyledSlider
      disabled={isAuto}
      segments={sliderLength}
      max={sliderLength}
      value={step}
      onChange={handleChange}
      segmentLabels={labels}
    />
  )

  return (
    <FormPage
      name="session-timout-form"
      isLoading={isLoading}
      inline
      onCancelClick={() => {
        setNewValue(initialStep)
        setIsAuto(initialStep === DEFAULT_STEP)
      }}
    >
      <OuterWrapper>
        <InputLabel>
          <FormattedMessage id="SETTINGS_SECURITY_SESSION_TIMEOUT" />
        </InputLabel>
        <InnerWrapper>
          <RadioWrapper>
            <Checkbox
              disabled={assuranceLevel?.data.level !== 2}
              id="standalone-checkbox"
              checked={isAuto}
              onChange={toggleAuto}
            >
              Auto
            </Checkbox>
          </RadioWrapper>
          <SliderWrapperLargeScreen>
            <Slider />
          </SliderWrapperLargeScreen>
        </InnerWrapper>
      </OuterWrapper>
      <SliderWrapperSmallScreen>
        <Slider />
      </SliderWrapperSmallScreen>
    </FormPage>
  )
}

const WrappedForm = withFormik<FormProps, FormValues>({
  mapPropsToValues: ({ session_ttl_seconds }) => ({
    session_ttl_seconds,
  }),
  handleSubmit: (values, { props: { handleSubmit } }) => handleSubmit(values),
  enableReinitialize: true,
})(injectIntl(SessionForm))

const mapStateToProps = (state: RootState) => ({
  ...selectProfileData(state),
  isLoading: selectProfileIsLoading(state),
})

const mapDispatchToProps = {
  handleSubmit: (data: FormValues) => updateProfileData(data),
}

export const WrappedSessionForm: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedForm)

const SliderWrapperLargeScreen = styled.div`
  height: 50px;
  @media (max-width: ${(p) => p.theme.media.small}) {
    height: 0px;
    display: none;
  }
`

const SliderWrapperSmallScreen = styled.div`
  height: 0px;
  display: none;
  @media (max-width: ${(p) => p.theme.media.small}) {
    height: 50px;
    display: block;
  }
`

const StyledSlider = styled(Slider)`
  margin: 10px 0px;
  padding: 0;
  max-width: 324px;
  @media (max-width: ${(p) => p.theme.media.small}) {
    max-width: 655px;
  }
`

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
`

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 650px;
`

const InnerWrapper = styled.div`
  justify-content: flex-end;
  align-items: right;

  @media (min-width: ${(p) => p.theme.media.small}) {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 360px;
  }
`
