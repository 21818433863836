import { all, put, select, spawn, takeEvery } from 'redux-saga/effects'
import { api } from 'api'

import { MFAType, fetchMFA } from 'modules/mfa'
import createDataModule from 'utils/moduleCreator'
import { SSOAPIError } from 'types/types'
import { setupMFAEmail } from 'modules/mfaSetup'
import { setVisibleModal } from 'modules/modals'
import { MFAAuthenticatorStatus } from 'enums'

import { EMAIL_VERIFY_CODE_MODAL_ID } from '../VerifyCodeModal'

const {
  api: { sso },
} = __CONFIG__

export const initiateEmailDataKey = 'MFAInitiateEmail'

const initiateEmailActions = {
  create: `${initiateEmailDataKey}/CREATE`,
}

type InitiateEmailState =
  | {
      email: string
      status: MFAAuthenticatorStatus
      last_success: string | null
      id: string
      created: string
      type: MFAType.email
    }
  | Record<string, never>

const dataModule = createDataModule<InitiateEmailState, SSOAPIError>(
  initiateEmailDataKey,
  `${sso.paths.mfa}/email`,
  api.ssoBase
)

const { selectData, selectIsLoading, selectErrors, resetData, CREATE_DONE } =
  dataModule

export {
  selectErrors as selectInitiateEmailErrors,
  selectIsLoading as selectInitiateEmailIsLoading,
  resetData as resetInitiateEmailData,
}

export const initiateEmail = (email: string) => {
  return {
    type: initiateEmailActions.create,
    payload: { email },
  }
}

function* initiateEmailDoneSaga() {
  const { id, email } = yield select(selectData)
  yield put(fetchMFA())
  yield put(setupMFAEmail(id, email))
  yield put(setVisibleModal(EMAIL_VERIFY_CODE_MODAL_ID))
}

function* subscribeToInitiateEmailDoneSaga() {
  yield takeEvery(CREATE_DONE, initiateEmailDoneSaga)
}

export function* initiateEmailRootSaga() {
  yield all([
    spawn(dataModule.rootSaga),
    spawn(subscribeToInitiateEmailDoneSaga),
  ])
}

export const initiateEmailReducer = dataModule.reducer
