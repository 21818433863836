import React from 'react'

import { NoBackupsAvailable } from 'features/backups/NoBackupsAvailable'
import { ALL_KEY } from 'sharedConstants'
import { UNCATEGORIZED } from 'features/devices/fallbackShadowData'

import { MINIMUM_LISTINGS_PER_PAGE } from './BackupsContext'
import { BackupsGroupItem } from './BackupsGroupItem'
import { BackupsListContainer, StyledAccordion } from './Backups.styles'
import { BackupsPagination } from './BackupsPagination'
import { DeviceBackupsItem } from './DeviceBackupsItem'
import { DeviceBackupsArchived, DeviceBackupsWithShadow } from './types'
import DeleteBackupModal from './DeleteBackupModal'

interface Props {
  handlePaginationClick: (page: number) => void
  selectedDevice: string
  devicesWithBackupsToDisplay: (
    | DeviceBackupsWithShadow
    | DeviceBackupsArchived
  )[]
  devicesWithBackups: (DeviceBackupsWithShadow | DeviceBackupsArchived)[]
  currentPage: number
  isArchived?: boolean
}

export const BackupsList: React.FC<Props> = ({
  handlePaginationClick,
  devicesWithBackupsToDisplay,
  devicesWithBackups,
  selectedDevice,
  currentPage,
  isArchived = false,
}) => {
  const handleNextClick = () => {
    handlePaginationClick(currentPage + 1)
  }

  const handlePreviousClick = () => {
    if (currentPage === 1) {
      handlePaginationClick(1)
    } else {
      handlePaginationClick(currentPage - 1)
    }
  }

  return (
    <BackupsListContainer>
      {devicesWithBackupsToDisplay.map((device, i) => {
        const isDeviceMissingShadow =
          (device as DeviceBackupsWithShadow).shadow?.state?.reported?.name ===
          UNCATEGORIZED

        return (
          <StyledAccordion
            multiOpen={true}
            key={`${device.deviceId}-${i}`}
            variant="secondary"
            contentContainerClassName="noMargin"
            items={[
              {
                id: device.deviceId,
                openByDefault: false,
                renderLabel: (itemExpanded, togglePanel) => (
                  <DeviceBackupsItem
                    handleToggle={() => togglePanel(i.toString())}
                    deviceBackups={device}
                    itemExpanded={itemExpanded}
                    totalAmount={device.backups.length}
                  />
                ),
                renderContent: () => {
                  return device.backups.length ? (
                    <BackupsGroupItem
                      backups={device.backups}
                      deviceId={device.deviceId}
                    />
                  ) : (
                    <NoBackupsAvailable
                      deviceId={device.deviceId}
                      hideRedirect={isDeviceMissingShadow}
                    />
                  )
                },
              },
            ]}
          />
        )
      })}

      {devicesWithBackups.length > MINIMUM_LISTINGS_PER_PAGE &&
        selectedDevice === ALL_KEY && (
          <BackupsPagination
            totalDevices={devicesWithBackups.length}
            selectedDevice={selectedDevice}
            handleNextClick={handleNextClick}
            handlePreviousClick={handlePreviousClick}
            isArchived={isArchived}
          />
        )}

      <DeleteBackupModal />
    </BackupsListContainer>
  )
}
