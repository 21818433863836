import { all, put, spawn, takeEvery } from 'redux-saga/effects'
import { api } from 'api'

import createDataModule from 'utils/moduleCreator'

const {
  api: { sso },
} = __CONFIG__

export const dataKey = 'twoFaReset'

export interface TwoFaResetState {
  isResetted?: boolean
}

const dataModule = createDataModule<TwoFaResetState>(
  dataKey,
  sso.paths.twoFaReset,
  api.ssoBase
)

export const { reducer: twoFaResetReducer } = dataModule

export function* resetTwoFaSaga() {
  yield put(dataModule.setData({ isResetted: true }))
}

export function* subscribeToResetTwoFaSaga() {
  yield takeEvery(dataModule.REMOVE_DONE, resetTwoFaSaga)
}

export function* twoFaResetRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToResetTwoFaSaga)])
}
