import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchInvoices } from 'features/subscriptions/module/invoices'
import {
  FullSubscription,
  selectFullSubscriptions,
} from 'features/subscriptions/fullSubscriptions/fullSubscriptions'

const RefetchInvoicesInProgress = () => {
  const dispatch = useDispatch()
  const fullSubscriptions = useSelector(selectFullSubscriptions)
  const [timesFetched, setTimesFetched] = useState(5)

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null
    fullSubscriptions.forEach(({ isInProgress }: FullSubscription) => {
      // If cancelled subscription has a prorated invoice that is in progress
      if (isInProgress && timesFetched > 0) {
        timer && clearTimeout(timer)
        timer = setTimeout(() => {
          setTimesFetched(timesFetched - 1)
          dispatch(fetchInvoices())
        }, 5000)
        return
      }
    })
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [fullSubscriptions, dispatch, timesFetched])

  return <></>
}

export default RefetchInvoicesInProgress
