import { all, put, spawn, takeEvery } from 'redux-saga/effects'

import createDataModule from 'utils/moduleCreator'
import { StripeData } from 'types/types'
import { closeVisibleModal } from 'modules/modals'

import { StripeTaxId } from '../types'
import { fetchTaxId } from './getTaxId'

const {
  api: { billing },
} = __CONFIG__

export const updateTaxIdDataKey = 'updateTaxId'

const dataModule = createDataModule<StripeData<StripeTaxId>>(
  updateTaxIdDataKey,
  billing.paths.taxId,
  billing.base
)

export const {
  UPDATE_DONE: UPDATE_TAX_ID_DONE,
  update: editTaxId,
  selectIsLoading: selectIsUpdateTaxIdIsLoading,
  selectIsDone: selectIsUpdateTaxIdDone,
  selectErrors: selectUpdateTaxIdErrors,
  resetErrors: resetUpdateTaxIdErrors,
  reducer: updateTaxIdReducer,
} = dataModule

const UPDATE_TAX_ID = updateTaxIdDataKey

function* updateTaxIdSaga(action: ReturnType<typeof editTaxId>) {
  yield put(dataModule.update(action.payload, { pick: ['owner'] }))
}

function* TaxIdDoneSaga() {
  yield put(closeVisibleModal())
  yield put(fetchTaxId())
}

function* subscribeToUpdateTaxIdSaga() {
  yield takeEvery(UPDATE_TAX_ID, updateTaxIdSaga)
}

function* subscribeToUpdateTaxIdDoneSaga() {
  yield takeEvery(UPDATE_TAX_ID_DONE, TaxIdDoneSaga)
}

export function* updateTaxIdRootSaga() {
  yield all([
    spawn(dataModule.rootSaga),
    spawn(subscribeToUpdateTaxIdDoneSaga),
    spawn(subscribeToUpdateTaxIdSaga),
  ])
}
