import React from 'react'
import { UidbImage_Console } from '@ubnt/ui-components/aria'
import {
  AccessLogo,
  AddClientIcon,
  AdminIcon,
  AllCamerasIcon,
  ConnectLogo,
  DashboardIcon,
  HomeIcon,
  IndicatorCkGen2PlusDisconnectedIcon,
  InternetShieldIcon,
  MultipleHostDevicesIconL,
  NetworkLogo,
  OfficeIcon,
  PortForwardIcon,
  ProtectLogo,
  TalkLogo,
  WiFiPlainIcon,
} from '@ubnt/icons'

import styled from 'theme/styled'
import switchImg from 'assets/images/support/switch.png'

import {
  AdoptionTrouble,
  App,
  Assistance,
  Console,
  Device,
  Model,
  TalkVip,
  Tech,
  Trouble,
  UniFiStateValues,
  UserType,
  ZDFormValueType,
} from './types'

const SwitchIcon = styled.img`
  width: 60px;
  height: 6opx;
`

export const app: ZDFormValueType[] = [
  {
    id: App.network,
    label: 'Network',
    icon: <InternetShieldIcon size="large" />,
    condition: { id: 'hasNetwork', field: UniFiStateValues.CONSOLE },
    extraConditions: {
      id: [
        Console.cloudGatewayUnlisted,
        Console.cloudKeyUnlisted,
        Console.cloudUnlisted,
        Console.selfHosted,
        Console.standalone,
      ],
      field: UniFiStateValues.CONSOLE,
    },
  },
  {
    id: App.protect,
    label: 'Protect',
    icon: <AllCamerasIcon size="large" />,
    condition: { id: 'hasProtect', field: UniFiStateValues.CONSOLE },
    extraConditions: {
      id: [
        Console.cloudGatewayUnlisted,
        Console.cloudKeyUnlisted,
        Console.unvrUnlisted,
      ],
      field: UniFiStateValues.CONSOLE,
    },
  },
  {
    id: App.other,
    label: 'Other',
  },
]

export const extraApp: ZDFormValueType[] = [
  {
    id: App.talk,
    label: 'Talk',
    condition: { id: 'hasTalk', field: UniFiStateValues.CONSOLE },
    extraConditions: {
      id: [Console.cloudGatewayUnlisted, Console.cloudKeyUnlisted],
      field: UniFiStateValues.CONSOLE,
    },
  },
  {
    id: App.access,
    label: 'Access',
    condition: { id: 'hasAccess', field: UniFiStateValues.CONSOLE },
    extraConditions: {
      id: [Console.cloudGatewayUnlisted, Console.cloudKeyUnlisted],
      field: UniFiStateValues.CONSOLE,
    },
  },
  {
    id: App.identity,
    label: 'Identity',
    condition: { id: 'hasUID', field: UniFiStateValues.CONSOLE },
    extraConditions: {
      id: [
        Console.cloudGatewayUnlisted,
        Console.cloudKeyUnlisted,
        Console.unvrUnlisted,
      ],
      field: UniFiStateValues.CONSOLE,
    },
  },
  {
    id: App.connect,
    label: 'Connect',
    condition: { id: 'hasConnect', field: UniFiStateValues.CONSOLE },
    extraConditions: {
      id: [Console.cloudGatewayUnlisted, Console.cloudKeyUnlisted],
      field: UniFiStateValues.CONSOLE,
    },
  },
]

export const adoptionTrouble: ZDFormValueType[] = [
  {
    id: AdoptionTrouble.deviceNoShow,
    label: 'Device not appearing for adoption',
  },
  {
    id: AdoptionTrouble.adoptionFailed,
    label: 'Device says “Adoption Failed”',
  },
]

export const assistance: ZDFormValueType[] = [
  {
    id: Assistance.setupAdopt,
    label: 'Setup & Device Adoption',
    icon: <AddClientIcon size="large" />,
  },
  {
    id: Assistance.hwFailure,
    label: 'Possible Hardware Failure',
    icon: <IndicatorCkGen2PlusDisconnectedIcon size="large" />,
  },
  {
    id: Assistance.adminUpdates,
    label: 'Administration & Updates',
    icon: <AdminIcon size="large" />,
  },
  {
    id: Assistance.performOperation,
    label: 'Performance & Operation',
    icon: <DashboardIcon size="large" />,
  },
  {
    id: Assistance.featureConfig,
    label: 'Pre-Sales & Configurations',
    icon: <MultipleHostDevicesIconL size="large" />,
  },
]

export const otherConsole: ZDFormValueType = {
  id: Console.other,
  label: 'Other',
}

export const extraConsoles: ZDFormValueType[] = [
  {
    id: Console.cloudGatewayUnlisted,
    label: 'Cloud Gateway',
    icon: <UidbImage_Console shortname="UDM-PRO" size="60px" />,
  },
  {
    id: Console.cloudKeyUnlisted,
    label: 'Cloud Key',
    icon: <UidbImage_Console shortname="UCK-G2-PLUS" size="60px" />,
  },
  {
    id: Console.unvrUnlisted,
    label: 'Network Video Recorder',
    icon: <UidbImage_Console shortname="UNVR" size="60px" />,
  },
  {
    id: Console.cloudUnlisted,
    label: 'Official UniFi Hosting',
    icon: <UidbImage_Console shortname="CLOUD" size="60px" />,
  },
  {
    id: Console.selfHosted,
    label: 'Self-Hosted Network Server',
    icon: (
      <UidbImage_Console shortname="SELF-HOSTED-NETWORK-SERVER" size="60px" />
    ),
  },
  {
    id: Console.standalone,
    label: 'Standalone AP',
    icon: <NetworkLogo variant="aqua" width="60px" height="60px" />,
  },
]

export const device: ZDFormValueType[] = [
  {
    id: Device.gatewayConsole,
    label: 'UniFi Console or Gateway',
    icon: <UidbImage_Console shortname="UDMPRO" size="60px" />,
  },
  {
    id: Device.ap,
    label: 'AP',
    icon: <NetworkLogo variant="aqua" width="60px" height="60px" />,
    condition: { id: 'hasNetwork', field: UniFiStateValues.CONSOLE },
  },
  {
    id: Device.switch,
    label: 'Switch',
    // TODO: Update icon to Switch when uidb adds support in uic
    icon: <SwitchIcon src={switchImg} />,
    condition: { id: 'hasNetwork', field: UniFiStateValues.CONSOLE },
  },
  {
    id: Device.camera,
    label: 'Camera',
    icon: <ProtectLogo variant="aqua" width="60px" height="60px" />,
    condition: { id: 'hasProtect', field: UniFiStateValues.CONSOLE },
  },
  {
    id: Device.talkDevice,
    label: 'Talk Device',
    icon: <TalkLogo variant="aqua" width="60px" height="60px" />,
    condition: { id: 'hasTalk', field: UniFiStateValues.CONSOLE },
  },
  {
    id: Device.accessDevice,
    label: 'Access Device',
    icon: <AccessLogo variant="aqua" width="60px" height="60px" />,
    condition: { id: 'hasAccess', field: UniFiStateValues.CONSOLE },
  },
  {
    id: Device.connectDevice,
    label: 'Connect Device',
    icon: <ConnectLogo variant="aqua" width="60px" height="60px" />,
    condition: { id: 'hasConnect', field: UniFiStateValues.CONSOLE },
  },
]

export const model: ZDFormValueType[] = [
  {
    id: Model.cloudGateway,
    label: 'Cloud Gateway',
    icon: <UidbImage_Console shortname="UDM-PRO" size="60px" />,
  },
  {
    id: Model.cloudKey,
    label: 'Cloud Key',
    icon: <UidbImage_Console shortname="UCK-G2-PLUS" size="60px" />,
  },
  {
    id: Model.networkVideoRecorder,
    label: 'Network Video Recorder',
    hasFooter: true,
    icon: <UidbImage_Console shortname="UNVR" size="60px" />,
  },
  {
    id: Model.officialCloudHosting,
    label: 'Official UniFi Hosting',
    icon: <UidbImage_Console shortname="CLOUD" size="60px" />,
  },
  {
    id: Model.selfHostedNetServer,
    label: 'Self-Hosted Network Server',
    icon: (
      <UidbImage_Console shortname="SELF-HOSTED-NETWORK-SERVER" size="60px" />
    ),
  },
  {
    id: Model.standaloneAp,
    label: 'Standalone AP',
    icon: <NetworkLogo variant="aqua" width="60px" height="60px" />,
  },
]

export const talkVip: ZDFormValueType[] = [
  {
    id: TalkVip.vip,
    label: 'Yes, I need help with that',
  },
  {
    id: TalkVip.regular,
    label: 'No, I don’t need help with that',
  },
]

export const tech: ZDFormValueType[] = [
  {
    id: Tech.wifi,
    label: 'WiFi',
    icon: <WiFiPlainIcon size="large" />,
    condition: { id: 'hasNetwork', field: UniFiStateValues.CONSOLE },
    extraConditions: {
      id: [
        Console.cloudGatewayUnlisted,
        Console.cloudKeyUnlisted,
        Console.cloudUnlisted,
        Console.selfHosted,
        Console.standalone,
      ],
      field: UniFiStateValues.CONSOLE,
    },
  },
  {
    id: Tech.routingAndSwitching,
    label: 'Routing & Switching',
    icon: <PortForwardIcon size="large" />,
    condition: { id: 'hasNetwork', field: UniFiStateValues.CONSOLE },
    extraConditions: {
      id: [
        Console.cloudGatewayUnlisted,
        Console.cloudKeyUnlisted,
        Console.cloudUnlisted,
        Console.selfHosted,
      ],
      field: UniFiStateValues.CONSOLE,
    },
  },
  {
    id: Tech.securityAndVPN,
    label: 'Security & VPN',
    icon: <InternetShieldIcon size="large" />,
    condition: { id: 'hasNetwork', field: UniFiStateValues.CONSOLE },
    extraConditions: {
      id: [
        Console.cloudGatewayUnlisted,
        Console.cloudKeyUnlisted,
        Console.cloudUnlisted,
        Console.selfHosted,
      ],
      field: UniFiStateValues.CONSOLE,
    },
  },
  {
    id: Tech.protect,
    label: 'Protect',
    icon: <AllCamerasIcon size="large" />,
    condition: { id: 'hasProtect', field: UniFiStateValues.CONSOLE },
    extraConditions: {
      id: [
        Console.cloudGatewayUnlisted,
        Console.cloudKeyUnlisted,
        Console.unvrUnlisted,
      ],
      field: UniFiStateValues.CONSOLE,
    },
  },
  {
    id: Tech.other,
    label: 'Other',
  },
]

export const extraTech: ZDFormValueType[] = [
  {
    id: Tech.talk,
    label: 'Talk',
    condition: { id: 'hasTalk', field: UniFiStateValues.CONSOLE },
    extraConditions: {
      id: [Console.cloudGatewayUnlisted, Console.cloudKeyUnlisted],
      field: UniFiStateValues.CONSOLE,
    },
  },
  {
    id: Tech.access,
    label: 'Access',
    condition: { id: 'hasAccess', field: UniFiStateValues.CONSOLE },
    extraConditions: {
      id: [Console.cloudGatewayUnlisted, Console.cloudKeyUnlisted],
      field: UniFiStateValues.CONSOLE,
    },
  },
  {
    id: Tech.identity,
    label: 'Identity',
    condition: { id: 'hasUID', field: UniFiStateValues.CONSOLE },
    extraConditions: {
      id: [
        Console.cloudGatewayUnlisted,
        Console.cloudKeyUnlisted,
        Console.unvrUnlisted,
      ],
      field: UniFiStateValues.CONSOLE,
    },
  },
  {
    id: Tech.connect,
    label: 'Connect',
    condition: { id: 'hasConnect', field: UniFiStateValues.CONSOLE },
    extraConditions: {
      id: [Console.cloudGatewayUnlisted, Console.cloudKeyUnlisted],
      field: UniFiStateValues.CONSOLE,
    },
  },
]

export const trouble: ZDFormValueType[] = [
  {
    id: Trouble.firstTimeSetup,
    label: 'First Time UniFi Setup',
    condition: {
      id: Assistance.setupAdopt,
      field: UniFiStateValues.ASSISTANCE,
    },
  },
  {
    id: Trouble.deviceAdoption,
    label: 'Adopting Devices to UniFi',
    condition: {
      id: Assistance.setupAdopt,
      field: UniFiStateValues.ASSISTANCE,
    },
  },
  {
    id: Trouble.noPowerOn,
    label: 'Device not turning on',
    condition: { id: Assistance.hwFailure, field: UniFiStateValues.ASSISTANCE },
  },
  {
    id: Trouble.crashing,
    label: 'Device crashing or rebooting',
    condition: { id: Assistance.hwFailure, field: UniFiStateValues.ASSISTANCE },
  },
  {
    id: Trouble.performance,
    label: 'Severely degraded performance',
    condition: { id: Assistance.hwFailure, field: UniFiStateValues.ASSISTANCE },
  },
  {
    id: Trouble.appNoStart,
    label: 'Application not starting',
    condition: {
      id: Assistance.adminUpdates,
      field: UniFiStateValues.ASSISTANCE,
    },
  },
  {
    id: Trouble.updates,
    label: 'Software and device updates',
    condition: {
      id: Assistance.adminUpdates,
      field: UniFiStateValues.ASSISTANCE,
    },
  },
  {
    id: Trouble.noSignIn,
    label: 'Unable to sign-in',
    condition: {
      id: Assistance.adminUpdates,
      field: UniFiStateValues.ASSISTANCE,
    },
  },
  {
    id: Trouble.adminUsersPermissions,
    label: 'Admins, users, and permissions',
    condition: {
      id: Assistance.adminUpdates,
      field: UniFiStateValues.ASSISTANCE,
    },
  },
]

export const userType: ZDFormValueType[] = [
  {
    id: UserType.home,
    label: 'Home User',
    icon: <HomeIcon size="large" />,
  },
  {
    id: UserType.business,
    label: 'Business User',
    icon: <OfficeIcon size="large" />,
  },
]
