import { createSelector } from 'reselect'
import { all, call, put, select, spawn, takeEvery } from 'redux-saga/effects'
import { api } from 'api'
import axios from 'axios'

import { RootState } from 'types/types'
import { setVisibleModal } from 'modules/modals'
import { REAUTH_MFA_MODAL_ID } from 'pages/security/components/ReauthModal'
import { createBackupCode } from 'pages/security/components/BackupCodes/modules/generateBackupCodes'
import {
  TOTP_QR_CODE_MODAL_ID,
  TOTP_REMOVE_MODAL_ID,
} from 'pages/security/components/TOTP'
import { EMAIL_REMOVE_MODAL_ID } from 'pages/security/components/Email'
import { selectMFASetup } from 'modules/mfaSetup'
import { initiateMFATOTP } from 'pages/security/components/TOTP/modules/initiateTOTP'
import { BACKUP_CODES_MODAL_ID } from 'pages/security/components/BackupCodes/BackupCodesModal'
import { removeMFAEmail } from 'pages/security/components/Email/modules/removeEmail'
import { SMS_REMOVE_MODAL_ID } from 'pages/security/components/SMS/RemoveModal'
import { removeMFASMS } from 'pages/security/components/SMS/modules/removeMFASMS'
import { PASSKEY_REMOVE_MODAL_ID } from 'pages/security/components/Passkey/RemoveModal'
import { removeMFAPasskey } from 'pages/security/components/Passkey/modules/removePasskey'
import { removeMFATOTP } from 'pages/security/components/TOTP/modules/removeTOTP'

export const reauthMFADataKey = 'reauthMFA'

export interface ReauthMFAState {
  modalId: string | null
  token: string | null
  defaultErrorMessage?: string
  isLoading: boolean
  error?: string
}

const initialState: ReauthMFAState = {
  modalId: null,
  token: null,
  isLoading: false,
  error: undefined,
}

export enum ReauthMFAActionTypes {
  REAUTH_MFA = 'REAUTH_MFA',
  REAUTH_MFA_SHOW_MODAL = 'REAUTH_MFA_SHOW_MODAL',
  REAUTH_MFA_START = 'REAUTH_MFA_START',
  REAUTH_MFA_FAILED = 'REAUTH_MFA_FAILED',
  REAUTH_MFA_DONE = 'REAUTH_MFA_DONE',
  REAUTH_MFA_RESET_ERROR = 'REAUTH_MFA_RESET_ERROR',
}

type Action =
  | {
      type: ReauthMFAActionTypes.REAUTH_MFA
      payload: {
        token: string
        defaultErrorMessage: string
      }
    }
  | {
      type:
        | ReauthMFAActionTypes.REAUTH_MFA_SHOW_MODAL
        | ReauthMFAActionTypes.REAUTH_MFA_FAILED
      payload: string
    }
  | {
      type:
        | ReauthMFAActionTypes.REAUTH_MFA_START
        | ReauthMFAActionTypes.REAUTH_MFA_DONE
        | ReauthMFAActionTypes.REAUTH_MFA_RESET_ERROR
    }

export const reauthMFAReducer = (state = initialState, action: Action) => {
  let newState: ReauthMFAState

  switch (action.type) {
    case ReauthMFAActionTypes.REAUTH_MFA:
      newState = {
        ...state,
        token: action.payload.token,
        defaultErrorMessage: action.payload.defaultErrorMessage,
        isLoading: false,
        error: undefined,
      }
      return newState
    case ReauthMFAActionTypes.REAUTH_MFA_SHOW_MODAL:
      newState = {
        ...state,
        modalId: action.payload,
        isLoading: false,
        error: undefined,
      }
      return newState
    case ReauthMFAActionTypes.REAUTH_MFA_START:
      newState = {
        ...state,
        isLoading: true,
        error: undefined,
      }
      return newState
    case ReauthMFAActionTypes.REAUTH_MFA_FAILED:
      newState = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      return newState
    case ReauthMFAActionTypes.REAUTH_MFA_DONE:
      newState = {
        ...state,
        isLoading: false,
        error: undefined,
      }
      return newState
    case ReauthMFAActionTypes.REAUTH_MFA_RESET_ERROR:
      newState = {
        ...state,
        error: undefined,
      }
      return newState

    default:
      return state
  }
}

/**
 * Actions
 */

export const reauthMFAStart = (): Action => ({
  type: ReauthMFAActionTypes.REAUTH_MFA_START,
})

export const reauthMFAFailed = (error: string): Action => ({
  type: ReauthMFAActionTypes.REAUTH_MFA_FAILED,
  payload: error,
})

export const reauthMFADone = (): Action => ({
  type: ReauthMFAActionTypes.REAUTH_MFA_DONE,
})

/**
 * Selectors
 */

export const selectReauthMFA = (state: RootState) => state[reauthMFADataKey]

const selectReauthMFAToken = createSelector(
  selectReauthMFA,
  ({ token }) => token
)

export const selectReauthMFADefaultErrorMessage = createSelector(
  selectReauthMFA,
  ({ defaultErrorMessage }) => defaultErrorMessage
)

export const selectReauthMFAModalId = createSelector(
  selectReauthMFA,
  ({ modalId }) => modalId
)

/**
 * Sagas
 */

function* reauthMFASaga() {
  const token: string = yield select(selectReauthMFAToken)
  const defaultErrorMessage: string = yield select(
    selectReauthMFADefaultErrorMessage
  )

  yield put(reauthMFAStart())

  try {
    yield call(api.loginMFA, { token })
  } catch (error) {
    let errorMessage = defaultErrorMessage
    if (axios.isAxiosError(error)) {
      const tokenError: string = error.response?.data?.token?.find(() => true)
      if (tokenError) {
        errorMessage = tokenError
      } else if (error.response?.data?.detail) {
        errorMessage = error.response?.data?.detail
      }
    }
    yield put(reauthMFAFailed(errorMessage))
    return
  }

  yield put(reauthMFADone())
}

function* subscribeToReauthMFASaga() {
  yield takeEvery(ReauthMFAActionTypes.REAUTH_MFA, reauthMFASaga)
}

function* reauthMFAShowModalSaga() {
  yield put(setVisibleModal(REAUTH_MFA_MODAL_ID))
}

function* subscribeToReauthMFAShowModalSaga() {
  yield takeEvery(
    ReauthMFAActionTypes.REAUTH_MFA_SHOW_MODAL,
    reauthMFAShowModalSaga
  )
}

function* reauthMFADoneSaga() {
  const modalId: string = yield select(selectReauthMFAModalId)
  const { removeSMS, removeEmail, removeTOTP, removePasskey } =
    yield select(selectMFASetup)

  switch (modalId) {
    case BACKUP_CODES_MODAL_ID:
      yield put(createBackupCode())
      yield put(setVisibleModal(modalId))
      break
    case TOTP_QR_CODE_MODAL_ID:
      yield put(initiateMFATOTP())
      yield put(setVisibleModal(modalId))
      break
    case TOTP_REMOVE_MODAL_ID:
      yield put(removeMFATOTP(removeTOTP.id))
      break
    case PASSKEY_REMOVE_MODAL_ID:
      yield put(removeMFAPasskey(removePasskey.id))
      break
    case SMS_REMOVE_MODAL_ID:
      yield put(removeMFASMS(removeSMS.id))
      break
    case EMAIL_REMOVE_MODAL_ID:
      yield put(removeMFAEmail(removeEmail.id))
      break
    default:
      yield put(setVisibleModal(modalId))
  }
}

function* subscribeToReauthMFADoneSaga() {
  yield takeEvery(ReauthMFAActionTypes.REAUTH_MFA_DONE, reauthMFADoneSaga)
}

export function* reauthMFARootSaga() {
  yield all([
    spawn(subscribeToReauthMFASaga),
    spawn(subscribeToReauthMFAShowModalSaga),
    spawn(subscribeToReauthMFADoneSaga),
  ])
}
