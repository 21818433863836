import { all, put, select, spawn, takeEvery } from 'redux-saga/effects'

import { api } from 'api'
import { MFAType, fetchMFA } from 'modules/mfa'
import createDataModule from 'utils/moduleCreator'
import { SSOAPIError } from 'types/types'
import { MFAAuthenticatorStatus } from 'enums'
import { setVisibleModal } from 'modules/modals'
import { setupMFASMS } from 'modules/mfaSetup'

import { SMS_VERIFY_CODE_MODAL_ID } from '../VerifyCodeModal'

const {
  api: { sso },
} = __CONFIG__

export const initiateMFASMSDataKey = 'initiateMFASMS'

const InitiateMFASMSActions = {
  create: `${initiateMFASMSDataKey}/CREATE`,
}

export type InitiateMFASMSState =
  | {
      phone_number: string
      type: MFAType.sms
      id: string
      created: string
      last_success: string | null
      status: MFAAuthenticatorStatus
    }
  | Record<string, never>

const dataModule = createDataModule<InitiateMFASMSState, SSOAPIError>(
  initiateMFASMSDataKey,
  `${sso.paths.mfa}/sms`,
  api.ssoBase
)

const { selectData, selectIsLoading, selectErrors, resetData, CREATE_DONE } =
  dataModule

export {
  selectIsLoading as selectInitiateMFASMSIsLoading,
  selectErrors as selectInitiateMFASMSErrors,
  resetData as resetInitiateMFASMSData,
}

export const initiateMFASMS = (phoneNumber: string) => {
  return {
    type: InitiateMFASMSActions.create,
    payload: { phone_number: phoneNumber },
  }
}

function* initiateSMSDoneSaga() {
  const { id, phone_number } = yield select(selectData)
  yield put(fetchMFA())
  yield put(setupMFASMS(id, phone_number))
  yield put(setVisibleModal(SMS_VERIFY_CODE_MODAL_ID))
}

function* subscribeToInitiateSMSDoneSaga() {
  yield takeEvery(CREATE_DONE, initiateSMSDoneSaga)
}

export function* initiateMFASMSRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToInitiateSMSDoneSaga)])
}

export const initiateMFASMSReducer = dataModule.reducer
