import React from 'react'

import { Bubble } from 'components/support/Bubble'
import { TilesWrapper } from 'components/support/Wrappers'
import { QuestionTile } from 'components/support/QuestionTile'

import { BubbleProps, SupportConsole } from './types'
import { config } from './config/formConfig'
import { billingSpecificService } from './config/ConfigOptions'

export const BillingServiceBubble: React.FC<BubbleProps> = ({
  handleChange,
  values,
}) => {
  const getNextSection = (): string | undefined =>
    config.billingSpecificService.nextSection?.section

  const handleClick = (value?: string | boolean | SupportConsole) => {
    const section = getNextSection()
    handleChange('billingSpecificService', value, section)
  }

  return (
    <Bubble question={config.billingSpecificService.fieldTitle}>
      <TilesWrapper>
        {billingSpecificService.map(({ icon, label, id }) => (
          <QuestionTile
            key={id}
            icon={icon}
            label={label}
            onClick={() => handleClick(id)}
            isActive={values.billingSpecificService === id}
          />
        ))}
      </TilesWrapper>
    </Bubble>
  )
}
