import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { ConfirmationCode } from '@ubnt/ui-components/ConfirmationCode'

import PublicPage from 'pages/PublicPage'
import { useHistory } from 'react-router-dom'
import styled from 'theme/styled'
import { ButtonLink } from '@ubnt/ui-components/Button'
import axios from 'axios'
import { ChangeEmailHeader } from './ChangeEmailHeader'
import { useSelector } from 'react-redux'
import { selectCanChangeEmail } from 'features/auth/modules/auth'

const {
  api: { sso, ssoUi },
  featureFlags: { isOneTimeEmailChangeEnabled },
} = __CONFIG__

const INPUT_LENGTH = 6

export const ConfirmEmailChange = () => {
  const intl = useIntl()
  const history = useHistory<{ email: string }>()
  const canChangeEmail = useSelector(selectCanChangeEmail)

  if (!canChangeEmail || !isOneTimeEmailChangeEnabled) {
    history.push('/login')
  }
  const [userInput, setUserInput] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [apiError, setApiError] = useState<boolean | null>(null)

  const handleChange = useCallback((val: string): void => {
    if (val.length === INPUT_LENGTH) {
      setIsLoading(true)
    }
    setApiError(null)
    setUserInput(val)
  }, [])

  const getHelperMessage = (): string | undefined => {
    if (isLoading) {
      return intl.formatMessage({
        id: 'LOGIN_CHANGE_EMAIL_CONFIRM_LOADING',
      })
    }

    if (apiError) {
      return intl.formatMessage({
        id: 'LOGIN_CHANGE_EMAIL_CONFIRM_API_ERROR',
      })
    }

    return undefined
  }

  useEffect(() => {
    const mockApiCall = async () => {
      try {
        const url = new URL(sso.paths.verifyEmailChangeOtp, ssoUi.base)
        const res = await axios.put(
          url.href,
          {
            email: history.location.state?.email,
            email_change_verification_code: userInput,
          },
          {
            withCredentials: true,
          }
        )

        if (res.status === 200) {
          history.push({
            pathname: '/login/change-email/success',
            state: {
              email: history.location.state?.email,
            },
          })
        } else {
          setApiError(true)
        }
      } catch (error) {
        setApiError(true)
      }
      setIsLoading(false)
    }

    if (userInput.length === INPUT_LENGTH && isLoading) {
      mockApiCall()
    }
  }, [userInput, isLoading, history])

  return (
    <PublicPage showLogo={false} formHeight="368px">
      <ChangeEmailHeader
        title={intl.formatMessage({ id: 'SETTINGS_PROFILE_CHANGE_EMAIL' })}
        subtitle={intl.formatMessage(
          { id: 'LOGIN_CAHNGE_EMAIL_CONFIRM_DESCRIPTION' },
          { b: () => <b>{history.location.state?.email}</b> }
        )}
      />
      <StyledConfirmationCode
        type="number"
        tokens={6}
        onChange={handleChange}
        disabled={isLoading}
        invalid={!!apiError}
        helperMessage={getHelperMessage()}
      />
      <StyledButtonLink onClick={() => history.push('/login')}>
        {intl.formatMessage({ id: 'COMMON_ACTION_CANCEL' })}
      </StyledButtonLink>
    </PublicPage>
  )
}

const StyledConfirmationCode = styled(ConfirmationCode)`
  margin-top: 32px;
  min-width: 275px;
`

const StyledButtonLink = styled(ButtonLink)`
  margin-top: 24px;
  color: ${({ theme }) => theme.neutral09};

  &:hover {
    color: ${({ theme }) => theme.neutral08};
  }
`
