import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Tooltip } from '@ubnt/ui-components/Tooltip'

import { MFAType, selectMFAAuthenticators } from 'modules/mfa'
import { Option } from './styled'

type MFARemoveButtonProps = {
  onClick: () => void
  type: MFAType
  isActive: boolean
}

export const MFARemoveButton: React.FC<MFARemoveButtonProps> = ({
  onClick,
  type,
  isActive,
}) => {
  const intl = useIntl()
  const authenticators = useSelector(selectMFAAuthenticators).filter(
    (auth) => auth.status === 'active'
  )

  const disabled =
    authenticators.length < 3 && type === MFAType.email && isActive

  if (disabled) {
    return (
      <Tooltip
        description={intl.formatMessage({
          id: 'SETTINGS_MFA_REMOVE_MODAL_DISABLED_TOOLTIP_DESCRIPTION',
        })}
        portal
      >
        <Option type="delete" disabled>
          {intl.formatMessage({ id: 'COMMON_ACTION_DELETE' })}
        </Option>
      </Tooltip>
    )
  }

  return (
    <Option onClick={onClick} type="delete">
      {intl.formatMessage({ id: 'COMMON_ACTION_DELETE' })}
    </Option>
  )
}
