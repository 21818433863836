import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'

import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { Input } from 'components/Input'
import { ModalForm } from 'components/ModalForm'
import Yup from 'validators/yupLocaleConfig'
import { InputGroup } from 'components/form'
import styled from 'theme/styled'
import { GenericModal } from 'components/generic-modal/GenericModal'

import {
  deleteAccount,
  selectDeleteAccountErrors,
  selectDeleteAccountIsLoading,
} from '../module/deleteAccount'

export const ConfirmDeleteAccountModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectDeleteAccountIsLoading)
  const apiErrors = useSelector(selectDeleteAccountErrors)

  const { handleChange, handleBlur, handleSubmit, touched, errors, values } =
    useFormik({
      initialValues: {
        password: '',
      },
      onSubmit: (values) => {
        dispatch(deleteAccount(values.password))
      },
      validationSchema: Yup.object().shape({
        password: Yup.string()
          .required()
          .label('SETTINGS_SECURITY_DELETE_PASSWORD'),
      }),
    })
  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={<FormattedMessage id="SETTINGS_SECURITY_DELETE" />}
      size="small"
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CANCEL" />,
          onClick: onClose,
          variant: 'tertiary',
        },
        {
          text: <FormattedMessage id="SETTINGS_SECURITY_DELETE_BUTTON" />,
          variant: 'primary',
          color: 'danger',
          onClick: handleSubmit as any,
          disabled: !values.password || isLoading,
          loader: isLoading ? 'dots' : undefined,
          type: 'submit',
        },
      ]}
    >
      <ModalForm onSubmit={handleSubmit} style={{ height: '100%' }}>
        <Wrapper>
          <FormattedMessage id="SETTINGS_SECURITY_DELETE_MODAL_CONFIRM" />
          <>
            <Label>
              <FormattedMessage id="SETTINGS_SECURITY_DELETE_MODAL_BOLD" />
            </Label>
            <InputGroupStyled full>
              <Input
                variant="secondary"
                type="password"
                name="password"
                value={values.password}
                disabled={isLoading}
                invalid={
                  touched.password && (errors?.password || apiErrors?.detail)
                }
                onChange={handleChange}
                onBlur={handleBlur}
                passwordToggle
                focus
                full
              />
            </InputGroupStyled>
          </>
        </Wrapper>
      </ModalForm>
    </GenericModal>
  )
}

export const CONFIRM_DELETE_ACCOUNT_MODAL = 'CONFIRM_DELETE_ACCOUNT_MODAL'

export const WrappedConfirmDeleteAccountModal = () => (
  <ModalWrapper modalId={CONFIRM_DELETE_ACCOUNT_MODAL}>
    <ConfirmDeleteAccountModal />
  </ModalWrapper>
)

const InputGroupStyled = styled(InputGroup)`
  span {
    top: 4px;
  }
`

const Wrapper = styled('div')`
  height: 100%;
`

const Label = styled('div')`
  font-size: 14px;
  color: ${({ theme }) => theme.text2};
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  margin: 10px 0;
`
