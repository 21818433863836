import { all, put, select, spawn, takeEvery } from 'redux-saga/effects'

import createDataModule from 'utils/moduleCreator'
import { ReduxAction } from 'types/types'

import {
  selectUnparsedSubscriptions,
  setSubscriptionsData,
} from './subscriptions'
import { CustomerSubscription, Subscriptions } from './types'

const {
  api: { billing },
} = __CONFIG__

export interface SubscriptionsUpdate {
  id?: string
  default_source?: string
  billing_cycle_anchor?: string
  region?: string
}

const getUrl = (url: string, { id, region }: SubscriptionsUpdate) => {
  return `${url}/${id}?region=${region}`
}

export const dataKey = 'subscriptionsUpdate'

export const dataModule = createDataModule<SubscriptionsUpdate>(
  dataKey,
  billing.paths.subscriptions,
  billing.base,
  undefined,
  getUrl
)

export const {
  reducer: subscriptionsUpdateReducer,
  updatePartial: updateSubscription,
  selectIsLoading: selectIsSubscriptionsUpdateLoading,
} = dataModule

function* subscriptionUpdatedSaga(action: ReduxAction<CustomerSubscription>) {
  const { payload } = action
  const fetchedSubscriptions: Subscriptions = yield select(
    selectUnparsedSubscriptions
  )
  
  const region = Object.keys(payload)[0]

  const idx = fetchedSubscriptions[region as keyof Subscriptions]?.findIndex(
    ({ id }) => id === payload[region as keyof Subscriptions]?.id
  )

  if (idx >= 0 && payload[region as keyof Subscriptions]) {
    fetchedSubscriptions[region as keyof Subscriptions]?.splice(
      idx,
      1,
      payload[region as keyof CustomerSubscription]
    )

    yield put(setSubscriptionsData({ ...fetchedSubscriptions }))
  }
}

function* subscribeToSubscriptionUpdatedSaga() {
  yield takeEvery(dataModule.UPDATE_PARTIAL_DONE, subscriptionUpdatedSaga)
}

export function* subscriptionsUpdateRootSaga() {
  yield all([
    spawn(dataModule.rootSaga),
    spawn(subscribeToSubscriptionUpdatedSaga),
  ])
}
