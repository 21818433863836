import { useEffect } from 'react'
import { DateTime } from 'luxon'
import { History } from 'history'
import { BrowserUtils } from 'utils/browserUtils'

// enforce checking that localStorage is available
// const localStorage: typeof window.localStorage | undefined = window.localStorage

export const storedOuterRedirectKey = 'storedOuterRedirect'
export const storedInnerRedirectKey = 'storedInnerRedirect'

const {
  api: { sso },
} = __CONFIG__

export interface StoredRedirect {
  url: string
  createdAt: string | null
}

export function useOuterRedirectStore() {
  useEffect(() => {
    const hostUrl = new URL(document.location.href)
    const storedRedirectItem = localStorage?.getItem(storedOuterRedirectKey)
    const storedRedirect: StoredRedirect | undefined =
      storedRedirectItem && JSON.parse(storedRedirectItem)

    const isShopifyRedirect = hostUrl.searchParams.get('shopify') === 'true'

    const createdAt = DateTime.utc().toISO()
    const redirectUrl = hostUrl.searchParams.get('redirect')

    if (storedRedirect) {
      if (
        DateTime.fromISO(storedRedirect.createdAt || '').diffNow('minutes')
          .minutes >= 20
      ) {
        localStorage?.removeItem(storedOuterRedirectKey)
        // return
      }
    }

    const isInvalidRedirect =
      !isShopifyRedirect && !BrowserUtils.validateUrl(redirectUrl)

    const isInvalidShopifyRedirect = !redirectUrl && !isShopifyRedirect

    if (isInvalidRedirect || isInvalidShopifyRedirect) {
      return
    }

    const shopifyRegion = hostUrl.searchParams.get('region')

    const item: StoredRedirect = {
      url:
        redirectUrl ??
        `${sso.base}${sso.paths.shopify_login}${
          shopifyRegion ? `?region=${shopifyRegion}` : ''
        }`,
      createdAt,
    }

    localStorage?.setItem(storedOuterRedirectKey, JSON.stringify(item))
    localStorage?.removeItem(storedInnerRedirectKey)
  }, [])
}

export const innerRedirect = (history: History<any>) => {
  const storedInnerRedirectItem = localStorage?.getItem(storedInnerRedirectKey)

  const innerRedirect: StoredRedirect | undefined =
    storedInnerRedirectItem && JSON.parse(storedInnerRedirectItem)

  const innerRedirectCreatedAt = innerRedirect?.createdAt
    ? DateTime.fromISO(innerRedirect.createdAt || '')
    : DateTime.fromMillis(Infinity)

  if (innerRedirectCreatedAt.diffNow('minutes').minutes > 20) {
    localStorage.removeItem(storedInnerRedirectKey)
    return false
  }

  if (innerRedirect?.url) {
    localStorage.removeItem(storedOuterRedirectKey)
    localStorage.removeItem(storedInnerRedirectKey)
    history?.push(innerRedirect.url)
  }
}

export const outerRedirect = () => {
  const storedOuterRedirectItem = localStorage?.getItem(storedOuterRedirectKey)

  const outerRedirect: StoredRedirect | undefined =
    storedOuterRedirectItem && JSON.parse(storedOuterRedirectItem)

  const isValidRedirect = BrowserUtils.validateUrl(outerRedirect?.url)

  if (!isValidRedirect) return

  const outerRedirectCreatedAt = outerRedirect?.createdAt
    ? DateTime.fromISO(outerRedirect.createdAt || '')
    : DateTime.fromMillis(Infinity)

  if (outerRedirectCreatedAt.diffNow('minutes').minutes > 20) {
    localStorage.removeItem(storedOuterRedirectKey)
    return false
  }

  if (outerRedirect?.url) {
    localStorage.removeItem(storedOuterRedirectKey)
    localStorage.removeItem(storedInnerRedirectKey)
    window.location.replace(outerRedirect.url)
    return true
  }

  return false
}
