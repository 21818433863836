import React, { useContext, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { ClassNames } from '@emotion/react'
import { MoreHorizontalIcon } from '@ubnt/icons'
import Popover from '@ubnt/ui-components/Popover/Popover'

import styled from 'theme/styled'
import closeCircleIconBlue from 'assets/svgs/closeCircleIconBlue.svg'
import retryIcon from 'assets/svgs/retryIcon.svg'
import paymentIcon from 'assets/svgs/paymentIcon.svg'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'

import { FullSubscription } from '../fullSubscriptions/fullSubscriptions'
import { useSubscriptionPaymentMethods } from './hooks/useSubscriptionPaymentMethod'

const MorePopover: React.FC<{
  popoverOpen: number | null
  setPopoverOpen: (value: number | null) => void
  id: number
  disabled?: boolean
  onCancelSubscription: () => void
  fullSubscription: FullSubscription
  setCardPopoverOpen: (id: number | null) => void
  cardPopoverOpen: number | null
  showFailedSubActionButtons: boolean
  isTalk?: boolean
  openChoosePaymentMethodModal?: (fullSubscription: FullSubscription) => {
    type: string
    payload: {
      modalId: string
      props: any
    }
  }
}> = ({
  popoverOpen,
  setPopoverOpen,
  id,
  disabled = false,
  onCancelSubscription,
  fullSubscription,
  setCardPopoverOpen,
  cardPopoverOpen,
  showFailedSubActionButtons,
  openChoosePaymentMethodModal,
  isTalk,
}) => {
  const { handlePayInvoice } = useSubscriptionPaymentMethods()
  const { isMaxXSmall } = useContext(MediaSizingContext)

  const subHasDefaultSource = useMemo(() => {
    return !fullSubscription.isSuspended && fullSubscription.default_source
  }, [fullSubscription])

  const { cancelAtPeriodEnd, status } = fullSubscription

  // Don't show the popover if it has no actions available
  if (
    (status === 'canceled' || cancelAtPeriodEnd) &&
    !showFailedSubActionButtons &&
    !isMaxXSmall
  ) {
    return null
  }

  return (
    <ClassNames>
      {({ css }) => {
        return (
          <Popover
            key={id}
            open={popoverOpen === id}
            classNameCardWrap="cardWrapper"
            onChange={() => setPopoverOpen(popoverOpen === id ? null : id)}
            disabled={disabled}
            renderToggle={() => <StyledMoreIcon size="large" key={id} />}
            size="large"
            classNameCard={css`
              padding: 4px 0;
              width: 170px;
              .is-active {
                font-weight: bold;
              }
              overflow-x: hidden;
            `}
            align="bottomRight"
          >
            {status !== 'canceled' && !cancelAtPeriodEnd && (
              <Option onClick={onCancelSubscription}>
                <Icon src={closeCircleIconBlue} />
                <StyledText>
                  <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION" />
                </StyledText>
              </Option>
            )}

            {(showFailedSubActionButtons || isMaxXSmall) && (
              <>
                {showFailedSubActionButtons && subHasDefaultSource && (
                  <Option onClick={() => handlePayInvoice(fullSubscription)}>
                    <Icon src={retryIcon} />
                    <StyledText>
                      <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_RETRY_PAYMENT" />
                    </StyledText>
                  </Option>
                )}
                {!isTalk && (
                  <Option
                    onClick={() => {
                      if (isMaxXSmall) {
                        openChoosePaymentMethodModal?.(fullSubscription)
                      } else {
                        setCardPopoverOpen(cardPopoverOpen === id ? null : id)
                      }
                    }}
                  >
                    <Icon src={paymentIcon} />
                    {subHasDefaultSource ? (
                      <StyledText>
                        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CHANGE_PAYMENT_METHOD" />
                      </StyledText>
                    ) : (
                      <StyledTextLong>
                        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CHOOSE_PAYMENT_METHOD" />
                      </StyledTextLong>
                    )}
                  </Option>
                )}
              </>
            )}
          </Popover>
        )
      }}
    </ClassNames>
  )
}

const StyledMoreIcon = styled(MoreHorizontalIcon)`
  color: ${({ theme }) => theme.neutral08};
  cursor: pointer;
`

const StyledText = styled.div`
  color: ${(p) => p.theme.ublue06};
  font-size: 14px;
  line-height: 20px;
  margin-left: 8px;
`
const StyledTextLong = styled(StyledText)`
  line-height: 16px;
`

const Option = styled.div`
  font-size: 14px;
  padding: 0 8px;
  height: 28px;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  :hover {
    background: ${({ theme }) => theme.neutral03};
  }
`

const Icon = styled.img`
  width: 20px;
  object-fit: contain;
`

export default MorePopover
