import { IntlShape } from 'react-intl'
import { DateTime } from 'luxon'

const TICKET_AUTO_CLOSE_DAYS = 15

export const getFormattedTime = (
  timestamp: Date,
  intl: IntlShape,
  shortVersion?: boolean
) => {
  const date = new Date(timestamp)
  const now = new Date()

  if (date.toDateString() === now.toDateString()) {
    return `${intl.formatMessage({
      id: 'COMMON_TIMES_TODAY_AT',
    })} ${intl.formatTime(timestamp)}`
  }

  return `${intl.formatDate(timestamp, {
    year: 'numeric',
    month: shortVersion ? 'short' : 'long',
    day: 'numeric',
  })}`
}

export const getDaysToClose = (lastUpdate: Date) => {
  const lastUpdateDate = new Date(lastUpdate)
  const lastUpdateDay = DateTime.fromJSDate(lastUpdateDate)

  const difference = lastUpdateDay.diffNow('days').toObject()
  const daysPassed = Math.ceil(Math.abs(difference.days ?? 0))

  return TICKET_AUTO_CLOSE_DAYS - daysPassed
}
