import { SupportFormState } from '../types'

export enum BillingSpecificService {
  lte = 'acc_unf_subs/bill_ulte',
  cloud = 'acc_unf_subs/bill_uos',
  talk = 'acc_unf_subs/bill_utlk',
  identity = 'acc_unf_subs/bill_uidx',
  other = 'acc_unf_subs/bill_other',
}

export enum Console {
  uck = 'uosfrm_cons_uck',
  uckG2 = 'uosfrm_cons_uckg2',
  uckG2Plus = 'uosfrm_cons_uckg2plus',
  udm = 'uosfrm_cons_udm',
  udmPro = 'uosfrm_cons_udm-pro',
  udmSe = 'uosfrm_cons_udm-se',
  udmProMax = 'uosfrm_cons_udm-pro-max',
  udr = 'uosfrm_cons_udr',
  udw = 'uosfrm_cons_udw',
  ucgUltra = 'uosfrm_cons_ucg-ultra',
  ux = 'uosfrm_cons_ux',
  cloud = 'uosfrm_cons_cloud',
  other = 'uosfrm_cons_other',
}

export enum UserType {
  home = 'home_user',
  business = 'business_user',
}

export interface ZDFormValueType {
  id: string
  label: string
  icon?: JSX.Element
}

type NextSection = {
  section: string
}

type ConfigElement = {
  fieldId: number
  fieldTitle: string | null
  options: ZDFormValueType[] | null
  nextSection: NextSection | null
  hide?: boolean
}

export type UserConsoleData = {
  mac?: string
  version?: string
  fwVersion?: string
  releaseChannel?: string
  totalDevices?: string
  totalConsoles?: string
}

type AllData = SupportFormState &
  UserConsoleData & {
    attachments?: string
    rawData?: string
  }

export type FormConfig = Record<keyof AllData, ConfigElement>
