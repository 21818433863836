import React from 'react'
import { useSelector } from 'react-redux'

import CurrentCycle from 'features/subscriptions/ui/CurrentCycle'
import {
  selectIsSubscriptionsFetching,
  selectSubscriptions,
} from 'features/subscriptions/module/subscriptions'
import {
  selectInvoices,
  selectIsInvoicesLoading,
} from 'features/subscriptions/module/invoices'
import InvoiceTable from 'features/subscriptions/ui/InvoiceTable'
import { deviceTalkRootSaga } from 'features/subscriptions/module/deviceTalk'
import ProtectedPage from 'pages/ProtectedPage'
import storeConfigs from 'store'
import styled from 'theme/styled'
import AddPaymentMethodModal from 'features/payment-methods/ui/AddPaymentMethodModal'
import { deviceTalkInstallsRootSaga } from 'features/subscriptions/module/deviceTalkInstalls'
import ChoosePaymentMethodModal from 'features/payment-methods/ui/ChoosePaymentMethodModal'
import TaxId from 'features/payment-methods/ui/TaxId/TaxId'
import PaymentCards from 'features/payment-methods/ui/paymentCards/PaymentCards'
import AppViewHeader from 'components/AppViewHeader'
import { Loader } from '@ubnt/ui-components/Loader'
import { BrowserUtils } from 'utils/browserUtils'

import RefetchInvoicesInProgress from './RefetchInvoiesInProgress'

const { sagaMiddleware } = storeConfigs
sagaMiddleware.run(deviceTalkRootSaga)
sagaMiddleware.run(deviceTalkInstallsRootSaga)

const Subscriptions = () => {
  const isLoading = [
    useSelector(selectIsSubscriptionsFetching),
    useSelector(selectIsInvoicesLoading),
  ].some(Boolean)

  const subscriptions = useSelector(selectSubscriptions)
  const invoices = useSelector(selectInvoices)

  const areAnySubsAndInvoicesLoaded = subscriptions.length && invoices.length
  const invoiceParam = BrowserUtils.getLocationQueryValue('invoiceId')

  return (
    <>
      <ProtectedPage renderMargin={true}>
        <AppViewHeader headerId="COMMON_SIDE_NAV_SUBSCRIPTIONS" />
        <PaymentCards />
        <TaxId />
        {isLoading && (invoiceParam || !areAnySubsAndInvoicesLoaded) ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <Wrapper>
            <CurrentCycle />
            <InvoiceTable invoiceId={invoiceParam} />
          </Wrapper>
        )}
        <AddPaymentMethodModal />
        <ChoosePaymentMethodModal />
      </ProtectedPage>
      <RefetchInvoicesInProgress />
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`

export default Subscriptions
