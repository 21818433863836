import { all, put, spawn, takeEvery } from 'redux-saga/effects'
import { createSelector } from 'reselect'

import createDataModule from 'utils/moduleCreator'
import { StripeRegionCode } from 'features/stripe/ui/types'

import { CustomTaxId, StripeTaxId } from '../types'

const {
  api: { billing },
} = __CONFIG__

export const getTaxIdDataKey = 'fetchTaxId'

const dataModule = createDataModule<StripeTaxId>(
  getTaxIdDataKey,
  billing.paths.taxId,
  billing.base
)

export const {
  fetch: fetchTaxId,
  selectIsLoading: selectIsFetchTaxIdLoading,
  selectIsDone: selectIsFetchTaxIdDone,
  reducer: fetchTaxIdReducer,
} = dataModule

const GET_TAX_ID = getTaxIdDataKey

export const selectCustomerTaxId = createSelector(
  dataModule.selectData,
  (taxId) => {
    const regions = Object.keys(taxId) as StripeRegionCode[]

    for (let i = 0; i < regions.length; i++) {
      if (taxId[regions[i]]?.data.length) {
        return taxId[regions[i]]?.data[0] as CustomTaxId
      }
    }
  }
)

function* getTaxIdSaga() {
  yield put(fetchTaxId())
}

function* subscribeToGetTaxIdSaga() {
  yield takeEvery(GET_TAX_ID, getTaxIdSaga)
}

export function* fetchTaxIdRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToGetTaxIdSaga)])
}
