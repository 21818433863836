import { all, spawn } from 'redux-saga/effects'
import { uniq } from 'lodash-es'

import createDataModule from 'utils/moduleCreator'
import { createSelector } from 'reselect'
import { StripeRegionCode } from 'features/stripe/ui/types'

import { regionsWithMultipleCountries } from 'features/stripe/config'
import { CustomerCards } from './types'

const {
  api: { billing },
} = __CONFIG__

export const dataKey = 'fetchCards'

const getUrl = (url: string) => `${url}?limit=100`

const dataModule = createDataModule<CustomerCards>(
  dataKey,
  billing.paths.cards,
  billing.base,
  undefined,
  getUrl
)

export const {
  fetch: fetchCards,
  selectIsLoading: selectIsFetchCardsLoading,
  reducer: fetchCardsReducer,
  selectData: selectCardsData,
} = dataModule

export const selectCustomerCards = createSelector(
  dataModule.selectData,
  (cardsData) => {
    const cardRegions = Object.keys(cardsData) as StripeRegionCode[]

    if (!cardRegions.length) {
      return []
    }

    const userCards = cardRegions
      .map((region) => {
        return (
          cardsData[region as keyof CustomerCards]?.data
            .filter((card) => card.type === 'card')
            .map((card) => {
              return { ...card, region }
            }) || []
        )
      })
      .flat()

    return userCards
  }
)

export const selectCardsCountries = createSelector(
  dataModule.selectData,
  (cardsData) => {
    const userCountries: string[] = []
    const cardRegions = Object.keys(cardsData) as StripeRegionCode[]

    cardRegions.map((region) => {
      const cards = cardsData[region as keyof CustomerCards]?.data

      if (cards?.length && regionsWithMultipleCountries.includes(region)) {
        cards.forEach((card) => {
          if (card.card?.country) {
            const { country } = card.card
            userCountries.push(country.toLowerCase())
          }
        })
      }

      if (cards?.length && !regionsWithMultipleCountries.includes(region)) {
        userCountries.push(region)
      }
    })

    return uniq(userCountries)
  }
)

export function* fetchCardsRootSaga() {
  yield all([spawn(dataModule.rootSaga)])
}
