import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Button } from '@ubnt/ui-components'

import PublicPage from 'pages/PublicPage'
import styled from 'theme/styled'
import { BrowserUtils } from 'utils/browserUtils'

import { ButtonContainer } from './styles'

const { email }: { email?: string } = BrowserUtils.parseLocationHash(
  location.hash
)

export const ResendVerificationComplete: React.FC = () => {
  const parsedEmail = email && decodeURIComponent(email)
  return (
    <PublicPage
      title={
        <FormattedMessage id="COMMON_AUTH_ACTION_RESEND_VERFICATION_EMAIL_SUCCESS_HEADER" />
      }
    >
      <CompletedMessage>
        {email ? (
          <FormattedMessage
            id="COMMON_AUTH_ACTION_RESEND_VERFICATION_EMAIL_SUCCESS"
            values={{ b: () => <b className="intl-message">{parsedEmail}</b> }}
          />
        ) : (
          <FormattedMessage id="COMMON_AUTH_ACTION_RESEND_VERFICATION_EMAIL_SUCCESS_FALLBACK" />
        )}
      </CompletedMessage>
      <ButtonContainer>
        <Link to="/login">
          <Button variant="primary" full>
            <FormattedMessage id="COMMON_AUTH_ACTION_SIGN_IN" />
          </Button>
        </Link>
      </ButtonContainer>
    </PublicPage>
  )
}

export default ResendVerificationComplete

const CompletedMessage = styled.div`
  padding: 16px 0 54px;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.text3};
`
