import React from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { selectMFASetup } from 'modules/mfaSetup'
import { MFAType } from 'modules/mfa'

import { GenericConfirmRemoveMFAModal } from '../RemoveModal/GenericConfirmRemoveMFAModal'

export const TOTP_REMOVE_MODAL_ID = 'TOTP_REMOVE_MODAL_ID'

const TOTPRemoveModal: React.FC<ModalProps & WrappedComponentProps> = ({
  intl,
  ...restProps
}) => {
  const { removeTOTP } = useSelector(selectMFASetup)

  return (
    <GenericConfirmRemoveMFAModal
      title={intl.formatMessage({
        id: 'SETTINGS_MFA_TOTP_REMOVE_MODAL_TITLE',
      })}
      removeId={removeTOTP.id}
      type={MFAType.totp}
      {...restProps}
    />
  )
}

const PushRemoveModalConnected = injectIntl(TOTPRemoveModal)

export const WrappedTOTPRemoveModal = () => (
  <ModalWrapper modalId={TOTP_REMOVE_MODAL_ID}>
    <PushRemoveModalConnected />
  </ModalWrapper>
)
