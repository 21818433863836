import React, { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { Dropdown } from '@ubnt/ui-components/Dropdown'

import styled from 'theme/styled'
import { GenericModal } from 'components/generic-modal/GenericModal'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { setVisibleModal } from 'modules/modals'
import { RoundFlag } from 'components/RoundFlag'
import { validStripeCountries } from 'features/stripe/ui/regions'
import { StripeRegionCode } from 'features/stripe/ui/types'

import { StripeRegionContext } from '../../stripe/ui/Region'
import { resetAddCardErrors } from '../modules/addCard'
import { ADD_PAYMENT_METHOD_MODAL_ID } from './AddPaymentMethodModal'

interface Props {
  notSupported?: boolean
}

const ChangeRegionModal: React.FC<
  Props & ModalProps & WrappedComponentProps
> = ({ isOpen, onClose, intl, notSupported }) => {
  const { region, setRegion, country, setCountry } =
    useContext(StripeRegionContext)
  const [regionToSave, setRegionToSave] = useState<
    StripeRegionCode | undefined
  >(region)
  const [countryToSave, setCountryToSave] = useState<string | undefined>(
    country
  )
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const handleSave = () => {
    if (!setRegion || !setCountry || !regionToSave || !countryToSave) return
    setIsLoading(true)
    if (regionToSave !== region) setRegion(regionToSave)
    if (countryToSave !== country) setCountry(countryToSave)
    dispatch(resetAddCardErrors())
    return dispatch(setVisibleModal(ADD_PAYMENT_METHOD_MODAL_ID))
  }

  return (
    <GenericModal
      size="auto"
      isOpen={isOpen}
      onRequestClose={onClose}
      title={
        <FormattedMessage
          id="SETTINGS_PAYMENTS_MODAL_TITLE_ADD"
          tagName="div"
        />
      }
      actions={[
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_CANCEL' }),
          onClick: onClose,
          variant: 'default',
          disabled: isLoading,
        },
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_SAVE' }),
          type: 'submit',
          variant: 'primary',
          onClick: handleSave,
          style: { outline: 'none' },
          loader: isOpen && isLoading ? 'spinner' : undefined,
        },
      ]}
    >
      <Subtitle>
        {notSupported ? (
          <FormattedMessage id="SETTINGS_PAYMENTS_MODAL_NOT_SUPPORTED" />
        ) : (
          <FormattedMessage id="SETTINGS_PAYMENTS_MODAL_SELECT_NEW_REGION" />
        )}
      </Subtitle>
      <ChangeRegion>
        {notSupported && (
          <Text>
            <FormattedMessage id="SETTINGS_PAYMENTS_MODAL_CHANGE_REGION" />
          </Text>
        )}
        <Dropdown
          label="Country"
          onChange={(option) => {
            setRegionToSave(option.region)
            setCountryToSave(option.code)
          }}
          options={validStripeCountries.map((country) => ({
            label: country.name,
            value: country.name,
            image: <RoundFlag countryCode={country.code} />,
            region: country.region,
            code: country.code,
          }))}
          searchable
          autoSize
          variant="secondary"
          minHeight="214px"
          optionContainerClassName="change-region__dropdown"
        />
      </ChangeRegion>
    </GenericModal>
  )
}

const ChangeRegionModalConnected = injectIntl(ChangeRegionModal)

export const CHANGE_REGION_MODAL_ID = 'CHANGE_REGION_MODAL_ID'

export default () => (
  <ModalWrapper modalId={CHANGE_REGION_MODAL_ID}>
    <ChangeRegionModalConnected />
  </ModalWrapper>
)

const Subtitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 10px;
`

const ChangeRegion = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`

const Text = styled.div`
  padding-bottom: 10px;
`
