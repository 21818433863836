import React, { useContext, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { AddPrimaryIcon, ArrowDownPrimaryIcon } from '@ubnt/icons'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'

import {
  Container,
  EmptyOption,
  StyledButton,
  Text,
  Wrapper,
} from './PaymentCards.styles'

interface IPaymentCardsBlockProps {
  addNewTitle: string
  cardCount: number
  onAddNewClick: () => void
}

const MIN_CARD_COUNT_FOR_VIEW_ALL = 3

export const PaymentCardsBlock: React.FC<IPaymentCardsBlockProps> = ({
  addNewTitle,
  onAddNewClick,
  cardCount,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { isMaxSmall, isMaxMobileXLarge } = useContext(MediaSizingContext)

  const isShowAllVisible = useMemo(() => {
    if (isMaxSmall && !isMaxMobileXLarge) {
      return cardCount >= MIN_CARD_COUNT_FOR_VIEW_ALL - 1
    }

    return cardCount >= MIN_CARD_COUNT_FOR_VIEW_ALL
  }, [cardCount, isMaxMobileXLarge, isMaxSmall])

  return (
    <>
      <Container $isExpanded={isExpanded}>
        <EmptyOption onClick={onAddNewClick}>
          <Wrapper>
            <AddPrimaryIcon size="large" />
            <Text>{addNewTitle}</Text>
          </Wrapper>
        </EmptyOption>

        {children}
      </Container>

      {isShowAllVisible && (
        <StyledButton
          $isExpanded={isExpanded}
          variant="inline"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <ArrowDownPrimaryIcon />
          <FormattedMessage
            id={isExpanded ? 'COMMON_SHOW_LESS' : 'COMMON_SHOW_ALL'}
          />
        </StyledButton>
      )}
    </>
  )
}
