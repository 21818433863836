import { handle } from 'redux-pack'
import { all, spawn, takeEvery } from 'redux-saga/effects'
import createDataModule from 'utils/moduleCreator'
import { api } from 'api'
import { ReduxAction } from 'types/types'

const {
  api: { sso },
} = __CONFIG__

interface AccountData {
  username?: string
  email?: string
  password?: string
  password_confirm?: string
  first_name?: string
  last_name?: string
  phone?: string
  curr_terms_rev?: string
  curr_privacy_rev?: string
  curr_terms_aircrm_rev?: string
  mailinglist?: boolean
  captcha?: string
  file?: Blob
}

export const dataKey = 'authCreateAccount'

const dataModule = createDataModule<AccountData>(
  dataKey,
  sso.paths.createAccount,
  api.ssoBase
)

const VERIFY_REGISTRATION = `${dataKey}/VERIFY_REGISTRATION`
export const verifyRegistration = (uuid: string) => ({
  type: VERIFY_REGISTRATION,
  promise: api.verifyRegistration(uuid),
})

const RESET_API_ERRORS = `${dataKey}/RESET_API_ERRORS`
export const resetApiErrors = () => ({
  type: RESET_API_ERRORS,
})

export const {
  create: createAccount,

  selectIsLoading: selectIsCreateAccountLoading,
  selectErrors: selectCreateAccountErrors,
} = dataModule

function* accountCreatedSaga() {
  window.location.href = '/register/success'
  yield
}

function* subscribeToAccountCreatedSaga() {
  yield takeEvery(dataModule.CREATE_DONE, accountCreatedSaga)
}

export function* createAccountRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToAccountCreatedSaga)])
}

export const createAccountReducer = (
  state = dataModule.initialState,
  action: ReduxAction
) => {
  const { type } = action
  switch (type) {
    case VERIFY_REGISTRATION:
      return handle(state, action, {
        start: (prevState) => ({
          ...prevState,
          isUpdateLoading: true,
        }),
        finish: (prevState) => ({
          ...prevState,
          isUpdateLoading: false,
        }),
      })
    case RESET_API_ERRORS:
      return {
        ...state,
        errors: {},
      }
    default:
      return dataModule.reducer(state, action)
  }
}
