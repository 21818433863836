import { Input, Tooltip } from '@ubnt/ui-components'
import { CheckmarkIcon, InfoIcon, PencilIcon } from '@ubnt/icons'
import { typography } from '@ubnt/ui-components/styles/designToken'

import ProfilePicture from 'features/profile-picture/ui/ProfilePicture'
import styled from 'theme/styled'
import { InputLabel } from 'components/Section.styles'

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 5fr 7fr;
  min-height: 32px;

  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    display: flex;
    flex-direction: column;
    height: auto;
  }
`

const PictureLabel = styled(InputLabel)`
  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    display: none;
  }
`

const StyledInput = styled(Input)`
  height: 32px;
  margin-bottom: 0;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    max-width: 100%;
  }
`

const ValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    max-width: 100%;
  }
`

const ImageValueWrapper = styled(ValueWrapper)`
  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    justify-content: flex-start;
  }
`

const EditIcon = styled(PencilIcon)`
  margin-right: 4px;
  height: 20px;
  width: 20px;
`

const StyledTooltipDefault = styled(Tooltip)`
  margin-left: 4px;
  margin-top: -1px;
`

const StyledTooltip = styled(StyledTooltipDefault)`
  circle {
    color: ${({ theme }) => theme.neutral00} !important;
  }
`

const StyledText = styled.div`
  align-items: center;
  display: flex;
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.text1};
  justify-content: center;
  margin-right: 4px;
`

const ButtonContainer = styled.div<{ $opacity: number }>`
  align-items: center;
  bottom: 32px;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 48px;
  opacity: ${({ $opacity }) => $opacity};
  position: absolute;
  right: 0;
  transition: opacity 0.2s linear;
  width: 100%;
  will-change: opacity;

  @media (max-width: ${({ theme }) => theme.media.mobileXLarge}) {
    margin-top: 24px;
    opacity: 1;
    position: initial;
  }
`

const StyledAvatar = styled(ProfilePicture)`
  margin-right: 4px;

  img {
    height: 32px;
    width: 32px;
  }
`

const CheckIcon = styled(CheckmarkIcon)`
  align-items: center;
  color: ${({ theme }) => theme.green06} !important;
  display: flex;
  height: 20px !important;
  margin-right: 4px;
  width: 20px !important;

  circle {
    color: ${({ theme }) => theme.green01} !important;
  }
`

const StyledInfoIcon = styled(InfoIcon)`
  circle {
    color: ${({ theme }) => theme.neutral00} !important;
  }
`

const EnrolledText = styled.div`
  display: flex;
  gap: 16px;
`

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.text0};
  @media (max-width: ${({ theme }) => theme.media.largeV2}) {
    padding-top: 4px;
    align-items: flex-start;
  }
`

const ColumnsWithGap = styled(Columns)`
  gap: 16px;
  @media (max-width: ${({ theme }) => theme.media.largeV2}) {
    padding-top: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
  }
`

export {
  FormContainer,
  GridWrapper,
  InputLabel,
  PictureLabel,
  StyledInput,
  ValueWrapper,
  ImageValueWrapper,
  EditIcon,
  StyledTooltipDefault,
  StyledTooltip,
  StyledText,
  ButtonContainer,
  StyledAvatar,
  CheckIcon,
  StyledInfoIcon,
  EnrolledText,
  Columns,
  ColumnsWithGap,
}
