import React from 'react'
import { MFAAuthenticator } from 'modules/mfa'
import { MultiFactorMethod } from './MultiFactorMethod'

interface MultiFactorMethodSectionProps {
  data: MFAAuthenticator[]
  isDeleteDisabled?: boolean
  isPrimary?: boolean
  isVisible?: boolean
  isPrimaryTOTP?: boolean
}

export const MultiFactorMethodSection: React.FC<
  MultiFactorMethodSectionProps
> = ({
  data,
  isDeleteDisabled,
  isPrimary = false,
  isVisible = true,
  isPrimaryTOTP,
}) => {
  if (!isVisible) return null

  return (
    <>
      {data.map((mfaMethod, index) => (
        <MultiFactorMethod
          key={index}
          authenticator={mfaMethod}
          isDeleteDisabled={!!isDeleteDisabled}
          isPrimary={!!isPrimary}
          iconBorder={mfaMethod.type === 'push' ? false : true}
          isPrimaryTOTP={isPrimaryTOTP}
        />
      ))}
    </>
  )
}
