import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import ReactCountryFlag from 'react-country-flag'
import { useFormik } from 'formik'
import {
  AsYouType,
  formatIncompletePhoneNumber,
  getCountryCallingCode,
  parsePhoneNumberFromString,
} from 'libphonenumber-js'
import {
  Dropdown,
  DropdownOption,
  DropdownRef,
} from '@ubnt/ui-components/Dropdown'
import { Input } from '@ubnt/ui-components/Input'
import { Button, Checkbox } from '@ubnt/ui-components'

import styled from 'theme/styled'
import Yup from 'validators/yupLocaleConfig'
import { getErrorMessage } from 'utils/mfa'
import { selectVisibleModal } from 'modules/modals'
import { selectDefaultAddress } from 'features/addresses/module/addresses'
import { parseSSOAPIError } from 'utils/parseSSOAPIError'
import { uiLinks } from 'redirectLinks'

import { GenericMFAModal } from '../GenericMFAModal'
import { RegionCountryCode, regionsCountries } from './regions_countries'
import {
  initiateMFASMS,
  selectInitiateMFASMSErrors,
  selectInitiateMFASMSIsLoading,
} from './modules/initiateMFASMS'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
`

const InputWrapper = styled.div`
  display: flex;

  @media (max-width: 600px) {
    max-width: 380px;
    width: 100%;
    flex-direction: column;
  }
`

const StyledDropdown = styled(Dropdown)`
  text-align: left;

  img {
    margin-bottom: -4px;
  }
`

const StyledInput = styled(Input)`
  margin-left: 18px;
  @media (max-width: 600px) {
    margin-top: 16px;
    margin-left: 0;
  }
`

const Policy = styled.div`
  position: relative;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  color: ${({ theme }) => theme.text3};
  margin-top: 16px;
`

const DropDownOverlay = styled.div`
  position: absolute;
  width: 113px;
  height: 45px;
  z-index: 10000000;
  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    width: 85%;
  }
`

const CustomCheckbox = styled(Checkbox)`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

export const SMS_INPUT_MOBILE_PHONE_NUMBER_MODAL_ID =
  'SMS_INPUT_MOBILE_PHONE_NUMBER_MODAL_ID'

interface FormValues {
  countryCode: RegionCountryCode
  phoneNumber: string
}

const countriesAndRegionsISOCodes = Object.keys(
  regionsCountries
) as RegionCountryCode[]

const dropdownCountriesAndRegionsOptionsWithName: DropdownOption[] =
  countriesAndRegionsISOCodes.map((countryISOCode: RegionCountryCode) => ({
    label: `${regionsCountries[countryISOCode].name}: +${getCountryCallingCode(
      countryISOCode
    )}`,
    filter: `${regionsCountries[countryISOCode].name}: +${getCountryCallingCode(
      countryISOCode
    )}`,
    value: countryISOCode,
    image: () => (
      <ReactCountryFlag svg countryCode={countryISOCode} width={18} />
    ),
  }))

const dropdownCountriesAndRegionsOptions: DropdownOption[] =
  countriesAndRegionsISOCodes.map((countryISOCode: RegionCountryCode) => ({
    label: `+${getCountryCallingCode(countryISOCode)}`,
    filter: `${regionsCountries[countryISOCode].name}: +${getCountryCallingCode(
      countryISOCode
    )}`,
    value: countryISOCode,
    image: () => (
      <ReactCountryFlag
        countryCode={countryISOCode}
        style={{ margin: 'auto 0', width: '18px', height: 'auto' }}
        svg
      />
    ),
  }))

interface DropdownState {
  isOpen: boolean
  options: DropdownOption[]
}

const dropdownStateOpen: DropdownState = {
  isOpen: true,
  options: dropdownCountriesAndRegionsOptionsWithName,
}

const dropdownStateClosed: DropdownState = {
  isOpen: false,
  options: dropdownCountriesAndRegionsOptions,
}

export const SMSInputMobilePhoneNumberModal: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const isLoading = useSelector(selectInitiateMFASMSIsLoading)
  const visibleModal = useSelector(selectVisibleModal)
  const defaultAddress = useSelector(selectDefaultAddress)

  const asYouTypePhoneNumber = useMemo(() => new AsYouType(), [])

  const apiError = useSelector(selectInitiateMFASMSErrors)

  const [dropdownState, setDropdownState] = useState(dropdownStateClosed)

  const mediaWidthMatch = useMediaQuery({ query: '(max-width: 600px)' })

  useEffect(() => {
    formik.setFieldValue(
      'countryCode',
      !defaultAddress?.country
        ? 'US'
        : (defaultAddress.country as RegionCountryCode)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultAddress?.country, visibleModal])

  const formik = useFormik<FormValues>({
    initialValues: {
      countryCode: !defaultAddress?.country
        ? 'US'
        : (defaultAddress.country as RegionCountryCode),
      phoneNumber: '',
    },
    enableReinitialize: true,
    onSubmit: ({ phoneNumber, countryCode }) => {
      const parsedPhoneNumber = parsePhoneNumberFromString(
        phoneNumber,
        countryCode
      )

      if (!parsedPhoneNumber) {
        formik.setFieldError(
          'phoneNumber',
          intl.formatMessage({
            id: 'SETTINGS_MFA_SMS_INPUT_MOBILE_NUMBER_INVALID',
          })
        )
        return
      }

      dispatch(initiateMFASMS(parsedPhoneNumber.number.toString()))
    },
    validationSchema: Yup.object().shape({
      countryCode: Yup.string()
        .required()
        .label('SETTINGS_MFA_SMS_COUNTRY_CODE'),
      phoneNumber: Yup.string()
        .required()
        .label('SETTINGS_MFA_SMS_MOBILE_NUMBER'),
    }),
  })

  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    const msg =
      getErrorMessage({
        apiError,
        defaultMessage: intl.formatMessage({
          id: 'GENERIC_ERROR_BOUNDARY_TITLE',
        }),
        fields: ['detail'],
      }) || ''
    setErrorMessage(
      msg === 'Phone number already exists'
        ? intl.formatMessage({
            id: 'SETTINGS_MFA_SMS_INPUT_MOBILE_NUMBER_EXISTS',
          })
        : ''
    )
  }, [apiError, intl])

  useEffect(() => {
    setErrorMessage('')
    const modalsToSaveDataFor: (string | undefined)[] = [
      'SETUP_SMS_INPUT_MOBILE_PHONE_NUMBER_MODAL_ID',
    ]
    if (!modalsToSaveDataFor.includes(visibleModal?.visibleModal)) {
      formik.resetForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleModal])

  const phoneNumberInputError = useMemo(
    () =>
      formik.errors.phoneNumber ?? parseSSOAPIError(apiError, 'phone_number'),
    [apiError, formik.errors.phoneNumber]
  )

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value

      asYouTypePhoneNumber.reset()
      asYouTypePhoneNumber.input(value)
      setErrorMessage('')
      //allow interpreting the country from the entered phone number
      if (
        asYouTypePhoneNumber.country &&
        asYouTypePhoneNumber.country !== formik.values.countryCode
      ) {
        formik.setFieldValue('countryCode', asYouTypePhoneNumber.country)
      } else {
        if (value.startsWith('+1')) {
          formik.setFieldValue('countryCode', 'US')
        } else if (value.startsWith('+7')) {
          formik.setFieldValue('countryCode', 'RU')
        } else if (value.startsWith('+39')) {
          formik.setFieldValue('countryCode', 'IT')
        } else if (value.startsWith('+44')) {
          formik.setFieldValue('countryCode', 'GB')
        } else if (value.startsWith('+47')) {
          formik.setFieldValue('countryCode', 'NO')
        } else if (value.startsWith('+61')) {
          formik.setFieldValue('countryCode', 'AU')
        } else if (value.startsWith('+358')) {
          formik.setFieldValue('countryCode', 'FI')
        }
      }

      formik.handleChange(event)
    },
    [asYouTypePhoneNumber, formik]
  )

  const [checked, setChecked] = useState<boolean>(false)

  const [countryCodeRef, setCountryCodeRef] = useState<DropdownRef>()
  const [phoneNumberRef, setPhoneNumberRef] = useState<HTMLInputElement>()

  const handleCountryCodeRef = useCallback(
    (node: DropdownRef) => setCountryCodeRef(node),
    []
  )
  const handlePhoneNumberRef = useCallback(
    (node: HTMLInputElement) => setPhoneNumberRef(node),
    []
  )

  useEffect(() => {
    setChecked(false)
  }, [visibleModal])

  const handleCheckbox = () => setChecked(!checked)

  const focusCountryCode = () => {
    countryCodeRef?.removeAttribute('readonly')
    countryCodeRef?.focus()
  }

  useEffect(() => {
    if (defaultAddress?.country) {
      phoneNumberRef?.focus()
    } else {
      countryCodeRef?.focus()
    }
  }, [phoneNumberRef, countryCodeRef, defaultAddress?.country])

  return (
    <GenericMFAModal
      modalId={SMS_INPUT_MOBILE_PHONE_NUMBER_MODAL_ID}
      title={intl.formatMessage({ id: 'SETTINGS_MFA_SMS_MODAL_TITLE' })}
      primaryButton={{
        text: intl.formatMessage({
          id: 'SETTINGS_MFA_SEND_CODE_ACTION',
        }),
        onClick: () => {
          if (checked) formik.handleSubmit()
        },
        disabled: !checked,
      }}
      isLoading={isLoading}
    >
      <Wrapper>
        <InputWrapper>
          <DropDownOverlay onClick={focusCountryCode}></DropDownOverlay>
          <StyledDropdown
            searchable
            variant="secondary"
            label={intl.formatMessage({
              id: 'SETTINGS_MFA_SMS_COUNTRY_CODE',
            })}
            options={dropdownState.options}
            value={formik.values.countryCode}
            onChange={(changedOption: DropdownOption) =>
              formik.setFieldValue('countryCode', changedOption.value)
            }
            imageWidth={16}
            imageBorderRadius={0}
            searchableProps={{ keys: ['filter'] }}
            onFocus={() => setDropdownState(dropdownStateOpen)}
            onBlur={() => setDropdownState(dropdownStateClosed)}
            ref={handleCountryCodeRef}
            width={mediaWidthMatch ? '100%' : 150}
          />

          <StyledInput
            name="phoneNumber"
            variant="secondary"
            label={intl.formatMessage({
              id: 'SETTINGS_MFA_SMS_MOBILE_NUMBER',
            })}
            onBlur={(event) => {
              formik.setFieldValue(
                'phoneNumber',
                formatIncompletePhoneNumber(
                  formik.values.phoneNumber,
                  formik.values.countryCode
                )
              )
              formik.handleBlur(event)
            }}
            onChange={handleOnChange}
            invalid={
              (formik.touched.phoneNumber && phoneNumberInputError) ||
              errorMessage
            }
            value={formik.values.phoneNumber}
            ref={handlePhoneNumberRef}
            width={mediaWidthMatch ? '100%' : 254}
          />
        </InputWrapper>

        <Policy>
          {intl.formatMessage({
            id: 'SETTINGS_MFA_SMS_POLICY',
          })}
          <br />
          <Button<'a'>
            component="a"
            variant="inline"
            href={uiLinks.privacyPolicy}
            target="_blank"
          >
            <FormattedMessage id="COMMON_LABEL_PRIVACY_POLICY" />
          </Button>
          <FormattedMessage id={'COMMON_LABEL_AND'} />
          <Button<'a'>
            component="a"
            variant="inline"
            href={uiLinks.termsOfService}
            target="_blank"
          >
            <FormattedMessage id="COMMON_LABEL_TERMS_AND_CONDITIONS" />
          </Button>
          <CustomCheckbox
            id="basic-checkbox"
            checked={checked}
            onChange={handleCheckbox}
            motif="light"
          >
            <FormattedMessage
              id="LOGIN_MFA_SMS_AGREE"
              values={{
                b: (text: string) => <b className="intl-message">{text}</b>,
              }}
            />
          </CustomCheckbox>
        </Policy>
      </Wrapper>
    </GenericMFAModal>
  )
}
