import React from 'react'
import { useMotif } from '@ubnt/ui-components'

import styled from 'theme/styled'
import { typography } from 'theme'

export interface DropzoneData {
  id: string
  isRequestDropzone?: boolean
}

export const SendSafelyDropzone: React.FC<DropzoneData> = ({
  id,
  isRequestDropzone = false,
}) => {
  const { motif } = useMotif()

  return (
    <DropzoneWrapper
      $isRequestDropzone={isRequestDropzone}
      $isDarkTheme={motif === 'dark'}
    >
      <div id={id}></div>
    </DropzoneWrapper>
  )
}

const DropzoneWrapper = styled.div<{
  $isRequestDropzone: boolean
  $isDarkTheme: boolean
}>`
  #unifi-dropzone,
  #uisp-dropzone,
  #store-dropzone,
  #billing-dropzone,
  #request-dropzone,
  #umr-dropzone {
    display: flex;
    flex-direction: column;
    gap: ${({ $isRequestDropzone }) => ($isRequestDropzone ? `8px` : `16px`)};

    & > div:first-of-type {
      border: ${({ theme }) => `1px solid ${theme.neutral03}`};
      border-radius: 8px;
      order: -100;

      &:hover {
        background-color: ${({ theme, $isDarkTheme }) =>
          $isDarkTheme ? theme.ublue10 : theme.ublue01};
        border: ${({ theme }) => `1px solid ${theme.ublue06}`};
        border-radius: 8px;
      }
    }

    #sendsafely-attached-file-list {
      display: flex;
      flex-direction: column;
      gap: 8px;

      li {
        margin: 0;

        img {
          display: none !important;
        }

        div {
          font: ${typography['desktop-heading-medium']} !important;
          line-height: 24px;
          color: ${({ theme }) => theme.text1};

          :first-of-type {
            display: inline-block !important;
            margin-top: 0 !important;
            max-width: calc(100vw - 210px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        span {
          font: ${typography['desktop-typography-link-small']} !important;
          color: ${({ theme }) => theme.text3} !important;
          margin-left: 4px !important;
        }

        span:first-of-type {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: ${({ theme }) => `1px solid ${theme.text3}`};
          border-radius: 50%;
          padding-bottom: 1px;
        }
      }
    }
  }

  #sendsafely-error-message {
    display: none !important;
  }

  img {
    display: flex;
    margin: 0 auto 8px !important;
    height: 20px;
    width: 20px;
  }
`
