import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import Text from '@ubnt/ui-components/SkeletonLoader/Skeleton/Text'
import { SkeletonLoader } from '@ubnt/ui-components/SkeletonLoader'
import { DropdownOption } from '@ubnt/ui-components/Dropdown'

import { Bubble } from 'components/support/Bubble'
import { BubbleWrapper, TilesWrapper } from 'components/support/Wrappers'
import { DeviceTile } from 'components/support/DeviceTile'
import { TilesLoader } from 'components/support/TilesLoader'
import { DeviceDropdown } from 'components/support/DeviceDropdown'
import { UmrDevice } from 'features/devices/umrDevices'
import { formatMac } from 'utils/formatMac'

import { DROPDOWN_BREAKPOINT, ELEMENT_COUNT } from '../constants'
import { config } from './config/formConfig'
import { BubbleProps, SupportConsole } from './types'
import { console as device } from './config/ConfigOptions'
import { Console } from './config/types'
import { useGetUmrDevices } from './utils/useGetUmrDevices'

const umrImg =
  'https://static.ui.com/fingerprint/ui/icons/1606c40f-e9b7-46d9-aeaf-e9afe52416e5_101x101.png'

export const ConsoleBubble: React.FC<BubbleProps> = ({
  handleChange,
  values,
  shouldScroll,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll, values.umrSpecificIssue])
  const { consoles, isConsolesLoading } = useGetUmrDevices()

  const userConsoles = useMemo(
    () =>
      consoles.map((console: UmrDevice) => ({
        id: Console.umr,
        mac: console.mac,
        label: console.name ? console.name : `UMR - ${formatMac(console.mac)}`,
        deviceId: console.id,
        icon: <img src={umrImg} style={{ width: '60px', height: '60px' }} />,
      })),
    [consoles]
  )

  const onChange = (option: DropdownOption) => {
    const selectedConsole = userConsoles.find(
      ({ mac }: UmrDevice) => mac === option.additionalInfo
    )
    if (selectedConsole) {
      return handleClick(selectedConsole)
    }
    return handleClick({ id: Console.umrUnlisted, ...option })
  }

  const getNextSection = (): string | undefined =>
    config.console.nextSection?.section

  const handleClick = useCallback(
    (value?: string | boolean | SupportConsole) => {
      const section = getNextSection()
      handleChange('console', value, section)
    },
    [handleChange]
  )

  useEffect(() => {
    if (!isConsolesLoading && !userConsoles.length && !values.console?.id) {
      const unlistedOption = device[device.length - 1]
      handleClick(unlistedOption)
    }
  }, [handleClick, isConsolesLoading, userConsoles.length, values.console?.id])

  if (isConsolesLoading) {
    return (
      <BubbleWrapper $isVisible ref={ref}>
        <SkeletonLoader>
          <Text width={220} />
        </SkeletonLoader>
        <TilesLoader numberOfTiles={ELEMENT_COUNT} />
      </BubbleWrapper>
    )
  }

  const renderDevices = () => {
    const unlistedOption = device[device.length - 1]
    const allConsoleOptions = [...userConsoles, unlistedOption]
    if (allConsoleOptions.length > DROPDOWN_BREAKPOINT) {
      return (
        <DeviceDropdown
          userConsoles={userConsoles}
          otherOption={unlistedOption}
          handleChange={onChange}
        />
      )
    }

    return (
      <TilesWrapper>
        {allConsoleOptions.map((el) => (
          <DeviceTile
            key={`${el.id}-${el.mac}`}
            icon={el.icon}
            label={el.label}
            onClick={() => handleClick(el)}
            deviceName={el.deviceName}
            isActive={
              el.id === Console.umrUnlisted
                ? el.id === values.console?.id
                : el.mac === values.console?.mac
            }
          />
        ))}
      </TilesWrapper>
    )
  }

  if (!isConsolesLoading && !userConsoles.length && values.console?.id) {
    return null
  }

  return (
    <Bubble question={config.console.fieldTitle} ref={ref}>
      {renderDevices()}
    </Bubble>
  )
}
