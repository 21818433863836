import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from '@ubnt/ui-components'
import styled from 'theme/styled'

import ActionPanel from './ActionPanel'

interface Props {
  visible?: boolean
  name: string
  disabled?: boolean
  isLoading?: boolean
  onCancelClick?(): void
  onClick?(): void
}

export const BasicActionPanel: React.FC<Props> = ({
  visible,
  name,
  disabled,
  isLoading,
  onCancelClick,
  onClick,
}) => {
  return (
    <ActionPanel visible={visible}>
      <Buttons>
        <FirstButtonContainer>
          <Button
            type="button"
            name={`cancel-changes:${name}`}
            disabled={isLoading}
            onClick={onCancelClick}
          >
            <FormattedMessage id="COMMON_ACTION_CANCEL" />
          </Button>
        </FirstButtonContainer>
        <Button
          type="submit"
          variant="primary"
          name={`apply-changes:${name}`}
          disabled={isLoading || disabled}
          onClick={onClick}
        >
          <FormattedMessage id="COMMON_ACTION_CONFIRM" />
        </Button>
      </Buttons>
    </ActionPanel>
  )
}

const Buttons = styled('div')`
  display: flex;
  flex-direction: row;
  margin: 0 20px;
`

const FirstButtonContainer = styled('div')`
  margin: 0 20px;
`
