import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import styled from 'theme/styled'
import ProtectedPage from 'pages/ProtectedPage'
import { Header } from 'components/support/Header'
import { SubmitSuccess } from 'components/support/SubmitSuccess'
import { UniFiForm } from 'features/support/unifi/UniFiForm'
import { BillingForm } from 'features/support/billing/BillingForm'
import { UmrForm } from 'features/support/mobility/UMRForm'
import { UispForm } from 'features/support/uisp/UispForm'
import { StoreForm } from 'features/support/store/StoreForm'

import { getFormTypeFromQueryString } from './utils'
import { Dropzone, FollowUpTicket, FormType } from './types'
import { isIpBlacklisted } from 'utils/redirectIfIpIsBlacklisted'
import { useDispatch } from 'react-redux'
import { setVisibleModal } from 'modules/modals'
import {
  BLACKLISTED_IP_INFO_MODAL,
  WrappedBlacklistedIpInfoModal,
} from 'components/BlacklistedIpInfoModal'

const FOLLOW_UP_TICKET_FALLBACK: FollowUpTicket = {
  followUpTicketId: '',
  followUpTicketDesc: '',
}

const SupportFormPage: React.FC = () => {
  const [formType, setFormType] = useState<FormType>(
    getFormTypeFromQueryString()
  )
  const dispatch = useDispatch()
  const history = useHistory()

  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [isCountryBlacklisted, setIsCountryBlacklisted] = useState<
    boolean | null
  >(null)

  const { followUpTicketId, followUpTicketDesc } =
    (history.location?.state as FollowUpTicket) || FOLLOW_UP_TICKET_FALLBACK

  useEffect(() => {
    const redirectIfIpIsBlacklisted = async () => {
      const { isBlacklisted, country } = await isIpBlacklisted()
      if (isBlacklisted) {
        setIsCountryBlacklisted(true)
        dispatch(setVisibleModal(BLACKLISTED_IP_INFO_MODAL, { country }))
      }
    }
    redirectIfIpIsBlacklisted()
  }, [dispatch])

  const placeholderDivId = useMemo(() => {
    return {
      [FormType.UNIFI]: Dropzone.UNIFI,
      [FormType.UISP]: Dropzone.UISP,
      [FormType.STORE]: Dropzone.STORE,
      [FormType.BILLING]: Dropzone.BILLING,
      [FormType.UMR]: Dropzone.UMR,
    }[formType]
  }, [formType])

  const form = useMemo(() => {
    return {
      [FormType.UNIFI]: (
        <UniFiForm
          dropzoneId={placeholderDivId}
          setIsFormSubmitted={setIsFormSubmitted}
          followUpTicketId={followUpTicketId}
        />
      ),
      [FormType.UISP]: (
        <UispForm
          dropzoneId={placeholderDivId}
          setIsFormSubmitted={setIsFormSubmitted}
          followUpTicketId={followUpTicketId}
        />
      ),
      [FormType.STORE]: (
        <StoreForm
          dropzoneId={placeholderDivId}
          setIsFormSubmitted={setIsFormSubmitted}
          followUpTicketId={followUpTicketId}
        />
      ),
      [FormType.UMR]: (
        <UmrForm
          dropzoneId={placeholderDivId}
          setIsFormSubmitted={setIsFormSubmitted}
          followUpTicketId={followUpTicketId}
        />
      ),
      [FormType.BILLING]: (
        <BillingForm
          dropzoneId={placeholderDivId}
          setIsFormSubmitted={setIsFormSubmitted}
          followUpTicketId={followUpTicketId}
        />
      ),
    }[formType]
  }, [formType, placeholderDivId, followUpTicketId])

  if (isCountryBlacklisted) {
    return (
      <ProtectedPage hideUserSection>
        <WrappedBlacklistedIpInfoModal />
      </ProtectedPage>
    )
  }

  return (
    <ProtectedPage hideUserSection>
      <FormContainer>
        <Header
          handleChange={setFormType}
          selectedForm={formType}
          isFormSubmitted={isFormSubmitted}
          followUpTicketId={followUpTicketId}
          followUpTicketDesc={followUpTicketDesc}
        />
        {isFormSubmitted ? (
          <SubmitSuccess isUnifiForm={formType === FormType.UNIFI} />
        ) : (
          form
        )}
      </FormContainer>
    </ProtectedPage>
  )
}

export default SupportFormPage

const FormContainer = styled.div`
  max-width: 1020px;
  background: ${({ theme }) => theme.neutral01};
  width: 100%;
  height: 100%;
  padding: 0 20px 90px 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`
