export type StatusTransitions = {
  finalized_at?: number | null
  marked_uncollectible_at?: number | null
  paid_at?: number | null
  voided_at?: number | null
}

export enum CurrencyRegion {
  EU = 'eu',
  EUR = 'eur',
  GB = 'gb',
  GBP = 'gbp',
}
