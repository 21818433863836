import React from 'react'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { useDispatch } from 'react-redux'
import { setVisibleModal } from 'modules/modals'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'

type FullScaleImageProps = {
  src: string
}

export const FULL_SCALE_IMAGE_MODAL_ID = 'FULL_SCALE_IMAGE_MODAL_ID'

// const {
//   api: { accountBE },
// } = __CONFIG__

export const FullScaleImage: React.FC<FullScaleImageProps> = ({ src }) => {
  const dispatch = useDispatch()

  // To be added later, when our BE is ready to handle it
  // const url = new URL(accountBE.paths.zendeskProxyImage, accountBE.base)
  // url.searchParams.set('src', src)

  return (
    <div
      onClick={async () => {
        dispatch(setVisibleModal(FULL_SCALE_IMAGE_MODAL_ID, { src }))
      }}
    >
      <img src={src} />
    </div>
  )
}

const FullScaleImageModalContent: React.FC<
  Partial<FullScaleImageProps> & ModalProps
> = ({ isOpen, onClose, src }) => {
  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={onClose}
      size="auto"
      overrideFullScreen
    >
      <img src={src} />
    </GenericModal>
  )
}

export const FullScaleImageModal = () => (
  <ModalWrapper modalId={FULL_SCALE_IMAGE_MODAL_ID}>
    <FullScaleImageModalContent />
  </ModalWrapper>
)
