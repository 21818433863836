function b64encode(buf: ArrayBuffer) {
  return btoa(
    new Uint8Array(buf).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ''
    )
  )
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
}

function b64decode(string: string) {
  return Uint8Array.from(
    atob(string.replace(/_/g, '/').replace(/-/g, '+')),
    (c) => c.charCodeAt(0)
  )
}

export const transformNewAssertionForServer = (
  newAssertion: Credential & {
    response: AuthenticatorAttestationResponse
  }
) => {
  const attObj = new Uint8Array(newAssertion.response.attestationObject)
  const clientDataJSON = new Uint8Array(newAssertion.response.clientDataJSON)

  return {
    id: newAssertion.id,
    rawId: newAssertion.id,
    type: newAssertion.type,
    response: {
      attestationObject: b64encode(attObj),
      clientDataJSON: b64encode(clientDataJSON),
    },
  }
}

type CustomPublicKeyCredentialCreationOptions = Omit<
  PublicKeyCredentialCreationOptions,
  'user' | 'challenge' | 'excludeCredentials'
> & {
  user: Omit<PublicKeyCredentialUserEntity, 'id'> & { id: string }
  challenge: string
  excludeCredentials?: (Omit<PublicKeyCredentialDescriptor, 'id'> & {
    id: string
  })[]
}

export const transformCredentialCreateOptions = (
  credentialCreateOptionsFromServer: CustomPublicKeyCredentialCreationOptions
): PublicKeyCredentialCreationOptions => {
  const { challenge, user, excludeCredentials, ...rest } =
    credentialCreateOptionsFromServer

  const transformedCredentialCreateOptions = {
    challenge: b64decode(challenge),
    user: {
      ...user,
      id: b64decode(user.id),
    },
    excludeCredentials: excludeCredentials?.map(
      ({ id, ...rest }: { id: string; type: 'public-key' }) => ({
        id: b64decode(id),
        ...rest,
      })
    ),
    ...rest,
  }

  return transformedCredentialCreateOptions
}

export type CustomPublicKeyCredentialRequestOptions = Omit<
  PublicKeyCredentialRequestOptions,
  | 'rpId'
  | 'challenge'
  | 'allowCredentials'
  | 'timeout'
  | 'userVerification'
  | 'type'
> & {
  challenge: string
  allowCredentials: (Omit<
    PublicKeyCredentialDescriptor,
    'id' | 'transports' | 'type'
  > & {
    id: string
    transports: string[]
    type: string
  })[]
}

export const transformCredentialRequestOptions = (
  credentialRequestOptionsFromServer: CustomPublicKeyCredentialRequestOptions
): PublicKeyCredentialRequestOptions => {
  const { challenge, allowCredentials } = credentialRequestOptionsFromServer
  const challengeDecoded = b64decode(challenge)
  const allowCredentialsDecoded = allowCredentials.map(
    (credentialDescriptor: any) => {
      let { id } = credentialDescriptor
      id = b64decode(id)
      return Object.assign({}, credentialDescriptor, { id })
    }
  )
  const transformedCredentialRequestOptions = Object.assign(
    {},
    credentialRequestOptionsFromServer,
    { challenge: challengeDecoded, allowCredentials: allowCredentialsDecoded }
  )
  return transformedCredentialRequestOptions
}

export const transformAssertionForServer = (
  newAssertion: PublicKeyCredential & {
    response: AuthenticatorAssertionResponse
  }
) => {
  const authData = new Uint8Array(newAssertion.response.authenticatorData)
  const clientDataJSON = new Uint8Array(newAssertion.response.clientDataJSON)
  const sig = new Uint8Array(newAssertion.response.signature)
  return {
    id: newAssertion.id,
    rawId: newAssertion.id,
    type: newAssertion.type,
    response: {
      authenticatorData: b64encode(authData),
      clientDataJSON: b64encode(clientDataJSON),
      signature: b64encode(sig),
    },
  }
}
