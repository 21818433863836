import { all, spawn } from 'redux-saga/effects'
import createDataModule from 'utils/moduleCreator'

const {
  api: { nca },
} = __CONFIG__

interface Credentials {
  identityId: string
  accessKeyId: string
  secretKey: string
  sessionToken: string
  expiration: number
  region: string
  turnCredentials: {
    username: string
    password: string
    ttl: string
    uris: string[]
  }
  roles: string[]
}

export const dataKey = 'nca'

const dataModule = createDataModule<Credentials | undefined>(
  dataKey,
  nca.paths.auth,
  nca.base
)

export const { reducer: ncaCredentialsReducer, rootSaga } = dataModule

export function* ncaRootSaga() {
  yield all([spawn(rootSaga)])
}
