import React, { useContext } from 'react'
import Dropdown from '@ubnt/ui-components/Dropdown/Dropdown'
import {
  CustomOptionProps,
  DropdownOption,
} from '@ubnt/ui-components/Dropdown/types'

import { typography } from 'theme'
import styled from 'theme/styled'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'

type Props = {
  handleChange: (value: DropdownOption) => void
  options: DropdownOption[]
  value?: string
}

export const FormDropdown: React.FC<Props> = ({
  handleChange,
  options,
  value,
}) => {
  const { isMaxMobileXLarge } = useContext(MediaSizingContext)

  const handleRenderOption = ({
    option,
    onClick,
  }: CustomOptionProps<DropdownOption>) => {
    return (
      <StyledDropdownOption onClick={onClick}>
        <DropdownLabel>{option.label}</DropdownLabel>
        {option.additionalInfo && (
          <DropdownMac>{option.additionalInfo}</DropdownMac>
        )}
      </StyledDropdownOption>
    )
  }

  return (
    <Dropdown
      value={value}
      variant="secondary"
      searchable
      onChange={handleChange}
      options={options}
      renderOption={handleRenderOption}
      width={isMaxMobileXLarge ? '100%' : '408px'}
    />
  )
}

const StyledDropdownOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  gap: 8px;
  font: ${typography['desktop-typography-label-generic']};
  color: ${({ theme }) => theme.text3};
`

const DropdownLabel = styled.div`
  color: ${({ theme }) => theme.text1};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const DropdownMac = styled.span`
  width: 150px;
  text-align: right;
`
