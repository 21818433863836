import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import QrCode from 'qrcode.react'
import { Button, Loader } from '@ubnt/ui-components'
import { ValidationMessage } from '@ubnt/ui-components/ValidationMessage'

import styled from 'theme/styled'
import { closeVisibleModal, setVisibleModal } from 'modules/modals'
import { getErrorMessage } from 'utils/mfa'

import { GenericMFAModal } from '../GenericMFAModal'
import { TOTP_SECRET_CODE_MODAL_ID } from './SecretCodeModal'
import { TOTP_VERIFY_CODE_MODAL_ID } from './VerifyCodeModal'
import {
  selectInitiateTOTPData,
  selectInitiateTOTPErrors,
  selectInitiateTOTPIsError,
  selectInitiateTOTPIsLoading,
} from './modules/initiateTOTP'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
`

const StyledButton = styled(Button)`
  font-size: 14px;
  line-height: 16px;
  margin-top: 16px;
  font-weight: bold;
  text-decoration: none !important;
`

const DescriptionWrapper = styled.div`
  line-height: 24px;
`

export const TOTP_QR_CODE_MODAL_ID = 'TOTP_QR_CODE_MODAL_ID'

export const TOTPQRCodeModal: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const { provisioning_uri } = useSelector(selectInitiateTOTPData)
  const isLoading = useSelector(selectInitiateTOTPIsLoading)
  const apiError = useSelector(selectInitiateTOTPErrors)
  const isError = useSelector(selectInitiateTOTPIsError)

  let contentToRender: JSX.Element = (
    <ValidationMessage>
      {intl.formatMessage({
        id: 'GENERIC_ERROR_BOUNDARY_TITLE',
      })}
    </ValidationMessage>
  )

  if (isError) {
    contentToRender = (
      <ValidationMessage>
        {getErrorMessage({
          apiError,
          defaultMessage: intl.formatMessage({
            id: 'GENERIC_ERROR_BOUNDARY_TITLE',
          }),
          fields: ['detail'],
        })}
      </ValidationMessage>
    )
  } else if (isLoading) {
    contentToRender = <Loader />
  } else if (provisioning_uri) {
    contentToRender = (
      <>
        <QrCode size={170} value={provisioning_uri} />
        <StyledButton
          variant="inline"
          size="medium"
          weight="bold"
          onClick={() =>
            dispatch(
              setVisibleModal(TOTP_SECRET_CODE_MODAL_ID, {
                setupButtonClicked: true,
              })
            )
          }
        >
          {intl.formatMessage({
            id: 'SETTINGS_MFA_TOTP_MODAL_ACTION_SHOW_TOTP_SECRET_CODE_MODAL',
          })}
        </StyledButton>
      </>
    )
  }

  return (
    <GenericMFAModal
      modalId={TOTP_QR_CODE_MODAL_ID}
      title={intl.formatMessage({ id: 'SETTINGS_MFA_TOTP_MODAL_TITLE' })}
      isLoading={isLoading}
      description={
        <DescriptionWrapper>
          <FormattedMessage id="SETTINGS_MFA_TOTP_MODAL_DESCRIPTION" />
        </DescriptionWrapper>
      }
      actions={[
        {
          text: intl.formatMessage({
            id: 'COMMON_ACTION_CANCEL',
          }),
          variant: 'tertiary',
          onClick: () => dispatch(closeVisibleModal()),
        },
        {
          text: intl.formatMessage({
            id: 'COMMON_ACTION_CONTINUE',
          }),
          disabled: isError,
          variant: 'primary',
          onClick: () => dispatch(setVisibleModal(TOTP_VERIFY_CODE_MODAL_ID)),
        },
      ]}
      overrideFullScreen
      size="auto"
    >
      <Wrapper>{contentToRender}</Wrapper>
    </GenericMFAModal>
  )
}
