import type Stripe from 'stripe'
import { all, put, spawn, takeEvery } from 'redux-saga/effects'

import createDataModule from 'utils/moduleCreator'
import { StripeData } from 'types/types'

import { fetchTaxId } from './getTaxId'

const {
  api: { billing },
} = __CONFIG__

export const removeTaxIdDataKey = 'removeTaxId'

const dataModule = createDataModule<StripeData<Stripe.TaxId>>(
  removeTaxIdDataKey,
  billing.paths.taxId,
  billing.base
)

export const {
  remove: removeTaxId,
  selectIsLoading: selectIsRemoveTaxIdLoading,
  selectIsDone: selectIsRemoveTaxIdDone,
  selectErrors: selectRemoveTaxIdErrors,
  resetErrors: resetRemoveTaxIdErrors,
  reducer: removeTaxIdReducer,
} = dataModule

function* taxIdDoneSaga() {
  yield put(fetchTaxId())
}

function* subscribeToRemoveTaxIdDoneSaga() {
  yield takeEvery([dataModule.REMOVE_DONE], taxIdDoneSaga)
}

export function* removeTaxIdRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToRemoveTaxIdDoneSaga)])
}
