import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Loader } from '@ubnt/ui-components'

import { selectIsFetchLoading, selectSessions } from 'modules/session'
import styled from 'theme/styled'

import { SessionItem } from './SessionItem'

export const SessionContent: React.FC = () => {
  const isFetchSessionsLoading = useSelector(selectIsFetchLoading)
  const sessions = useSelector(selectSessions)

  const currentSession = useMemo(() => {
    if (sessions?.length) {
      return sessions.find((session) => session.is_current_session)
    }
  }, [sessions])

  const nonCurrentSessions = useMemo(() => {
    if (sessions?.length) {
      return sessions.filter((session) => !session.is_current_session)
    }
  }, [sessions])

  if (isFetchSessionsLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    )
  }

  return (
    <Wrapper>
      {currentSession && (
        <SessionItem session={currentSession} isCurrentSession />
      )}
      {nonCurrentSessions?.map((session, i) => (
        <SessionItem session={session} key={i} />
      ))}
    </Wrapper>
  )
}

const LoaderContainer = styled.div`
  display: flex;
  margin: 50px;
  justify-content: center;
`

const Wrapper = styled.div`
  > div:last-child {
    border: none;
  }
`
