import { domainNameList } from 'utils/domainNameList'

export class BrowserUtils {
  static getLocationQueryValue(queryParam: string) {
    return this.getQueryValue(window.location.toString(), queryParam)
  }

  static getLocationInnerQueryValue(
    queryParam: string,
    innerQueryParam: string
  ) {
    return this.getInnerQueryValue(
      window.location.toString(),
      queryParam,
      innerQueryParam
    )
  }

  static getInnerQueryValue(
    url: string,
    queryParam: string,
    innerQueryParam: string
  ) {
    const subUrl = this.getQueryValue(url, queryParam)
    if (subUrl && this.validURL(subUrl)) {
      return this.getQueryValue(subUrl, innerQueryParam)
    }
    return false
  }

  static parseLocationHash(hash: string) {
    if (!hash) return {}
    return hash
      .split('#')[1]
      .split('&')
      .map((v) => v.split('='))
      .reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {})
  }

  static getQueryValue(url: string, queryParam: string) {
    let decoded: URL | undefined
    try {
      decoded = new URL(decodeURI(encodeURI(url)))
    } catch (e) {
      console.error('Could  not decode url', { url, queryParam, e })
    }
    return (
      decoded && decoded.searchParams && decoded.searchParams.get(queryParam)
    )
  }

  static getQueryVariable(variable: string) {
    const query = decodeURIComponent(window.location.search.substring(1))
    const vars = query.split('&')
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=')
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1])
      }
    }
  }

  // INFO: This is only valid for domain + one suffix. Example:
  // ui.com -> success
  // ui.co.uk -> failure
  static validateUrl(url?: string | null, customDomainNameListArr?: string[]) {
    if (!url) return

    try {
      const inputUrl = new URL(url)
      const domainNameArr = customDomainNameListArr || domainNameList

      const domainName = inputUrl.hostname.split('.').slice(-2).join('.')

      const isDomainValid = domainNameArr.includes(domainName)

      const isValidProtocol =
        inputUrl.protocol === 'http:' || inputUrl.protocol === 'https:'

      return isDomainValid && isValidProtocol
    } catch {
      return false
    }
  }

  static validURL(str: string) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ) // fragment locator
    return !!pattern.test(encodeURI(decodeURI(str)))
  }
}
