import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import PublicPage from 'pages/PublicPage'
import { MfaFeedbackForm } from './MfaFeedbackForm'
import { MfaFeedbackInfo } from './MfaFeedbackInfo'
import { selectCanSubmitFeedback } from 'features/auth/modules/auth'
import { useSelector } from 'react-redux'
import { selectHasSubmittedMfaFeedbackRecently } from 'features/auth/modules/loginIssueFeedback'

export const MfaFeedback = () => {
  const history = useHistory()
  const canSubmitMfaFeedback = useSelector(selectCanSubmitFeedback)
  const hasSubmittedMfaFeedbackRecently = useSelector(
    selectHasSubmittedMfaFeedbackRecently
  )

  if (!canSubmitMfaFeedback || hasSubmittedMfaFeedbackRecently) {
    history.push('/login')
  }

  return (
    <PublicPage showLogo={false}>
      <Container>
        <MfaFeedbackInfo
          titleMessageId="LOGIN_MFA_FEEDBACK_TITLE"
          descriptionMessageId="LOGIN_MFA_FEEDBACK_DESCRIPTION"
        />
        <MfaFeedbackForm />
      </Container>
    </PublicPage>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`
