import styled from 'theme/styled'
import { media, space, typography } from 'theme'
import ExclamationMarkIcon from '@ubnt/icons/ExclamationMarkIcon'

export const Columns = styled.div`
  margin: auto 0;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 150px 150px 120px 24px;

  justify-content: space-between;
  @media only screen and (max-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
`

export const Row = styled.div`
  height: 32px;
  padding-left: ${space['desktop-spacing-base-01']};
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.neutral00};
  transition: background-color 0.2s ease-in;
  will-change: background-color;

  &:not(:first-of-type):hover {
    background-color: ${({ theme }) => theme.neutral01};
  }
`

export const CellColumns = styled(Columns)<{
  $mixedRegions?: boolean
  $cancelAtPeriodEnd?: boolean
  $skipFirstColumn?: boolean
}>`
  grid-template-columns:
    ${({ $mixedRegions, $cancelAtPeriodEnd, $skipFirstColumn }) => {
      const mixedRegions = $mixedRegions ? '35px' : ''
      const cancelAtPeriodEnd = $cancelAtPeriodEnd ? '28px' : ''
      const firstColumn = $skipFirstColumn ? '' : '90px'

      return `${cancelAtPeriodEnd} ${mixedRegions} 1fr ${firstColumn} 110px 160px 70px 24px;`
    }}
    @media only screen and (max-width: ${media.xSmall}) {
    grid-template-columns: ${({ $mixedRegions, $cancelAtPeriodEnd }) => {
      const mixedRegions = $mixedRegions ? '35px' : ''
      const cancelAtPeriodEnd = $cancelAtPeriodEnd ? '28px' : ''

      return `${cancelAtPeriodEnd} ${mixedRegions} 1fr 70px 24px;`
    }};
  }
`

export const Cell = styled.div`
  display: flex;
  height: 100%;
  margin: auto 0;
  align-items: center;
`

export const ChargeCell = styled(Cell)`
  display: flex;
  flex-direction: row;
  margin-right: ${space['desktop-spacing-base-05']};
`

export const CellColumnLabel = styled(CellColumns)`
  font-weight: bold;
`

export const LabelCell = styled(Cell)`
  height: unset;
`

export const ChargeLabelCell = styled(LabelCell)`
  display: flex;
  margin-right: ${space['desktop-spacing-base-05']};
`

export const CardCell = styled(Cell)`
  justify-content: space-between;
`

export const CellContainer = styled.div<{ noBorder?: boolean }>`
  ${({ theme, noBorder }) =>
    !noBorder && `border-top: 1px solid ${theme.neutral03}`};
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.text2};
`

export const StyledTextSmall = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 0 ${space['desktop-spacing-base-01']};
  color: ${({ color, theme }) => color ?? theme.text2};
`

export const AddWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
export const NumberWrapper = styled.div`
  color: ${({ theme }) => theme.text2};
`

export const CellTextPrimary = styled.div`
  color: ${({ theme }) => theme.text2};
  font: ${typography['desktop-body']};
`

export const CellTextSecondary = styled.div`
  color: ${({ theme }) => theme.text3};
  font: ${typography['desktop-caption']};
`

export const RowWarningIcon = styled(ExclamationMarkIcon)`
  color: ${({ theme }) => theme.orange06};
`
