import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { compose } from 'redux'
import { ToastProvider } from '@ubnt/ui-components'
import { ConnectedRouter } from 'connected-react-router'
import { ThemeProvider } from '@emotion/react'

import { history } from 'store'
import Logout from 'features/auth/ui/Logout'
import {
  checkAuth,
  checkAuthCookie,
  selectIsDoneAuthenticating,
} from 'features/auth/modules/auth'
import { fetchLegalInfo } from 'modules/legal'
import { fetchRoles } from 'features/early-access/module/roles'
import LoginForm from 'features/auth/ui/LoginForm'
import RegisterForm from 'features/auth/ui/RegisterForm'
import RegisterSuccess from 'features/auth/ui/RegisterSuccess'
import ChangePasswordForm from 'features/auth/ui/ChangePasswordForm'
import ForgotPasswordForm from 'features/auth/ui/ForgotPasswordForm'
import VerifyEmail from 'features/auth/ui/VerifyEmail'
import {
  innerRedirect,
  outerRedirect,
  useOuterRedirectStore,
} from 'features/redirect-after-register'
import requiresAuth from 'components/requiresAuth'
import LoginMFA from 'features/auth/ui/LoginMFA'
import SetMFAFirstLogin from 'features/auth/ui/SetMFAFirstLogin'
import ResendVerificationComplete from 'features/auth/ui/ResendVerificationFormComplete'
import { fetchSubscriptions } from 'features/subscriptions/module/subscriptions'
import { fetchProducts } from 'features/subscriptions/module/products'
import { fetchInvoices } from 'features/subscriptions/module/invoices'
import { fetchMFA, selectMFAAuthenticators } from 'modules/mfa'
import { useTracking } from 'utils/useTracking'
import { fetchDevices, selectOwnedUcoreDevices } from 'features/devices/devices'
import { fetchAddresses } from 'features/addresses/module/addresses'
import { selectProfileData } from 'modules/profile'
import { RedirectPage } from 'features/auth/ui/RedirectPage'
import { ErrorPageFourOhFour } from 'core/error-handling/ErrorPages'
import { fetchSessions } from 'modules/session'
import { fetchDeviceBackups } from 'features/backups/module/backups'
import { StripeRegionProvider } from 'features/stripe/ui/Region'
import theme, { motifPalette } from 'theme'
import { useMotif } from 'motif/useMotif'
import { fetchUmrInfo } from 'features/subscriptions/module/deviceUmr'
import { MaintenancePage } from 'features/status/ui/MaintenancePage'
import { fetchTaxId } from 'features/payment-methods/modules/taxId/getTaxId'
import {
  selectActiveMaintenance,
  statusApi,
} from 'features/status/modules/statusApi'
import { fetchUmrRDevices } from 'features/devices/umrDevices'
import { fetchAnnouncements } from 'features/announcements/module/announcements'

import { QuickSupportPage } from 'features/support/support-quick-link/QuickSupportPage'
import { ChangeEmail } from 'features/auth/ui/emailChange/ChangeEmail'
import { ConfirmEmailChange } from 'features/auth/ui/emailChange/ConfirmEmailChange'
import { ChangeEmailSuccess } from 'features/auth/ui/emailChange/ChangeEmailSuccess'
import { MfaFeedback } from 'features/auth/ui/mfaFeedback/MfaFeedback'
import { MfaFeedbackSuccess } from 'features/auth/ui/mfaFeedback/MfaFeedbackSuccess'
import { Requests } from './requests/Requests'
import { Request } from './request/Request'
import { RequestsProvider } from './requests/RequestsContext'
import { fetchActivity } from './activity/module/activity'
import Activate from './activate/Activate'
import SupportFormPage from './supportForm/SupportFormPage'
import Home from './home/Home'
import Backups from './backups/Backups'
import Subscriptions from './subscriptions/Subscriptions'
import Security from './security/Security'
import Profile from './profile/Profile'
import { AlertSlackChannel } from './request/AlertSlackChannel'

const App = () => {
  useTracking(__CONFIG__.GOOGLE_ANALYTICS_KEY)
  useOuterRedirectStore()
  const dispatch = useDispatch()
  const mfaAuthenticators = useSelector(selectMFAAuthenticators)
  const profile = useSelector(selectProfileData)
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)
  const activeMaintenance = useSelector(selectActiveMaintenance)

  const [isRedirecting, setIsRedirecting] = useState(false)
  const chatContainerRef = useRef(null)
  const devices = useSelector(selectOwnedUcoreDevices)

  const FETCH_USER_INTERVAL = 15000

  const motif = useMotif()

  useEffect(() => {
    if (isDoneAuthenticating) {
      dispatch(fetchActivity())
      dispatch(fetchSessions())
    }
  }, [dispatch, isDoneAuthenticating, mfaAuthenticators, profile])

  useEffect(() => {
    if (isDoneAuthenticating) {
      const interval = setInterval(async () => {
        await checkAuthCookie()
      }, FETCH_USER_INTERVAL)
      return () => clearInterval(interval)
    }
  }, [isDoneAuthenticating])

  useEffect(() => {
    dispatch(statusApi())
    dispatch(checkAuth())
    dispatch(fetchLegalInfo())
    dispatch(fetchRoles())
  }, [dispatch])

  useEffect(() => {
    if (isDoneAuthenticating) {
      dispatch(fetchSubscriptions())
      dispatch(fetchUmrInfo())
      dispatch(fetchUmrRDevices())
      dispatch(fetchProducts())
      dispatch(fetchInvoices())
      dispatch(fetchTaxId())
      dispatch(fetchAnnouncements())
    }
  }, [dispatch, isDoneAuthenticating])

  useEffect(() => {
    if (isDoneAuthenticating) {
      const date = new Date()
      const threeMonthsAgo = date.setMonth(date.getMonth() - 3)

      dispatch(fetchMFA())
      /* we only want devices that are connected + devices that were
      disconnected less than 3 months ago */
      dispatch(fetchDevices(threeMonthsAgo))
      dispatch(fetchAddresses())
    }
  }, [dispatch, isDoneAuthenticating])

  useEffect(() => {
    if (devices.length) {
      dispatch(fetchDeviceBackups(devices))
    }
  }, [devices, dispatch])

  const checkOuterRedirect = useCallback(() => {
    if (!isDoneAuthenticating) return false
    if (!isRedirecting && !outerRedirect()) return false
    if (!isRedirecting) {
      setIsRedirecting(true)
    }
    return <Route component={RedirectPage} />
  }, [isRedirecting, isDoneAuthenticating])

  const checkInnerRedirect = useCallback(() => {
    if (!isDoneAuthenticating) return false
    return innerRedirect(history)
  }, [isDoneAuthenticating])

  if (activeMaintenance) return <MaintenancePage />

  return (
    <ThemeProvider theme={{ ...theme, ...motifPalette[motif] }}>
      <ConnectedRouter history={history}>
        <div id="app" ref={chatContainerRef} />
        <ToastProvider fixed>
          <StripeRegionProvider>
            <Switch>
              {checkOuterRedirect()}
              {checkInnerRedirect()}
              <Route
                exact
                path="/reset-password/password-reset-uuid/:uuid"
                component={ChangePasswordForm}
              />
              <Route
                exact
                path="/reset-password"
                component={ForgotPasswordForm}
              />
              <Route
                exact
                path="/verify/verification-code/:uuid"
                component={VerifyEmail as any}
              />
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/login" component={LoginForm} />
              <Route exact path="/login/mfa" component={LoginMFA} />
              <Route
                exact
                path="/reset-2fa"
                component={() => <Redirect to="/login" />}
              />
              <Route exact path="/register" component={RegisterForm} />
              <Route
                exact
                path="/register/success"
                component={RegisterSuccess}
              />
              <Route
                exact
                path="/verify/success"
                component={SetMFAFirstLogin}
              />
              <Route
                exact
                path="/resend-verification"
                component={() => <Redirect to="/login" />}
              />
              <Route
                exact
                path="/resend-verification/success"
                component={ResendVerificationComplete}
              />
              <Route exact path="/" component={Home} />
              <Route exact path="/profile" component={Profile} />
              <Route path="/security" component={Security} />
              <Route
                exact
                path="/payments"
                component={({ location }: RouteComponentProps) => (
                  <Redirect to={`/subscriptions${location.search}`} />
                )}
              />
              <Route path="/subscriptions" component={Subscriptions} />
              <Route
                exact
                path="/invoices/:invoiceId"
                component={({
                  match: {
                    params: { invoiceId },
                  },
                }: RouteComponentProps<{ invoiceId: string }>) => {
                  return (
                    <Redirect to={`/subscriptions?invoiceId=${invoiceId}`} />
                  )
                }}
              />
              <Route exact path="/backups" component={Backups} />
              <Route
                exact
                path="/mysupport"
                component={() => <Redirect to="/requests" />}
              />
              <Route exact path="/supportform">
                <SupportFormPage />
              </Route>
              <Route exact path="/support/quick-link">
                <QuickSupportPage />
              </Route>
              <Route
                exact
                path="/supportform-uisp"
                component={() => <Redirect to="/supportform?formtype=uisp" />}
              />
              <Route
                exact
                path="/storeform"
                component={() => <Redirect to="/supportform?formtype=store" />}
              />
              <Route
                exact
                path="/supportform-umr"
                component={() => <Redirect to="/supportform?formtype=umr" />}
              />
              <Route exact path="/requests">
                <RequestsProvider>
                  <Requests />
                </RequestsProvider>
              </Route>
              <Route exact path="/requests/:ticketId">
                <RequestsProvider>
                  <Request />
                </RequestsProvider>
              </Route>
              <Route exact path="/activate" component={Activate} />
              <Route
                exact
                path="/advanced"
                component={() => <Redirect to="/" />}
              />
              <Route
                exact
                path="/earlyaccess"
                component={() => <Redirect to="/profile" />}
              />
              <Route
                path="/manage"
                component={requiresAuth(() => (
                  <Redirect to="/" />
                ))}
              />
              <Route
                exact
                path="/request/alert/:ticketId"
                component={AlertSlackChannel}
              />
              <Route exact path="/login/change-email" component={ChangeEmail} />
              <Route
                exact
                path="/login/change-email/confirm"
                component={ConfirmEmailChange}
              />
              <Route
                exact
                path="/login/change-email/success"
                component={ChangeEmailSuccess}
              />
              <Route exact path="/login/mfa-feedback" component={MfaFeedback} />
              <Route
                exact
                path="/login/mfa-feedback/success"
                component={MfaFeedbackSuccess}
              />
              <Route component={ErrorPageFourOhFour} />
            </Switch>
          </StripeRegionProvider>
        </ToastProvider>
      </ConnectedRouter>
    </ThemeProvider>
  )
}

const AppConnected = compose<typeof App>()(App)

export default AppConnected
