import { Accordion } from '@ubnt/ui-components'
import { Checkbox } from '@ubnt/ui-components/Checkbox'
import { Dropdown } from '@ubnt/ui-components/Dropdown'
import { PopoverCardMenu } from '@ubnt/ui-components/Popover/PopoverCardMenu'
import { ExclamationMarkIcon } from '@ubnt/icons'
import { typography } from '@ubnt/ui-components/styles/designToken'
import styled from 'theme/styled'

const EmptyBackupContentWrapper = styled.div`
  background: ${({ theme }) => theme.neutral01};
  border-radius: 4px;
  display: flex;
  gap: 8px;
  margin: 16px 4px 16px 44px;
  max-width: 562px;
  padding: 8px;
  width: auto;

  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    max-width: 100%;
    margin-left: 4px;
  }
`

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const Text = styled.p`
  margin: 0;
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.text2};
`

const LoaderWrap = styled.div`
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled.div`
  font: ${typography['desktop-typography-heading-medium']};
  color: ${({ theme }) => theme.text2};
`

const UnifiLink = styled.a`
  color: ${({ theme }) => theme.ublue06};
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;

  svg {
    color: ${({ theme }) => theme.orange06};
    height: 20px;
    width: 20px;
  }
`

const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 220px;
  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    width: 100%;
  }
`

const StyledDropdown = styled(Dropdown)`
  .allOptionClassName {
    padding-left: 5px;
  }

  .consoleDropdown-option {
    div {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      height: auto;
      height: 20px;
    }
  }
`

const StyledCheckbox = styled(Checkbox)`
  margin: auto 0;
  margin-left: 18px;

  .backupsCheckboxLabel {
    div {
      margin-right: 0 !important;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.text3};
`

const Row = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.neutral03};
  height: 49px;
  justify-content: space-between;

  :last-of-type {
    border-bottom: none;
  }
`

const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 8px 0 44px;
  width: 100%;
`

const Labels = styled(Columns)`
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  margin: 16px 8px 16px 44px;
  width: 100%;
`

const Cell = styled.div<{ height?: string }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: ${({ height }) => height ?? '100%'};
  margin: auto 0;
`

const LabelCell = styled(Cell)`
  height: unset;
`

const CellContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const CellWithRightMargin = styled(Cell)`
  margin-right: 10px;
`

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    align-items: start;
    justify-content: start;
    flex-direction: column;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: max-content;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    flex-direction: column;
  }
`

const HeaderTitle = styled.div`
  font: ${typography['desktop-typography-heading-medium']};
  color: ${({ theme }) => theme.text2};
  display: flex;
  align-items: center;
  gap: 8px;
`

const SortContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
  margin-top: 6px;
  svg {
    color: ${({ theme }) => theme.neutral08} !important;
    margin-right: 6px;
  }
`

const StyledPopoverCardMenu = styled(PopoverCardMenu)`
  li {
    height: max-content;
    padding: 0;
    background: ${({ theme }) => theme.neutral01};

    :hover {
      background: ${({ theme }) => theme.neutral03};
    }

    span {
      align-items: center;
      color: ${({ theme }) => theme.text1};
      display: flex;
      font-size: 14px;
      line-height: 20px;
      padding: 8px;
    }

    .selected {
      background: ${({ theme }) => theme.neutral02};
      padding: 8px;
    }

    span > img {
      margin-right: 8px;
    }

    svg {
      color: ${({ theme }) => theme.neutral08} !important;
    }
  }
`

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.neutral02};
`

const ModalMessage = styled.div`
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.text2};
`

const BackupsListContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledAccordion = styled(Accordion)`
  .noMargin {
    margin-bottom: 0;
  }

  li {
    &[aria-expanded='false'] {
      border-bottom: none;
    }

    &[aria-expanded='true'] {
      border-bottom: 1px solid ${({ theme }) => theme.neutral03} !important;
    }
  }

  :last-child {
    li {
      :last-child {
        border-bottom: none !important;
      }
    }
    & div:last-child {
      border-bottom: none;
    }
  }
`

const DeviceBackupsContainer = styled.div`
  cursor: pointer;
`

const DeviceBackupsWrapper = styled.div<{ $isExpanded: boolean }>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 4px 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.neutral03};
  ${({ $isExpanded, theme }) =>
    $isExpanded &&
    `border-bottom: 1px solid ${theme.neutral03} !important;
`}
`

const BackupsInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const TitleWrapper = styled.div`
  display: flex;

  @media (max-width: ${({ theme }) => theme.media.mobileLarge}) {
    margin-bottom: 16px;
    width: 100%;
  }
`

const Amount = styled.span<{ hasBackups: boolean }>`
  color: ${({ hasBackups, theme }) => (hasBackups ? theme.text2 : theme.text3)};
  font: ${typography['desktop-body']};
`

const DeviceNameWrapper = styled.div`
  align-items: center;
  display: flex;
`

const DeviceImage = styled.img`
  height: 36px;
  width: 36px;
`

const DeviceInfo = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
`

const Name = styled.span`
  color: ${({ theme }) => theme.text2};
  font: ${typography['desktop-typography-heading-medium']};
`

const Detail = styled.span`
  color: ${({ theme }) => theme.text3};
  font: ${typography['desktop-caption']};
  line-height: 20px;
`

const EmptyStateContainer = styled.div`
  height: 524px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const StyledExclamationMarkIcon = styled(ExclamationMarkIcon)`
  height: 40px;
  width: 40px;
  color: ${({ theme }) => theme.orange06};
`

const EmptyStateContent = styled.p`
  color: ${({ theme }) => theme.text2};
  font: ${typography['desktop-body']};
  margin: 0;
  padding: 16px 0;
  text-align: center;
`

const WarningIconContainer = styled.div`
  display: flex;
  align-items: center;
`

export {
  Amount,
  BackupsInfo,
  BackupsListContainer,
  Cell,
  CellContainer,
  CellWithRightMargin,
  Columns,
  Detail,
  DeviceBackupsContainer,
  DeviceBackupsWrapper,
  DeviceImage,
  DeviceInfo,
  DeviceNameWrapper,
  Divider,
  EmptyBackupContentWrapper,
  EmptyStateContainer,
  EmptyStateContent,
  FilterContainer,
  HeaderTitle,
  HeaderWrapper,
  IconContainer,
  LabelCell,
  Labels,
  LoaderWrap,
  ModalMessage,
  Name,
  PageHeader,
  Row,
  SortContainer,
  StyledAccordion,
  StyledCheckbox,
  StyledDropdown,
  StyledExclamationMarkIcon,
  StyledPopoverCardMenu,
  Text,
  TextWrap,
  Title,
  UnifiLink,
  WarningIconContainer,
  Wrapper,
  TitleWrapper,
}
