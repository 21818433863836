//supported regions and countries from https://docs.aws.amazon.com/sns/latest/dg/sns-supported-regions-countries.html
// commented out countries are not supported since they require a dedicated phone number

export type RegionCountryCode =
  | 'AF'
  | 'AL'
  | 'DZ'
  | 'AD'
  | 'AO'
  | 'AI'
  | 'AG'
  | 'AR'
  | 'AM'
  | 'AW'
  | 'AU'
  | 'AT'
  | 'AZ'
  | 'BS'
  | 'BH'
  | 'BD'
  | 'BB'
  // | 'BY'
  | 'BE'
  | 'BZ'
  | 'BJ'
  | 'BM'
  | 'BT'
  | 'BO'
  | 'BA'
  | 'BW'
  | 'BR'
  | 'BN'
  | 'BG'
  | 'BF'
  | 'BI'
  | 'KH'
  | 'CM'
  | 'CA'
  | 'CV'
  | 'KY'
  | 'CF'
  | 'TD'
  | 'CL'
  | 'CN'
  | 'CO'
  | 'KM'
  | 'CK'
  | 'CR'
  | 'HR'
  | 'CY'
  | 'CZ'
  | 'CD'
  | 'DK'
  | 'DJ'
  | 'DM'
  | 'DO'
  | 'EC'
  // | 'EG'
  | 'SV'
  | 'GQ'
  | 'EE'
  | 'ET'
  | 'FO'
  | 'FJ'
  | 'FI'
  | 'FR'
  | 'GF'
  | 'GA'
  | 'GM'
  | 'GE'
  | 'DE'
  | 'GH'
  | 'GI'
  | 'GR'
  | 'GL'
  | 'GD'
  | 'GP'
  | 'GU'
  | 'GT'
  | 'GN'
  | 'GW'
  | 'GY'
  | 'HT'
  | 'HN'
  | 'HK'
  | 'HU'
  | 'IS'
  // | 'IN'
  | 'ID'
  | 'IQ'
  | 'IE'
  | 'IL'
  | 'IT'
  | 'CI'
  | 'JM'
  | 'JP'
  // | 'JO'
  // | 'KZ'
  | 'KE'
  | 'KI'
  // | 'KW'
  | 'KG'
  | 'LA'
  | 'LV'
  | 'LB'
  | 'LS'
  | 'LR'
  | 'LY'
  | 'LI'
  | 'LT'
  | 'LU'
  | 'MO'
  | 'MK'
  | 'MG'
  | 'MW'
  | 'MY'
  | 'MV'
  | 'ML'
  | 'MT'
  | 'MQ'
  | 'MR'
  | 'MU'
  | 'MX'
  | 'MD'
  | 'MC'
  | 'MN'
  | 'ME'
  | 'MS'
  | 'MA'
  | 'MZ'
  | 'MM'
  | 'NA'
  | 'NP'
  | 'NL'
  // | 'AN'
  | 'NC'
  | 'NZ'
  | 'NI'
  | 'NE'
  | 'NG'
  | 'NO'
  | 'OM'
  | 'PK'
  | 'PW'
  | 'PS'
  | 'PA'
  | 'PG'
  | 'PY'
  | 'PE'
  // | 'PH'
  | 'PL'
  | 'PT'
  | 'PR'
  // | 'QA'
  | 'CG'
  | 'RE'
  | 'RO'
  // | 'RU'
  | 'RW'
  | 'KN'
  | 'LC'
  | 'VC'
  | 'WS'
  | 'ST'
  // | 'SA'
  | 'SN'
  | 'RS'
  | 'SC'
  | 'SL'
  | 'SG'
  | 'SK'
  | 'SI'
  | 'SB'
  | 'SO'
  | 'ZA'
  | 'KR'
  | 'SS'
  | 'ES'
  // | 'LK'
  | 'SR'
  | 'SZ'
  | 'SE'
  | 'CH'
  | 'TW'
  | 'TJ'
  | 'TZ'
  // | 'TH'
  | 'TL'
  | 'TG'
  | 'TO'
  | 'TT'
  | 'TN'
  // | 'TR'
  | 'TM'
  | 'TC'
  | 'UG'
  | 'UA'
  // | 'AE'
  | 'GB'
  | 'US'
  | 'UY'
  | 'UZ'
  | 'VU'
  | 'VE'
  // | 'VN'
  | 'VG'
  | 'VI'
  | 'YE'
  | 'ZM'
  | 'ZW'

type RegionsCountries = {
  [key in RegionCountryCode]: {
    name: string
  }
}
export const regionsCountries: RegionsCountries = {
  AF: {
    name: 'Afghanistan',
  },
  AL: {
    name: 'Albania',
  },
  DZ: {
    name: 'Algeria',
  },
  AD: {
    name: 'Andorra',
  },
  AO: {
    name: 'Angola',
  },
  AI: {
    name: 'Anguilla',
  },
  AG: {
    name: 'Antigua and Barbuda',
  },
  AR: {
    name: 'Argentina',
  },
  AM: {
    name: 'Armenia',
  },
  AW: {
    name: 'Aruba',
  },
  AU: {
    name: 'Australia',
  },
  AT: {
    name: 'Austria',
  },
  AZ: {
    name: 'Azerbaijan',
  },
  BS: {
    name: 'Bahamas',
  },
  BH: {
    name: 'Bahrain',
  },
  BD: {
    name: 'Bangladesh',
  },
  BB: {
    name: 'Barbados',
  },
  // BY: {
  //   name: 'Belarus',
  // },
  BE: {
    name: 'Belgium',
  },
  BZ: {
    name: 'Belize',
  },
  BJ: {
    name: 'Benin',
  },
  BM: {
    name: 'Bermuda',
  },
  BT: {
    name: 'Bhutan',
  },
  BO: {
    name: 'Bolivia',
  },
  BA: {
    name: 'Bosnia and Herzegovina',
  },
  BW: {
    name: 'Botswana',
  },
  BR: {
    name: 'Brazil',
  },
  BN: {
    name: 'Brunei',
  },
  BG: {
    name: 'Bulgaria',
  },
  BF: {
    name: 'Burkina Faso',
  },
  BI: {
    name: 'Burundi',
  },
  KH: {
    name: 'Cambodia',
  },
  CM: {
    name: 'Cameroon',
  },
  CA: {
    name: 'Canada',
  },
  CV: {
    name: 'Cape Verde',
  },
  KY: {
    name: 'Cayman Islands',
  },
  CF: {
    name: 'Central African Republic',
  },
  TD: {
    name: 'Chad',
  },
  CL: {
    name: 'Chile',
  },
  CN: {
    name: 'China',
  },
  CO: {
    name: 'Colombia',
  },
  KM: {
    name: 'Comoros',
  },
  CK: {
    name: 'Cook Islands',
  },
  CR: {
    name: 'Costa Rica',
  },
  HR: {
    name: 'Croatia',
  },
  CY: {
    name: 'Cyprus',
  },
  CZ: {
    name: 'Czechia',
  },
  CD: {
    name: 'Democratic Republic of the Congo',
  },
  DK: {
    name: 'Denmark',
  },
  DJ: {
    name: 'Djibouti',
  },
  DM: {
    name: 'Dominica',
  },
  DO: {
    name: 'Dominican Republic',
  },
  EC: {
    name: 'Ecuador',
  },
  // EG: {
  //   name: 'Egypt',
  // },
  SV: {
    name: 'El Salvador',
  },
  GQ: {
    name: 'Equatorial Guinea',
  },
  EE: {
    name: 'Estonia',
  },
  ET: {
    name: 'Ethiopia',
  },
  FO: {
    name: 'Faroe Islands',
  },
  FJ: {
    name: 'Fiji',
  },
  FI: {
    name: 'Finland',
  },
  FR: {
    name: 'France',
  },
  GF: {
    name: 'French Guiana',
  },
  GA: {
    name: 'Gabon',
  },
  GM: {
    name: 'Gambia',
  },
  GE: {
    name: 'Georgia',
  },
  DE: {
    name: 'Germany',
  },
  GH: {
    name: 'Ghana',
  },
  GI: {
    name: 'Gibraltar',
  },
  GR: {
    name: 'Greece',
  },
  GL: {
    name: 'Greenland',
  },
  GD: {
    name: 'Grenada',
  },
  GP: {
    name: 'Guadeloupe',
  },
  GU: {
    name: 'Guam',
  },
  GT: {
    name: 'Guatemala',
  },
  GN: {
    name: 'Guinea',
  },
  GW: {
    name: 'Guinea-Bissau',
  },
  GY: {
    name: 'Guyana',
  },
  HT: {
    name: 'Haiti',
  },
  HN: {
    name: 'Honduras',
  },
  HK: {
    name: 'Hong Kong',
  },
  HU: {
    name: 'Hungary',
  },
  IS: {
    name: 'Iceland',
  },
  // IN: {
  //   name: 'India',
  // },
  ID: {
    name: 'Indonesia',
  },
  IQ: {
    name: 'Iraq',
  },
  IE: {
    name: 'Ireland',
  },
  IL: {
    name: 'Israel',
  },
  IT: {
    name: 'Italy',
  },
  CI: {
    name: 'Ivory Coast',
  },
  JM: {
    name: 'Jamaica',
  },
  JP: {
    name: 'Japan',
  },
  // JO: {
  //   name: 'Jordan',
  // },
  // KZ: {
  //   name: 'Kazakhstan',
  // },
  KE: {
    name: 'Kenya',
  },
  KI: {
    name: 'Kiribati',
  },
  // KW: {
  //   name: 'Kuwait',
  // },
  KG: {
    name: 'Kyrgyzstan',
  },
  LA: {
    name: 'Laos',
  },
  LV: {
    name: 'Latvia',
  },
  LB: {
    name: 'Lebanon',
  },
  LS: {
    name: 'Lesotho',
  },
  LR: {
    name: 'Liberia',
  },
  LY: {
    name: 'Libya',
  },
  LI: {
    name: 'Liechtenstein',
  },
  LT: {
    name: 'Lithuania',
  },
  LU: {
    name: 'Luxembourg',
  },
  MO: {
    name: 'Macau',
  },
  MK: {
    name: 'Former Yugoslav Republic of Macedonia',
  },
  MG: {
    name: 'Madagascar',
  },
  MW: {
    name: 'Malawi',
  },
  MY: {
    name: 'Malaysia',
  },
  MV: {
    name: 'Maldives',
  },
  ML: {
    name: 'Mali',
  },
  MT: {
    name: 'Malta',
  },
  MQ: {
    name: 'Martinique',
  },
  MR: {
    name: 'Mauritania',
  },
  MU: {
    name: 'Mauritius',
  },
  MX: {
    name: 'Mexico',
  },
  MD: {
    name: 'Moldova',
  },
  MC: {
    name: 'Monaco',
  },
  MN: {
    name: 'Mongolia',
  },
  ME: {
    name: 'Montenegro',
  },
  MS: {
    name: 'Montserrat',
  },
  MA: {
    name: 'Morocco',
  },
  MZ: {
    name: 'Mozambique',
  },
  MM: {
    name: 'Myanmar',
  },
  NA: {
    name: 'Namibia',
  },
  NP: {
    name: 'Nepal',
  },
  NL: {
    name: 'Netherlands',
  },
  // AN: {
  //   name: 'Netherlands Antilles', //not supported by libphonenumber-js
  // },
  NC: {
    name: 'New Caledonia',
  },
  NZ: {
    name: 'New Zealand',
  },
  NI: {
    name: 'Nicaragua',
  },
  NE: {
    name: 'Niger',
  },
  NG: {
    name: 'Nigeria',
  },
  NO: {
    name: 'Norway',
  },
  OM: {
    name: 'Oman',
  },
  PK: {
    name: 'Pakistan',
  },
  PW: {
    name: 'Palau',
  },
  PS: {
    name: 'Palestinian Territories',
  },
  PA: {
    name: 'Panama',
  },
  PG: {
    name: 'Papua New Guinea',
  },
  PY: {
    name: 'Paraguay',
  },
  PE: {
    name: 'Peru',
  },
  // PH: {
  //   name: 'Philippines',
  // },
  PL: {
    name: 'Poland',
  },
  PT: {
    name: 'Portugal',
  },
  PR: {
    name: 'Puerto Rico',
  },
  // QA: {
  //   name: 'Qatar',
  // },
  CG: {
    name: 'Republic of the Congo',
  },
  RE: {
    name: 'Reunion Island',
  },
  RO: {
    name: 'Romania',
  },
  // RU: {
  //   name: 'Russia',
  // },
  RW: {
    name: 'Rwanda',
  },
  KN: {
    name: 'Saint Kitts and Nevis',
  },
  LC: {
    name: 'Saint Lucia',
  },
  VC: {
    name: 'Saint Vincent and the Grenadines',
  },
  WS: {
    name: 'Samoa',
  },
  ST: {
    name: 'Sao Tome and Principe',
  },
  // SA: {
  //   name: 'Saudi Arabia',
  // },
  SN: {
    name: 'Senegal',
  },
  RS: {
    name: 'Serbia',
  },
  SC: {
    name: 'Seychelles',
  },
  SL: {
    name: 'Sierra Leone',
  },
  SG: {
    name: 'Singapore',
  },
  SK: {
    name: 'Slovakia',
  },
  SI: {
    name: 'Slovenia',
  },
  SB: {
    name: 'Solomon Islands',
  },
  SO: {
    name: 'Somalia',
  },
  ZA: {
    name: 'South Africa',
  },
  KR: {
    name: 'South Korea',
  },
  SS: {
    name: 'South Sudan',
  },
  ES: {
    name: 'Spain',
  },
  // LK: {
  //   name: 'Sri Lanka',
  // },
  SR: {
    name: 'Suriname',
  },
  SZ: {
    name: 'Swaziland',
  },
  SE: {
    name: 'Sweden',
  },
  CH: {
    name: 'Switzerland',
  },
  TW: {
    name: 'Taiwan',
  },
  TJ: {
    name: 'Tajikistan',
  },
  TZ: {
    name: 'Tanzania',
  },
  // TH: {
  //   name: 'Thailand',
  // },
  TL: {
    name: 'Timor-Leste',
  },
  TG: {
    name: 'Togo',
  },
  TO: {
    name: 'Tonga',
  },
  TT: {
    name: 'Trinidad and Tobago',
  },
  TN: {
    name: 'Tunisia',
  },
  // TR: {
  //   name: 'Turkey',
  // },
  TM: {
    name: 'Turkmenistan',
  },
  TC: {
    name: 'Turks and Caicos Islands',
  },
  UG: {
    name: 'Uganda',
  },
  UA: {
    name: 'Ukraine',
  },
  // AE: {
  //   name: 'United Arab Emirates',
  // },
  GB: {
    name: 'United Kingdom',
  },
  US: {
    name: 'United States',
  },
  UY: {
    name: 'Uruguay',
  },
  UZ: {
    name: 'Uzbekistan',
  },
  VU: {
    name: 'Vanuatu',
  },
  VE: {
    name: 'Venezuela',
  },
  // VN: {
  //   name: 'Vietnam',
  // },
  VG: {
    name: 'Virgin Islands, British',
  },
  VI: {
    name: 'Virgin Islands, US',
  },
  YE: {
    name: 'Yemen',
  },
  ZM: {
    name: 'Zambia',
  },
  ZW: {
    name: 'Zimbabwe',
  },
}
