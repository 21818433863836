import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { compose } from 'redux'
import { History } from 'history'
import { Alert } from '@ubnt/ui-components/Alert'

import {
  checkAuth,
  loginAuthUser,
  selectErrors,
} from 'features/auth/modules/auth'
import authorizedRedirect from 'components/authorizedRedirect'
import PublicPage from 'pages/PublicPage'
import 'utils/inputAutoFillFix.css'
import { MFAOption } from 'pages/security/components/SetupMFAModal/MFAOption'
import { closeVisibleModal, setVisibleModal } from 'modules/modals'
import {
  TOTPCompletedModal,
  TOTPQRCodeModal,
  TOTPSecretCodeModal,
  TOTPVerifyCodeModal,
} from 'pages/security/components/TOTP'
import {
  EmailCompletedModal,
  EmailInputEmailModal,
  EmailVerifyCodeModal,
} from 'pages/security/components/Email'
import { Description } from 'components/Description'
import {
  getAssuranceLevel,
  selectAssuranceLevel,
  selectErrors as selectAssuranceLevelErrors,
} from 'features/auth/modules/assuranceLevel'
import {
  PushCompletedModal,
  PushFailedModal,
  PushQRCodeModal,
  WrappedPushRemoveModal,
} from 'pages/security/components/Push'
import { SUBMIT_PASSWORD_MODAL_ID } from 'pages/security/SubmitPasswordModal'
import ShowOtherButton from 'components/showOtherButton/ShowOtherButton'
import HideOptions from 'components/hideOptions/HideOptions'
import { fetchAddresses } from 'features/addresses/module/addresses'
import styled from 'theme/styled'
import { space, typography } from 'theme'
import { selectInitiateEmailErrors } from 'pages/security/components/Email/modules/initiateEmail'
import { selectVerifyEmailErrors } from 'pages/security/components/Email/modules/verifyEmail'
import { selectInitiatePushErrors } from 'pages/security/components/Push/modules/initiatePush'
import { selectVerifyPushErrors } from 'pages/security/components/Push/modules/verifyPush'
import { SMSCompletedModal } from 'pages/security/components/SMS/CompletedModal'
import { SMSInputMobilePhoneNumberModal } from 'pages/security/components/SMS/InputMobilePhoneNumberModal'
import { SMSVerifyCodeModal } from 'pages/security/components/SMS/VerifyCodeModal'
import { selectInitiateTOTPErrors } from 'pages/security/components/TOTP/modules/initiateTOTP'
import { selectVerifyTOTPErrors } from 'pages/security/components/TOTP/modules/verifyTOTP'
import { selectInitiatePasskeyErrors } from 'pages/security/components/Passkey/modules/setupPasskey'
import { PasskeyAddKeyNameModal } from 'pages/security/components/Passkey/AddKeyNameModal'
import { PasskeyNoBrowserSupportModal } from 'pages/security/components/Passkey/NoBrowserSupportModal'
import { PasskeyLoadingModal } from 'pages/security/components/Passkey/LoadingModal'
import { PasskeyFailedModal } from 'pages/security/components/Passkey/FailedModal'
import { PasskeyCompletedModal } from 'pages/security/components/Passkey/CompletedModal'

import AssuranceLevelTimer from './AssuranceLevelTimer'
import { useGetMFAOptions } from './useGetMFAOptions'

interface Props {
  isLoading: boolean
  isAuthenticated: boolean
  isNotVerified: boolean
  history: History
}

export const SetMFAFirstLogin: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const mfaOptions = useGetMFAOptions()

  const [showOtherMethods, setShowOtherMethods] = useState(false)

  const initiatePasskeyErrors = useSelector(selectInitiatePasskeyErrors)

  const assuranceLevel = useSelector(selectAssuranceLevel)
  const [isCaptchaLoading, setCaptchaLoading] = useState(false)
  const [assuranceCheckDone, setAssuranceCheckDone] = useState<boolean | null>(
    null
  )

  const generalErrors = useSelector(selectErrors)
  const initiateTOTPErrors = useSelector(selectInitiateTOTPErrors)
  const verifyTOTPErrors = useSelector(selectVerifyTOTPErrors)
  const initiateEmailErrors = useSelector(selectInitiateEmailErrors)
  const verifyEmailErrors = useSelector(selectVerifyEmailErrors)
  const verifyPushErrors = useSelector(selectVerifyPushErrors)
  const initiatePushErrors = useSelector(selectInitiatePushErrors)
  const assuranceLevelErrors = useSelector(selectAssuranceLevelErrors)

  const mfaErrors = useMemo(
    () => [
      initiateTOTPErrors,
      verifyTOTPErrors,
      initiateEmailErrors,
      verifyEmailErrors,
      initiatePushErrors,
      verifyPushErrors,
      initiatePasskeyErrors,
    ],
    [
      initiateEmailErrors,
      initiateTOTPErrors,
      verifyEmailErrors,
      verifyTOTPErrors,
      verifyPushErrors,
      initiatePushErrors,
      initiatePasskeyErrors,
    ]
  )

  useEffect(() => {
    dispatch(getAssuranceLevel())
    dispatch(fetchAddresses())
    dispatch(loginAuthUser(window.location.search))
  }, [dispatch])

  const emailUpdatedItem = localStorage?.getItem('emailUpdated')
  const emailUpdated: boolean | undefined =
    emailUpdatedItem && JSON.parse(emailUpdatedItem)

  const redirect = useCallback(
    (pathname = '/') => {
      dispatch(closeVisibleModal())
      if (pathname === '/security/')
        dispatch(setVisibleModal(SUBMIT_PASSWORD_MODAL_ID))
      dispatch(checkAuth())
      props.history.push({ pathname })
    },
    [dispatch, props.history]
  )

  if (emailUpdated) {
    localStorage.removeItem('emailUpdated')
    redirect()
  }

  useEffect(() => {
    if (generalErrors || assuranceLevelErrors) {
      redirect()
    } else {
      mfaErrors?.find((error) => {
        if (typeof error === 'string' && error === 'is not allowed') {
          redirect('/login/')
        } else if (
          typeof error !== 'string' &&
          error?.ERRCODE &&
          error.ERRCODE === 'L2_REQUIRED'
        ) {
          redirect('/security/')
        }
      })
    }
  }, [mfaErrors, generalErrors, assuranceLevelErrors, redirect])

  useEffect(() => {
    if (assuranceLevel.isLoading === true) return setAssuranceCheckDone(false)
    if (assuranceCheckDone === false)
      setAssuranceCheckDone(!assuranceLevel.isLoading)
  }, [assuranceCheckDone, assuranceLevel.isLoading])

  useEffect(() => {
    if (
      assuranceCheckDone === true &&
      assuranceLevel?.data?.level &&
      assuranceLevel.data.level === 1
    ) {
      redirect('/security/')
      setAssuranceCheckDone(null)
    }
  }, [assuranceCheckDone, assuranceLevel, redirect])

  useEffect(() => {
    isCaptchaLoading &&
      // make sure that captcha stops loading in case the challenge window has been closed
      setTimeout(() => isCaptchaLoading && setCaptchaLoading(false), 1000)
  }, [isCaptchaLoading, setCaptchaLoading])

  return emailUpdated ? null : (
    <PublicPage>
      <Wrapper>
        <StyledDescription>
          {intl.formatMessage({ id: 'LOGIN_MFA_DESCRIPTION' })}
        </StyledDescription>
        <StyledAlert
          text={intl.formatMessage({ id: 'LOGIN_MFA_EMAIL_INFO' })}
        />
        {mfaOptions.featured}
        <HideOptions isShown={showOtherMethods}>
          {mfaOptions.other}
          <MFAOption
            description={intl.formatMessage({
              id: 'COMMON_ACTION_SKIP_FOR_NOW',
            })}
            onClick={() => props.history.push('/')}
          />
        </HideOptions>
        <ButtonWrapper>
          <ShowOtherButton
            handleClick={() => setShowOtherMethods(!showOtherMethods)}
            isOpen={showOtherMethods}
          />
        </ButtonWrapper>
      </Wrapper>
      <PasskeyAddKeyNameModal />
      <PasskeyLoadingModal />
      <PasskeyFailedModal />
      <PasskeyCompletedModal />
      <PasskeyNoBrowserSupportModal />
      <TOTPQRCodeModal />
      <TOTPSecretCodeModal />
      <TOTPVerifyCodeModal />
      <TOTPCompletedModal />
      <SMSInputMobilePhoneNumberModal />
      <SMSVerifyCodeModal />
      <SMSCompletedModal />
      <EmailInputEmailModal />
      <EmailCompletedModal />
      <EmailVerifyCodeModal />
      <PushQRCodeModal />
      <PushCompletedModal />
      <PushFailedModal />
      <WrappedPushRemoveModal />
      <AssuranceLevelTimer />
    </PublicPage>
  )
}

export default compose<React.FC<Record<string, unknown>>>(authorizedRedirect)(
  SetMFAFirstLogin
)

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 36px;
`

const StyledDescription = styled(Description)`
  margin-bottom: 16px;
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.text3};
  padding: 0;
`

const ButtonWrapper = styled.div`
  text-align: left;
  margin-top: ${space['desktop-spacing-base-04']};
`

const StyledAlert = styled(Alert)`
  margin-bottom: 8px;
`
