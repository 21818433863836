import { StripeCountry, StripeRegionCode } from '../types'

export const rowStripeCountries = [
  { code: 'za', name: 'South Africa', region: StripeRegionCode.ROW },
  { code: 'id', name: 'Indonesia', region: StripeRegionCode.ROW },
  { code: 'co', name: 'Colombia', region: StripeRegionCode.ROW },
  { code: 'ar', name: 'Argentina', region: StripeRegionCode.ROW },
  { code: 'ke', name: 'Kenya', region: StripeRegionCode.ROW },
  {
    code: 've',
    name: 'Venezuela, Bolivarian Republic of',
    region: StripeRegionCode.ROW,
  },
  { code: 'ph', name: 'Philippines', region: StripeRegionCode.ROW },
  { code: 'iq', name: 'Iraq', region: StripeRegionCode.ROW },
  { code: 'cl', name: 'Chile', region: StripeRegionCode.ROW },
  { code: 'pe', name: 'Peru', region: StripeRegionCode.ROW },
  { code: 'sa', name: 'Saudi Arabia', region: StripeRegionCode.ROW },
  {
    code: 'do',
    name: 'Dominican Republic',
    region: StripeRegionCode.ROW,
  },
  { code: 'pk', name: 'Pakistan', region: StripeRegionCode.ROW },
  { code: 'gt', name: 'Guatemala', region: StripeRegionCode.ROW },
  { code: 'th', name: 'Thailand', region: StripeRegionCode.ROW },
  { code: 'cr', name: 'Costa Rica', region: StripeRegionCode.ROW },
  { code: 'in', name: 'India', region: StripeRegionCode.ROW },
  {
    code: 'bo',
    name: 'Bolivia, Plurinational State of',
    region: StripeRegionCode.ROW,
  },
  { code: 'ec', name: 'Ecuador', region: StripeRegionCode.ROW },
  { code: 'il', name: 'Israel', region: StripeRegionCode.ROW },
  { code: 'jm', name: 'Jamaica', region: StripeRegionCode.ROW },
  {
    code: 'ly',
    name: 'Libya',
    region: StripeRegionCode.ROW,
  },
  { code: 'hn', name: 'Honduras', region: StripeRegionCode.ROW },
  { code: 'vn', name: 'Viet Nam', region: StripeRegionCode.ROW },
  { code: 'eg', name: 'Egypt', region: StripeRegionCode.ROW },
  { code: 'my', name: 'Malaysia', region: StripeRegionCode.ROW },
  { code: 'ng', name: 'Nigeria', region: StripeRegionCode.ROW },
  { code: 'pa', name: 'Panama', region: StripeRegionCode.ROW },
  { code: 'py', name: 'Paraguay', region: StripeRegionCode.ROW },
  { code: 'ma', name: 'Morocco', region: StripeRegionCode.ROW },
  { code: 'hk', name: 'Hong Kong', region: StripeRegionCode.ROW },
  { code: 'mm', name: 'Myanmar', region: StripeRegionCode.ROW },
  { code: 'kz', name: 'Kazakhstan', region: StripeRegionCode.ROW },
  { code: 'uy', name: 'Uruguay', region: StripeRegionCode.ROW },
  { code: 'gy', name: 'Guyana', region: StripeRegionCode.ROW },
  { code: 'ao', name: 'Angola', region: StripeRegionCode.ROW },
  { code: 'sv', name: 'El Salvador', region: StripeRegionCode.ROW },
  { code: 'gh', name: 'Ghana', region: StripeRegionCode.ROW },
  { code: 'ye', name: 'Yemen', region: StripeRegionCode.ROW },
  { code: 'ni', name: 'Nicaragua', region: StripeRegionCode.ROW },
  {
    code: 'ps',
    name: 'Palestine, State of',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'tt',
    name: 'Trinidad And Tobago',
    region: StripeRegionCode.ROW,
  },
  { code: 'kr', name: 'Korea, Republic of', region: StripeRegionCode.ROW },
  { code: 'na', name: 'Namibia', region: StripeRegionCode.ROW },
  {
    code: 'tz',
    name: 'Tanzania, United Republic of',
    region: StripeRegionCode.ROW,
  },
  { code: 'np', name: 'Nepal', region: StripeRegionCode.ROW },
  { code: 'bz', name: 'Belize', region: StripeRegionCode.ROW },
  { code: 'bd', name: 'Bangladesh', region: StripeRegionCode.ROW },
  { code: 'om', name: 'Oman', region: StripeRegionCode.ROW },
  { code: 'af', name: 'Afghanistan', region: StripeRegionCode.ROW },
  { code: 'ht', name: 'Haiti', region: StripeRegionCode.ROW },
  { code: 'tg', name: 'Togo', region: StripeRegionCode.ROW },
  { code: 'al', name: 'Albania', region: StripeRegionCode.ROW },
  { code: 'uz', name: 'Uzbekistan', region: StripeRegionCode.ROW },
  { code: 'lk', name: 'Sri Lanka', region: StripeRegionCode.ROW },
  { code: 'ug', name: 'Uganda', region: StripeRegionCode.ROW },
  { code: 'pg', name: 'Papua New Guinea', region: StripeRegionCode.ROW },
  { code: 'md', name: 'Moldova, Republic of', region: StripeRegionCode.ROW },
  { code: 'mv', name: 'Maldives', region: StripeRegionCode.ROW },
  { code: 'sn', name: 'Senegal', region: StripeRegionCode.ROW },
  { code: 'am', name: 'Armenia', region: StripeRegionCode.ROW },
  { code: 'dz', name: 'Algeria', region: StripeRegionCode.ROW },
  { code: 'mz', name: 'Mozambique', region: StripeRegionCode.ROW },
  { code: 'az', name: 'Azerbaijan', region: StripeRegionCode.ROW },
  { code: 'cw', name: 'Curaçao', region: StripeRegionCode.ROW },
  { code: 'lb', name: 'Lebanon', region: StripeRegionCode.ROW },
  { code: 'sr', name: 'Suriname', region: StripeRegionCode.ROW },
  { code: 'bb', name: 'Barbados', region: StripeRegionCode.ROW },
  { code: 'cv', name: 'Cape Verde', region: StripeRegionCode.ROW },
  { code: 'jo', name: 'Jordan', region: StripeRegionCode.ROW },
  { code: 'pf', name: 'French Polynesia', region: StripeRegionCode.ROW },
  { code: 'qa', name: 'Qatar', region: StripeRegionCode.ROW },
  { code: 'bs', name: 'Bahamas', region: StripeRegionCode.ROW },
  { code: 'ga', name: 'Gabon', region: StripeRegionCode.ROW },
  {
    code: 'gq',
    name: 'Equatorial Guinea',
    region: StripeRegionCode.ROW,
  },
  { code: 'me', name: 'Montenegro', region: StripeRegionCode.ROW },
  {
    code: 'cd',
    name: 'Congo, Democratic Republic of the',
    region: StripeRegionCode.ROW,
  },
  { code: 'kh', name: 'Cambodia', region: StripeRegionCode.ROW },
  { code: 'mn', name: 'Mongolia', region: StripeRegionCode.ROW },
  { code: 'mu', name: 'Mauritius', region: StripeRegionCode.ROW },
  { code: 'mw', name: 'Malawi', region: StripeRegionCode.ROW },
  { code: 'sb', name: 'Solomon Islands', region: StripeRegionCode.ROW },
  { code: 'sz', name: 'Eswatini', region: StripeRegionCode.ROW },
  { code: 'bf', name: 'Burkina Faso', region: StripeRegionCode.ROW },
  { code: 'bh', name: 'Bahrain', region: StripeRegionCode.ROW },
  { code: 'bw', name: 'Botswana', region: StripeRegionCode.ROW },
  { code: 'fj', name: 'Fiji', region: StripeRegionCode.ROW },
  { code: 'gf', name: 'French Guiana', region: StripeRegionCode.ROW },
  { code: 're', name: 'Réunion', region: StripeRegionCode.ROW },
  { code: 'zm', name: 'Zambia', region: StripeRegionCode.ROW },
  {
    code: 'ag',
    name: 'Antigua And Barbuda',
    region: StripeRegionCode.ROW,
  },
  { code: 'bj', name: 'Benin', region: StripeRegionCode.ROW },
  { code: 'gm', name: 'Gambia', region: StripeRegionCode.ROW },
  { code: 'mk', name: 'North Macedonia', region: StripeRegionCode.ROW },
  { code: 'ss', name: 'South Sudan', region: StripeRegionCode.ROW },
  { code: 'zw', name: 'Zimbabwe', region: StripeRegionCode.ROW },
  { code: 'gn', name: 'Guinea', region: StripeRegionCode.ROW },
  { code: 'kw', name: 'Kuwait', region: StripeRegionCode.ROW },
  { code: 'mo', name: 'Macao', region: StripeRegionCode.ROW },
  { code: 'nc', name: 'New Caledonia', region: StripeRegionCode.ROW },
  {
    code: 'tc',
    name: 'Turks And Caicos Islands',
    region: StripeRegionCode.ROW,
  },
  { code: 'aw', name: 'Aruba', region: StripeRegionCode.ROW },
  { code: 'bi', name: 'Burundi', region: StripeRegionCode.ROW },
  { code: 'cg', name: 'Congo', region: StripeRegionCode.ROW },
  { code: 'ck', name: 'Cook Islands', region: StripeRegionCode.ROW },
  { code: 'cm', name: 'Cameroon', region: StripeRegionCode.ROW },
  { code: 'dj', name: 'Djibouti', region: StripeRegionCode.ROW },
  { code: 'et', name: 'Ethiopia', region: StripeRegionCode.ROW },
  { code: 'gd', name: 'Grenada', region: StripeRegionCode.ROW },
  { code: 'gp', name: 'Guadeloupe', region: StripeRegionCode.ROW },
  { code: 'kg', name: 'Kyrgyzstan', region: StripeRegionCode.ROW },
  { code: 'ki', name: 'Kiribati', region: StripeRegionCode.ROW },
  {
    code: 'kn',
    name: 'Saint Kitts And Nevis',
    region: StripeRegionCode.ROW,
  },
  { code: 'ky', name: 'Cayman Islands', region: StripeRegionCode.ROW },
  {
    code: 'la',
    name: "Lao People's Democratic Republic",
    region: StripeRegionCode.ROW,
  },
  { code: 'mg', name: 'Madagascar', region: StripeRegionCode.ROW },
  { code: 'ml', name: 'Mali', region: StripeRegionCode.ROW },
  { code: 'mq', name: 'Martinique', region: StripeRegionCode.ROW },
  { code: 'ne', name: 'Niger', region: StripeRegionCode.ROW },
  { code: 'pn', name: 'Pitcairn', region: StripeRegionCode.ROW },
  { code: 'rw', name: 'Rwanda', region: StripeRegionCode.ROW },
  { code: 'sc', name: 'Seychelles', region: StripeRegionCode.ROW },
  { code: 'so', name: 'Somalia', region: StripeRegionCode.ROW },
  { code: 'tj', name: 'Tajikistan', region: StripeRegionCode.ROW },
  { code: 'tn', name: 'Tunisia', region: StripeRegionCode.ROW },
  {
    code: 'vg',
    name: 'Virgin Islands (British)',
    region: StripeRegionCode.ROW,
  },
  { code: 'bm', name: 'Bermuda', region: StripeRegionCode.ROW },
  {
    code: 'bq',
    name: 'Bonaire, Sint Eustatius and Saba',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'cf',
    name: 'Central African Republic',
    region: StripeRegionCode.ROW,
  },
  { code: 'eh', name: 'Western Sahara', region: StripeRegionCode.ROW },
  { code: 'er', name: 'Eritrea', region: StripeRegionCode.ROW },
  { code: 'lc', name: 'Saint Lucia', region: StripeRegionCode.ROW },
  {
    code: 'mf',
    name: 'Saint Martin (French part)',
    region: StripeRegionCode.ROW,
  },

  { code: 'mr', name: 'Mauritania', region: StripeRegionCode.ROW },
  { code: 'nu', name: 'Niue', region: StripeRegionCode.ROW },
  {
    code: 'pm',
    name: 'Saint Pierre And Miquelon',
    region: StripeRegionCode.ROW,
  },
  { code: 'sl', name: 'Sierra Leone', region: StripeRegionCode.ROW },
  { code: 'sm', name: 'San Marino', region: StripeRegionCode.ROW },
  {
    code: 'sx',
    name: 'Sint Maarten (Dutch part)',
    region: StripeRegionCode.ROW,
  },
  { code: 'td', name: 'Chad', region: StripeRegionCode.ROW },
  { code: 'tm', name: 'Turkmenistan', region: StripeRegionCode.ROW },
  {
    code: 'vc',
    name: 'Saint Vincent And Grenadines',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'ai',
    name: 'Anguilla',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'bl',
    name: 'Saint Barthélemy',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'bn',
    name: 'Brunei Darussalam',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'bt',
    name: 'Bhutan',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'bv',
    name: 'Bouvet Island',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'cc',
    name: 'Cocos (Keeling) Islands',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'dm',
    name: 'Dominica',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'fk',
    name: 'Falkland Islands (Malvinas)',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'gs',
    name: 'South Georgia and the South Sandwich Islands',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'gw',
    name: 'Guinea-Bissau',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'hm',
    name: 'Heard Island and McDonald Islands',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'io',
    name: 'British Indian Ocean Territory',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'km',
    name: 'Comoros',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'lr',
    name: 'Liberia',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'ls',
    name: 'Lesotho',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'ms',
    name: 'Montserrat',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'nf',
    name: 'Norfolk Island',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'nr',
    name: 'Nauru',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'sd',
    name: 'Sudan',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'sh',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'sj',
    name: 'Svalbard and Jan Mayen',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'st',
    name: 'Sao Tome and Principe',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'tf',
    name: 'French Southern Territories',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'tk',
    name: 'Tokelau',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'tl',
    name: 'Timor-Leste',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'to',
    name: 'Tonga',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'tv',
    name: 'Tuvalu',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'va',
    name: 'Holy See',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'vu',
    name: 'Vanuatu',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'wf',
    name: 'Wallis and Futuna',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'ws',
    name: 'Samoa',
    region: StripeRegionCode.ROW,
  },
  {
    code: 'yt',
    name: 'Mayotte',
    region: StripeRegionCode.ROW,
  },
] as const satisfies Readonly<StripeCountry[]>
