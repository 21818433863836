import { SupportFormState } from '../types'

export enum Country {
  brazil = 'brazil',
  canada = 'canada',
  china = 'china',
  europe = 'europe',
  india = 'india',
  japan = 'japan',
  mexico = 'mexico',
  middleEast = 'middle_east',
  singapore = 'singapore',
  southAfrica = 'south_africa',
  taiwan = 'taiwan',
  uk = 'uk',
  usa = 'usa',
}

export enum OrderIssue {
  trackOrder = 'track_my_order',
  cancelOrder = 'change_or_cancel_my_order',
  returnOrder = 'return_my_order',
  productAvailability = 'product_availability',
  taxExemption = 'tax_exemption_order',
  other = 'other_order_issue',
}

export interface ZDFormValueType {
  id: string
  label: string
}

type NextSection = {
  section: string
  condition?: { field: string; value: any }
}

export type FormValue = string | undefined

type ConfigElement = {
  fieldId: number
  fieldTitle: string | null
  options: ZDFormValueType[] | null
  nextSection: NextSection[] | null
  hide?: boolean
}

type AllData = SupportFormState & {
  info?: string
  attachments?: string
  rawData?: string
}

export type FormConfig = Record<keyof AllData, ConfigElement>

export const StoreRegionFaqMap = {
  [Country.brazil]: 'https://br.store.ui.com/a/faq#REEMBOLSOEGARANTIA',
  [Country.canada]: 'https://ca.store.ui.com/ca/en/faq',
  [Country.china]:
    'https://store.ui.com.cn/a/faq#%E9%80%80%E6%AC%BE%E4%B8%8E%E8%B4%A8%E9%87%8F%E4%BF%9D%E8%AF%81',
  [Country.europe]: 'https://eu.store.ui.com/eu/en/faq',
  [Country.india]: 'https://in.store.ui.com/in/en/faq',
  [Country.japan]:
    'https://jp.store.ui.com/jp/en/faq',
  [Country.mexico]: 'https://mx.store.ui.com/a/faq#REEMBOLSOYGARANTA',
  [Country.middleEast]:
    'https://me.store.ui.com/me/en/faq',
  [Country.singapore]: 'https://sg.store.ui.com/sg/en/faq',
  [Country.southAfrica]:
    'https://za.store.ui.com/za/en/faq',
  [Country.taiwan]:
    'https://tw.store.ui.com/a/faq#%E9%80%80%E6%AC%BE%E8%88%87%E4%BF%9D%E5%9B%BA',
  [Country.uk]:
    'https://uk.store.ui.com/uk/en/faq',
  [Country.usa]: 'https://store.ui.com/us/en/faq',
} satisfies Record<Country, string>
