import type Stripe from 'stripe'
import { all, put, spawn, takeEvery } from 'redux-saga/effects'

import { StripeData } from 'types/types'
import createDataModule from 'utils/moduleCreator'
import { closeVisibleModal } from 'modules/modals'

import { fetchTaxId } from './getTaxId'

const {
  api: { billing },
} = __CONFIG__

export const addTaxIdDataKey = 'addTaxId'

const dataModule = createDataModule<StripeData<Stripe.TaxId>>(
  addTaxIdDataKey,
  billing.paths.taxId,
  billing.base
)

export const {
  CREATE_DONE: ADD_TAX_ID_DONE,
  create: createTaxId,
  selectIsLoading: selectIsAddTaxIdLoading,
  selectIsDone: selectIsAddTaxIdDone,
  selectErrors: selectAddTaxIdErrors,
  resetErrors: resetAddTaxIdErrors,
  reducer: addTaxIdReducer,
} = dataModule

const ADD_TAX_ID = addTaxIdDataKey

function* addTaxIdSaga(action: ReturnType<typeof createTaxId>) {
  yield put(createTaxId(action.payload))
}

function* taxIdDoneSaga() {
  yield put(closeVisibleModal())
  yield put(fetchTaxId())
}

function* subscribeToCreateTaxIdDoneSaga() {
  yield takeEvery(ADD_TAX_ID_DONE, taxIdDoneSaga)
}

function* subscribeToAddTaxIdSaga() {
  yield takeEvery(ADD_TAX_ID, addTaxIdSaga)
}

export function* createTaxIdRootSaga() {
  yield all([
    spawn(dataModule.rootSaga),
    spawn(subscribeToAddTaxIdSaga),
    spawn(subscribeToCreateTaxIdDoneSaga),
  ])
}
