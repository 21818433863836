import React, { KeyboardEvent, useEffect, useState } from 'react'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import { FormikProps, withFormik } from 'formik'
import { api } from 'api'

import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { ApiUpdateError, RootState } from 'types/types'
import {
  selectIsPasswordChanged,
  selectProfileData,
  selectProfileErrors,
  selectProfileIsLoading,
  updateProfileData,
} from 'modules/profile'
import { ModalForm } from 'components/ModalForm'
import Yup from 'validators/yupLocaleConfig'
import { InputGroup } from 'components/form'
import styled from 'theme/styled'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { Input, ValidationError } from 'components/Input'
import ChromeAutofillBuster from 'components/BrowserAutofillBuster'

interface MappedProps {
  apiErrors: ApiUpdateError
}

interface Props extends ModalProps, MappedProps {
  isLoading?: boolean
  username?: string
}

interface FormValues {
  password?: string
  username?: string
}

interface DispatchProps {
  updateProfileData: typeof updateProfileData
}

const ChangeUsernameModal: React.FC<
  Props & FormikProps<FormValues> & WrappedComponentProps
> = ({
  apiErrors,
  isOpen,
  isLoading,
  onClose,
  intl,
  values,
  handleSubmit,
  handleBlur,
  handleChange,
  touched,
  errors,
  username,
}) => {
  const [usernameChecking, setUsernameChecking] = useState(false)
  const [usernameError, setUsernameError] = useState<string | null>(null)
  const hasErrors: boolean =
    !!usernameError || !!errors.password || !!errors.username
  const isSubmitButtonDisabled: boolean =
    isLoading ||
    usernameChecking ||
    hasErrors ||
    !values.password ||
    !values.username

  const checkUsername = useDebouncedCallback(async () => {
    if (!values.username) return
    if (values.username === username) {
      return setUsernameError(
        intl.formatMessage({
          id: 'SETTINGS_PROFILE_CHANGE_USERNAME_DUPLICATE',
        })
      )
    }
    setUsernameChecking(true)
    try {
      await api.checkUsername(values.username)
      /* on successfull responses the username was found on our db,
      therefore it is not available and we display a error message */
      setUsernameError(
        intl.formatMessage({
          id: 'COMMON_AUTH_REGISTER_USERNAME_TAKEN',
        })
      )
    } catch (e) {
      // on error responses the username is available
      setUsernameError(null)
    }
    setUsernameChecking(false)
  }, 200)

  useEffect(() => {
    checkUsername()
  }, [checkUsername, values?.username])

  const handleOnSubmit = (e: KeyboardEvent<HTMLFormElement>) => {
    if (isSubmitButtonDisabled) {
      return e.preventDefault()
    } else {
      handleSubmit()
    }
  }

  return (
    <GenericModal
      isOpen={isOpen}
      title={<FormattedMessage id="COMMON_LABEL_CHANGE_USERNAME" />}
      size="small"
      onRequestClose={() => onClose?.()}
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CANCEL" />,
          disabled: isLoading || usernameChecking,
          onClick: onClose,
          variant: 'tertiary',
        },
        {
          text: <FormattedMessage id="COMMON_ACTION_CHANGE" />,
          variant: 'primary',
          disabled: isSubmitButtonDisabled,
          type: 'submit',
          onClick: handleSubmit as any,
          loader: isLoading ? 'spinner' : undefined,
        },
      ]}
    >
      <ModalForm onSubmit={handleOnSubmit}>
        <InputGroupStyled full>
          <Input
            type="password"
            name="password"
            variant="secondary"
            label={intl.formatMessage({
              id: 'COMMON_LABEL_PASSWORD',
            })}
            value={values.password}
            disabled={isLoading}
            invalid={errors.password || apiErrors.password}
            onChange={handleChange}
            onBlur={handleBlur}
            passwordToggle
            focus
            full
          />
        </InputGroupStyled>
        <InputGroupStyled full topSpace>
          <ChromeAutofillBuster />
          <Input
            name="username"
            variant="secondary"
            label={intl.formatMessage({
              id: 'COMMON_LABEL_NEW_USERNAME',
            })}
            value={values.username}
            invalid={
              (touched.username && errors.username) ||
              (!usernameChecking && usernameError) ||
              apiErrors.username ||
              undefined
            }
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isLoading}
            full
          />
        </InputGroupStyled>
      </ModalForm>
      {apiErrors?.detail && (
        <ValidationError>{apiErrors.detail}</ValidationError>
      )}
    </GenericModal>
  )
}

const enchance = withFormik<Props & DispatchProps, FormValues>({
  mapPropsToValues: () => ({
    username: '',
  }),
  handleSubmit: (values, { props: { updateProfileData } }) =>
    updateProfileData?.({
      username: values.username,
      old_password: values.password,
    }),
  validationSchema: Yup.object().shape({
    password: Yup.string().required().label('COMMON_LABEL_PASSWORD'),
    username: Yup.string()
      .required()
      .label('COMMON_LABEL_USERNAME')
      .min(1)
      .max(30)
      .username(),
  }),
  enableReinitialize: true,
})

const ChangeUsernameModalEnchanced = enchance(injectIntl(ChangeUsernameModal))

export const CHANGE_USERNAME_MODAL_ID = 'CHANGE_USERNAME_MODAL_ID'

const mapStateToProps = (state: RootState) => ({
  ...selectProfileData(state),
  apiErrors: selectProfileErrors(state),
  isLoading: selectProfileIsLoading(state),
  isPasswordChanged: selectIsPasswordChanged(state),
})

const mapDispatchToProps = {
  updateProfileData,
}

export const WrappedChangeUsernameModal: any = connect(
  mapStateToProps,
  mapDispatchToProps
)(({ apiErrors, isLoading, username, updateProfileData }) => (
  <ModalWrapper modalId={CHANGE_USERNAME_MODAL_ID}>
    <ChangeUsernameModalEnchanced
      apiErrors={apiErrors}
      isLoading={isLoading}
      updateProfileData={updateProfileData}
      username={username}
    />
  </ModalWrapper>
))

const InputGroupStyled = styled(InputGroup)<{ topSpace?: boolean }>`
  ${(p) => (p.topSpace ? 'margin-top: 40px' : 'margin-bottom: 40px')};
  span {
    top: 4px;
  }
`
