import { all, spawn } from 'redux-saga/effects'
import createDataModule from 'utils/moduleCreator'

const {
  api: { talk },
} = __CONFIG__

export const dataKey = 'deviceTalkInstalls'

interface Install {
  device_name: string
  device_serial: string
  device_type: string
  id: number
  phone_number_count: number
  phone_number_list: { phone_number: number }[]
  phone_numbers: number
  subscription: string
  time: string
}

let customHeaders: { 'x-unifi-sso-env': string } | undefined = undefined

if (talk.base.includes('.stg.')) {
  customHeaders = {
    'x-unifi-sso-env': 'stg',
  }
} else if (talk.base.includes('.dev.')) {
  customHeaders = {
    'x-unifi-sso-env': 'dev',
  }
}

const dataModule = createDataModule<Install[]>(
  dataKey,
  talk.paths.installs,
  talk.base,
  undefined,
  undefined,
  undefined,
  undefined,
  customHeaders
)

export const {
  fetch: fetchTalkInstalls,

  selectData: selectTalkInstallsData,
  selectIsLoading: selectTalkInstallsIsLoading,
  selectIsDone: selectTalkInstallsIsDone,
  selectErrors: selectTalkInstallsErrors,
  resetErrors: resetTalkInstallsErrors,

  reducer: deviceTalkInstallsReducer,
} = dataModule

export function* deviceTalkInstallsRootSaga() {
  yield all([spawn(dataModule.rootSaga)])
}
