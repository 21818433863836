import { all, put, select, spawn, take } from 'redux-saga/effects'

import createDataModule from 'utils/moduleCreator'
import { ReduxAction, StripeData } from 'types/types'
import { ProductName } from 'types/enums'

import {
  SUBSCRIPTION_FETCH_ALL_DONE,
  selectActiveSubscriptions,
} from './subscriptions'
import { selectProducts } from './products'

const {
  api: { talk },
} = __CONFIG__

export const dataKey = 'deviceTalk'

export interface DeviceTalkState {
  subscription_count?: number
  metered_usage?: number
  subscription_usage?: number
  calling_status?: string
  trial_status?: string
  trial_start_time?: string
  payment_status?: null | 'succeeded' | 'failed'
  region: string | null
}

const dataModule = createDataModule<DeviceTalkState>(
  dataKey,
  talk.paths.subscriptions,
  talk.base
)

export const { fetch: fetchTalk, reducer: deviceTalkReducer } = dataModule

export function* fetchTalkSaga() {
  let subscriptions: ReturnType<typeof selectActiveSubscriptions> =
    yield select(selectActiveSubscriptions)
  if (!subscriptions.length) {
    const {
      payload,
    }: ReduxAction<
      StripeData<ReturnType<typeof selectActiveSubscriptions>[0]>
    > = yield take(SUBSCRIPTION_FETCH_ALL_DONE)
    subscriptions = payload.stripeData ? payload.stripeData.data : []
  }

  const products: ReturnType<typeof selectProducts> = yield select(
    selectProducts
  )

  const talkProduct = products.find(
    ({ name }) => name === ProductName.UNIFI_TALK
  )
  const talkProductId = talkProduct && talkProduct.id
  const talkSubscription = subscriptions.find((subscription) => {
    const product = subscription.plan && subscription.plan.product
    if (typeof product === 'string') return product === talkProductId
    return product && product.id === talkProductId
  })
  if (talkSubscription) yield put(fetchTalk())
}

export function* deviceTalkRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(fetchTalkSaga)])
}
