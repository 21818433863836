import React from 'react'

import { StyledDropdownArrow } from 'features/shared/styles'

import { DeviceName } from './DeviceName'
import { DeviceBackupsArchived, DeviceBackupsWithShadow } from './types'
import {
  Amount,
  BackupsInfo,
  DeviceBackupsContainer,
  DeviceBackupsWrapper,
} from './Backups.styles'

interface Props {
  deviceBackups: DeviceBackupsWithShadow | DeviceBackupsArchived
  handleToggle: () => void
  itemExpanded: boolean
  totalAmount: number
}

export const DeviceBackupsItem: React.FC<Props> = ({
  deviceBackups,
  handleToggle,
  itemExpanded,
  totalAmount,
}) => {
  return (
    <DeviceBackupsContainer onClick={handleToggle}>
      <DeviceBackupsWrapper $isExpanded={itemExpanded}>
        <DeviceName deviceBackups={deviceBackups} />
        <BackupsInfo>
          <Amount hasBackups={totalAmount > 0}>
            {totalAmount} {totalAmount === 1 ? 'Backup' : 'Backups'}
          </Amount>
          <StyledDropdownArrow $isDropdownOpen={itemExpanded} />
        </BackupsInfo>
      </DeviceBackupsWrapper>
    </DeviceBackupsContainer>
  )
}
