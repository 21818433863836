import { all, put, spawn, takeEvery } from 'redux-saga/effects'

import { closeVisibleModal } from 'modules/modals'
import createDataModule from 'utils/moduleCreator'

import { fetchSubscriptions } from './subscriptions'

const {
  api: { talk },
} = __CONFIG__

export const dataKey = 'deviceTalkCancel'

let customHeaders: { 'x-unifi-sso-env': string } | undefined = undefined

if (talk.base.includes('.stg.')) {
  customHeaders = {
    'x-unifi-sso-env': 'stg',
  }
} else if (talk.base.includes('.dev.')) {
  customHeaders = {
    'x-unifi-sso-env': 'dev',
  }
}

const dataModule = createDataModule<any>(
  dataKey,
  talk.paths.cancel,
  talk.base,
  undefined,
  undefined,
  undefined,
  undefined,
  customHeaders
)

export const {
  CREATE: removeTalk,
  selectIsLoading: selectCancelTalkLoading,
  selectErrors: selectCancelTalkErrors,
  resetErrors: resetTalkCancelErrors,
  reducer: deviceTalkCancelReducer,
} = dataModule

export const cancelTalk = (payload: { install: any }) => {
  return {
    type: removeTalk,
    payload,
  }
}

export function* cancelTalkDoneSaga() {
  yield put(closeVisibleModal())
  yield put(fetchSubscriptions())
}

export function* subscribeToCancelTalkDoneSaga() {
  yield takeEvery(dataModule.CREATE_DONE, cancelTalkDoneSaga)
}

export function* deviceTalkCancelRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToCancelTalkDoneSaga)])
}
