import { boolean, object, string } from 'yup'
import {
  DESCRIPTION_MAX_LENGTH,
  DESCRIPTION_MIN_LENGTH,
} from 'features/support/constants'

import { Assistance, Trouble, UniFiStateValues } from './types'

export const UniFiFormValidationSchema = object({
  assistance: string().required().label(UniFiStateValues.ASSISTANCE),
  trouble: string().when(UniFiStateValues.ASSISTANCE, {
    is: (assistance: Assistance) =>
      [
        Assistance.setupAdopt,
        Assistance.hwFailure,
        Assistance.adminUpdates,
      ].includes(assistance),
    then: (schema) => schema.required().label(UniFiStateValues.TROUBLE),
  }),
  adoptionTrouble: string().when(UniFiStateValues.TROUBLE, {
    is: Trouble.deviceAdoption,
    then: (schema) => schema.required().label(UniFiStateValues.TROUBLE),
  }),
  console: object().label(UniFiStateValues.CONSOLE),
  device: string().label(UniFiStateValues.DEVICE),
  model: string().label(UniFiStateValues.MODEL),
  tech: string().label(UniFiStateValues.TECH),
  app: string().label(UniFiStateValues.APP),
  talk: boolean().label('Talk'),
  userType: string().label('User Type'),
  description: string()
    .max(DESCRIPTION_MAX_LENGTH)
    .min(DESCRIPTION_MIN_LENGTH)
    .required()
    .label(UniFiStateValues.DESCRIPTION),
})
