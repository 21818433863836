import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { RouteChildrenProps } from 'react-router'
import { Link, Redirect } from 'react-router-dom'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { Dispatch } from 'redux'
import { FormikProps, withFormik } from 'formik'
import { api } from 'api'
import { AxiosError } from 'axios'
import { CheckmarkIcon } from '@ubnt/icons'
import { Button } from '@ubnt/ui-components'
import { useDebouncedCallback } from 'use-debounce'

import Yup from 'validators/yupLocaleConfig'
import {
  resetPassword,
  selectErrors,
  selectIsLoading,
  selectIsPasswordChanged,
  verifyResetPassword,
} from 'features/auth/modules/resetPassword'
import styled from 'theme/styled'
import authorizedRedirect from 'components/authorizedRedirect'
import PublicPage from 'pages/PublicPage'
import { ApiUpdateError, RootState } from 'types/types'
import { withDidMount } from 'utils/withDidMount'
import { PASSWORD_MIN_LENGTH } from 'utils/passwordContants'
import ChangePasswordNotification from 'components/notifications/ChangePasswordNotification'
import { size, space, typography } from 'theme'
import { Input } from 'components/Input'

import PasswordFeedback, {
  PasswordFeedbackData,
  passwordScoreBarWidth,
  passwordScoreColor,
  passwordScoreText,
} from './PasswordFeedback'
import { AuthForm, ButtonContainer } from './styles'

interface Props extends RouteChildrenProps {
  isLoading?: boolean
  isPasswordChanged: boolean
  apiErrors: ApiUpdateError
  handleSubmit(password: string): void
}

interface FormValues {
  password?: string
  confirmNewPassword?: string
}

export const ResetUrlExpired = () => (
  <PublicPage>
    <InfoContainer>
      <div>
        <ErrorTitle>
          <FormattedMessage id="COMMON_AUTH_PASSWORD_CHANGE_FAILED" />
        </ErrorTitle>
        <ErrorDescription>
          <FormattedMessage id="COMMON_AUTH_PASSWORD_CHANGE_FAILED_DESCRIPTION" />
        </ErrorDescription>
      </div>
      <Link to="/reset-password">
        <StyledButton variant="primary" full>
          <FormattedMessage id="COMMON_AUTH_ACTION_RESET_PASSWORD" />
        </StyledButton>
      </Link>
    </InfoContainer>
  </PublicPage>
)

export const ChangePasswordForm: React.FC<
  Props & FormikProps<FormValues> & WrappedComponentProps
> = ({
  touched,
  values,
  handleChange,
  handleSubmit,
  errors,
  isLoading,
  isPasswordChanged,
  apiErrors,
  intl,
  handleBlur,
}) => {
  const [passwordData, setPasswordData] = useState<PasswordFeedbackData | null>(
    null
  )
  const [passwordFocused, setPasswordFocused] = useState(false)
  const [passwordCheckLoading, setPasswordCheckLoading] =
    useState<boolean>(false)

  const passwordLongEnough = useMemo(() => {
    if (values.password) {
      if (values.password.length >= PASSWORD_MIN_LENGTH) return true
      return false
    }
  }, [values.password])

  const checkPassword = useDebouncedCallback(async () => {
    const validator = Yup.string()
    if (!values.password || !validator.isValidSync(values.password)) return
    try {
      const { is_acceptable_password, suggestions, warning, score } =
        await api.checkPassword(values.password!)
      setPasswordCheckLoading(false)

      warning && suggestions.unshift(warning)

      score !== undefined &&
        setPasswordData({
          is_acceptable_password: is_acceptable_password ?? null,
          suggestions: suggestions ?? null,
          score: score,
          barWidth: passwordScoreBarWidth(score),
          feedbackColor: passwordScoreColor(score),
          text: (
            <FormattedMessage
              id={passwordScoreText(score)}
              values={{
                b: (text: string) => <b className="intl-message">{text}</b>,
              }}
            />
          ),
          error: null,
        })
    } catch (e) {
      setPasswordCheckLoading(false)
      setPasswordData({
        is_acceptable_password: null,
        suggestions: null,
        score: null,
        barWidth: null,
        feedbackColor: null,
        text: null,
        error: (e as AxiosError<any>).response?.data?.detail || '',
      })
    }
  }, 200)

  useEffect(() => {
    if (passwordFocused) {
      checkPassword()
    }
  }, [checkPassword, passwordFocused, values.password])

  useEffect(() => {
    if (values?.password && values?.password.length >= PASSWORD_MIN_LENGTH) {
      setPasswordCheckLoading(true)
    }
  }, [checkPassword, values?.password])

  if (apiErrors?.detail) return <ResetUrlExpired />
  if (isPasswordChanged) return <Redirect to="/login?passwordReset=true" />

  return (
    <PublicPage>
      <ChangePasswordNotification
        margin={`-${space['desktop-spacing-base-03']} 0 ${space['desktop-spacing-base-02']}`}
      />
      <AuthForm onSubmit={handleSubmit}>
        <StyledInput
          variant="secondary"
          type="password"
          name="password"
          label={intl.formatMessage({
            id: 'COMMON_LABEL_NEW_PASSWORD',
          })}
          value={values.password}
          disabled={isLoading}
          invalid={(touched.password && errors.password) || apiErrors?.password}
          helperMessage={
            !passwordLongEnough ? (
              intl.formatMessage(
                {
                  id: 'COMMON_AUTH_REGISTER_PASSWORD_LABEL_HELPER',
                },
                { passwordLength: PASSWORD_MIN_LENGTH }
              )
            ) : (
              <PasswordFeedback
                passwordLongEnough={passwordLongEnough ?? false}
                passwordCheckLoading={passwordCheckLoading}
                passwordData={passwordData}
                apiErrors={apiErrors}
              />
            )
          }
          onFocus={() => setPasswordFocused(true)}
          onBlur={handleBlur}
          onChange={handleChange}
          passwordToggle
          full
        />
        <StyledInput
          type="password"
          variant="secondary"
          name="confirmNewPassword"
          label={intl.formatMessage({
            id: 'SETTINGS_SECURITY_CONFIRM_NEW_PASSWORD_LABEL',
          })}
          value={values.confirmNewPassword}
          disabled={isLoading}
          invalid={
            (touched.confirmNewPassword && errors.confirmNewPassword) ?? ''
          }
          onChange={handleChange}
          contentAfter={
            values?.confirmNewPassword &&
            values.confirmNewPassword?.length >= PASSWORD_MIN_LENGTH &&
            !errors.confirmNewPassword && <StyledCheckIcon variant="twoTone" />
          }
          onBlur={handleBlur}
          passwordToggle
          full
        />
        <ButtonContainer>
          <Button
            type="submit"
            variant="primary"
            onClick={handleSubmit as () => void}
            disabled={
              isLoading ||
              passwordCheckLoading ||
              !passwordData?.score ||
              passwordData.score < 3 ||
              !!errors?.confirmNewPassword ||
              !passwordLongEnough
            }
            loader={isLoading || passwordCheckLoading ? 'dots' : undefined}
            full
          >
            {intl.formatMessage({ id: 'COMMON_ACTION_CONFIRM' })}
          </Button>
        </ButtonContainer>
      </AuthForm>
    </PublicPage>
  )
}

const enchance = withFormik<Props, FormValues>({
  handleSubmit: (values, { props }) => props.handleSubmit(values.password!),
  mapPropsToValues: () => ({ password: '', confirmNewPassword: '' }),
  validationSchema: Yup.object().shape({
    password: Yup.string()
      .required()
      .min(PASSWORD_MIN_LENGTH)
      .label('COMMON_LABEL_PASSWORD'),
    confirmNewPassword: Yup.string()
      .required()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .label('SETTINGS_SECURITY_CONFIRM_NEW_PASSWORD_LABEL'),
  }),
})

const mapStateToProps = (state: RootState) => ({
  isLoading: selectIsLoading(state),
  isPasswordChanged: selectIsPasswordChanged(state),
  apiErrors: selectErrors(state),
})

const mapDispatchToProps = (dispatch: Dispatch, { match }: Props) => {
  const uuid = match && (match.params as any).uuid
  return {
    handleSubmit: (newPassword: string) =>
      dispatch(resetPassword(uuid, newPassword)),
    onDidMount: () => uuid && dispatch(verifyResetPassword(uuid)),
  }
}

export default authorizedRedirect(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withDidMount(enchance(injectIntl(ChangePasswordForm))))
)

const InfoContainer = styled.div`
  text-align: center;
  height: ${size['desktop-sizing-base-81']};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledInput = styled(Input)`
  height: ${size['desktop-sizing-base-15']};
`

const StyledCheckIcon = styled(CheckmarkIcon)`
  position: absolute;
  transform: translate(-10%, -10%);
  color: ${({ theme }) => theme.green06};
`

const ErrorTitle = styled.div`
  font: ${typography['desktop-heading-xlarge-1']};
  color: ${({ theme }) => theme.text2};
`

const ErrorDescription = styled.div`
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.text2};
  padding-top: ${space['desktop-spacing-base-06']};
  text-align: left;
`

const StyledButton = styled(Button)`
  height: ${size['desktop-sizing-base-10']};
`
