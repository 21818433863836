import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  ArrowDownPrimaryIcon,
  ArrowUpPrimaryIcon,
  ThreatManagementIcon,
} from '@ubnt/icons'
import { Button, ToastContext } from '@ubnt/ui-components'
import { typography } from '@ubnt/ui-components/styles/designToken'

import styled from 'theme/styled'
import { LockTooltip } from 'pages/security/LockTooltip'
import {
  MFAAuthenticator,
  MFAType,
  selectMFAAuthenticators,
  selectPendingMFAAuthenticators,
} from 'modules/mfa'
import { selectProfileDefaultMFA } from 'modules/profile'
import { selectAssuranceLevel } from 'features/auth/modules/assuranceLevel'
import { MFAAuthenticatorStatus } from 'enums'
import HideOptions from 'components/hideOptions/HideOptions'
import StyledEntityToast from 'components/EntityToast'

import {
  resetRemoveTempMFAEmailData,
  selectRemoveTempMFAEmailIsDone,
} from '../Email/modules/removeTempEmail'
import { MultiFactorMethodSection } from './MultiFactorMethodSection'

interface Props {
  activeMFAAuthenticators: MFAAuthenticator[]
}

export const MultiFactorMethods: React.FC<Props> = ({
  activeMFAAuthenticators,
}) => {
  const toast = useContext(ToastContext)
  const intl = useIntl()
  const dispatch = useDispatch()
  const authenticators = useSelector(selectMFAAuthenticators)
  const defaultMFAId = useSelector(selectProfileDefaultMFA)
  const removeTempEmailDone = useSelector(selectRemoveTempMFAEmailIsDone)
  const resetTempEmailDone = useSelector(resetRemoveTempMFAEmailData)
  const assuranceLevel = useSelector(selectAssuranceLevel)
  const pendingMethods = useSelector(selectPendingMFAAuthenticators)
  const [isOtherMethodsOpen, setIsOtherMethodsOpen] = useState(false)

  const primaryIsTOTP = useMemo(() => {
    const primary = authenticators.find((auth) => auth.id === defaultMFAId)
    return primary?.type === MFAType.totp
  }, [authenticators, defaultMFAId])

  const showButton = useMemo(
    () => activeMFAAuthenticators.some(({ id }) => id !== defaultMFAId),
    [activeMFAAuthenticators, defaultMFAId]
  )

  const renderOtherMethodsButton = () => {
    return isOtherMethodsOpen ? (
      <>
        <ArrowUpPrimaryIcon size="medium" />
        <OtherMethodsText>
          <FormattedMessage id="COMMON_SHOW_LESS" />
        </OtherMethodsText>
      </>
    ) : (
      <>
        <ArrowDownPrimaryIcon size="medium" />
        <OtherMethodsText>
          <FormattedMessage id="COMMON_SHOW_ALL" />
        </OtherMethodsText>
      </>
    )
  }

  useEffect(() => {
    if (assuranceLevel?.data.level === 2 && removeTempEmailDone) {
      toast.createNotification(
        <StyledEntityToast
          id="tempMFAEmailRemoved"
          centerText={true}
          icon={<ThreatManagementIcon />}
          stateIndicator="warning"
          onClose={() => {
            toast.removeNotification('tempMFAEmailRemoved')
          }}
          title={intl.formatMessage({
            id: 'SETTINGS_MFA_TEMP_REMOVED',
          })}
          duration={5000}
        />
      )
      dispatch(resetTempEmailDone)
    }
  }, [
    assuranceLevel,
    dispatch,
    intl,
    removeTempEmailDone,
    resetTempEmailDone,
    toast,
  ])

  const primaryMethod = useMemo(() => {
    const authenticator = authenticators.find(
      (authenticator) => authenticator.id === defaultMFAId
    )
    return authenticator ? [authenticator] : []
  }, [authenticators, defaultMFAId])

  const backupMethods = useMemo(
    () =>
      authenticators
        .filter(
          (method) =>
            // Filter out: Primary MFA, Pending, Recovery Code
            method.id !== defaultMFAId &&
            method.status !== MFAAuthenticatorStatus.PENDING &&
            method.status !== MFAAuthenticatorStatus.DEACTIVATED &&
            method.type !== MFAType.backupCodes
        )
        .sort(
          (a, b) =>
            new Date(a.created).getTime() - new Date(b.created).getTime()
        ),
    [authenticators, defaultMFAId]
  )

  const deactivatedMethods = useMemo(
    () =>
      authenticators
        .filter(
          (method) =>
            method.id !== defaultMFAId &&
            method.status === MFAAuthenticatorStatus.DEACTIVATED
        )
        .sort(
          (a, b) =>
            new Date(a.created).getTime() - new Date(b.created).getTime()
        ),
    [authenticators, defaultMFAId]
  )

  if (authenticators.length === 0) {
    return <></>
  }

  //need at least 2 active MFA methods
  const isDeleteDisabled =
    authenticators.filter((auth: MFAAuthenticator) => auth.status === 'active')
      .length < 2

  return (
    <Wrapper>
      <PrimaryWrapper
        showBottomBorder={
          isOtherMethodsOpen ||
          !!pendingMethods.length ||
          !!deactivatedMethods.length
        }
      >
        <MultiFactorMethodSection
          data={primaryMethod}
          isPrimary
          isDeleteDisabled
        />
      </PrimaryWrapper>
      <MultiFactorMethodSection data={pendingMethods} />
      <MultiFactorMethodSection data={deactivatedMethods} />
      <HideOptions maxHeight={1500} isShown={isOtherMethodsOpen}>
        <MultiFactorMethodSection
          data={backupMethods}
          isDeleteDisabled={isDeleteDisabled}
          isPrimaryTOTP={primaryIsTOTP}
        />
      </HideOptions>

      {showButton ? (
        <ButtonWrapper>
          <LockTooltip inline>
            <ExtendButton
              variant="inline"
              size="medium"
              className="viewMoreButton"
              onClick={() => setIsOtherMethodsOpen(!isOtherMethodsOpen)}
            >
              {renderOtherMethodsButton()}
            </ExtendButton>
          </LockTooltip>
        </ButtonWrapper>
      ) : null}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: ${({ theme }) => `1px solid ${theme.neutral03}`};
  padding: 0 16px;
  border-radius: 10px;
`
const PrimaryWrapper = styled.div<{ showBottomBorder: boolean }>`
  > * {
    &:last-child {
      border-bottom: ${({ theme, showBottomBorder }) =>
        showBottomBorder ? `1px solid ${theme.neutral03}` : `none`};
    }
  }
`

const ButtonWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${(p) => p.theme.media.mobileLarge}) {
    padding: 0;
  }
`

const ExtendButton = styled(Button)`
  text-decoration: none !important;
  &:active,
  &:focus {
    box-shadow: none !important;
  }
`
const OtherMethodsText = styled.span`
  margin-left: 3px;
  font: ${typography['desktop-typography-body']};
  color: ${({ theme }) => theme.ublue06};
`
