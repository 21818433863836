import React, { useContext, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { ToastContext } from '@ubnt/ui-components'
import { PaymentIcon } from '@ubnt/icons'

import {
  FullSubscription,
  selectFullSubscriptionsToDisplay,
} from 'features/subscriptions/fullSubscriptions/fullSubscriptions'
import { selectIsSubscriptionsFetching } from 'features/subscriptions/module/subscriptions'
import { selectIsInvoicesLoading } from 'features/subscriptions/module/invoices'
import { BrowserUtils } from 'utils/browserUtils'

import StyledEntityToast from '../components/EntityToast'

export const Toasts = () => {
  const toast = useContext(ToastContext)
  const intl = useIntl()
  const history = useHistory()

  const isLoading = [
    useSelector(selectIsSubscriptionsFetching),
    useSelector(selectIsInvoicesLoading),
  ].some(Boolean)

  const fullSubscriptions = useSelector(selectFullSubscriptionsToDisplay)

  const addPaymentViewQuery = BrowserUtils.getQueryValue(
    window.location.toString(),
    'addpaymentview'
  )

  const isFailedSubscriptions = useMemo(() => {
    if (isLoading || !fullSubscriptions.length) return
    return !!fullSubscriptions.find(
      (fullSubcription: FullSubscription) => fullSubcription.isFailed
    )
  }, [fullSubscriptions, isLoading])

  const isCardNotFoundSubscriptions = useMemo(() => {
    if (isLoading || !fullSubscriptions.length) return
    return !!fullSubscriptions.find(
      (fullSubscription: FullSubscription) => fullSubscription.cardNotFound
    )
  }, [fullSubscriptions, isLoading])

  useEffect(() => {
    const ToastShownJSON = localStorage?.getItem?.('storedToastShown')
    const ToastShown = ToastShownJSON && JSON.parse(ToastShownJSON)
    if (
      isFailedSubscriptions &&
      !ToastShown?.ToastShownFailed &&
      history?.location?.pathname !== '/subscriptions' &&
      !addPaymentViewQuery
    ) {
      toast.createNotification(
        <StyledEntityToast
          id="failedSubscriptionWarning"
          icon={<PaymentIcon />}
          stateIndicator="warning"
          title={intl.formatMessage({ id: 'SETTINGS_SUBSCRIPTIONS_FAILED' })}
          duration={5000}
          details={intl.formatMessage({
            id: 'SETTINGS_SUBSCRIPTIONS_FAILED_DETAILS',
          })}
          primaryButton={{
            label: intl.formatMessage({
              id: 'SETTINGS_SUBSCRIPTIONS_FAILED_UPDATE_PAYMENT_METHOD',
            }),
            onClick: () => {
              toast.removeNotification('failedSubscriptionWarning')
              history.push('/subscriptions')
            },
          }}
          secondaryButton={{
            label: intl.formatMessage({ id: 'COMMON_ACTION_DISMISS' }),
            onClick: () =>
              toast.removeNotification('failedSubscriptionWarning'),
          }}
        />
      )
      localStorage?.setItem(
        'storedToastShown',
        JSON.stringify({ ...ToastShown, ToastShownFailed: true })
      )
    }
  }, [history, intl, isFailedSubscriptions, toast, addPaymentViewQuery])

  useEffect(() => {
    const ToastShownJSON = localStorage?.getItem?.('storedToastShown')
    const ToastShown = ToastShownJSON && JSON.parse(ToastShownJSON)
    if (
      isCardNotFoundSubscriptions &&
      !ToastShown?.ToastShownCardNotFound &&
      history?.location?.pathname !== '/subscriptions' &&
      !addPaymentViewQuery
    ) {
      toast.createNotification(
        <StyledEntityToast
          id="invalidCardSubscriptionWarning"
          icon={<PaymentIcon />}
          stateIndicator="warning"
          title={intl.formatMessage({
            id: 'SETTINGS_SUBSCRIPTIONS_CARD_NOT_FOUND',
          })}
          duration={5000}
          details={intl.formatMessage({
            id: 'SETTINGS_SUBSCRIPTIONS_CARD_NOT_FOUND_DETAILS',
          })}
          primaryButton={{
            label: intl.formatMessage({
              id: 'SETTINGS_SUBSCRIPTIONS_CARD_NOT_FOUND_UPDATE_PAYMENT_METHOD',
            }),
            onClick: () => {
              toast.removeNotification('cardNotFoundSubscriptionWarning')
              history.push('/subscriptions')
            },
          }}
          secondaryButton={{
            label: intl.formatMessage({ id: 'COMMON_ACTION_DISMISS' }),
            onClick: () =>
              toast.removeNotification('cardNotFoundSubscriptionWarning'),
          }}
        />
      )
      localStorage?.setItem(
        'storedToastShown',
        JSON.stringify({ ...ToastShown, ToastShownCardNotFound: true })
      )
    }
  }, [history, intl, isCardNotFoundSubscriptions, toast, addPaymentViewQuery])
}
