import { all, put, race, spawn, take, takeEvery } from 'redux-saga/effects'
import { closeVisibleModal } from 'modules/modals'
import createDataModule, { defaultInitialState } from 'utils/moduleCreator'

import { fetchInvoices } from './invoices'
import { fetchSubscriptions } from './subscriptions'

type DeviceSiteSupport = {
  subscriptionId: string
  userId: string
  hardwareId: string
  status: string
  expireDate: string
  createdAt: string
  updatedAt: string
}

const {
  api: { siteSupport },
} = __CONFIG__

export const siteSupportDataKeyCancel = 'deviceSiteSupportDataCancel'

const getUrl = (url: string, item: { id?: string }) =>
  item && item.id ? `${url}/${item.id}` : url

const initialState = {
  ...defaultInitialState,
}

const dataModule = createDataModule<DeviceSiteSupport>(
  siteSupportDataKeyCancel,
  siteSupport.paths.subscriptions,
  siteSupport.base,
  initialState,
  getUrl
)

export const {
  reducer: deviceSiteSupportInfoCancelReducer,
  selectErrors: selectSiteSupportErrors,
  selectIsLoading: selectIsSiteSupportLoading,
  resetErrors: resetSiteSupportErrors,
} = dataModule

export const removeSiteSupport = (payload: { id?: string }) => {
  return {
    type: dataModule.REMOVE,
    payload,
  }
}

export function* removeSubscriptionSaga() {
  const { error } = yield race({
    success: take(dataModule.REMOVE_DONE),
    error: take(dataModule.REMOVE_FAILED),
  })

  if (error) {
    return
  }
  yield put(fetchSubscriptions())
  yield put(fetchInvoices())
  yield put(closeVisibleModal())
}

export function* subscribeToRemoveSubscriptionSaga() {
  yield takeEvery(dataModule.REMOVE, removeSubscriptionSaga)
}

export function* deviceSiteSupportCancelRootSaga() {
  yield all([
    spawn(dataModule.rootSaga),
    spawn(subscribeToRemoveSubscriptionSaga),
  ])
}
