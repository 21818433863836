import styled from 'theme/styled'

const Wrapper = styled.div<{ $isAppView?: boolean }>`
  display: flex;
  height: 100%;
  padding-top: ${({ $isAppView }) => ($isAppView ? 0 : '50px')};
`

const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 296px;
  width: 296px;
  margin-left: 16px;

  @media (max-width: ${({ theme }) => theme.media.large}) {
    margin-left: 0;
    min-width: 256px;
    width: 256px;
  }

  @media (max-width: ${({ theme }) => theme.media.mediumV2}) {
    display: none;
  }
`

const Sidebar = styled.div<{ $isAppView?: boolean }>`
  border-right: 1px solid ${({ theme }) => theme.neutral03};
  height: calc(100vh - 50px);
  left: 0;
  padding: 16px;
  margin-left: 16px;
  position: fixed;
  top: ${({ $isAppView }) => ($isAppView ? 0 : '50px')};
  width: calc(280px + 16px);
  z-index: 2;

  @media (max-width: ${({ theme }) => theme.media.large}) {
    margin-left: 0;
    width: 256px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  padding: 16px 16px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.media.large}) {
    padding: 16px;
  }

  @media (max-width: ${({ theme }) => theme.media.mobileMedium}) {
    padding: 12px;
  }
`

const Content = styled.div`
  max-width: 800px;
  position: relative;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.media.mediumV2}) {
    max-width: 100%;
  }
`

export { Wrapper, SidebarWrapper, Sidebar, ContentWrapper, Content }
