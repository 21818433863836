import React from 'react'
import { useSelector } from 'react-redux'

import styled from 'theme/styled'
import { selectProfilePicture } from 'features/profile-picture/module/profilePicture'

interface Props {
  dimensions?: number
  onClick?(): void
}

export const UserImage: React.FC<Props> = ({ dimensions = 38 }) => {
  const avatar = useSelector(selectProfilePicture)

  return <StyledImage dimensions={dimensions} src={avatar} alt="Avatar" />
}

export default UserImage

const StyledImage = styled.img<{ dimensions?: number }>`
  ${({ dimensions = '28' }) => `
    height: ${dimensions}px;
    width: ${dimensions}px;
  `}
  border-radius: 50%;
  object-fit: cover;
  display: 'block';
`
