import { UcoreDevice } from './types'

export const UNCATEGORIZED = 'Uncategorized'

export const FALLBACK_SHADOW_DATA: UcoreDevice['shadow'] = {
  deviceId: UNCATEGORIZED,
  state: {
    reported: {
      releaseChannel: UNCATEGORIZED,
      auth_token: UNCATEGORIZED,
      controller_uuid: UNCATEGORIZED,
      controllers: [],

      host_type: 0,
      hostname: UNCATEGORIZED,
      ipAddrs: [UNCATEGORIZED],

      mac: UNCATEGORIZED,
      mgmt_port: 0,
      name: UNCATEGORIZED,
      version: UNCATEGORIZED,
      hardware: {
        bom: UNCATEGORIZED,
        firmwareVersion: UNCATEGORIZED,
        hwrev: 0,
        name: UNCATEGORIZED,
        qrid: UNCATEGORIZED,
        reboot: UNCATEGORIZED,
        serialno: UNCATEGORIZED,
        shortname: UNCATEGORIZED,
        subtype: UNCATEGORIZED,
        sysid: 0,
        upgrade: UNCATEGORIZED,
        uuid: UNCATEGORIZED,
        'cpu.id': UNCATEGORIZED,
      },
      deviceState: UNCATEGORIZED,
    },
  },
}
