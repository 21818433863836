import { all, spawn } from 'redux-saga/effects'

import createDataModule from 'utils/moduleCreator'

const {
  api: { megaphone },
} = __CONFIG__

export const editAnnouncementsDataKey = 'editAnnouncements'

const dataModule = createDataModule(
  editAnnouncementsDataKey,
  megaphone.paths.announcements,
  megaphone.base
)

export const { reducer: editAnnouncementsReducer } = dataModule

export const updateAnnouncement = (id: string) => ({
  type: `${editAnnouncementsDataKey}/UPDATE_PARTIAL`,
  urlPath: `/${id}/read`,
})

export function* editAnnouncementsRootSaga() {
  yield all([spawn(dataModule.rootSaga)])
}
