import {
  all,
  put,
  race,
  select,
  spawn,
  take,
  takeEvery,
} from 'redux-saga/effects'
import { createSelector } from 'reselect'

import {
  GlobalCustomerData,
  selectCustomerData,
} from 'features/stripe/modules/stripeCustomer'
import { closeVisibleModal } from 'modules/modals'
import { StripeData } from 'types/types'
import createDataModule from 'utils/moduleCreator'
import { sendWebKitMessage } from 'utils/sendWebKitMessage'
import {
  CUSTOMER_CREATE_DONE,
  CUSTOMER_CREATE_FAILED,
  createCustomer,
} from 'features/stripe/modules/createStripeCustomer'
import { StripeRegionCode } from 'features/stripe/ui/types'
import { BrowserUtils } from 'utils/browserUtils'

import { CardData, CardType } from './types'
import { fetchCards } from './fetchCards'

const {
  api: { billing },
} = __CONFIG__

export const addCardDataKey = 'addCard'

const addPaymentViewQuery = BrowserUtils.getQueryValue(
  window.location.toString(),
  'addpaymentview'
)

const getUrl = (url: string, card: CardData) => `${url}?region=${card.region}`

const dataModule = createDataModule<StripeData<CardType>>(
  addCardDataKey,
  billing.paths.cards,
  billing.base,
  undefined,
  getUrl
)

export const {
  CREATE_DONE: ADD_CARD_DONE,
  create: createCard,
  selectIsLoading: selectIsAddCardLoading,
  resetErrors: resetAddCardErrors,
  reducer: addCardReducer,
} = dataModule

const ADD_CARD = `${addCardDataKey}/ADD_CARD`
export const addCard = (sourceId: string, region: StripeRegionCode) => {
  return {
    type: ADD_CARD,
    payload: { source: sourceId, region: region },
  }
}

export const selectCardsApiError = createSelector(
  dataModule.selectErrors,
  (errors) => (errors.message ? errors.message : null)
)

export const selectStripeCardError = createSelector(
  dataModule.selectErrors,
  (errors) => (errors.metaData?.dependencyMessage ? errors.metaData.dependencyMessage : null)
)

function* addCardSaga(action: ReturnType<typeof addCard>) {
  const customer: GlobalCustomerData = yield select(selectCustomerData)

  if (
    !customer ||
    !customer[action.payload.region as keyof GlobalCustomerData]?.stripeData
  ) {
    yield put(createCustomer(action.payload.region))

    const { error } = yield race({
      success: take(CUSTOMER_CREATE_DONE),
      error: take(CUSTOMER_CREATE_FAILED),
    })

    if (error) {
      console.error('Could not create stripe customer', error)
      return
    }
  }

  yield put(createCard(action.payload))
}

function* cardDoneSaga() {
  if (addPaymentViewQuery) {
    sendWebKitMessage('success')
  } else {
    yield put(fetchCards())
  }
  yield put(closeVisibleModal())
}

function* subscribeToCreateCardDoneSaga() {
  yield takeEvery(ADD_CARD_DONE, cardDoneSaga)
}

function* subscribeToAddCardSaga() {
  yield takeEvery(ADD_CARD, addCardSaga)
}

export function* createCardRootSaga() {
  yield all([
    spawn(dataModule.rootSaga),
    spawn(subscribeToAddCardSaga),
    spawn(subscribeToCreateCardDoneSaga),
  ])
}
