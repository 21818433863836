import { all, put, spawn, takeEvery } from 'redux-saga/effects'

import { api } from 'api'
import { fetchMFA } from 'modules/mfa'
import createDataModule from 'utils/moduleCreator'
import { SSOAPIError } from 'types/types'
import { closeVisibleModal } from 'modules/modals'

const {
  api: { sso },
} = __CONFIG__

export const removeTOTPDataKey = 'MFARemoveTOTP'

const removeTOTPActions = {
  remove: `${removeTOTPDataKey}/REMOVE`,
}

export type RemoveTOTPState = Record<string, never>

const dataModule = createDataModule<RemoveTOTPState, SSOAPIError>(
  removeTOTPDataKey,
  `${sso.paths.mfa}/totp`,
  api.ssoBase
)

export const {
  selectIsLoading,
  REMOVE_DONE,
  selectErrors: selectRemoveTOTPErrors,
  resetErrors: resetRemoveTOTPErrors,
} = dataModule

export { selectIsLoading as selectRemoveTOTPIsLoading }

export const removeMFATOTP = (id: string, token?: string) => ({
  type: removeTOTPActions.remove,
  payload: { oneTimePassword: token },
  urlPath: `/${id}`,
})

function* closeModalSaga() {
  yield put(fetchMFA())
  yield put(closeVisibleModal())
}

function* subscribeToRemovedTOTPDoneSaga() {
  yield takeEvery(REMOVE_DONE, closeModalSaga)
}

export function* removeTOTPRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToRemovedTOTPDoneSaga)])
}

export const removeTOTPReducer = dataModule.reducer
