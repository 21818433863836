import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

import { CANCEL_SUBSCRIPTION_ETU_MODAL_ID } from 'pages/subscriptions/CancelSubscriptionEnhancedThreatUpdatesModal'
import { RoundFlag } from 'components/RoundFlag'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'
import {
  FullSubscription,
  SubscriptionStatus,
} from 'features/subscriptions/fullSubscriptions/fullSubscriptions'
import { DateCell } from 'features/subscriptions/ui/cells/DateCell'
import { getSubscriptionBillingDate } from 'features/subscriptions/ui/utils'

import { useSelector } from 'react-redux'
import { selectOwnedUcoreDevices } from 'features/devices/devices'
import {
  CardCell,
  Cell,
  CellColumns,
  CellContainer,
  ChargeCell,
  Row,
  RowWarningIcon,
} from '../Styles'
import {
  SubscriptionCard,
  SubscriptionCharge,
  SubscriptionMorePopover,
} from '../components'

interface Props {
  fullSubscription: FullSubscription
  mixedRegions: boolean
  id: number
  morePopoverOpen: number | null
  setMorePopoverOpen: React.Dispatch<React.SetStateAction<number | null>>
  setCardPopoverOpen: React.Dispatch<React.SetStateAction<number | null>>
  cardPopoverOpen: number | null
}

export const SubscriptionRow: React.FC<Props> = ({
  fullSubscription,
  mixedRegions,
  id,
  morePopoverOpen,
  setMorePopoverOpen,
  setCardPopoverOpen,
  cardPopoverOpen,
}) => {
  const intl = useIntl()

  const { metadata, cancelAtPeriodEnd, status } = fullSubscription
  const { isMaxXSmall } = useContext(MediaSizingContext)

  const hidePopoverToggle =
    status === SubscriptionStatus.CANCELED && !isMaxXSmall

  const devices = useSelector(selectOwnedUcoreDevices)

  const subscriptionDevice = devices.find(
    (device) => device.hardwareId === metadata.device_uuid
  )

  return (
    <Row key={id}>
      <CellColumns
        $mixedRegions={mixedRegions}
        $cancelAtPeriodEnd={cancelAtPeriodEnd}
        $skipFirstColumn
      >
        {cancelAtPeriodEnd && (
          <CellContainer>
            <RowWarningIcon width={20} height={20} variant="fill" />
          </CellContainer>
        )}
        {mixedRegions && (
          <CellContainer>
            <Cell>
              <RoundFlag countryCode={fullSubscription.region} noMargin />
            </Cell>
          </CellContainer>
        )}
        <CellContainer>
          <Cell>
            {subscriptionDevice?.shadow?.state.reported.name
              ? subscriptionDevice.shadow.state.reported.name
              : metadata.device_shortname}
          </Cell>
        </CellContainer>
        {!isMaxXSmall && (
          <>
            <CellContainer>
              <Cell>
                <DateCell dt={getSubscriptionBillingDate(fullSubscription)} />
              </Cell>
            </CellContainer>
            <CellContainer>
              <CardCell>
                <SubscriptionCard
                  fullSubscription={fullSubscription}
                  cardPopoverOpen={cardPopoverOpen}
                  setCardPopoverOpen={setCardPopoverOpen}
                  id={id}
                />
              </CardCell>
            </CellContainer>
          </>
        )}
        <CellContainer>
          <ChargeCell>
            {cancelAtPeriodEnd ? (
              intl.formatMessage({
                id: 'SETTINGS_SUBSCRIPTIONS_CANCELED_AT_MONTH_END_ROW',
              })
            ) : (
              <SubscriptionCharge fullSubscription={fullSubscription} />
            )}
          </ChargeCell>
        </CellContainer>

        <CellContainer>
          {!hidePopoverToggle && (
            <SubscriptionMorePopover
              fullSubscription={fullSubscription}
              morePopoverOpen={morePopoverOpen}
              setCardPopoverOpen={setCardPopoverOpen}
              setMorePopoverOpen={setMorePopoverOpen}
              cardPopoverOpen={cardPopoverOpen}
              modalId={CANCEL_SUBSCRIPTION_ETU_MODAL_ID}
              id={id}
            />
          )}
        </CellContainer>
      </CellColumns>
    </Row>
  )
}
