import React from 'react'

import styled from 'theme/styled'

interface Props {
  visible?: boolean
  children: React.ReactNode
}

const ActionPanel: React.FC<Props> = ({ visible, children }) => {
  return <Panel visible={visible}>{children}</Panel>
}

export default ActionPanel

const PANEL_HEIGHT = 100

interface PanelProps {
  visible?: boolean
}

const Panel = styled.div<PanelProps>`
  background: ${(p) =>
    `linear-gradient(to right, ${p.theme.neutral00} 0%, ${p.theme.neutral03} 100%)`};
  height: ${(p) => (p.visible ? PANEL_HEIGHT : 0)}px;
  bottom: 0;
  transition: all 0.25s ease;
  transform: translateY(${(p) => (p.visible ? 0 : PANEL_HEIGHT)}px);

  position: fixed;
  left: 0;
  width: 100%;

  @media (min-width: ${(p) => p.theme.media.medium}) {
    left: 256px;
    width: calc(100% - 256px);
  }

  @media (min-width: ${({ theme }) => theme.media.large}) {
    left: 296px;
    width: calc(100% - 296px);
  }

  display: flex;
  justify-content: flex-end;
  align-items: center;

  z-index: 100;
`
