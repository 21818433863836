import { StripeCountry, StripeRegionCode } from '../types'

const {
  featureFlags: { areExtraAUCountriesEnabled },
} = __CONFIG__

const extraCountries = [
  {
    code: 'nz',
    name: 'New Zealand',
    region: StripeRegionCode.AU,
    taxId: [] as StripeCountry['taxId'],
  },
  {
    code: 'cx',
    name: 'Christmas Island',
    region: StripeRegionCode.AU,
    taxId: [] as StripeCountry['taxId'],
  },
] as const satisfies Readonly<StripeCountry[]>

export const auStripeCountries = [
  {
    code: 'au',
    name: 'Australia',
    region: StripeRegionCode.AU,
  },
  ...(areExtraAUCountriesEnabled ? extraCountries : []),
] as const satisfies Readonly<StripeCountry[]>
