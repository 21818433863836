import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  selectClosedTickets,
  selectClosedTicketsAreLoading,
  selectClosedTicketsErrors,
  selectClosedTicketsFetched,
  selectHasMoreClosedTickets,
  selectTotalClosedTickets,
} from 'modules/zendesk/closedUserTickets'
import {
  selectCcdTickets,
  selectCcdTicketsAreLoading,
  selectCcdTicketsErrors,
  selectCcdTicketsFetched,
  selectFetchedCcdTickets,
} from 'modules/zendesk/ccdUserTickets'
import {
  selectHasMoreOpenTickets,
  selectOpenTickets,
  selectOpenTicketsAreLoading,
  selectOpenTicketsErrors,
  selectOpenTicketsFetched,
  selectTotalOpenTickets,
} from 'modules/zendesk/openUserTickets'

import { HeaderTab, SearchData } from './types'

export const useGetRequests = (
  statusToShow: HeaderTab,
  searchData: SearchData
) => {
  const openTickets = useSelector(selectOpenTickets)
  const closedTickets = useSelector(selectClosedTickets)
  const ccdTickets = useSelector(selectCcdTickets)

  const haveOpenFetched = useSelector(selectOpenTicketsFetched)
  const haveClosedFetched = useSelector(selectClosedTicketsFetched)
  const haveCcdFetched = useSelector(selectCcdTicketsFetched)

  const hasMoreOpenTickets = useSelector(selectHasMoreOpenTickets)
  const hasMoreClosedTickets = useSelector(selectHasMoreClosedTickets)

  const openTicketsError = useSelector(selectOpenTicketsErrors)
  const closedTicketsError = useSelector(selectClosedTicketsErrors)
  const ccdTicketsError = useSelector(selectCcdTicketsErrors)

  const totalOpenTickets = useSelector(selectTotalOpenTickets)
  const totalClosedTickets = useSelector(selectTotalClosedTickets)
  const totalCcdTickets = useSelector(selectFetchedCcdTickets)

  const areOpenTicketsLoading = useSelector(selectOpenTicketsAreLoading)
  const areClosedTicketsLoading = useSelector(selectClosedTicketsAreLoading)
  const areCcdTicketsLoading = useSelector(selectCcdTicketsAreLoading)

  const allTickets = useMemo(() => {
    if (searchData.results.length > 0 || searchData.error) {
      return searchData.results
    }
    if (statusToShow === 'open') {
      return openTickets
    }
    if (statusToShow === 'closed') {
      return closedTickets
    }
    return ccdTickets
  }, [
    statusToShow,
    ccdTickets,
    closedTickets,
    openTickets,
    searchData.error,
    searchData.results,
  ])

  const hasMore: boolean = useMemo(() => {
    if (statusToShow === 'open') {
      return hasMoreOpenTickets
    }
    if (statusToShow === 'closed') {
      return hasMoreClosedTickets
    }
    return false
  }, [hasMoreClosedTickets, hasMoreOpenTickets, statusToShow])

  const totalTickets: number = useMemo(() => {
    if (searchData.results.length > 0 || searchData.error) {
      return searchData.results.length
    }
    if (statusToShow === 'open') {
      return totalOpenTickets
    }
    if (statusToShow === 'closed') {
      return totalClosedTickets
    }
    return totalCcdTickets
  }, [
    searchData.results.length,
    searchData.error,
    totalClosedTickets,
    totalOpenTickets,
    totalCcdTickets,
    statusToShow,
  ])

  const ticketsError: boolean | null = useMemo(() => {
    if (statusToShow === 'open') {
      return !!Object.keys(openTicketsError).length
    }
    if (statusToShow === 'closed') {
      return !!Object.keys(closedTicketsError).length
    }
    if (statusToShow === 'ccd') {
      return !!Object.keys(ccdTicketsError).length
    }

    return null
  }, [ccdTicketsError, closedTicketsError, openTicketsError, statusToShow])

  const hasFetchedOnce: boolean = useMemo(() => {
    if (statusToShow === 'open') {
      return haveOpenFetched
    }
    if (statusToShow === 'closed') {
      return haveClosedFetched
    }
    return haveCcdFetched
  }, [haveCcdFetched, haveClosedFetched, haveOpenFetched, statusToShow])

  const isFetching: boolean = useMemo(() => {
    if (statusToShow === 'open') {
      return areOpenTicketsLoading
    }
    if (statusToShow === 'closed') {
      return areClosedTicketsLoading
    }
    return areCcdTicketsLoading
  }, [
    areCcdTicketsLoading,
    areClosedTicketsLoading,
    areOpenTicketsLoading,
    statusToShow,
  ])

  return {
    allTickets,
    hasMore,
    ticketsError,
    totalTickets,
    hasFetchedOnce,
    isFetching,
    totalFetchedTickets: allTickets.length,
  }
}
