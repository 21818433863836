import React, { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { CANCEL_SUBSCRIPTION_SITE_SUPPORT_MODAL_ID } from 'pages/subscriptions/CancelSubscriptionSiteSupportModal'
import { RoundFlag } from 'components/RoundFlag'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/fullSubscriptions'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'

import {
  CardCell,
  Cell,
  CellColumnLabel,
  CellColumns,
  CellContainer,
  CellTextPrimary,
  ChargeCell,
  ChargeLabelCell,
  Container,
  LabelCell,
  Row,
  RowWarningIcon,
} from '../Styles'
import { DateCell } from '../../cells/DateCell'
import { getSubscriptionBillingDate } from '../../utils'
import {
  SubscriptionCard,
  SubscriptionCharge,
  SubscriptionMorePopover,
} from '../components'

interface Props {
  fullSubscriptions: FullSubscription[]
  mixedRegions: boolean
}

export const SiteSupportSubscriptionList: React.FC<Readonly<Props>> = ({
  fullSubscriptions,
  mixedRegions,
}) => {
  const { isMaxXSmall } = useContext(MediaSizingContext)
  const intl = useIntl()

  const [cardPopoverOpen, setCardPopoverOpen] = useState<number | null>(null)
  const [morePopoverOpen, setMorePopoverOpen] = useState<number | null>(null)

  if (!fullSubscriptions.length) return null

  return (
    <Container>
      <Row>
        <CellColumnLabel $mixedRegions={mixedRegions}>
          {mixedRegions && <LabelCell />}
          <LabelCell>
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_TABLE_COL_DEVICE_NAME" />
          </LabelCell>
          {!isMaxXSmall && (
            <>
              <LabelCell>
                <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_TABLE_COL_DEVICE" />
              </LabelCell>
              <LabelCell>
                <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_COLUMN_LABEL_BILLING_DATE" />
              </LabelCell>
              <LabelCell>
                <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_COLUMN_LABEL_PAYMENT_METHOD" />
              </LabelCell>
            </>
          )}
          <ChargeLabelCell>
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_COLUMN_LABEL_CHARGE" />
          </ChargeLabelCell>
        </CellColumnLabel>
      </Row>
      {fullSubscriptions.map(
        (fullSubscription: FullSubscription, i: number) => {
          const { status, cancelAtPeriodEnd } = fullSubscription

          const hidePopoverToggle = status === 'canceled' && !isMaxXSmall

          return (
            <Row key={i}>
              <CellColumns
                $mixedRegions={mixedRegions}
                $cancelAtPeriodEnd={cancelAtPeriodEnd}
              >
                {cancelAtPeriodEnd && (
                  <CellContainer>
                    <RowWarningIcon width={20} height={20} variant="fill" />
                  </CellContainer>
                )}

                {mixedRegions && (
                  <CellContainer>
                    <Cell>
                      <RoundFlag
                        countryCode={fullSubscription.region}
                        noMargin
                      />
                    </Cell>
                  </CellContainer>
                )}
                <CellContainer>
                  <CellTextPrimary>
                    <Cell>{fullSubscription.metadata?.deviceName || '-'}</Cell>
                  </CellTextPrimary>
                </CellContainer>
                {!isMaxXSmall && (
                  <>
                    <CellContainer>
                      <Cell>{fullSubscription.metadata?.model || '-'}</Cell>
                    </CellContainer>
                    <CellContainer>
                      <Cell>
                        <DateCell
                          dt={getSubscriptionBillingDate(fullSubscription)}
                        />
                      </Cell>
                    </CellContainer>
                    <CellContainer>
                      <CardCell>
                        <SubscriptionCard
                          fullSubscription={fullSubscription}
                          cardPopoverOpen={cardPopoverOpen}
                          setCardPopoverOpen={setCardPopoverOpen}
                          id={i}
                        />
                      </CardCell>
                    </CellContainer>
                  </>
                )}
                <CellContainer>
                  <ChargeCell>
                    {cancelAtPeriodEnd ? (
                      intl.formatMessage({
                        id: 'SETTINGS_SUBSCRIPTIONS_CANCELED_AT_MONTH_END_ROW',
                      })
                    ) : (
                      <SubscriptionCharge fullSubscription={fullSubscription} />
                    )}
                  </ChargeCell>
                </CellContainer>
                <CellContainer>
                  {!hidePopoverToggle && (
                    <SubscriptionMorePopover
                      fullSubscription={fullSubscription}
                      morePopoverOpen={morePopoverOpen}
                      setCardPopoverOpen={setCardPopoverOpen}
                      setMorePopoverOpen={setMorePopoverOpen}
                      cardPopoverOpen={cardPopoverOpen}
                      modalId={CANCEL_SUBSCRIPTION_SITE_SUPPORT_MODAL_ID}
                      id={i}
                    />
                  )}
                </CellContainer>
              </CellColumns>
            </Row>
          )
        }
      )}
    </Container>
  )
}

export default SiteSupportSubscriptionList
