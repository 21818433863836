import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { parsePhoneNumber } from 'libphonenumber-js'
import { Button } from '@ubnt/ui-components/Button'

import styled from 'theme/styled'
import { selectMFASetupSMS } from 'modules/mfaSetup'
import { selectVisibleModal } from 'modules/modals'
import { getErrorMessage } from 'utils/mfa'

import { GenericVerifyTokenModal } from '../GenericVerifyTokenModal'
import {
  selectVerifyMFASMSErrors,
  selectVerifyMFASMSIsLoading,
  verifyMFASMS,
} from './modules/verifyMFASMS'
import { selectSendMFASMSIsLoading, sendMFASMS } from './modules/sendMFASMS'

const Description = styled.div`
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.text3};
`

const ResendCode = styled.div`
  margin-top: 16px;
  font-size: 14px;
  line-height: 24px;
  button {
    outline: none;
  }
  color: ${({ theme }) => theme.text3};
`

export const SMS_VERIFY_CODE_MODAL_ID = 'SMS_VERIFY_CODE_MODAL_ID'

interface FormValues {
  token: string
}

export const SMSVerifyCodeModal: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const isLoading = useSelector(selectVerifyMFASMSIsLoading)

  const { id, phoneNumber } = useSelector(selectMFASetupSMS)
  const resendIsLoading = useSelector(selectSendMFASMSIsLoading)
  const apiError = useSelector(selectVerifyMFASMSErrors)
  const visibleModal = useSelector(selectVisibleModal)

  const prettyPhoneNumber = phoneNumber
    ? parsePhoneNumber(phoneNumber, 'US').formatInternational()
    : ''

  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    setErrorMessage(
      getErrorMessage({
        apiError,
        defaultMessage: intl.formatMessage({
          id: 'GENERIC_ERROR_BOUNDARY_TITLE',
        }),
        fields: ['detail'],
      }) || ''
    )
  }, [apiError, intl])

  useEffect(() => setErrorMessage(''), [visibleModal])

  const handleOnSubmit = useCallback(
    ({ token }: FormValues) => {
      if (!token) {
        console.error('token is not provided')
        return
      }

      dispatch(verifyMFASMS(id, phoneNumber, token))
    },
    [dispatch, id, phoneNumber]
  )

  const handleResendCode = () => {
    dispatch(sendMFASMS(id))
  }

  const description = (
    <div>
      <Description>
        {intl.formatMessage(
          {
            id: 'SETTINGS_MFA_SMS_VERIFY_CODE_DESCRIPTION',
          },
          {
            phoneNumber: (
              <b>
                <br />
                {prettyPhoneNumber}
              </b>
            ),
          }
        )}
      </Description>
      <ResendCode>
        {intl.formatMessage(
          {
            id: 'SETTINGS_MFA_VERIFY_CODE_RESEND_ACTION',
          },
          {
            link: (
              <Button
                variant="inline"
                onClick={handleResendCode}
                loader={resendIsLoading ? 'dots' : undefined}
              >
                {intl.formatMessage({
                  id: 'SETTINGS_MFA_VERIFY_CODE_RESEND',
                })}
              </Button>
            ),
          }
        )}
      </ResendCode>
    </div>
  )

  return (
    <GenericVerifyTokenModal
      modalId={SMS_VERIFY_CODE_MODAL_ID}
      title={intl.formatMessage({ id: 'SETTINGS_MFA_SMS_MODAL_TITLE' })}
      description={description}
      handleOnSubmit={handleOnSubmit}
      isLoading={isLoading}
      errorMessage={errorMessage}
    />
  )
}
