import { Country } from '../config/types'

export const mapCountryToStore = (country: string) => {
  switch (country) {
    case 'us':
      return Country.usa
    case 'ca':
      return Country.canada
    case 'cn':
      return Country.china
    case 'in':
      return Country.india
    case 'jp':
      return Country.japan
    case 'mx':
      return Country.mexico
    case 'sg':
      return Country.singapore
    case 'za':
      return Country.southAfrica
    case 'uk':
    case 'gb':
      return Country.uk
    case 'ir':
    case 'eg':
    case 'sa':
    case 'ae':
    case 'bh':
    case 'jo':
    case 'iq':
    case 'il':
    case 'lb':
    case 'qa':
    case 'sy':
    case 'kw':
    case 'ye':
    case 'om':
    case 'tr':
    case 'ps':
      return Country.middleEast
    case 'cy':
    case 'se':
    case 'at':
    case 'be':
    case 'bg':
    case 'hr':
    case 'cz':
    case 'dk':
    case 'ee':
    case 'fi':
    case 'fr':
    case 'de':
    case 'gr':
    case 'hu':
    case 'is':
    case 'ie':
    case 'it':
    case 'lv':
    case 'li':
    case 'lt':
    case 'lu':
    case 'mt':
    case 'nl':
    case 'no':
    case 'pl':
    case 'pt':
    case 'ro':
    case 'sk':
    case 'si':
    case 'es':
    case 'ch':
      return Country.europe
    default:
      return
  }
}
