import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from '@ubnt/ui-components/Button'
import { ArrowDownPrimaryIcon, ArrowUpPrimaryIcon } from '@ubnt/icons'

import styled from 'theme/styled'
import { space, typography } from 'theme'

interface Props {
  handleClick: () => void
  isOpen: boolean
}

export const ShowOtherButton: React.FC<Props> = ({ handleClick, isOpen }) => {
  return (
    <StyledButton variant="inline" onClick={handleClick} size="medium">
      <IconWrapper>
        {isOpen ? (
          <ArrowUpPrimaryIcon size="original" strokeWidth={0.5} />
        ) : (
          <ArrowDownPrimaryIcon size="original" strokeWidth={0.5} />
        )}
      </IconWrapper>
      {isOpen ? (
        <FormattedMessage id="SETTINGS_MFA_OPTIONS_LESS_METHODS" />
      ) : (
        <FormattedMessage id="SETTINGS_MFA_OPTIONS_OTHER_METHODS" />
      )}
    </StyledButton>
  )
}

export default ShowOtherButton

const IconWrapper = styled.div`
  margin-right: ${space['desktop-spacing-base-02']};
`
const StyledButton = styled(Button)`
  font: ${typography['desktop-body']};
`
