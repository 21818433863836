import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { ErrorPage_Default } from '@ubnt/ui-components/aria'

export const ErrorPageFourOhFour: React.FC = () => {
  const history = useHistory()
  const intl = useIntl()
  return (
    <ErrorPage_Default
      title={intl.formatMessage({ id: 'GENERIC_ERROR_BOUNDARY_TITLE_404' })}
      actionButtonText={intl.formatMessage({
        id: 'GENERIC_ERROR_BOUNDARY_ACTION_404',
      })}
      description={intl.formatMessage({
        id: 'GENERIC_ERROR_BOUNDARY_DESCRIPTION_404',
      })}
      actionButtonProps={{ onClick: () => history.push('/') }}
    />
  )
}

export const ErrorPageGeneric: React.FC = () => {
  const intl = useIntl()
  return (
    <ErrorPage_Default
      title={intl.formatMessage({ id: 'GENERIC_ERROR_BOUNDARY_TITLE' })}
      actionButtonText={intl.formatMessage({
        id: 'GENERIC_ERROR_BOUNDARY_ACTION',
      })}
      description={intl.formatMessage({
        id: 'GENERIC_ERROR_BOUNDARY_DESCRIPTION',
      })}
      actionButtonProps={{ onClick: () => window.location.reload() }}
    />
  )
}
