import { all, put, spawn, takeEvery } from 'redux-saga/effects'
import { api } from 'api'

import { MFAType, fetchMFA } from 'modules/mfa'
import createDataModule from 'utils/moduleCreator'
import { SSOAPIError } from 'types/types'
import { MFAAuthenticatorStatus } from 'enums'

const {
  api: { sso },
} = __CONFIG__

export const removeTempMFAEmailDataKey = 'MFARemoveTempEmail'

type RemoveTempEmailState =
  | {
      email: string
      status: MFAAuthenticatorStatus
      last_success: string | null
      id: string
      created: string
      type: MFAType.email
    }
  | Record<string, never>

const dataModule = createDataModule<RemoveTempEmailState, SSOAPIError>(
  removeTempMFAEmailDataKey,
  `${sso.paths.mfa}/email`,
  api.ssoBase
)

const { selectIsDone, resetData, REMOVE_DONE } = dataModule

export {
  selectIsDone as selectRemoveTempMFAEmailIsDone,
  resetData as resetRemoveTempMFAEmailData,
}

function* removeMFAEmailDoneSaga() {
  yield put(fetchMFA())
}

function* subscribeToRemoveTempEmailDoneSaga() {
  yield takeEvery(REMOVE_DONE, removeMFAEmailDoneSaga)
}

export function* removeTempEmailRootSaga() {
  yield all([
    spawn(dataModule.rootSaga),
    spawn(subscribeToRemoveTempEmailDoneSaga),
  ])
}

export const removeTempMFAEmailReducer = dataModule.reducer
