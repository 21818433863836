import React, { useCallback, useContext, useEffect, useState } from 'react'
import { BatteryIcon } from '@ubnt/icons'
import { EntityToast, ToastContext } from '@ubnt/ui-components'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { selectIsAddTaxIdDone } from 'features/payment-methods/modules/taxId/addTaxId'
import { selectIsRemoveTaxIdDone } from 'features/payment-methods/modules/taxId/removeTaxId'
import { selectIsUpdateTaxIdDone } from 'features/payment-methods/modules/taxId/updateTaxId'
import usePrevious from 'utils/usePrevious'

import { ToastType } from '../types'
import { createId, getStatusIndicator, getToastTitle } from './utils'
import useTaxIdErrors from './useTaxIdErrors'

const useTaxIdToast = () => {
  const isAddDone = useSelector(selectIsAddTaxIdDone)
  const isUpdateDone = useSelector(selectIsUpdateTaxIdDone)
  const isRemoveDone = useSelector(selectIsRemoveTaxIdDone)

  const previousAddDone = usePrevious(isAddDone)
  const previousUpdateDone = usePrevious(isUpdateDone)
  const previousRemoveDone = usePrevious(isRemoveDone)
  const { createNotification, removeNotification } = useContext(ToastContext)
  const intl = useIntl()
  const { hasError, errorMessage } = useTaxIdErrors()

  const [toastType, setToastType] = useState<ToastType | undefined>()

  const toastTitle = intl.formatMessage(
    { id: 'SETTINGS_PAYMENTS_TAX_ID_SUCCESS' },
    {
      action: toastType,
    }
  )
  const toastError = hasError
    ? intl.formatMessage(
        { id: 'SETTINGS_PAYMENTS_TAX_ID_FAILURE' },
        { action: errorMessage }
      )
    : null

  const createToast = useCallback(
    (type: ToastType) => {
      createNotification(
        <EntityToast
          id={createId(type)}
          icon={<BatteryIcon />}
          stateIndicator={getStatusIndicator(hasError)}
          onClose={(_, id) => id && removeNotification(id)}
          title={getToastTitle(toastTitle, toastError)}
        />
      )
    },
    [createNotification, hasError, toastTitle, toastError, removeNotification]
  )

  useEffect(() => {
    if (isAddDone && previousAddDone === false) {
      setToastType('added')
    } else if (isUpdateDone && previousUpdateDone === false) {
      setToastType('updated')
    } else if (isRemoveDone && previousRemoveDone === false) {
      setToastType('removed')
    } else {
      setToastType(undefined)
    }
  }, [
    createToast,
    isAddDone,
    isRemoveDone,
    isUpdateDone,
    previousAddDone,
    previousRemoveDone,
    previousUpdateDone,
  ])

  return { createToast, toastType }
}

export default useTaxIdToast
