import { all, put, spawn, takeEvery } from 'redux-saga/effects'
import { api } from 'api'

import createDataModule from 'utils/moduleCreator'
import { logout } from 'features/auth/modules/auth'

const {
  api: { sso },
} = __CONFIG__

export const dataKey = 'deleteProfile'

const dataModule = createDataModule(dataKey, sso.paths.self, api.ssoBase)

export const {
  remove: removeAccount,
  reducer: deleteAccountReducer,
  selectErrors: selectDeleteAccountErrors,
  selectIsLoading: selectDeleteAccountIsLoading,
  REMOVE_DONE,
} = dataModule

const REMOVE_PROFILE_ACCOUNT = `${dataKey}/REMOVE_PROFILE_ACCOUNT`
export const deleteAccount = (password: string) => ({
  type: REMOVE_PROFILE_ACCOUNT,
  payload: { password },
})

function* deleteAccountSaga(action: { type: string; payload: string }) {
  yield put(removeAccount(action.payload))
}

function* subscribeToDeleteAccountSaga() {
  yield takeEvery(REMOVE_PROFILE_ACCOUNT, deleteAccountSaga)
}

function* deleteAccountDoneSaga() {
  localStorage.setItem('accountDeleted', 'true')
  yield put(logout())
}

function* subscribeToDeleteAccountDoneSaga() {
  yield takeEvery(REMOVE_DONE, deleteAccountDoneSaga)
}

export function* deleteAccountRootSaga() {
  yield all([
    spawn(dataModule.rootSaga),
    spawn(subscribeToDeleteAccountSaga),
    spawn(subscribeToDeleteAccountDoneSaga),
  ])
}
