import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'theme/styled'
import { closeVisibleModal } from 'modules/modals'
import { selectMFAAuthenticators } from 'modules/mfa'
import { selectProfileDefaultMFA } from 'modules/profile'

import { GenericMFAModal } from '../GenericMFAModal'
import { MultiFactorMethod } from './MultiFactorMethod'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`

export const SETUP_PRIMARY_METHOD_MODAL = 'SETUP_PRIMARY_METHOD_MODAL'

export const SetupPrimaryMethodModal: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const authenticators = useSelector(selectMFAAuthenticators)
  const defaultMFAId = useSelector(selectProfileDefaultMFA)

  const primaryMethod = useMemo(() => {
    const authenticator = authenticators.find(
      (authenticator) => authenticator.id === defaultMFAId
    )
    return authenticator ? [authenticator] : []
  }, [authenticators, defaultMFAId])

  const backupMethods = useMemo(
    () => authenticators.filter((method) => method.id !== defaultMFAId),
    [authenticators, defaultMFAId]
  )

  const auths = [...primaryMethod, ...backupMethods]

  return (
    <GenericMFAModal
      modalId={SETUP_PRIMARY_METHOD_MODAL}
      title={intl.formatMessage({
        id: 'SETTINGS_MFA_PRIMARY_METHOD_CHANGE',
      })}
      actions={[
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_CLOSE' }),
          variant: 'secondary',
          onClick: () => dispatch(closeVisibleModal()),
        },
      ]}
    >
      <Wrapper>
        {auths.map((mfaMethod, index) => (
          <MultiFactorMethod
            key={index}
            authenticator={mfaMethod}
            defaultMFAId={defaultMFAId}
          />
        ))}
      </Wrapper>
    </GenericMFAModal>
  )
}
