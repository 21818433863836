import {
  selectDevices,
  selectFetchingDevices,
  selectValidUcoreDevices,
} from 'features/devices/devices'
import { UcoreDevice } from 'features/devices/types'
import { useSelector } from 'react-redux'

const useGetDevices = () => {
  const consoles: UcoreDevice[] | undefined = useSelector(
    selectValidUcoreDevices
  )
  const isConsolesLoading = useSelector(selectFetchingDevices)
  const totalDevices = useSelector(selectDevices)

  return { consoles, totalDevices, isConsolesLoading }
}

export default useGetDevices
