import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import QrCode from 'qrcode.react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Loader } from '@ubnt/ui-components'
import { ValidationMessage } from '@ubnt/ui-components/ValidationMessage'
import isMobile from 'is-mobile'

import styled from 'theme/styled'
import { closeVisibleModal } from 'modules/modals'
import { getErrorMessage } from 'utils/mfa'

import { StoreButtons } from '../MultiFactor/StoreButtons'
import { GenericMFAModal } from '../GenericMFAModal'
import {
  selectInitiatePushData,
  selectInitiatePushErrors,
  selectInitiatePushIsError,
  selectInitiatePushIsLoading,
} from './modules/initiatePush'
import { pushVerifyCancel } from './modules/verifyPush'

export const PUSH_QR_CODE_MODAL_ID = 'PUSH_QR_CODE_MODAL_ID'

export const PushQRCodeModal: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const isMobileDevice = isMobile()

  const { setup_uri } = useSelector(selectInitiatePushData)
  const isLoading = useSelector(selectInitiatePushIsLoading)
  const apiError = useSelector(selectInitiatePushErrors)
  const isError = useSelector(selectInitiatePushIsError)

  const [viewQRCode, setViewQRCode] = useState(true)
  const [setupButtonClicked, setSetupButtonClicked] = useState(false)

  let contentToRender: JSX.Element = (
    <ValidationMessage>
      {intl.formatMessage({
        id: 'GENERIC_ERROR_BOUNDARY_TITLE',
      })}
    </ValidationMessage>
  )

  if (isError) {
    contentToRender = (
      <ValidationMessage>
        {getErrorMessage({
          apiError,
          defaultMessage: intl.formatMessage({
            id: 'GENERIC_ERROR_BOUNDARY_TITLE',
          }),
          fields: ['detail'],
        })}
      </ValidationMessage>
    )
  } else if (isLoading) {
    contentToRender = <Loader />
  } else if (setup_uri && !viewQRCode) {
    if (!setupButtonClicked && isMobileDevice) {
      contentToRender = (
        <>
          <Button
            variant="primary"
            full
            href={setup_uri}
            onClick={() => setSetupButtonClicked(true)}
          >
            {intl.formatMessage({
              id: 'SETTINGS_MFA_TOTP_MODAL_OPEN_APP_BUTTON',
            })}
          </Button>
        </>
      )
    } else {
      contentToRender = (
        <>
          {intl.formatMessage({
            id: 'SETTINGS_MFA_PUSH_OPENED_URI',
          })}
        </>
      )
    }
  } else if (setup_uri) {
    contentToRender = isLoading ? (
      <Loader type="spinner" />
    ) : (
      <QrCode size={164} value={setup_uri} />
    )
  }

  return (
    <GenericMFAModal
      modalId={PUSH_QR_CODE_MODAL_ID}
      title={intl.formatMessage({ id: 'SETTINGS_MFA_PUSH_MODAL_TITLE' })}
      isLoading={isLoading}
      description={
        <DescriptionWrapper>
          {viewQRCode ? (
            <FormattedMessage id="SETTINGS_MFA_PUSH_MODAL_DESCRIPTION_QR" />
          ) : (
            <FormattedMessage id="SETTINGS_MFA_PUSH_MODAL_DESCRIPTION_CODE" />
          )}
        </DescriptionWrapper>
      }
      onAfterClose={() => {
        setSetupButtonClicked(false)
        dispatch(pushVerifyCancel())
      }}
      onAfterOpen={() => {
        setViewQRCode(!isMobileDevice)
      }}
      actions={[
        {
          text: intl.formatMessage({
            id: 'COMMON_ACTION_CANCEL',
          }),
          variant: 'tertiary',
          onClick: () => dispatch(closeVisibleModal()),
        },
        {
          text: intl.formatMessage({
            id: 'COMMON_ACTION_CONTINUE',
          }),
          disabled: true,
          variant: 'primary',
          onClick: () => undefined,
        },
      ]}
      overrideFullScreen
      size="auto"
    >
      <Wrapper>
        <IconSectionWrapper>
          <StoreButtons />
        </IconSectionWrapper>
        {contentToRender}
      </Wrapper>
    </GenericMFAModal>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
`

const IconSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 120px;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 30px;
  align-items: center;
`

const DescriptionWrapper = styled.div`
  line-height: 24px;
`
