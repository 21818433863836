import { all, spawn } from 'redux-saga/effects'
import { api } from 'api'

import createDataModule from 'utils/moduleCreator'
import { ActivityState } from './types'

const {
  api: { sso },
} = __CONFIG__

const INCLUDED_EVENTS_QUERY = '?source=sso'

export const activityDataKey = 'activity'

const getUrl = (url: string) => `${url}${INCLUDED_EVENTS_QUERY}`

const dataModule = createDataModule<ActivityState>(
  activityDataKey,
  sso.paths.activity,
  api.ssoBase,
  undefined,
  getUrl
)

export const {
  fetch: fetchActivity,
  selectData: selectActivityData,
  selectErrors: selectActivityError,
  selectIsDone: selectIsDoneFetchingActivities,
  selectIsLoading: selectIsActivitiesLoading,
  reducer: activityReducer,
} = dataModule

export function* activityRootSaga() {
  yield all([spawn(dataModule.rootSaga)])
}
