import React, { useContext, useMemo } from 'react'
import { isEmpty } from 'lodash-es'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { AccessPolicyIcon, MapsIcon } from '@ubnt/icons'
import { SkeletonLoader, Tooltip } from '@ubnt/ui-components'
import Block from '@ubnt/ui-components/SkeletonLoader/Skeleton/Block'

import styled from 'theme/styled'
import theme, { typography } from 'theme'
import { RoundFlag } from 'components/RoundFlag'
import { Section, SectionHeader } from 'components/Section.styles'
import { MediaSizingContext } from 'components/mediaSizing/MediaSizingContext'

import {
  selectActivityData,
  selectActivityError,
  selectIsActivitiesLoading,
  selectIsDoneFetchingActivities,
} from '../module/activity'
import {
  isActivitySSOBackupCodesUsed,
  isActivitySSOEmailChanged,
  isActivitySSOLogin,
  isActivitySSOMethodAdded,
  isActivitySSOMethodRemoved,
  isActivitySSOPasswordChanged,
  isActivitySSOPasswordReset,
  isActivitySSOPrimaryMethodChanged,
  isActivitySSOSessionTimeoutChanged,
} from '../module/guards'
import { ActivityItem, ActivityState } from '../module/types'
import {
  formatUserAgent,
  getFormattedTime,
  getLocationString,
  getTTLString,
} from '../utils'

export const Activities: React.FC<{ numberOfItems?: number }> = ({
  numberOfItems = 10,
}) => {
  const intl = useIntl()
  const isLoading = useSelector(selectIsActivitiesLoading)
  const isDoneFetching = useSelector(selectIsDoneFetchingActivities)
  const error = useSelector(selectActivityError)
  const { items }: ActivityState = useSelector(selectActivityData)

  const { isMaxMediumV2 } = useContext(MediaSizingContext)

  const activities = useMemo(() => {
    const getActivityMessage = (item: ActivityItem) => {
      const locationMessage = item.location?.countryCode && (
        <Message>
          <LocationContainer>
            <RoundFlag
              size="small"
              marginRight="4px"
              countryCode={item.location.countryCode.toLowerCase()}
            />
            {item.ip ? (
              <StyledTooltip
                description={<>IP: {item.ip}</>}
                portal
                position="bottom"
                width="130px"
                maxWidth="100%"
              >
                {getLocationString(item.location, intl)}
              </StyledTooltip>
            ) : (
              <>{getLocationString(item.location, intl)}</>
            )}
          </LocationContainer>
        </Message>
      )

      const getLocationMessage = () => {
        if (locationMessage) return locationMessage
        if (item.ip) {
          return (
            <Message>
              {isMaxMediumV2 && ', '}IP: {item.ip}
            </Message>
          )
        }
        return null
      }

      // This is matching the key of the message in the translation file
      const messageId = `ACTIVITY_${item.eventName}`

      if (
        isActivitySSOMethodAdded(item) ||
        isActivitySSOMethodRemoved(item) ||
        isActivitySSOPrimaryMethodChanged(item)
      ) {
        const { methodMessageId } = item.data

        return (
          <ItemColumns>
            <StyledActivitySecurityIcon transparent />
            <ActivityText>
              {intl.formatMessage(
                { id: messageId },
                { method: intl.formatMessage({ id: methodMessageId }) }
              )}
              {getLocationMessage()}
            </ActivityText>
          </ItemColumns>
        )
      }

      if (
        isActivitySSOLogin(item) ||
        isActivitySSOPasswordChanged(item) ||
        isActivitySSOPasswordReset(item) ||
        isActivitySSOEmailChanged(item)
      ) {
        return (
          <ItemColumns>
            <StyledActivitySignInIcon transparent />
            <ActivityText>
              {intl.formatMessage(
                { id: messageId },
                { userAgent: formatUserAgent(item) }
              )}
              {getLocationMessage()}
            </ActivityText>
          </ItemColumns>
        )
      }

      if (isActivitySSOBackupCodesUsed(item)) {
        return (
          <ItemColumns>
            <StyledActivitySecurityIcon transparent />
            <ActivityText>
              {intl.formatMessage(
                { id: messageId },
                { codesLeft: item.data.codesLeft }
              )}
              {getLocationMessage()}
            </ActivityText>
          </ItemColumns>
        )
      }

      if (isActivitySSOSessionTimeoutChanged(item)) {
        return (
          <ItemColumns>
            <StyledActivitySecurityIcon transparent />
            <ActivityText>
              {intl.formatMessage(
                { id: messageId },
                { sessionTTL: getTTLString(item.data.sessionTTLSeconds, intl) }
              )}
              {getLocationMessage()}
            </ActivityText>
          </ItemColumns>
        )
      }

      return (
        <ItemColumns>
          <StyledActivitySecurityIcon transparent />
          <ActivityText>
            {intl.formatMessage({ id: messageId })}
            {getLocationMessage()}
          </ActivityText>
        </ItemColumns>
      )
    }

    if (!isEmpty(error)) {
      return <></>
    } else if (items?.length) {
      const sortedAndSliced = items.slice(0, numberOfItems)
      return sortedAndSliced.map((item: ActivityItem) => {
        return (
          <ActivityItemContainer key={item.id}>
            {getActivityMessage(item)}
            <MessageSecondary>
              {getFormattedTime(item.timestamp, intl, isMaxMediumV2)}
            </MessageSecondary>
          </ActivityItemContainer>
        )
      })
    }
  }, [error, items, intl, isMaxMediumV2, numberOfItems])

  // Skeleton Loader
  if (!items?.length && (!isDoneFetching || isLoading)) {
    return (
      <Section $omitMarginTop>
        <SectionHeader>
          <FormattedMessage id="ACTIVITY_HEADER" />
        </SectionHeader>
        <SkeletonLoader>
          {[...Array(numberOfItems)].map((_, index) => (
            <ActivityItemContainer key={index.toString()}>
              <ItemColumns>
                <ActivityText>
                  <Block width={200} height={20} />
                </ActivityText>
              </ItemColumns>
              <MessageSecondary>
                <Block width={100} height={20} />
              </MessageSecondary>
            </ActivityItemContainer>
          ))}
        </SkeletonLoader>
      </Section>
    )
  }

  // Empty State
  if (!items?.length) {
    return (
      <Section $omitMarginTop>
        <SectionHeader>
          <FormattedMessage id="ACTIVITY_HEADER" />
        </SectionHeader>
        <Message>
          <FormattedMessage id="ACTIVITY_EMPTY_STATE" />
        </Message>
      </Section>
    )
  }

  // Items
  return (
    <Section $omitMarginTop>
      <SectionHeader>
        <FormattedMessage id="ACTIVITY_HEADER" />
      </SectionHeader>
      {activities}
    </Section>
  )
}

const ActivityItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${theme.media.mediumV2}) {
    margin-top: 8px;
    flex-direction: column;
    align-items: flex-start;
  }
  :not(:last-child) {
    padding-bottom: 8px;
  }
`

const ActivityText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.text2};
  gap: 4px;
  @media (max-width: ${theme.media.mediumV2}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Message = styled.div`
  font: ${typography['desktop-body']};
  color: ${({ theme }) => theme.text2};
  display: flex;
  flex-direction: column;
`

const MessageSecondary = styled(Message)`
  color: ${({ theme }) => theme.text3};
  @media (max-width: ${theme.media.mediumV2}) {
    display: flex;
    margin-left: 24px;
  }
`

const LocationContainer = styled.div`
  display: flex;
  margin-left: 8px;
  @media (max-width: ${theme.media.mediumV2}) {
    margin-bottom: 4px;
  }
`

const StyledActivitySecurityIcon = styled(AccessPolicyIcon)`
  margin-right: 4px;
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.text1};
`

const StyledActivitySignInIcon = styled(MapsIcon)`
  margin-right: 4px;
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.text1};
`

const ItemColumns = styled.div`
  display: flex;
  align-items: flex-start;
`

const StyledTooltip = styled(Tooltip)`
  color: ${({ theme }) => theme.ublue06};
`
