import { DateTime } from 'luxon'

export const getIsChatOpen = (date = DateTime.now()) => {
  /* Chat is closed between Saturday 3am and Monday 3am Stockholm (CET) */
  const dateCET = date.setZone('Europe/Stockholm')
  const weekdayCET = dateCET.weekday
  const hourCET = dateCET.hour
  const isMonday = weekdayCET === 1
  const isSunday = weekdayCET === 7
  const isSaturday = weekdayCET === 6

  if ((isMonday && hourCET < 3) || isSunday || (isSaturday && hourCET >= 3)) {
    return false
  }
  return true
}
