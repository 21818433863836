import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'theme/styled'
import { MFAAuthenticatorStatus } from 'enums'
import {
  MFAAuthenticator,
  selectHasPendingMFAAuthenticators,
  selectMFAAuthenticators,
} from 'modules/mfa'
import { selectProfileDefaultMFA, updateProfileData } from 'modules/profile'

import { MultiFactorMethods } from './MultiFactorMethods'
import { DownloadUIVerify } from './DownloadUIVerify'

const Wrapper = styled.div`
  margin-bottom: 16px;
`

export const MultiFactorContent: React.FC = () => {
  const MFAauthenticators = useSelector(selectMFAAuthenticators)
  const defaultMFAId = useSelector(selectProfileDefaultMFA)
  const dispatch = useDispatch()
  const hasPendingAuthinticators = useSelector(
    selectHasPendingMFAAuthenticators
  )

  const activeMFAAuthenticators = useMemo<MFAAuthenticator[]>(
    () =>
      MFAauthenticators.filter(
        ({ status }) => status === MFAAuthenticatorStatus.ACTIVE
      ),
    [MFAauthenticators]
  )

  useEffect(() => {
    if (!defaultMFAId && activeMFAAuthenticators?.length === 1) {
      dispatch(
        updateProfileData({
          default_mfa: activeMFAAuthenticators[0].id,
        })
      )
    }
  }, [activeMFAAuthenticators, defaultMFAId, dispatch])

  return (
    <>
      {activeMFAAuthenticators?.length ? (
        <MultiFactorMethods activeMFAAuthenticators={activeMFAAuthenticators} />
      ) : (
        <>
          {hasPendingAuthinticators ? (
            <Wrapper>
              <MultiFactorMethods
                activeMFAAuthenticators={activeMFAAuthenticators}
              />
            </Wrapper>
          ) : null}
          <DownloadUIVerify />
        </>
      )}
    </>
  )
}
