import { FullSubscription } from 'features/subscriptions/fullSubscriptions/fullSubscriptions'
import { SubscriptionItem } from '../../cells/types'

const getNumberOfDevices = (sub: FullSubscription | SubscriptionItem) => {
  const numberOfDevices = sub.plan?.nickname?.match(/(\d+)/)
  if (numberOfDevices) return numberOfDevices[0]
  else return '-'
}

export default getNumberOfDevices
