import React, { useEffect, useMemo, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import { Button } from '@ubnt/ui-components'
import {
  AccountMenu,
  AccountMenu_WithMessageList,
  Avatar_Announcement,
  useAnnouncementAnimation,
} from '@ubnt/ui-components/aria'

import { ProfileData, RootState } from 'types/types'
import { selectProfileData } from 'modules/profile'
import {
  GreyButton,
  PopoverOption,
  SignOutButton,
} from 'features/user-popover/ui/UserPopover.styles'
import { ROUTES, Route } from 'types/routes'
import { useExternalLink } from 'utils/useExternalLink'
import {
  fetchAnnouncementsDone,
  selectUnreadAnnouncements,
  setAnnouncementData,
} from 'features/announcements/module/announcements'
import { updateAnnouncement } from 'features/announcements/module/editAnnouncements'
import { getProfileNameToDisplay } from 'utils/getProfileNameToDisplay'
import { useHistory } from 'react-router-dom'
import { sessionStorageChatKey } from 'features/support/unifi/UniFiForm'
import { selectProfilePicture } from 'features/profile-picture/module/profilePicture'

export interface Announcement {
  id: string
  title: string
  description: string
  externalLink: string
}

interface Props {
  profile?: ProfileData | null
  profilePicture?: string
}

const {
  api: { account },
} = __CONFIG__

export const UserPopover: React.FC<Props> = ({ profile, profilePicture }) => {
  const history = useHistory()
  const unreadAnnouncements = useSelector(selectUnreadAnnouncements)
  const dispatch = useDispatch()
  const goToExternalLink = useExternalLink()
  const fetchDone = useSelector(fetchAnnouncementsDone)
  const [shouldAnimateIn] = useState(!fetchDone) // Set once and shouldnt change

  const { animate, display, animateIn, animateOut } = useAnnouncementAnimation(
    undefined,
    unreadAnnouncements.length && fetchDone ? 'announcement' : 'userAvatar'
  )

  useEffect(() => {
    if (unreadAnnouncements.length > 0 && shouldAnimateIn) animateIn()
    if (unreadAnnouncements.length === 0) animateOut()
  }, [unreadAnnouncements.length, shouldAnimateIn, animateIn, animateOut])

  const announcementsToShow = useMemo(() => {
    const markAsRead = (selectedId: string) => {
      const announcementsWithSelectedRead = unreadAnnouncements.map(
        (announcement) => {
          if (announcement.announcementId === selectedId)
            return { ...announcement, isRead: true }
          return announcement
        }
      )
      dispatch(setAnnouncementData({ data: announcementsWithSelectedRead }))
      dispatch(updateAnnouncement(selectedId))
    }
    const actionButtons = (announcement: Announcement) => [
      <GreyButton
        key={announcement.id.toString()}
        variant="inline"
        type="button"
        onClick={() => markAsRead(announcement.id)}
      >
        <FormattedMessage id="COMMON_DESIGN_CLEAR" />
      </GreyButton>,
      <Button
        key={announcement.id.toString()}
        variant="inline"
        onClick={() => {
          markAsRead(announcement.id)
          goToExternalLink(announcement.externalLink)
          // TODO: Check if we want to keep this tracking
          window.gtag?.('event', 'announcement_click')
        }}
      >
        <FormattedMessage id="COMMON_DESIGN_SHOW_ME" />
      </Button>,
    ]
    return unreadAnnouncements?.map((announcement, index) => {
      let headerImg = undefined

      if (announcement.media.image && index === 0) {
        headerImg = (
          <img
            style={{ width: '100%', objectFit: 'cover' }}
            src={announcement.media.image}
          />
        )
      }
      return (
        {
          ...announcement,
          showIndicator: true,
          actionButtons: actionButtons(announcement),
          headerImg,
        } || []
      )
    })
  }, [unreadAnnouncements, goToExternalLink, dispatch])

  const signOut = () => {
    sessionStorage.removeItem(sessionStorageChatKey)
    history.push(account.paths.logout)
  }

  return (
    <AccountMenu.Root>
      <AccountMenu.Trigger>
        <Avatar_Announcement
          image={profilePicture}
          alt="User avatar"
          animate={animate}
          display={display}
          notices={unreadAnnouncements.length ?? 0}
        />
      </AccountMenu.Trigger>
      <AccountMenu_WithMessageList
        image={profilePicture}
        alt="User avatar"
        actionButtons={[
          <SignOutButton
            id="signOut"
            key="signOut"
            size="medium"
            onClick={signOut}
          >
            <FormattedMessage id="COMMON_AUTH_ACTION_SIGN_OUT" />
          </SignOutButton>,
        ]}
        emailAddress={profile?.email}
        messages={announcementsToShow}
        footerLinks={[
          <PopoverOption href={ROUTES[Route.TermsAndConditions]}>
            <FormattedMessage id="COMMON_LABEL_TERMS_AND_CONDITIONS" />
          </PopoverOption>,
          <PopoverOption href={ROUTES[Route.PrivacyPolicy]}>
            <FormattedMessage id="COMMON_LABEL_PRIVACY_POLICY" />
          </PopoverOption>,
        ]}
        name={getProfileNameToDisplay(profile)}
      />
    </AccountMenu.Root>
  )
}

const mapStateToProps = (state: RootState) => ({
  profile: selectProfileData(state),
  profilePicture: selectProfilePicture(state),
})

export const UserPopoverConnected = connect(mapStateToProps)(UserPopover)
