import React, { useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { ClassNames } from '@emotion/react'
import Text from '@ubnt/ui-components/Text/Text'
import Popover from '@ubnt/ui-components/Popover/Popover'
import Loader from '@ubnt/ui-components/Loader/Loader'
import { AddWithCircleIcon, ArrowDownPrimaryIcon, CheckIcon } from '@ubnt/icons'

import {
  CustomerDefaults,
  selectDefaultSource,
} from 'features/stripe/modules/stripeCustomer'
import { getPaymentMethodIcon } from 'features/payment-methods/ui/utils'
import styled from 'theme/styled'
import { selectCustomerCards } from 'features/payment-methods/modules/fetchCards'
import usePrevious from 'utils/usePrevious'

import { FullSubscription } from '../fullSubscriptions/fullSubscriptions'
import { selectIsSubscriptionsUpdateLoading } from '../module/subscriptionsUpdate'
import { useSubscriptionPaymentMethods } from './hooks/useSubscriptionPaymentMethod'

const CardsPopover: React.FC<{
  subscription: FullSubscription
  popoverOpen: number | null
  setPopoverOpen: (value: number | null) => void
  id: number
  disabled?: boolean
  isTalk?: boolean
}> = ({
  subscription,
  popoverOpen,
  setPopoverOpen,
  id,
  disabled = false,
  isTalk = false,
}) => {
  const cards = useSelector(selectCustomerCards)
  const defaultSourceId = useSelector(selectDefaultSource)
  const { handlePayInvoice, handleCardChange, addPaymentMethod } =
    useSubscriptionPaymentMethods()
  const isLoading = useSelector(selectIsSubscriptionsUpdateLoading)
  const isFailed = subscription.isFailed

  const defaultCard = useMemo(() => {
    const { region } = subscription
    const regionDefault =
      defaultSourceId && defaultSourceId[region as keyof CustomerDefaults]
    return regionDefault
      ? cards.find((card) => card.id === regionDefault)
      : undefined
  }, [cards, defaultSourceId, subscription])

  const selectedCardId = useMemo(
    () => subscription.default_source,
    [subscription]
  )

  const selectedCard = useMemo(
    () => cards.find((card) => card.id === selectedCardId),
    [cards, selectedCardId]
  )

  const originalySelectedCard = usePrevious(selectedCard)

  useEffect(() => {
    originalySelectedCard !== selectedCard && setPopoverOpen(null)
  }, [selectedCard, setPopoverOpen, originalySelectedCard])

  if ((subscription.isSuspended || !subscription.default_source) && !isTalk) {
    return (
      <ClassNames>
        {({ css }) => (
          <Popover
            open={popoverOpen === id}
            classNameCardWrap={'cardWrapper'}
            onChange={() => setPopoverOpen(popoverOpen === id ? null : id)}
            disabled={disabled}
            renderToggle={() => (
              <CardToggler disabled={disabled}>
                <CardOptionContainer>
                  <StyledImg src={getPaymentMethodIcon('Unknown')}></StyledImg>
                  <TextWrapper>
                    <StyledText>
                      <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_NO_CHOSEN_CARD" />
                    </StyledText>
                    <StyledArrowDownIcon $isDropdownOpen={popoverOpen === id} />
                  </TextWrapper>
                </CardOptionContainer>
              </CardToggler>
            )}
            classNameCard={css`
              padding: 0;
              width: 178px;
              .is-active {
                font-weight: bold;
              }
            `}
            align="bottomLeft"
          >
            <CardsContainer>
              {isLoading && <StyledLoader size="tiny" type="spinner" />}
              {cards
                .filter((card) => card.region === subscription.region)
                .map((card) => (
                  <Option
                    onClick={() => {
                      if (
                        subscription.status === 'past_due' ||
                        subscription.isSuspended
                      ) {
                        handlePayInvoice(subscription, card.id)
                      } else if (!subscription.default_source) {
                        handleCardChange(card.id, subscription)
                      }
                    }}
                    key={card.id}
                    isLoading={isLoading}
                  >
                    <div>
                      {defaultSourceId &&
                      Object.values(defaultSourceId).some(
                        (id) => id === card.id
                      ) ? (
                        <>
                          <StyledImg
                            src={getPaymentMethodIcon(
                              card.card?.brand || 'Unknown'
                            )}
                          ></StyledImg>
                          <DefaultCardText>
                            {`····${card.card?.last4} (Default)`}
                          </DefaultCardText>
                        </>
                      ) : (
                        <>
                          <StyledImg
                            src={getPaymentMethodIcon(
                              card.card?.brand || 'Unknown'
                            )}
                          ></StyledImg>
                          <StyledTextSmall>
                            {`····${card.card?.last4}`}
                          </StyledTextSmall>
                        </>
                      )}
                    </div>
                  </Option>
                ))}
              <Option
                onClick={() => addPaymentMethod(subscription.region)}
                isLoading={isLoading}
              >
                <AddWithCircleIcon width={16} isActive />
                <StyledTextSmall>
                  <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_POPOVER_ADD" />
                </StyledTextSmall>
              </Option>
            </CardsContainer>
          </Popover>
        )}
      </ClassNames>
    )
  } else if (selectedCard || isTalk) {
    return (
      <ClassNames>
        {({ css }) => {
          const cardToShow = isTalk ? defaultCard : selectedCard
          return (
            <Popover
              open={popoverOpen === id}
              classNameCardWrap={'cardWrapper'}
              disabled={disabled || isTalk}
              onChange={() => setPopoverOpen(popoverOpen === id ? null : id)}
              renderToggle={() => (
                <CardToggler disabled={disabled || isTalk}>
                  <CardOptionContainer>
                    <StyledImg
                      src={getPaymentMethodIcon(
                        cardToShow?.card?.brand || 'Unknown'
                      )}
                    ></StyledImg>
                    <TextWrapper>
                      <StyledText
                        isFailed={isFailed}
                      >{`····${cardToShow?.card?.last4}`}</StyledText>
                      {!isTalk && (
                        <StyledArrowDownIcon
                          $isDropdownOpen={popoverOpen === id}
                        />
                      )}
                    </TextWrapper>
                  </CardOptionContainer>
                </CardToggler>
              )}
              classNameCard={css`
                padding: 0;
                width: 178px;
                .is-active {
                  font-weight: bold;
                }
              `}
              align="bottomLeft"
            >
              <CardsContainer>
                {isLoading && <StyledLoader size="tiny" type="spinner" />}
                {cards
                  .filter((card) => card.region === subscription.region)
                  .map((card) => (
                    <Option
                      onClick={() => handleCardChange(card.id, subscription)}
                      key={card.id}
                      isLoading={isLoading}
                    >
                      {!selectedCard &&
                      defaultSourceId &&
                      Object.values(defaultSourceId).some(
                        (id) => id === card.id || card.id === selectedCardId
                      ) ? (
                        <CheckIcon size="original" className="checkIcon" />
                      ) : (
                        <></>
                      )}
                      {defaultSourceId &&
                      Object.values(defaultSourceId).some(
                        (id) => id === card.id
                      ) ? (
                        <>
                          <StyledImg
                            src={getPaymentMethodIcon(
                              card.card?.brand || 'Unknown'
                            )}
                          />
                          <DefaultCardText>
                            {`····${card.card?.last4} (Default)`}
                          </DefaultCardText>
                        </>
                      ) : (
                        <>
                          <StyledImg
                            src={getPaymentMethodIcon(
                              card.card?.brand || 'Unknown'
                            )}
                          />
                          <StyledTextSmall>
                            {`····${card.card?.last4}`}
                          </StyledTextSmall>
                        </>
                      )}
                    </Option>
                  ))}
                <Option
                  onClick={() => addPaymentMethod(subscription.region)}
                  isLoading={isLoading}
                >
                  <AddWithCircleIcon width={16} isActive />
                  <AddMethodText>
                    <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_POPOVER_ADD" />
                  </AddMethodText>
                </Option>
              </CardsContainer>
            </Popover>
          )
        }}
      </ClassNames>
    )
  } else {
    return <Loader />
  }
}

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const DefaultCardText = styled(Text)`
  font-weight: bold;
  font-size: 12px;
  color: ${({ theme }) => theme.text2};
`

const CardToggler = styled.span<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

const CardsContainer = styled.div`
  position: relative;
  max-height: 30vh;
  padding: 10px 0;
`

const StyledLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const StyledText = styled(Text, {
  shouldForwardProp: (props) => props !== 'isFailed',
})<{ isFailed?: boolean }>`
  font-size: 14px;
  margin: 0;
  color: ${({ isFailed, theme }) => (isFailed ? theme.red06 : theme.text2)};
`

const StyledTextSmall = styled(StyledText)`
  margin-left: 4px;
  font-size: 12px;
`
const AddMethodText = styled(StyledTextSmall)`
  font-size: 12px;
  margin: 0 0 0 4px;
  color: ${({ theme }) => theme.ublue06};
`

const StyledImg = styled.img`
  width: 24px;
  margin-right: 8px;
`

const Option = styled.div<{ isLoading?: boolean }>`
  color: ${({ theme }) => theme.text2};
  font-size: 14px;
  width: 100%;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  opacity: ${({ isLoading }) => (isLoading ? 0.3 : 1)};
  pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'auto')};
  :hover {
    background: ${({ theme }) => theme.neutral03};
  }
`

const CardOptionContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledArrowDownIcon = styled(ArrowDownPrimaryIcon, {
  shouldForwardProp: (props) => props !== '$isDropdownOpen',
})<{ $isDropdownOpen: boolean }>`
  width: 18px;
  height: 18px;
  margin-left: 4px;
  color: ${({ theme }) => theme.ublue06};
  transform: ${({ $isDropdownOpen }) =>
    $isDropdownOpen ? `rotate(180deg);` : `none`};
  transition: transform 0.3s;
`

export default CardsPopover
