import React, { Fragment, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash-es'
import { FormattedMessage } from 'react-intl'

import {
  selectArchievedDeviceBackups,
  selectBackupFetchErrors,
  selectDevicesBackups,
  selectFetchBackupsIsLoading,
} from 'features/backups/module/backups'
import {
  selectFetchingDevices,
  selectHasFetchedOnce,
} from 'features/devices/devices'
import BackupsHeader from 'features/backups/BackupsHeader'
import ProtectedPage from 'pages/ProtectedPage'
import WrappedSubmitPasswordModal from 'features/backups/SubmitPasswordModal'
import AppViewHeader from 'components/AppViewHeader'
import { ALL_KEY } from 'sharedConstants'
import { BackupsContext } from 'features/backups/BackupsContext'
import { BackupsErrorPlaceholder } from 'features/backups/BackupsErrorPlaceholder'
import { BackupsList } from 'features/backups/BackupsList'
import { DeviceBackups } from 'features/backups/types'
import { Section } from 'components/Section.styles'
import { StyledEmptyResult } from 'components/SharedComponents'
import { BackupsHeaderRemoved } from 'features/backups/BackupsHeaderRemoved'

import BackupsSkeleton from './BackupsSkeleton'
import { getSortingFunctionForBackupDevices } from './utils'

const Backups: React.FC = () => {
  const isDevicesLoading = useSelector(selectFetchingDevices)
  const isLoading = useSelector(selectFetchBackupsIsLoading)
  const errors = useSelector(selectBackupFetchErrors)
  const hasFetchedOnce = useSelector(selectHasFetchedOnce)
  const filteredDeviceBackups = useSelector(selectDevicesBackups)
  const removedDevicesBackups = useSelector(selectArchievedDeviceBackups)

  const {
    devicesPerPage,
    selectedDevice,
    currentPage,
    setCurrentPage,
    selectedSort,

    devicesPerPageRemoved,
    selectedDeviceRemoved,
    currentPageRemoved,
    setCurrentPageRemoved,
    selectedSortRemoved,
  } = useContext(BackupsContext)

  const displayedDevicesWithBackups = useMemo(() => {
    if (selectedDevice === ALL_KEY) {
      return filteredDeviceBackups
        ?.sort(getSortingFunctionForBackupDevices(selectedSort))
        .slice(devicesPerPage * (currentPage - 1), devicesPerPage * currentPage)
    }

    const foundDeviceBackup = filteredDeviceBackups?.find(
      ({ deviceId }: DeviceBackups) => selectedDevice === deviceId
    )

    return foundDeviceBackup ? [foundDeviceBackup] : []
  }, [
    filteredDeviceBackups,
    devicesPerPage,
    selectedSort,
    currentPage,
    selectedDevice,
  ])

  // Archieved Backups
  const displayedDevicesWithBackupsRemoved = useMemo(() => {
    if (selectedDeviceRemoved === ALL_KEY) {
      return removedDevicesBackups
        ?.sort(getSortingFunctionForBackupDevices(selectedSortRemoved))
        .slice(
          devicesPerPageRemoved * (currentPageRemoved - 1),
          devicesPerPageRemoved * currentPageRemoved
        )
    }

    const foundDeviceBackup = removedDevicesBackups?.find(
      ({ deviceId }: DeviceBackups) => selectedDeviceRemoved === deviceId
    )

    return foundDeviceBackup ? [foundDeviceBackup] : []
  }, [
    removedDevicesBackups,
    devicesPerPageRemoved,
    selectedSortRemoved,
    currentPageRemoved,
    selectedDeviceRemoved,
  ])

  const isPageLoading = useMemo(() => {
    return isLoading || isDevicesLoading || !hasFetchedOnce
  }, [isLoading, isDevicesLoading, hasFetchedOnce])

  const hasBackups = useMemo(() => {
    return filteredDeviceBackups?.length && isEmpty(errors)
  }, [errors, filteredDeviceBackups])

  const isSortFilterVisible = useMemo(() => {
    return filteredDeviceBackups?.length > 1 && selectedDevice === ALL_KEY
  }, [filteredDeviceBackups, selectedDevice])

  const handlePaginationClick = (page: number) => {
    setCurrentPage?.(page < 1 ? 1 : page)
  }

  const handlePaginationClickRemovedBackups = (page: number) => {
    setCurrentPageRemoved?.(page < 1 ? 1 : page)
  }

  const renderPageContent = () => {
    if (isPageLoading) {
      return <BackupsSkeleton />
    }

    if (
      (!filteredDeviceBackups?.length || !hasBackups) &&
      !removedDevicesBackups?.length
    ) {
      return isEmpty(errors) ? (
        <StyledEmptyResult
          description={<FormattedMessage id="SETTINGS_BACKUPS_EMPTY" />}
          size="large"
          style={{ marginTop: '24px' }}
        />
      ) : (
        <BackupsErrorPlaceholder />
      )
    }

    return (
      <Fragment>
        <Section $omitMarginTop>
          <BackupsHeader
            displaySort={isSortFilterVisible}
            devices={filteredDeviceBackups}
          />
          <BackupsList
            handlePaginationClick={handlePaginationClick}
            selectedDevice={selectedDevice}
            devicesWithBackupsToDisplay={displayedDevicesWithBackups}
            devicesWithBackups={filteredDeviceBackups}
            currentPage={currentPage}
          />
        </Section>
        {!!removedDevicesBackups?.length && (
          <Section $omitMarginTop>
            <BackupsHeaderRemoved removedDevices={removedDevicesBackups} />
            <BackupsList
              handlePaginationClick={handlePaginationClickRemovedBackups}
              selectedDevice={selectedDeviceRemoved}
              devicesWithBackupsToDisplay={displayedDevicesWithBackupsRemoved}
              devicesWithBackups={removedDevicesBackups}
              currentPage={currentPageRemoved}
              isArchived
            />
          </Section>
        )}
      </Fragment>
    )
  }

  return (
    <ProtectedPage renderMargin={true}>
      <AppViewHeader headerId="COMMON_SIDE_NAV_CLOUD_BACKUPS" />
      {renderPageContent()}
      <WrappedSubmitPasswordModal />
    </ProtectedPage>
  )
}

export default Backups
