import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import type Stripe from 'stripe'

import { selectFullSubscriptions } from 'features/subscriptions/fullSubscriptions/fullSubscriptions'
import { StripeInvoice } from 'features/subscriptions/module/types'
import { media, typography } from 'theme'
import styled from 'theme/styled'

import { InvoiceIdentifier } from './InvoiceIdentifier'
import { getSubscriptionForInvoice } from './utils'

const hasDetails = (
  charge: string | Stripe.Charge | null
): charge is Stripe.Charge => {
  return (charge as Stripe.Charge)?.billing_details?.address !== undefined
}

const renderField = (field?: string | null, hideComma?: boolean): string => {
  if (field) {
    return hideComma ? `${field}` : `${field}, `
  }
  return hideComma ? '-' : '-, '
}

const InvoiceIssuingInfo: React.FC<{
  invoice: StripeInvoice
  siteName: string
}> = ({ invoice, siteName }) => {
  const fullSubscriptions = useSelector(selectFullSubscriptions)
  const fullSubscriptionForInvoice = getSubscriptionForInvoice(
    invoice,
    fullSubscriptions
  )

  return (
    <Wrapper>
      <Info>
        <Title>
          <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_INVOICE_ISSUED" />
        </Title>
        <Content>{invoice.customer_email}</Content>
        {hasDetails(invoice.charge) && (
          <>
            <Content>
              {renderField(invoice.charge?.billing_details?.address?.line1)}
            </Content>
            <Content>
              <span>
                {renderField(invoice.charge?.billing_details?.address?.city)}
              </span>
              <span>
                {renderField(
                  invoice.charge?.billing_details?.address?.postal_code
                )}
              </span>
              <span>
                {renderField(
                  invoice.charge?.billing_details?.address?.country,
                  true
                )}
              </span>
            </Content>
          </>
        )}
      </Info>

      <Info>
        <InvoiceIdentifier subscription={fullSubscriptionForInvoice} />
      </Info>

      {invoice.customer_tax_ids?.[0]?.value && (
        <Info>
          <Title>
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_INVOICE_TAX_ID" />
          </Title>
          <Content>{invoice.customer_tax_ids[0].value}</Content>
        </Info>
      )}

      {siteName && (
        <Info>
          <Title>
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_INVOICE_SITE_NAME" />
          </Title>
          <Content>{siteName}</Content>
        </Info>
      )}
    </Wrapper>
  )
}

export default InvoiceIssuingInfo

const Wrapper = styled.section`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  gap: 64px;

  @media (max-width: ${media.mediumV2}) {
    gap: 24px;
  }

  @media (max-width: ${media.tablet}) {
    margin-top: 8px;
    justify-content: space-between;
    flex-direction: column;
    gap: 8px;
  }
`

const Info = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  font: ${typography['desktop-body']};
  text-align: left;

  @media (max-width: ${media.tablet}) {
    margin-right: 4px;
  }
`

const Content = styled(Title)`
  color: ${({ theme }) => theme.text0};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 224px;
`
