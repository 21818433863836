import { all, put, spawn, takeEvery } from 'redux-saga/effects'
import type Stripe from 'stripe'
import { createSelector } from 'reselect'

import { closeVisibleModal } from 'modules/modals'
import createDataModule from 'utils/moduleCreator'
import { ReduxAction } from 'types/types'
import { fetchCards } from 'features/payment-methods/modules/fetchCards'
import { CHECK_AUTH } from 'features/auth/modules/auth'
import { ADD_CARD_DONE } from 'features/payment-methods/modules/addCard'
import { REMOVE_CARD_DONE } from 'features/payment-methods/modules/removeCard'

import type { StripeRegionCode } from '../ui/types'

const {
  api: { billing },
} = __CONFIG__

export const customerDataKey = 'stripeCustomer'

export interface CustomerData {
  userId?: string
  customerId?: string
  createdAt?: string
  region?: string
  stripeData?: Stripe.Customer
}

export type GlobalCustomerData = {
  [key in StripeRegionCode]?: CustomerData
}

export type CustomerDefaults = {
  [key in StripeRegionCode]?: string | Stripe.CustomerSource
}

const dataModule = createDataModule<GlobalCustomerData | undefined>(
  customerDataKey,
  billing.paths.customer,
  billing.base
)

export const {
  fetch: fetchCustomer,
  selectIsFetchLoading: selectFetchCustomerIsLoading,
  selectData: selectCustomerData,
  selectIsLoading: selectIsCustomerLoading,

  reducer: stripeCustomerReducer,
} = dataModule

export const selectDefaultSource = createSelector(
  dataModule.selectData,
  (customerData) => {
    if (!customerData) return undefined
    const customerRegions = Object.keys(customerData)
    if (!customerRegions.length) return undefined

    const userDefaults: CustomerDefaults = {}

    customerRegions.forEach((region) => {
      userDefaults[region as keyof CustomerDefaults] =
        customerData[region as keyof GlobalCustomerData]?.stripeData
          ?.default_source ?? undefined
    })

    return userDefaults
  }
)

function* fetchStripeDataSaga({ meta = {} }: ReduxAction) {
  if (meta && meta['redux-pack/LIFECYCLE'] !== 'success') {
    return null
  }

  yield put(fetchCustomer())
  yield put(fetchCards())
}

function* subscribeToStripeCustomerSagas() {
  yield takeEvery(CHECK_AUTH, fetchStripeDataSaga)
}

function* refreshCustomerSaga() {
  yield put(fetchCustomer())
  yield put(closeVisibleModal())
}

function* subscribeToRefreshCustomerSaga() {
  yield takeEvery([ADD_CARD_DONE, REMOVE_CARD_DONE], refreshCustomerSaga)
}

export function* stripeCustomerRootSaga() {
  yield all([
    spawn(dataModule.rootSaga),
    spawn(subscribeToStripeCustomerSagas),
    spawn(subscribeToRefreshCustomerSaga),
  ])
}
