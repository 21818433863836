import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { CheckmarkIcon } from '@ubnt/icons'

import { RoundFlag } from 'components/RoundFlag'
import { StripeRegionCode } from 'features/stripe/ui/types'

import { regionsWithMultipleCountries } from 'features/stripe/config'
import { CardType } from '../modules/types'
import { getPaymentMethodIcon } from './utils'
import {
  Container,
  ContentWrapper,
  DefaultText,
  Description,
  Footer,
  FooterElement,
  HeaderContainer,
  ImageWrap,
  MethodIcon,
  StyledButton,
  Title,
} from './Payments.styles'

interface Props {
  card: CardType
  isLoading?: boolean
  isDefault?: boolean
  displayFlag?: boolean
  talkRegions: Partial<Record<StripeRegionCode, boolean>>
  handleEdit: () => void
  handleRemove: () => void
  handleSetDefault: () => void
}

const PaymentMethodInfo: React.FC<Props> = ({
  card: fullCard,
  isDefault,
  displayFlag,
  talkRegions,
  handleEdit,
  handleRemove,
  handleSetDefault,
}) => {
  const {
    region,
    owner: { name },
    card,
  } = fullCard

  const expirationDate = useMemo(() => {
    const year = card?.exp_year.toString().slice(-2)
    const month = card?.exp_month.toString()
    return month && month.length < 2 ? `0${month}/${year}` : `${month}/${year}`
  }, [card?.exp_month, card?.exp_year])

  const onDefaultClick = () => {
    if (isDefault) {
      return
    }

    handleSetDefault()
  }

  const hasConnectedTalkSubscription = talkRegions[region] && isDefault

  const cardCountry =
    card?.country && regionsWithMultipleCountries.includes(region)
      ? card.country
      : region

  return (
    <Container>
      <ContentWrapper>
        <HeaderContainer>
          {displayFlag && <RoundFlag countryCode={cardCountry} noMargin />}
          <Title>
            {card?.brand} ••••{card?.last4}
          </Title>
        </HeaderContainer>
        <Description>{name}</Description>
        <Description>
          <FormattedMessage id="SETTINGS_PAYMENTS_CARD_EXPIRY" /> {''}
          {expirationDate}
        </Description>
        <ImageWrap>
          <MethodIcon src={getPaymentMethodIcon(card?.brand)} />
        </ImageWrap>
      </ContentWrapper>

      <Footer>
        <FooterElement>
          {isDefault ? (
            <DefaultText>
              <CheckmarkIcon variant="fill" isActive size="original" />
              <FormattedMessage id="SETTINGS_PAYMENTS_DEFAULT" />
            </DefaultText>
          ) : (
            <StyledButton variant="inline" onClick={onDefaultClick}>
              <FormattedMessage id="SETTINGS_PAYMENTS_SET_DEFAULT_BUTTON" />
            </StyledButton>
          )}
        </FooterElement>

        <FooterElement>
          <StyledButton variant="inline" onClick={handleEdit}>
            <FormattedMessage id="COMMON_ACTION_EDIT" />
          </StyledButton>

          {!hasConnectedTalkSubscription && (
            <StyledButton variant="inline" onClick={handleRemove}>
              <FormattedMessage id="COMMON_ACTION_REMOVE" />
            </StyledButton>
          )}
        </FooterElement>
      </Footer>
    </Container>
  )
}

export default PaymentMethodInfo
