import {
  selectIsFetchingUmrDevices,
  umrDevices,
} from 'features/devices/umrDevices'
import { useSelector } from 'react-redux'

export const useGetUmrDevices = () => {
  const consoles = useSelector(umrDevices)
  const isConsolesLoading = useSelector(selectIsFetchingUmrDevices)

  return { consoles, isConsolesLoading }
}
