import React, { useMemo } from 'react'
import { DropdownOption } from '@ubnt/ui-components/Dropdown'

import { ALL_KEY, ALL_LABEL, BASE_KEY } from 'sharedConstants'

import { DeviceBackupsArchived, DeviceBackupsWithShadow } from './types'
import { FilterContainer, StyledDropdown } from './Backups.styles'
import DropdownIcon from './DropdownIcon'
import { cleanHardwareShortname } from './utils'

const ALL_FILTER_VALUE = {
  label: ALL_LABEL,
  value: ALL_KEY,
  labelClassName: 'allOptionClassName',
}

interface IBackupsFilterProps {
  devices: DeviceBackupsWithShadow[] | DeviceBackupsArchived[]
  selected: string
  onChange: (option: string) => void
}

export const BackupsFilter: React.FC<IBackupsFilterProps> = ({
  devices,
  selected,
  onChange,
}) => {
  const DROPDOWN_OPTIONS: DropdownOption[] = useMemo(() => {
    const deviceOptions = devices
      .sort((a, b) => {
        const nameA = a.name?.toString()
        const nameB = b.name?.toString()

        return nameA.localeCompare(nameB, undefined, {
          numeric: true,
          sensitivity: BASE_KEY,
        })
      })
      .map((device) => ({
        image: (
          <DropdownIcon
            name={
              'shadow' in device
                ? device.shadow.state?.reported?.hardware?.shortname
                : cleanHardwareShortname(
                    device.backups?.[0]?.metadata?.hardware_shortname
                  )
            }
          />
        ),

        label: device.name,
        value: device.deviceId,
      }))

    return [ALL_FILTER_VALUE, ...deviceOptions]
  }, [devices])

  return (
    <FilterContainer>
      <StyledDropdown
        variant="secondary"
        searchable
        value={selected}
        placeholder="Filter by console or cloud"
        options={DROPDOWN_OPTIONS}
        optionContainerClassName="consoleDropdown-option"
        width="100%"
        onChange={({ value }: DropdownOption) => onChange(value as string)}
      />
    </FilterContainer>
  )
}
