import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { FullSubscription } from 'features/subscriptions/fullSubscriptions/fullSubscriptions'
import { setVisibleModal } from 'modules/modals'
import { ADD_PAYMENT_METHOD_MODAL_ID } from 'features/payment-methods/ui/AddPaymentMethodModal'
import { payInvoice } from 'features/subscriptions/module/invoicesUpdate'
import { CHOOSE_PAYMENT_METHOD_MODAL_ID } from 'features/payment-methods/ui/ChoosePaymentMethodModal'
import type { StripeRegionCode } from 'features/stripe/ui/types'

import { updateSubscription } from '../../module/subscriptionsUpdate'
import { useToasts } from './useToasts'

export const useSubscriptionPaymentMethods = () => {
  const dispatch = useDispatch()
  const { payInvoiceSuccessToast, payInvoiceErrorToast } = useToasts()

  const handleCardChange = useCallback(
    (value: string, fullSubscription: FullSubscription) => {
      const { id, region } = fullSubscription
      dispatch(
        updateSubscription(
          {
            id,
            default_source: value,
            region,
          },
          {
            pick: ['default_source'],
            onError: (error) => console.error(error),
          }
        )
      )
    },
    [dispatch]
  )

  const addPaymentMethod = useCallback(
    (region: StripeRegionCode) =>
      dispatch(
        setVisibleModal(ADD_PAYMENT_METHOD_MODAL_ID, {
          subscriptionRegion: region,
        })
      ),
    [dispatch]
  )

  const openChoosePaymentMethodModal = useCallback(
    (fullSubscription) =>
      dispatch(
        setVisibleModal(CHOOSE_PAYMENT_METHOD_MODAL_ID, {
          fullSubscription,
        })
      ),
    [dispatch]
  )

  const handlePayInvoice = useCallback(
    (fullSubscription: FullSubscription, source?: string) => {
      const { invoice, region, default_source, isSuspended } = fullSubscription
      dispatch(
        payInvoice(
          {
            id: invoice?.id,
            source: source || default_source,
            region: region,
          },
          {
            pick: isSuspended ? ['source'] : [],
            onSuccess: () => payInvoiceSuccessToast(isSuspended),
            onError: (data = {}) => payInvoiceErrorToast(data),
          }
        )
      )
    },
    [dispatch, payInvoiceErrorToast, payInvoiceSuccessToast]
  )

  return {
    handleCardChange,
    addPaymentMethod,
    handlePayInvoice,
    openChoosePaymentMethodModal,
  }
}
