import type Stripe from 'stripe'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/fullSubscriptions'
import { StripeInvoice } from 'features/subscriptions/module/types'

import { CurrencyRegion } from './types'

export const removeQuantityPrefix = (description: string): string => {
  /* Regex will match '1 × ' or '1 Byte × ' for example. \u00d7
  refers to the multiplication character */
  const regexPattern = /^[0-9]*\s(Byte\s)?\u00d7\s/g
  return description.replace(regexPattern, '')
}

const tierRegexPattern = /\(Tier/
const atRegexPattern = /\(at /
const lineRegexPattern =
  /line(s)?\s\u00d7\s/ /* Will look for line × or lines ×  */

const getCleanMeteredInvoices = (
  invoiceItems: Stripe.InvoiceLineItem[]
): Stripe.InvoiceLineItem[] => {
  /* Regex will look for '(Tier' in the subscription description to confirm
  if it's the initial invoice or not. The initial invoices do not have the keyword
  Tier and those should be zeroed out */
  let correctQuantity = 0
  const cleanItems: Stripe.InvoiceLineItem[] = []
  invoiceItems.map((item) => {
    if (!item.description) return
    const hasTierKeyword = tierRegexPattern.test(item.description)
    if (item.amount === 0 && !hasTierKeyword) {
      cleanItems.push(item)
    }
    if (item.amount === 0 && hasTierKeyword && item.quantity) {
      correctQuantity = item.quantity
    }
    if (item.amount !== 0 && item.quantity !== 0) {
      cleanItems.push(item)
    }
    if (item.amount !== 0 && item.quantity === 0) {
      item.quantity = correctQuantity
      cleanItems.push(item)
    }
  })
  return getCleanLineDescription(cleanItems)
}

const getCleanLineDescription = (
  invoiceItems: Stripe.InvoiceLineItem[]
): Stripe.InvoiceLineItem[] => {
  return invoiceItems.map((item) => {
    if (!item.description) return item
    if (tierRegexPattern.test(item.description)) {
      item.description = item.description.split(tierRegexPattern)[0]
    }
    if (atRegexPattern.test(item.description)) {
      item.description = item.description.split(atRegexPattern)[0]
    }
    if (lineRegexPattern.test(item.description)) {
      item.description = item.description.split(lineRegexPattern).reverse()[0]
    }
    return item
  })
}

export const getCleanInvoiceLines = (
  invoiceItems: Stripe.InvoiceLineItem[]
) => {
  const isMeteredUsage = invoiceItems.some((item) => {
    if (item.plan) {
      return item.plan.billing_scheme === 'tiered'
    }
  })
  if (!isMeteredUsage) {
    return getCleanLineDescription(invoiceItems)
  }
  return getCleanMeteredInvoices(invoiceItems)
}

export const getSubscriptionForInvoice = (
  invoice: StripeInvoice,
  fullSubscriptions: FullSubscription[]
) => {
  return fullSubscriptions.find(({ id }) => id === invoice.subscription)
}

export const getCurrencySymbol = (
  nameOrRegion: CurrencyRegion | string
): string => {
  switch (nameOrRegion) {
    case CurrencyRegion.GB:
    case CurrencyRegion.GBP:
      return '£'
    case CurrencyRegion.EU:
    case CurrencyRegion.EUR:
      return '€'
    default:
      return '$'
  }
}
