import { api } from 'api'
import createDataModule from 'utils/moduleCreator'

const {
  api: { sso },
} = __CONFIG__

export const dataKey = 'legal'

export interface LegalInfo {
  rev_privacy?: string
  rev_terms?: string
  rev_terms_aircrm?: string
}

const dataModule = createDataModule<LegalInfo>(
  'legal',
  sso.paths.legal,
  api.ssoBase
)

export const {
  fetch: fetchLegalInfo,

  selectData: selectLegalInfo,
  selectIsLoading: selectIsLegalLoading,

  rootSaga: legalRootSaga,
  reducer: legalReducer,
} = dataModule
