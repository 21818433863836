import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Input } from '@ubnt/ui-components'

import Yup from 'validators/yupLocaleConfig'
import styled from 'theme/styled'
import { selectVisibleModal } from 'modules/modals'
import { getErrorMessage } from 'utils/mfa'

import { GenericMFAModal } from '../GenericMFAModal'
import {
  initiatePasskey,
  selectInitiatePasskeyErrors,
} from './modules/setupPasskey'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`

export const PASSKEY_ADD_KEY_NAME_MODAL_ID = 'PASSKEY_ADD_KEY_NAME_MODAL_ID'

interface FormValues {
  key_name: string
}

export const PasskeyAddKeyNameModal: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const visibleModal = useSelector(selectVisibleModal)
  const initiatePassKeyErrors = useSelector(selectInitiatePasskeyErrors)

  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    setErrorMessage(
      getErrorMessage({
        apiError: initiatePassKeyErrors,
        defaultMessage: intl.formatMessage({
          id: 'GENERIC_ERROR_BOUNDARY_TITLE',
        }),
        fields: ['key_name', 'detail'],
      }) || ''
    )
  }, [initiatePassKeyErrors, intl])

  useEffect(() => setErrorMessage(''), [visibleModal])

  const handleOnSubmit = useCallback(
    ({ key_name }: FormValues) => {
      if (!key_name) {
        return
      }

      dispatch(initiatePasskey(key_name))
    },
    [dispatch]
  )

  const formik = useFormik<FormValues>({
    initialValues: {
      key_name: intl.formatMessage({
        id: 'SETTINGS_MFA_PASSKEY_ADD_KEY_NAME_DEFAULT_NAME',
      }),
    },
    onSubmit: handleOnSubmit,
    validationSchema: Yup.object().shape({
      key_name: Yup.string()
        .required()
        .min(1)
        .max(30)
        .label('SETTINGS_MFA_PASSKEY_MODAL_LABEL'),
    }),
  })

  const [inputElement, setInputElement] = useState<
    HTMLInputElement | undefined
  >()
  const handleRef = useCallback(
    (node: HTMLInputElement) => setInputElement(node),
    []
  )

  return (
    <GenericMFAModal
      modalId={PASSKEY_ADD_KEY_NAME_MODAL_ID}
      title={intl.formatMessage({
        id: 'SETTINGS_MFA_PASSKEY_MODAL_TITLE',
      })}
      description={intl.formatMessage({
        id: 'SETTINGS_MFA_PASSKEY_ADD_KEY_NAME_MODAL_DESCRIPTION',
      })}
      primaryButton={{
        text: intl.formatMessage({
          id: 'COMMON_ACTION_CONTINUE',
        }),
        onClick: () => {
          formik.handleSubmit()
        },
        disabled: !formik.values.key_name.length || !!formik.errors.key_name,
      }}
      onAfterClose={() => formik.resetForm()}
      focusElement={inputElement}
      size="small"
      overrideFullScreen
    >
      <Wrapper>
        <Input
          {...formik.getFieldProps('key_name')}
          ref={handleRef}
          type="text"
          label={intl.formatMessage({
            id: 'SETTINGS_MFA_PASSKEY_MODAL_LABEL',
          })}
          invalid={formik.errors.key_name || errorMessage}
          width="100%"
          variant="secondary"
        />
      </Wrapper>
    </GenericMFAModal>
  )
}
