import type Stripe from 'stripe'
import { DeleteNotAllowedType } from './ui/types'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/fullSubscriptions'

export const getPendingItemsType = (
  invoices: Stripe.Invoice[],
  fullSubscriptions: FullSubscription[]
): DeleteNotAllowedType | null => {
  const openInvoices = invoices.filter(
    (inv) => inv.status === 'open' || inv.status === 'draft'
  )

  if (openInvoices.length > 0) {
    return DeleteNotAllowedType.INVOICE
  }

  const activeSubscriptions = fullSubscriptions.filter(
    ({ isActive, cancelAtPeriodEnd }) => isActive && !cancelAtPeriodEnd
  )

  if (activeSubscriptions.length > 0) {
    return DeleteNotAllowedType.SUBSCRIPTION
  }

  return null
}
