import { all, put, spawn, takeEvery } from 'redux-saga/effects'
import { api } from 'api'
import createDataModule from 'utils/moduleCreator'
import { createSelector } from 'reselect'

const {
  api: { sso },
} = __CONFIG__

export const sessionDataKey = 'session'

const SessionActions = {
  fetch: `${sessionDataKey}/FETCH`,
  remove: `${sessionDataKey}/REMOVE`,
}

export interface Session {
  session_id: string
  user_agent: string | null
  device_model: string | null
  is_current_session: boolean
  is_trusted_device_session: boolean
  location: {
    city: string | null
    state: string | null
    country: string | null
    countryCode: string | null
  } | null
  ip: string | null
  isp: string | null
  device_id: string | null
  device_name: string | null
  extra_fields: {
    referer?: string | null
  }
  created: string
  simplified_agent: {
    agent: string | null
    is_ui_product: boolean
    os: string
    console?: {
      name?: string
      type?: string
      product_code?: string
    } | null
  }
}

export type SessionResponse = Session[]

const dataModule = createDataModule<SessionResponse>(
  sessionDataKey,
  sso.paths.sessions,
  api.ssoBase
)

export const {
  fetch: fetchSessions,
  remove: removeAllSessionsExceptCurrent,
  selectData: selectSessions,
  selectIsFetchLoading,
  REMOVE_DONE,
  reducer: sessionReducer,
} = dataModule

export const selectCurrentSessionLocation = createSelector(
  selectSessions,
  (sessions) => {
    if (!sessions?.length) return 'unknown'
    const currentSession = sessions.find(
      (session) => session.is_current_session
    )
    return currentSession?.location?.countryCode?.toLowerCase() || 'unknown'
  }
)

export const selectHasMoreThanCurrentSession = createSelector(
  selectSessions,
  (sessions): boolean => sessions?.length > 1
)

export const removeSession = (id: string) => {
  return { type: SessionActions.remove, urlPath: `/${id}` }
}

function* removeSessionDoneSaga() {
  yield put(fetchSessions())
}

function* subscribeToRemoveDoneSaga() {
  yield takeEvery(REMOVE_DONE, removeSessionDoneSaga)
}

export function* sessionRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToRemoveDoneSaga)])
}
