import React, { useCallback, useContext, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '@ubnt/ui-components/Button/Button'
import { BasicToast, ToastContext } from '@ubnt/ui-components/Toast'

import styled from 'theme/styled'
import theme from 'theme'
import ExternalLink from 'components/ExternalLink'
import { uiLinks } from 'redirectLinks'

type Props = {
  isFormLoading?: boolean
  isFormValid: boolean
  errorMessage?: string
  startChat?: () => void
  hideButton?: boolean
  isInActiveChat?: boolean
  isAttachmentUploading: boolean
}

const STRICT_ERROR_MESSAGE_ID =
  'SUPPORT_SUBMIT_FAILED_TOAST_DESCRIPTION_STRICT_MODE'
const RATE_LIMIT_ERROR_MESSAGE_ID =
  'SUPPORT_SUBMIT_FAILED_TOAST_DESCRIPTION_RATE_LIMIT'

export const Footer: React.FC<Props> = ({
  isFormLoading = false,
  isFormValid,
  errorMessage,
  startChat,
  hideButton,
  isInActiveChat,
  isAttachmentUploading,
}) => {
  const { createNotification, removeNotification } = useContext(ToastContext)
  const isBrowserBlockErrorMessage = errorMessage === STRICT_ERROR_MESSAGE_ID
  const isRateLimitErrorMessage = errorMessage === RATE_LIMIT_ERROR_MESSAGE_ID

  const getTitleId = useCallback((): string => {
    if (isBrowserBlockErrorMessage) {
      return 'SUPPORT_SUBMIT_FAILED_TOAST_DESCRIPTION_STRICT_MODE_TITLE'
    }
    if (isRateLimitErrorMessage) {
      return 'SUPPORT_SUBMIT_FAILED_TOAST_DESCRIPTION_RATE_LIMIT_TITLE'
    }

    return 'SUPPORT_SUBMIT_FAILED_TOAST_TITLE'
  }, [isBrowserBlockErrorMessage, isRateLimitErrorMessage])

  useEffect(() => {
    if (errorMessage) {
      createNotification(
        <BasicToast
          stateIndicator="danger"
          title={<FormattedMessage id={getTitleId()} />}
          details={
            <FormattedMessage
              id={errorMessage}
              {...(isRateLimitErrorMessage
                ? {
                    values: {
                      a: (text: string) => (
                        <ExternalLink href={uiLinks.helpUiRequests}>
                          {text}
                        </ExternalLink>
                      ),
                    },
                  }
                : {})}
            />
          }
        />
      )
    }
  }, [
    createNotification,
    errorMessage,
    getTitleId,
    isRateLimitErrorMessage,
    removeNotification,
  ])

  return (
    <FooterContainer>
      {startChat && (
        <Button
          variant="primary"
          onClick={startChat}
          disabled={!isFormValid || isInActiveChat || isAttachmentUploading}
          type="button"
        >
          <FormattedMessage id="SUPPORT_UNIFI_START_CHAT" />
        </Button>
      )}
      {hideButton ? null : (
        <Button
          variant="primary"
          type="submit"
          disabled={!isFormValid || isAttachmentUploading}
          loader={isFormLoading ? 'loading' : undefined}
        >
          <FormattedMessage id="SUPPORT_FOOTER_SUBMIT" />
        </Button>
      )}
    </FooterContainer>
  )
}

const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  margin-top: 32px;
  border-top: ${({ theme }) => `1px solid ${theme.neutral03}`};
  padding-top: 32px;

  @media (max-width: ${theme.media.mobileLarge}) {
    flex-direction: column;
    border-top: none;
    margin-top: 0;
    padding-top: 16px;

    div {
      width: 100%;
    }
  }
`
