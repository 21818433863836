import { createSelector } from 'reselect'
import createDataModule, { defaultInitialState } from 'utils/moduleCreator'

export type location = {
  place: string
  region: string
  region_code: string
  country: string
  country_code: string
}

export type DeviceUmrData = {
  stripe_id: string
  device: {
    name: string
    location?: location
  }
}

export type DeviceUmr = {
  data?: DeviceUmrData[]
}

const {
  api: { umr },
} = __CONFIG__

export const umrDataKey = 'deviceUmrData'

const initialState = {
  ...defaultInitialState,
}

const dataModule = createDataModule<DeviceUmr>(
  umrDataKey,
  umr.paths.subscriptions,
  umr.base,
  initialState
)

export const {
  fetch: fetchUmrInfo,
  selectData,
  rootSaga: deviceUmrInfoRootSaga,
  reducer: deviceUmrInfoReducer,
} = dataModule

export const selectDeviceUmrInfo = createSelector(selectData, (umrData) => {
  if (umrData.data) return umrData.data
  return []
})
