import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { WrappedComponentProps, injectIntl, useIntl } from 'react-intl'
import { Loader } from '@ubnt/ui-components/Loader'
import { ToastContext } from '@ubnt/ui-components/Toast'

import { RootState } from 'types/types'
import { selectVisibleModal, setVisibleModal } from 'modules/modals'
import { PaymentCardsBlock } from 'features/payment-methods/ui/paymentCards/PaymentCardsBlock'
import {
  CustomerDefaults,
  selectDefaultSource,
  selectFetchCustomerIsLoading,
  selectIsCustomerLoading,
} from 'features/stripe/modules/stripeCustomer'
import { validStripeRegionMap } from 'features/stripe/ui/regions'
import {
  selectIsCustomerUpdateLoading,
  selectUpdateCustomerData,
  updateCustomer,
} from 'features/stripe/modules/updateStripeCustomer'
import usePrevious from 'utils/usePrevious'
import cardOtherSVG from 'assets/svgs/cardOther.svg'
import { ALL_KEY } from 'sharedConstants'
import { selectOngoingTalkRegions } from 'features/subscriptions/fullSubscriptions/fullSubscriptions'

import { CardType } from '../modules/types'
import { StripeRegionContext } from '../../stripe/ui/Region'
import { resetAddCardErrors } from '../modules/addCard'
import {
  selectCustomerCards,
  selectIsFetchCardsLoading,
} from '../modules/fetchCards'
import PaymentMethodInfo from './PaymentMethodInfo'
import NotSupportedRegionModal, {
  CHANGE_REGION_MODAL_ID,
} from './ChangeRegionModal'
import RemovePaymentMethodModal, {
  DELETE_PAYMENT_METHOD_MODAL_ID,
} from './RemovePaymentMethodModal'
import EditPaymentMethodModal, {
  EDIT_PAYMENT_METHOD_MODAL_ID,
} from './EditPaymentMethodModal'
import { ADD_PAYMENT_METHOD_MODAL_ID } from './AddPaymentMethodModal'
import {
  LoaderContainer,
  StyledEntityToast,
  StyledPaymentMethodIcon,
} from './Payments.styles'

interface Props {
  cards: CardType[]
  hasMultipleCountries: boolean
  defaultSourceId?: CustomerDefaults
  addPaymentView?: boolean
  filter?: string
}

const pushDefaultsFirst = (a: CardType, b: CardType) =>
  !a.is_default && b.is_default ? 1 : -1

const PaymentMethods: React.FC<Props & WrappedComponentProps> = ({
  cards = [],
  defaultSourceId,
  addPaymentView = false,
  filter = ALL_KEY,
  hasMultipleCountries,
}) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const [settingDefault, setSettingDefault] = useState(null)
  const isSetToDefaultLoading = useSelector(selectIsCustomerLoading)
  const isFetchCustomerLoading = useSelector(selectFetchCustomerIsLoading)
  const isFetchCardsLoading = useSelector(selectIsFetchCardsLoading)
  const isDefaultCardLoading = useSelector(selectIsCustomerUpdateLoading)
  const changedDefaultMethod = useSelector(selectUpdateCustomerData)
  const visibleModal = useSelector(selectVisibleModal)
  const { region } = useContext(StripeRegionContext)
  const talkRegions = useSelector(selectOngoingTalkRegions)

  const stripeRegion = validStripeRegionMap.get(region)?.code

  const cardsToDisplay = useMemo(() => {
    const extendedCards = cards.map((card) => {
      const isDefault =
        defaultSourceId &&
        Object.values(defaultSourceId).some((id) => id === card.id)
      return { ...card, is_default: isDefault }
    })

    if (filter === ALL_KEY) return extendedCards.sort(pushDefaultsFirst)

    return extendedCards
      .filter((card) => card.card?.country?.toLowerCase() === filter)
      .sort(pushDefaultsFirst)
  }, [cards, defaultSourceId, filter])

  const toast = useContext(ToastContext)
  const previousDefaultMethod = usePrevious(changedDefaultMethod)

  const defaultMethodUpdatedSuccessfully =
    changedDefaultMethod &&
    previousDefaultMethod &&
    changedDefaultMethod !== previousDefaultMethod

  useEffect(() => {
    if (defaultMethodUpdatedSuccessfully) {
      toast.createNotification(
        <StyledEntityToast
          id="changedDefaultMethod"
          icon={<StyledPaymentMethodIcon src={cardOtherSVG} />}
          stateIndicator="success"
          onClose={() => {
            toast.removeNotification('changedDefaultMethod')
          }}
          title={intl.formatMessage({
            id: 'SETTINGS_PAYMENTS_UPDATE_METHOD_SUCCESS',
          })}
        />
      )
    }
  }, [changedDefaultMethod, defaultMethodUpdatedSuccessfully, intl, toast])

  useEffect(() => {
    if (settingDefault && !isSetToDefaultLoading) {
      setSettingDefault(null)
    }
  }, [isSetToDefaultLoading, settingDefault])

  const handleEditPayment = useCallback(
    (card) => {
      dispatch(resetAddCardErrors())
      dispatch(
        setVisibleModal(EDIT_PAYMENT_METHOD_MODAL_ID, {
          card,
          cardRegion: card.region,
        })
      )
    },
    [dispatch]
  )

  const handleRemovePayment = useCallback(
    (card) =>
      dispatch(
        setVisibleModal(DELETE_PAYMENT_METHOD_MODAL_ID, {
          card,
        })
      ),
    [dispatch]
  )

  const handleAddPayment = useCallback(
    (props?: any) => {
      if (!stripeRegion) {
        dispatch(
          setVisibleModal(CHANGE_REGION_MODAL_ID, {
            notSupported: true,
          })
        )
      }
      if (
        visibleModal.visibleModal !== 'ADD_PAYMENT_METHOD_MODAL_ID' &&
        stripeRegion
      ) {
        dispatch(resetAddCardErrors())
        dispatch(
          setVisibleModal(ADD_PAYMENT_METHOD_MODAL_ID, {
            ...props,
          })
        )
      }
    },
    [dispatch, stripeRegion, visibleModal.visibleModal]
  )

  useEffect(() => {
    if (
      addPaymentView &&
      visibleModal.visibleModal !== ADD_PAYMENT_METHOD_MODAL_ID
    ) {
      dispatch(resetAddCardErrors())
      dispatch(
        setVisibleModal(ADD_PAYMENT_METHOD_MODAL_ID, {
          addPaymentView: true,
        })
      )
    }
  }, [addPaymentView, visibleModal, dispatch, region])

  const setDefault = useCallback(
    (cardId, cardRegion) => {
      dispatch(updateCustomer({ default_source: cardId }, cardRegion))
      setSettingDefault(cardId)
    },
    [dispatch]
  )

  if (addPaymentView || isFetchCustomerLoading || isFetchCardsLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    )
  }

  const displayFlag = hasMultipleCountries && filter === ALL_KEY

  return (
    <>
      <PaymentCardsBlock
        onAddNewClick={handleAddPayment}
        addNewTitle={intl.formatMessage({
          id: 'SETTINGS_PAYMENTS_MODAL_TITLE_ADD',
        })}
        cardCount={cardsToDisplay?.length ?? 0}
      >
        {cardsToDisplay.map((card, index) => (
          <PaymentMethodInfo
            key={`${card?.id}-${index}`}
            handleEdit={() => handleEditPayment(card)}
            handleRemove={() => handleRemovePayment(card)}
            isLoading={isSetToDefaultLoading || isDefaultCardLoading}
            isDefault={card.is_default}
            card={card}
            talkRegions={talkRegions}
            handleSetDefault={() => setDefault(card.id, card.region)}
            displayFlag={displayFlag}
          />
        ))}
      </PaymentCardsBlock>
      <EditPaymentMethodModal />
      <RemovePaymentMethodModal />
      <NotSupportedRegionModal />
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  cards: selectCustomerCards(state),
  defaultSourceId: selectDefaultSource(state),
})

export default connect(mapStateToProps)(injectIntl(PaymentMethods))
