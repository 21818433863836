import { all, put, spawn, takeEvery } from 'redux-saga/effects'
import { api } from 'api'

import { MFAType, fetchMFA } from 'modules/mfa'
import createDataModule from 'utils/moduleCreator'
import { SSOAPIError } from 'types/types'
import { closeVisibleModal } from 'modules/modals'
import { MFAAuthenticatorStatus } from 'enums'

const {
  api: { sso },
} = __CONFIG__

export const removeMFAEmailDataKey = 'MFARemoveEmail'

const removeMFAEmailActions = {
  remove: `${removeMFAEmailDataKey}/REMOVE`,
}

type RemoveEmailState =
  | {
      email: string
      status: MFAAuthenticatorStatus
      last_success: string | null
      id: string
      created: string
      type: MFAType.email
    }
  | Record<string, never>

const dataModule = createDataModule<RemoveEmailState, SSOAPIError>(
  removeMFAEmailDataKey,
  `${sso.paths.mfa}/email`,
  api.ssoBase
)

export const {
  REMOVE_DONE,
  selectErrors: selectRemoveEmailErrors,
  resetErrors: resetRemoveEmailErrors,
} = dataModule

export const removeMFAEmail = (id: string, token?: string) => ({
  type: removeMFAEmailActions.remove,
  payload: { oneTimePassword: token },
  urlPath: `/${id}`,
})

function* removeMFAEmailDoneSaga() {
  yield put(fetchMFA())
  yield put(closeVisibleModal())
}

function* subscribeToRemoveEmailDoneSaga() {
  yield takeEvery(REMOVE_DONE, removeMFAEmailDoneSaga)
}

export function* removeEmailRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToRemoveEmailDoneSaga)])
}

export const removeMFAEmailReducer = dataModule.reducer
