import React, { createContext, useCallback, useEffect, useState } from 'react'
import { CssVariableProvider, Motif } from '@ubnt/ui-components'

const DEFAULT_MOTIF = 'light'

export const MotifContext = createContext<{
  motif: Motif
  setMotif?: (motif: Motif) => void
}>({
  motif: DEFAULT_MOTIF,
  setMotif: undefined,
})

export const MotifProvider: React.FC = ({ children }) => {
  const [motif, setMotif] = useState<Motif>(DEFAULT_MOTIF)

  // TODO: Later remove the following functionality, its for testing darkmode with hotkeys
  // ctrl + shift + t
  const matchesCombo = useCallback((keys: number[]) => {
    const keyCombos = ['16,17,84', '17,16,84']
    return keyCombos.includes(keys.join(','))
  }, [])

  const [activeKeys, setActiveKeys] = useState<number[]>([])

  const onUp = useCallback(() => {
    if (matchesCombo(activeKeys)) {
      setMotif?.(motif === 'light' ? 'dark' : 'light')
    }
    setActiveKeys([])
  }, [activeKeys, matchesCombo, motif])

  const onDown = useCallback(
    ({ keyCode }: KeyboardEvent) => {
      activeKeys.push(keyCode)
    },
    [activeKeys]
  )

  useEffect(() => {
    window.addEventListener('keydown', onDown)
    window.addEventListener('keyup', onUp)
    return () => {
      window.removeEventListener('keydown', onDown)
      window.removeEventListener('keyup', onUp)
    }
  }, [onDown, onUp])

  return (
    <MotifContext.Provider value={{ motif, setMotif }}>
      <CssVariableProvider motif={motif}>{children}</CssVariableProvider>
    </MotifContext.Provider>
  )
}
