import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import isString from 'lodash-es/isString'

import styled from 'theme/styled'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { selectIsEmployeeAccount } from 'modules/profile'
import {
  selectCustomerTaxId,
  selectIsFetchTaxIdDone,
} from 'features/payment-methods/modules/taxId/getTaxId'
import {
  editTaxId,
  selectIsUpdateTaxIdIsLoading,
} from 'features/payment-methods/modules/taxId/updateTaxId'
import {
  createTaxId,
  selectIsAddTaxIdLoading,
} from 'features/payment-methods/modules/taxId/addTaxId'

import { NewTaxIdSate, TaxIdState } from '../types'
import useTaxIdToast from './useTaxIdToast'
import useTaxIdErrors from './useTaxIdErrors'
import {
  assertIfTaxIdIsValid,
  deriveCountryFromValue,
  disableAddOrUpdatedButton,
  getValidationRegex,
} from './utils'
import TaxIdDropdown from './TaxIdDropdown'
import TaxIdInput from './TaxIdInput'

const TaxIdModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()

  const isFetchDone = useSelector(selectIsFetchTaxIdDone)
  const taxId = useSelector(selectCustomerTaxId)
  const isEmployee = useSelector(selectIsEmployeeAccount)

  const { createToast, toastType } = useTaxIdToast()
  const { clearAllErrors } = useTaxIdErrors()

  const [isTaxIdFormatValid, setIsTaxIdFormatValid] = useState(false)

  const [taxIdState, setTaxIdState] = useState<TaxIdState>({
    type: 'eu_vat',
    value: '',
    country: '',
    dropdownValue: '',
  })

  const validationRegex = useMemo(
    () => getValidationRegex(taxIdState.dropdownValue),
    [taxIdState.dropdownValue]
  )

  useEffect(() => {
    if (isFetchDone) {
      setTaxIdState({
        dropdownValue: deriveCountryFromValue(taxId?.value || ''),
        type: taxId?.type,
        value: taxId?.value || '',
        country: taxId?.country?.toLowerCase() || '',
      })
    }
  }, [isFetchDone, taxId?.country, taxId?.type, taxId?.value])

  useEffect(() => {
    const { isValidForEmployee, isTaxIdValid } = assertIfTaxIdIsValid(
      taxId?.value || '',
      validationRegex
    )
    setIsTaxIdFormatValid(isEmployee ? isValidForEmployee : isTaxIdValid)
  }, [isEmployee, taxId?.value, validationRegex])

  useEffect(() => {
    toastType && createToast(toastType)
  }, [createToast, toastType])

  const ActionButton = useMemo(
    () => disableAddOrUpdatedButton(taxId, taxIdState, isTaxIdFormatValid),
    [taxId, taxIdState, isTaxIdFormatValid]
  )

  const handleInputChange = (
    _e: React.ChangeEvent<HTMLInputElement>,
    value: string | number
  ) => {
    if (isString(value)) {
      const { isValidForEmployee, isTaxIdValid } = assertIfTaxIdIsValid(
        value,
        validationRegex
      )
      setTaxIdState({ ...taxIdState, value })
      setIsTaxIdFormatValid(isEmployee ? isValidForEmployee : isTaxIdValid)
    }
  }

  const handleDropdownChange = (newTaxIdState: NewTaxIdSate) => {
    setTaxIdState({
      ...taxIdState,
      ...newTaxIdState,
    })
  }

  const handleClick = useCallback(() => {
    const { type, value } = taxIdState || {}

    if (!type) return

    clearAllErrors()

    if (value) {
      dispatch(editTaxId({ type: type, value: value }))
      return
    }

    dispatch(createTaxId({ type: type, value: value }))
  }, [taxIdState, clearAllErrors, dispatch])

  const isUpdatingTaxIdLoading = useSelector(selectIsUpdateTaxIdIsLoading)
  const isAddingTaxIdLoading = useSelector(selectIsAddTaxIdLoading)

  const loader = useMemo(() => {
    if (isUpdatingTaxIdLoading || isAddingTaxIdLoading) {
      return 'dots'
    }
    return undefined
  }, [isAddingTaxIdLoading, isUpdatingTaxIdLoading])

  return (
    <StyledGenericModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={
        <FormattedMessage
          id={
            taxId?.value
              ? 'SETTINGS_PAYMENTS_TAX_ID_CHANGE'
              : 'SETTINGS_PAYMENTS_TAX_ID_ADD'
          }
        />
      }
      size="small"
      contentClassName="content"
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CLOSE" />,
          onClick: onClose,
        },
        {
          variant: 'primary',
          text: <FormattedMessage id="COMMON_ACTION_SAVE" />,
          onClick: handleClick,
          loader,
          disabled: ActionButton,
        },
      ]}
    >
      <ContentContainer>
        <TaxIdDropdown
          value={taxIdState.dropdownValue}
          onChange={handleDropdownChange}
        />
        <TaxIdInput
          taxIdState={taxIdState}
          onChange={handleInputChange}
          isTaxIdFormatValid={isTaxIdFormatValid}
        />
      </ContentContainer>
    </StyledGenericModal>
  )
}

export const TAX_ID_MODAL_ID = 'TAX_ID_MODAL_ID'

const WrappedTaxIdModal = () => (
  <ModalWrapper modalId={TAX_ID_MODAL_ID}>
    <TaxIdModal />
  </ModalWrapper>
)

export default WrappedTaxIdModal

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  overflow: visible;
`

const StyledGenericModal = styled(GenericModal)`
  .content {
    overflow: visible;
  }
  overflow: visible !important;
`
