import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash-es'
import { useDebouncedCallback } from 'use-debounce'
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js'
import {
  BasicToast,
  Button,
  Dropdown,
  Input,
  ToastContext,
} from '@ubnt/ui-components'

import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { RoundFlag } from 'components/RoundFlag'
import {
  fetchAddresses,
  selectFetchAddressIsLoading,
} from 'features/addresses/module/addresses'
import {
  addCard,
  resetAddCardErrors,
  selectCardsApiError,
  selectIsAddCardLoading,
  selectStripeCardError,
} from 'features/payment-methods/modules/addCard'
import { selectIsCustomerLoading } from 'features/stripe/modules/stripeCustomer'
import { StripeElements } from 'features/stripe/ui/StripeElements'
import {
  validStripeCountries,
  validStripeCountryMap,
  validStripeRegionMap,
} from 'features/stripe/ui/regions'
import { setVisibleModal } from 'modules/modals'
import { selectIsEmployeeAccount } from 'modules/profile'
import { sendWebKitMessage } from 'utils/sendWebKitMessage'
import Yup from 'validators/yupLocaleConfig'
import { ValidationError } from 'components/Input'
import { getFormattedAddress } from 'utils/getFormattedAddress'
import { useAutocomplete } from 'utils/useAutocomplete'
import { StripeCountryCode, StripeRegionCode } from 'features/stripe/ui/types'
import { MotifContext } from 'motif/MotifProvider'
import { useOutsideClick } from 'utils/useOutsideClick'

import { usStripeCountries } from 'features/stripe/ui/countries/usStripeCountries'
import { regionsWithMultipleCountries } from 'features/stripe/config'
import { StripeRegionContext } from '../../stripe/ui/Region'
import CardProviderOverlay from './CardProviderOverlay'
import { CHANGE_REGION_MODAL_ID } from './ChangeRegionModal'
import {
  AddressResult,
  AddressResultsContainer,
  AddressResultsInnerContainer,
  Country,
  CountryTooltip,
  ElementWrapper,
  HalfFields,
  InputWrapper,
  Label,
  SectionWrapper,
  StripeErrors,
  StripeInputWrapper,
  StyledCardCVCElement,
  StyledCardExpiryElement,
  StyledCardNumberElement,
  StyledModal,
  Title,
  Wrapper,
} from './Components'
import {
  cardCvcErrorMessage,
  cardExpiryErrorMessage,
  cardNumberErrorMessage,
  checkAddress,
  handleCaughtAddressError,
  stripeStyles,
  stripeStylesDark,
} from './utils'
import {
  PAST_EXPIRY,
  STRIPE_VALUE_COMPLETE,
  STRIPE_VALUE_EMPTY,
  STRIPE_VALUE_INCOMPLETE,
  STRIPE_VALUE_INVALID,
} from './common'

interface Props {
  addPaymentView?: boolean
  subscriptionRegion?: StripeRegionCode
}

enum CardFields {
  NUMBER = 'NUMBER',
  EXP = 'EXP',
  CVC = 'CVC',
}

const AddPaymentMethodForm: React.FC<
  Props & ModalProps & WrappedComponentProps
> = ({ addPaymentView = false, isOpen, onClose, intl, subscriptionRegion }) => {
  const dispatch = useDispatch()
  const isCardLoading = useSelector(selectIsAddCardLoading)
  const isCustomerLoading = useSelector(selectIsCustomerLoading)
  const addressIsLoading = useSelector(selectFetchAddressIsLoading)
  const isLoading = isCardLoading || isCustomerLoading || addressIsLoading
  const apiError = useSelector(selectCardsApiError)
  const stripeError = useSelector(selectStripeCardError)

  const [createError, setCreateError] = useState<string | null>(null)
  const [cardBrand, setCardBrand] = useState<string | undefined>(undefined)
  const [focusedCardElement, setFocusedCardElement] = useState<
    CardFields | undefined
  >(undefined)
  const [addressFocused, setAddressFocused] = useState(false)
  const [addressError, setAddressError] = useState<{
    [key: string]: string | string[]
  } | null>(null)
  const [active, setActive] = useState<number>(0)

  const stripe = useStripe()
  const elements = useElements()
  const { country, region } = useContext(StripeRegionContext)
  const isEmployee = useSelector(selectIsEmployeeAccount)
  const { createNotification, removeNotification } = useContext(ToastContext)
  const { motif } = useContext(MotifContext)

  const cardRegion = subscriptionRegion || region

  const selectedRegion = validStripeRegionMap.get(cardRegion)

  const userCountry = useMemo(() => {
    if (!selectedRegion?.code) {
      return null
    }
    // Since we default the region to be US when the user is in an unsupported stripe region we also need to confirm that the country the user
    // is in belongs to the US region. In cases where it does not, it means it's actually our default value and we should also default the country
    // to be the same as the region (the US)
    if (
      selectedRegion.code === StripeRegionCode.US &&
      !usStripeCountries.find((stripeCountry) => stripeCountry.code === country)
    ) {
      return selectedRegion.code
    }
    if (regionsWithMultipleCountries.includes(selectedRegion.code)) {
      return country
    }

    return selectedRegion.code
  }, [selectedRegion, country])

  const [cardCountry, setCardCountry] = useState<string | null>(userCountry)

  useEffect(() => {
    dispatch(fetchAddresses())
  }, [dispatch])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      country: userCountry?.toUpperCase() || 'US',
      line1: '',
      line2: '',
      city: '',
      state: '',
      postal_code: '',
      cardNumber: '',
      cardExpiry: '',
      cardCvc: '',
      cardCountry: '',
    },
    onSubmit: async (values) => {
      const { name, country, line1, line2, city, state, postal_code } = values
      try {
        await checkAddress(values)
      } catch (error) {
        handleCaughtAddressError(error, intl, setAddressError)
        return
      }
      if (!stripe || !elements) return
      const card = elements.getElement(CardNumberElement)

      if (!card) return
      const { source, error } = await stripe.createSource(card, {
        type: 'card',
        owner: {
          name,
          address: {
            country,
            line1,
            line2,
            city,
            state,
            postal_code,
          },
        },
      })

      if (
        source &&
        (source.card?.country?.toLowerCase() === userCountry || isEmployee)
      ) {
        dispatch(
          addCard(source.id, selectedRegion?.code || StripeRegionCode.US)
        )
      } else if (
        source?.card?.country &&
        !validStripeCountryMap.has(
          source.card.country.toLowerCase() as StripeCountryCode
        )
      ) {
        setCreateError(
          intl.formatMessage({ id: 'STRIPE_PAYMENT_ERROR_INVALID_REGION' })
        )
      } else if (
        source &&
        source.card?.country?.toLowerCase() !== selectedRegion
      ) {
        setCreateError(
          intl.formatMessage({ id: 'STRIPE_PAYMENT_ERROR_WRONG_REGION' })
        )
      } else if (error) {
        setCreateError(error.message || null)
      }
      formik.setSubmitting(false)
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required().label('SETTINGS_PAYMENTS_NAME_ON_CARD'),
      country: Yup.string().required().label('COMMON_LABEL_COUNTRY'),
      line1: Yup.string().required().label('COMMON_LABEL_STREET_ADDRESS'),
      city: Yup.string().required().label('COMMON_LABEL_CITY'),
      state: Yup.string().required().label('COMMON_LABEL_STATE'),
      postal_code: Yup.string().required().label('COMMON_LABEL_POSTAL_CODE'),

      cardNumber: Yup.string()
        .required()
        .matches(/^complete$/, 'Your card number is invalid')
        .label('SETTINGS_PAYMENTS_LABEL_CARD_NUMBER'),
      cardExpiry: Yup.string()
        .required()
        .matches(/^complete$/, 'Your Exipration date is invalid')
        .label('SETTINGS_PAYMENTS_LABEL_EXPIRATION_DATE'),
      cardCvc: Yup.string()
        .required()
        .matches(/^complete$/, 'Your CVC is invalid')
        .label('SETTINGS_PAYMENTS_LABEL_SECURITY_CODE'),
    }),
  })

  useEffect(() => {
    setCreateError(null)
  }, [formik.values])

  useEffect(() => {
    if (apiError === 'Duplicate card is not allowed.') {
      return setCreateError(
        intl.formatMessage({ id: 'STRIPE_PAYMENT_ERROR_DUPLICATED_CARD' })
      )
    }
    if (apiError === 'Invalid card country.') {
      return setCreateError(
        intl.formatMessage(
          { id: 'STRIPE_PAYMENT_ERROR_UNSUPORTED_CARD' },
          {
            region: selectedRegion?.name,
          }
        )
      )
    }
    if (stripeError) {
      return setCreateError(stripeError)
    }
    if (apiError) {
      return setCreateError(intl.formatMessage({ id: 'GENERIC_ERROR_MESSAGE' }))
    }
    setCreateError(null)
  }, [apiError, selectedRegion?.name, intl, stripeError])

  type StripeFormKey = 'cardNumber' | 'cardExpiry' | 'cardCvc' | 'cardCountry'

  const isCardFieldError = (key: StripeFormKey) => {
    return (
      (formik.touched[key] && formik.values[key] !== STRIPE_VALUE_COMPLETE) ||
      (formik.touched[key] && formik.values[key] === STRIPE_VALUE_INCOMPLETE) ||
      formik.values[key] === STRIPE_VALUE_INVALID
    )
  }

  const handleStripeChange = (
    e:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent
  ) => {
    setCreateError(null)
    const formikValues = [
      formik.values.cardNumber,
      formik.values.cardExpiry,
      formik.values.cardCvc,
      formik.values.cardCountry,
    ]
    const invalidErrors = [
      'invalid_number',
      'invalid_expiry_year',
      'invalid_expiry_year_past',
    ]
    const incompleteErrors = [
      'incomplete_number',
      'incomplete_cvc',
      'incomplete_expiry',
    ]
    if (!e.error && e.complete) {
      formik.setFieldValue(e.elementType, STRIPE_VALUE_COMPLETE)
    }
    if (!e.error && e.empty) {
      formik.setFieldValue(e.elementType, STRIPE_VALUE_EMPTY)
    }
    if (
      !e.error &&
      !e.complete &&
      !e.empty &&
      (formikValues.includes(STRIPE_VALUE_COMPLETE) ||
        formikValues.includes(STRIPE_VALUE_INVALID))
    ) {
      formik.setFieldValue(e.elementType, STRIPE_VALUE_INCOMPLETE)
    }
    if (e.error?.code && invalidErrors.includes(e.error.code)) {
      e.error.code === 'invalid_expiry_year_past'
        ? formik.setFieldValue(e.elementType, PAST_EXPIRY)
        : formik.setFieldValue(e.elementType, STRIPE_VALUE_INVALID)
    }
    if (e.error?.code && incompleteErrors.includes(e.error.code)) {
      formik.setFieldValue(e.elementType, STRIPE_VALUE_INCOMPLETE)
    }
  }

  const handleStripeElementTouch = (field: string) => {
    formik.setFieldTouched(field, true)
  }

  const [addressResultsVisible, setAddressResultsVisible] = useState(false)

  const { placesService, placePredictions, getPlacePredictions } =
    useAutocomplete()

  useEffect(() => {
    if (placePredictions?.length && formik.values.country === 'US') {
      setAddressResultsVisible(true)
    } else {
      setAddressResultsVisible(false)
      setActive(0)
    }
  }, [formik.values.country, placePredictions])

  const checkAddressDebounced = useDebouncedCallback(async () => {
    try {
      await checkAddress(formik.values)
      setAddressError(null)
    } catch (error) {
      handleCaughtAddressError(error, intl, setAddressError)
    }
  }, 200)

  useEffect(() => {
    if (addressFocused) {
      checkAddressDebounced()
    }
  }, [checkAddressDebounced, addressFocused, formik.values])

  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, () => {
    setAddressResultsVisible(false)
    setActive(0)
  })

  const handleAddressSelected = useCallback(
    (addressDetails: google.maps.places.PlaceResult) => {
      formik.setValues({
        ...formik.values,
        ...getFormattedAddress(addressDetails),
      })
      formik.setFieldTouched('postal_code', false)
    },
    [formik]
  )

  const handleKeyDown = useCallback(
    (event) => {
      if (!event?.key) {
        return
      } else if (event.key === 'Tab') {
        setAddressResultsVisible(false)
        setActive(0)
      } else if (event.key === 'Enter') {
        event.preventDefault()
        setAddressResultsVisible(false)
        setActive(0)
        placesService?.getDetails(
          {
            placeId: placePredictions[active]?.place_id,
            fields: ['address_component'],
          },
          (addressDetails) => {
            addressDetails && handleAddressSelected(addressDetails)
          }
        )
      } else if (event.key === 'ArrowDown') {
        setActive(
          active < placePredictions.length - 1
            ? active + 1
            : placePredictions.length - 1
        )
      } else if (event.key === 'ArrowUp') {
        setActive(active > 0 ? active - 1 : 0)
      }
    },
    [active, handleAddressSelected, placePredictions, placesService]
  )

  const handleClose = useCallback(() => {
    if (addPaymentView) {
      sendWebKitMessage('cancel')
    } else {
      onClose?.()
      dispatch(resetAddCardErrors())
    }
  }, [addPaymentView, dispatch, onClose])

  const handleChangeRegion = () => {
    dispatch(
      setVisibleModal(CHANGE_REGION_MODAL_ID, {
        notSupported: false,
      })
    )
  }

  useEffect(() => {
    if (createError) {
      createNotification(
        <BasicToast
          stateIndicator="danger"
          title={<FormattedMessage id="GENERIC_ERROR_MESSAGE" />}
          details={createError}
        />
      )
    }
  }, [createError, createNotification, removeNotification])
  const customStripeStyles = motif === 'dark' ? stripeStylesDark : stripeStyles

  const hasRegionMultipleCountries = useMemo(() => {
    if (!selectedRegion?.code || !cardCountry) {
      return false
    }
    return regionsWithMultipleCountries.includes(selectedRegion.code)
  }, [selectedRegion?.code, cardCountry])

  return (
    <StyledModal
      size="small"
      isOpen={isOpen}
      onRequestClose={() => handleClose()}
      appView={addPaymentView ? true : false}
      title={
        <FormattedMessage
          id="SETTINGS_PAYMENTS_MODAL_TITLE_ADD"
          tagName="div"
        />
      }
      actions={[
        {
          text: addPaymentView
            ? intl.formatMessage({ id: 'COMMON_ACTION_BACK' })
            : intl.formatMessage({ id: 'COMMON_ACTION_CANCEL' }),
          onClick: () => handleClose(),
          variant: 'default',
          disabled: isLoading || formik.isSubmitting,
        },
        {
          text: intl.formatMessage({ id: 'COMMON_ACTION_ADD' }),
          type: 'submit',
          variant: 'primary',
          onClick: formik.handleSubmit as any,
          disabled:
            isLoading ||
            formik.isSubmitting ||
            !formik.isValid ||
            !isEmpty(addressError),
          loader:
            isOpen && (isLoading || formik.isSubmitting)
              ? 'spinner'
              : undefined,
          style: { outline: 'none' },
        },
      ]}
    >
      <Wrapper>
        <SectionWrapper>
          <Title>
            <FormattedMessage id="SETTINGS_PAYMENTS_CARD_REGION" />
          </Title>
          {selectedRegion && (
            <InputWrapper>
              <CountryTooltip
                description={intl.formatMessage({
                  id: 'SETTINGS_PAYMENTS_CARD_REGION_TOOLTIP',
                })}
                isOpen={subscriptionRegion ? undefined : false}
                position="topRight"
                tooltipClassName="country-toolip"
              >
                <Country disabled={!!subscriptionRegion}>
                  <div>
                    <RoundFlag
                      countryCode={selectedRegion.code}
                      size="small"
                      noMargin
                    />
                    <span className="country-name">{selectedRegion.name}</span>
                  </div>
                  <Button
                    variant="link"
                    onClick={handleChangeRegion}
                    disabled={!!subscriptionRegion}
                  >
                    <FormattedMessage id="COMMON_ACTION_CHANGE" />
                  </Button>
                </Country>
              </CountryTooltip>
            </InputWrapper>
          )}
        </SectionWrapper>
        <SectionWrapper>
          <Title>
            <FormattedMessage id="SETTINGS_PAYMENTS_CARD_INFORMATION" />
          </Title>
          <StripeInputWrapper>
            <Label
              focus={focusedCardElement === CardFields.NUMBER}
              error={isCardFieldError('cardNumber')}
            >
              <FormattedMessage id="SETTINGS_PAYMENTS_LABEL_CARD_NUMBER" />
            </Label>
            <CardProviderOverlay
              cardBrand={cardBrand}
              invalidCard={formik.values.cardNumber === STRIPE_VALUE_INVALID}
            >
              <ElementWrapper
                focus={focusedCardElement === CardFields.NUMBER}
                hasError={isCardFieldError('cardNumber')}
              >
                <StyledCardNumberElement
                  onChange={(e) => {
                    setCardBrand(e.brand)
                    handleStripeChange(e)
                  }}
                  onBlur={() => {
                    handleStripeElementTouch('cardNumber')
                    setFocusedCardElement(undefined)
                  }}
                  options={{
                    style: customStripeStyles,
                    classes: {
                      base:
                        formik.touched.cardNumber &&
                        formik.values.cardNumber !== STRIPE_VALUE_COMPLETE
                          ? 'StripeElement invalid-value'
                          : undefined,
                    },
                  }}
                  onFocus={() => setFocusedCardElement(CardFields.NUMBER)}
                  $invalid={!!createError}
                />
              </ElementWrapper>
            </CardProviderOverlay>
            <StripeErrors>
              {isCardFieldError('cardNumber') && (
                <ValidationError>
                  {cardNumberErrorMessage(formik.values.cardNumber) ||
                    formik.errors.cardNumber}
                </ValidationError>
              )}
            </StripeErrors>
          </StripeInputWrapper>
          <HalfFields>
            <StripeInputWrapper>
              <Label
                focus={focusedCardElement === CardFields.EXP}
                error={
                  isCardFieldError('cardExpiry') ||
                  formik.values.cardExpiry === PAST_EXPIRY
                }
              >
                <FormattedMessage id="SETTINGS_PAYMENTS_LABEL_EXPIRATION_DATE" />
              </Label>
              <ElementWrapper
                focus={focusedCardElement === CardFields.EXP}
                hasError={
                  isCardFieldError('cardExpiry') ||
                  formik.values.cardExpiry === PAST_EXPIRY
                }
              >
                <StyledCardExpiryElement
                  onChange={handleStripeChange}
                  onBlur={() => {
                    handleStripeElementTouch('cardExpiry')
                    setFocusedCardElement(undefined)
                  }}
                  onFocus={() => setFocusedCardElement(CardFields.EXP)}
                  options={{
                    style: customStripeStyles,
                    classes: {
                      base:
                        formik.touched.cardNumber &&
                        formik.values.cardNumber !== STRIPE_VALUE_COMPLETE
                          ? 'StripeElement invalid-value'
                          : undefined,
                    },
                  }}
                  $invalid={!!createError}
                />
              </ElementWrapper>
              <StripeErrors>
                {(isCardFieldError('cardExpiry') ||
                  formik.values.cardExpiry === PAST_EXPIRY) && (
                  <ValidationError>
                    {cardExpiryErrorMessage(formik.values.cardExpiry) ||
                      formik.errors.cardExpiry}
                  </ValidationError>
                )}
              </StripeErrors>
            </StripeInputWrapper>
            <StripeInputWrapper>
              <Label
                focus={focusedCardElement === CardFields.CVC}
                error={isCardFieldError('cardCvc')}
              >
                <FormattedMessage id="SETTINGS_PAYMENTS_LABEL_SECURITY_CODE" />
              </Label>
              <ElementWrapper
                focus={focusedCardElement === CardFields.CVC}
                hasError={isCardFieldError('cardCvc')}
              >
                <StyledCardCVCElement
                  onChange={handleStripeChange}
                  onBlur={() => {
                    handleStripeElementTouch('cardCvc')
                    setFocusedCardElement(undefined)
                  }}
                  onFocus={() => setFocusedCardElement(CardFields.CVC)}
                  options={{
                    style: customStripeStyles,
                    classes: {
                      base:
                        formik.touched.cardNumber &&
                        formik.values.cardNumber !== STRIPE_VALUE_COMPLETE
                          ? 'StripeElement invalid-value'
                          : undefined,
                    },
                  }}
                  $invalid={!!createError}
                />
              </ElementWrapper>
              <StripeErrors>
                {isCardFieldError('cardCvc') && (
                  <ValidationError>
                    {cardCvcErrorMessage(formik.values.cardCvc) ||
                      formik.errors.cardCvc}
                  </ValidationError>
                )}
              </StripeErrors>
            </StripeInputWrapper>
          </HalfFields>
          <Input
            name="name"
            variant="secondary"
            label={intl.formatMessage({
              id: 'SETTINGS_PAYMENTS_NAME_ON_CARD',
            })}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={
              (formik.touched.name && formik.errors.name) || !!createError
            }
            value={formik.values.name}
            full
          />
          <StripeErrors></StripeErrors>
        </SectionWrapper>
        <Title>
          <FormattedMessage id="SETTINGS_PAYMENTS_BILLING_ADDRESS" />
        </Title>
        {hasRegionMultipleCountries && cardCountry && (
          <InputWrapper>
            <Dropdown
              label="Country"
              value={cardCountry}
              onChange={(option) => {
                setCardCountry(option.value.toString())
                formik.setValues({
                  ...formik.values,
                  country: option.value.toUpperCase(),
                })
              }}
              options={validStripeCountries
                .filter((country) => country.region === selectedRegion?.code)
                .map((country) => ({
                  label: country.name,
                  value: country.code,
                  image: <RoundFlag countryCode={country.code} />,
                }))}
              searchable
              variant="secondary"
              minHeight="214px"
              width="100%"
              optionContainerClassName="change-region__dropdown"
            />
          </InputWrapper>
        )}
        <InputWrapper>
          <Input
            onKeyDown={handleKeyDown}
            name="line1"
            variant="secondary"
            label={intl.formatMessage({
              id: 'COMMON_LABEL_STREET_ADDRESS',
            })}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.values.country === 'US' &&
                getPlacePredictions({ input: e.target.value })
              formik.handleChange(e)
            }}
            invalid={
              formik.touched.line1 &&
              (formik.errors.line1 || addressError?.['line1'])
            }
            value={formik.values.line1}
            autoComplete="new-line1"
            full
          />
          <AddressResultsContainer
            ref={wrapperRef}
            visible={addressResultsVisible}
          >
            <AddressResultsInnerContainer>
              {placePredictions?.length &&
                placePredictions.map(
                  (
                    place: google.maps.places.AutocompletePrediction,
                    i: number
                  ) => (
                    <AddressResult
                      active={active === i}
                      key={i.toString()}
                      onClick={() => {
                        setAddressResultsVisible(false)
                        setActive(0)
                        placesService?.getDetails(
                          {
                            placeId: place?.place_id,
                            fields: ['address_component'],
                          },
                          (addressDetails) => {
                            addressDetails &&
                              handleAddressSelected(addressDetails)
                          }
                        )
                      }}
                    >
                      {place?.description}
                    </AddressResult>
                  )
                )}
            </AddressResultsInnerContainer>
          </AddressResultsContainer>
        </InputWrapper>
        <InputWrapper>
          <Input
            name="city"
            variant="secondary"
            label={intl.formatMessage({ id: 'COMMON_LABEL_CITY' })}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalid={
              formik.touched.city &&
              (formik.errors.city || addressError?.['city'])
            }
            value={formik.values.city}
            autoComplete="new-city"
            full
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            name="state"
            variant="secondary"
            label={intl.formatMessage({ id: 'COMMON_LABEL_STATE' })}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalid={
              formik.touched.state &&
              (formik.errors.state || addressError?.['state'])
            }
            value={formik.values.state}
            autoComplete="new-state"
            onFocus={() => setAddressFocused(true)}
            full
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            name="postal_code"
            variant="secondary"
            label={intl.formatMessage({ id: 'COMMON_LABEL_POSTAL_CODE' })}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            invalid={
              (formik.touched.postal_code &&
                (formik.errors.postal_code || addressError?.['postal_code'])) ||
              !!createError
            }
            value={formik.values.postal_code}
            autoComplete="new-postal-code"
            onFocus={() => setAddressFocused(true)}
            full
          />
        </InputWrapper>
      </Wrapper>
      {addressError?.['detail'] ? (
        <ValidationError>
          <FormattedMessage id="SETTINGS_PAYMENTS_ADDRESS_VALIDATION_FAILED" />
        </ValidationError>
      ) : null}
    </StyledModal>
  )
}

const AddPaymentMethodModalConnected = injectIntl(AddPaymentMethodForm)

export const ADD_PAYMENT_METHOD_MODAL_ID = 'ADD_PAYMENT_METHOD_MODAL_ID'

const StripeWrappedModal: React.FC<Props & ModalProps> = (props) => {
  const { region } = useContext(StripeRegionContext)
  const selectedRegion = props.subscriptionRegion
    ? props.subscriptionRegion
    : region
  return (
    <StripeElements region={selectedRegion}>
      <AddPaymentMethodModalConnected {...props} />
    </StripeElements>
  )
}

export default () => (
  <ModalWrapper modalId={ADD_PAYMENT_METHOD_MODAL_ID}>
    <StripeWrappedModal />
  </ModalWrapper>
)
