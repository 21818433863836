export enum TicketStatus {
  Open = 'Open',
  Closed = 'Closed',
  Answered = 'Answered',
}

export type HeaderTab = 'open' | 'closed' | 'ccd'

export enum ErrCode {
  NOT_FOUND = 'NOT_FOUND',
}

export enum CaptchaAction {
  // Account BE service
  ZENDESK_SEARCH_TICKETS = 'ZENDESK_SEARCH_TICKETS',
  ZENDESK_SEARCH_TICKETS_COLLABORATORS = 'ZENDESK_SEARCH_TICKETS_COLLABORATORS',
  ZENDESK_CREATE_TICKET_COMMENT = 'ZENDESK_CREATE_TICKET_COMMENT',
  // Feedback BE service
  FEEDBACK_SSO_ISSUE_LOGIN = 'sso_issue_login_feedback',
}

export enum CommentUserRole {
  AGENT = 'agent',
  END_USER = 'end-user',
  UNKNOWN = 'Unknown',
  SELF = 'self',
}

export interface ZendeskTicket {
  assignee_id: string
  collaborator_ids: string[]
  created_at: Date
  description: string
  id: string
  is_pending: boolean
  requester_id: string
  status: TicketStatus
  subject: string
  updated_at: Date
}

export interface ZendeskTicketsResponse {
  tickets: ZendeskTicket[]
  totalTickets: number
}

export interface ZendeskCCdTicketsResponse {
  tickets: ZendeskTicket[]
}

export interface ZendeskComment {
  author_id: string
  body: string | null
  id: string
  plain_body: string
  created_at: Date
  author_type: CommentUserRole
  author_name: string
  html_body: string
}

export interface ZendeskTicketWithComments extends ZendeskTicket {
  comments: ZendeskComment[]
}

export interface RequestsContextType {
  allTickets: ZendeskTicket[]
  paginatedTickets: ZendeskTicket[]
  areTicketsLoading: boolean
  statusToShow: HeaderTab
  handleChangeStatusToShow?: (id: HeaderTab) => void
  hasMultiplePages: boolean
  ticketsPerPage: number
  setTicketsPerPage?: React.Dispatch<React.SetStateAction<number>>
  currentPage: number
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>
  handleSearch?: (e: React.ChangeEvent, value: string | number) => Promise<void>
  isSearchLoading: boolean
  userSearchValue: string
  ticketsError: boolean | null
  totalTickets: number
  searchData: SearchData
}

export type SearchData = {
  value: string
  results: ZendeskTicket[]
  error: string | null
}
