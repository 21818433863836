import React, { createContext, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { useDebouncedCallback } from 'use-debounce'

import { fetchExtraClosedTickets } from 'modules/zendesk/closedUserTickets'
import { fetchExtraOpenTickets } from 'modules/zendesk/openUserTickets'
import { useGoogleReCaptchaV3 } from 'features/ReCaptchaV3/useGoogleReCaptchaV3'

import {
  CaptchaAction,
  HeaderTab,
  RequestsContextType,
  SearchData,
} from './types'
import { useGetRequests } from './useGetRequests'

const {
  api: { accountBE },
  RECAPTCHA_ACCOUNT_BACKEND_SITE_KEY_ID,
} = __CONFIG__
const API_FETCH_SIZE = 100
const FIRST_PAGE = 1

const DEFAULT_SEARCH_DATA = {
  value: '',
  results: [],
  error: null,
}

const TICKETS_PER_PAGE = [10, 25, 50]
const MIN_TICKETS_PER_PAGE = TICKETS_PER_PAGE[0]

export const PAGINATION_OPTIONS = TICKETS_PER_PAGE.map((option) => ({
  value: option,
  label: option.toString(),
}))

export const RequestsContext = createContext<RequestsContextType>({
  allTickets: [],
  paginatedTickets: [],
  areTicketsLoading: true,
  statusToShow: 'open',
  hasMultiplePages: false,
  ticketsPerPage: MIN_TICKETS_PER_PAGE,
  currentPage: 1,
  isSearchLoading: false,
  userSearchValue: '',
  ticketsError: false,
  totalTickets: 0,
  searchData: DEFAULT_SEARCH_DATA,
})

export const RequestsProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch()
  const [ticketsPerPage, setTicketsPerPage] = useState(MIN_TICKETS_PER_PAGE)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchData, setSearchData] = useState<SearchData>(DEFAULT_SEARCH_DATA)
  const [userSearchValue, setUserSearchValue] = useState('')
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [currentRequest, setCurrentRequest] = useState<AbortController | null>(
    null
  )
  const [statusToShow, setStatusToShow] = useState<HeaderTab>('open')
  const {
    allTickets,
    hasMore,
    ticketsError,
    totalTickets,
    isFetching,
    hasFetchedOnce,
    totalFetchedTickets,
  } = useGetRequests(statusToShow, searchData)
  const { getReCaptchaToken } = useGoogleReCaptchaV3(
    RECAPTCHA_ACCOUNT_BACKEND_SITE_KEY_ID,
    statusToShow === 'ccd'
      ? CaptchaAction.ZENDESK_SEARCH_TICKETS_COLLABORATORS
      : CaptchaAction.ZENDESK_SEARCH_TICKETS
  )

  const totalFetchedPages = Math.ceil(allTickets.length / ticketsPerPage)

  const handleClearSearch = () => {
    setSearchData(DEFAULT_SEARCH_DATA)
    setUserSearchValue('')
  }

  const handleChangeStatusToShow = (id: HeaderTab) => {
    setStatusToShow(id)
    handleClearSearch()
    setCurrentPage(FIRST_PAGE)
  }

  const paginatedTickets = useMemo(() => {
    const sliceStart = (currentPage - 1) * ticketsPerPage
    const maxSliceEnd = ticketsPerPage * currentPage
    const sliceEnd =
      maxSliceEnd > totalFetchedTickets ? totalFetchedTickets : maxSliceEnd

    if (ticketsPerPage > allTickets.length) {
      return allTickets
    }

    if (maxSliceEnd > allTickets.length + ticketsPerPage) {
      setCurrentPage(FIRST_PAGE)
    }

    return allTickets.slice(sliceStart, sliceEnd)
  }, [currentPage, ticketsPerPage, allTickets, totalFetchedTickets])

  const hasMultiplePages = useMemo(
    () => allTickets.length > MIN_TICKETS_PER_PAGE,
    [allTickets.length]
  )

  const areTicketsLoading = useMemo(() => {
    if (searchData.results.length > 0 || isSearchLoading) {
      return isSearchLoading
    }
    const isCurrentPageLoading = !paginatedTickets.length && isFetching
    return !hasFetchedOnce || isCurrentPageLoading
  }, [
    hasFetchedOnce,
    isFetching,
    isSearchLoading,
    paginatedTickets.length,
    searchData.results.length,
  ])

  useEffect(() => {
    return () => {
      if (currentRequest) currentRequest.abort()
    }
  }, [currentRequest])

  const handleDebouncedSearch = useDebouncedCallback(
    async (_e, value: string) => {
      if (value === searchData.value) return
      if (value.length === 0) {
        if (currentRequest) currentRequest.abort()
        handleClearSearch()
        setIsSearchLoading(false)
        return
      }
      if (value.length < 3) {
        setSearchData(DEFAULT_SEARCH_DATA)
        if (currentRequest) currentRequest.abort()
        setIsSearchLoading(false)
        return
      }
      setIsSearchLoading(true)
      setCurrentPage(FIRST_PAGE)

      const urlPath =
        statusToShow == 'ccd'
          ? accountBE.paths.zendeskSearchCcdTickets
          : accountBE.paths.zendeskSearchTickets
      grecaptcha.enterprise.ready(async () => {
        const token = await getReCaptchaToken()
        if (currentRequest) currentRequest.abort()
        const controller = new AbortController()
        setCurrentRequest(controller)
        const { data } = await axios.post(
          `${accountBE.base}/${urlPath}`,
          { query: value, status: statusToShow },
          // Send Recaptcha Token in Headers
          {
            signal: controller.signal,
            withCredentials: true,
            headers: { 'x-captcha-token': token },
          }
        )
        setSearchData({
          value,
          results: data.results,
          error: data.results?.length ? null : 'No results found',
        })
        setIsSearchLoading(false)
      })
    },
    300
  )

  const handleSearch = async (e: React.ChangeEvent, value: string | number) => {
    setUserSearchValue(value.toString())
    await handleDebouncedSearch(e, value.toString())
  }

  useEffect(() => {
    if (hasMore && currentPage === totalFetchedPages - 1) {
      const pageToFetch = Math.ceil(allTickets.length / API_FETCH_SIZE) + 1
      statusToShow === 'open'
        ? dispatch(fetchExtraOpenTickets(pageToFetch, allTickets))
        : dispatch(fetchExtraClosedTickets(pageToFetch, allTickets))
    }
  }, [
    allTickets,
    currentPage,
    dispatch,
    hasMore,
    statusToShow,
    totalFetchedPages,
  ])

  return (
    <RequestsContext.Provider
      value={{
        allTickets,
        paginatedTickets,
        areTicketsLoading,
        statusToShow,
        handleChangeStatusToShow,
        hasMultiplePages,
        ticketsPerPage,
        setTicketsPerPage,
        currentPage,
        setCurrentPage,
        handleSearch,
        isSearchLoading,
        userSearchValue,
        ticketsError,
        totalTickets,
        searchData,
      }}
    >
      {children}
    </RequestsContext.Provider>
  )
}
