import { all, put, select, spawn, takeEvery } from 'redux-saga/effects'
import { api } from 'api'

import createDataModule from 'utils/moduleCreator'
import { SSOAPIError } from 'types/types'
import {
  MFAAuthenticator,
  fetchMFA,
  selectMFAAuthenticators,
} from 'modules/mfa'
import { closeVisibleModal } from 'modules/modals'
import { resetMFASetupEmail } from 'modules/mfaSetup'
import { emailAdded } from 'modules/mfaAdded'
import { checkAuth } from 'features/auth/modules/auth'

import { resetInitiateEmailData } from './initiateEmail'

const {
  api: { sso },
} = __CONFIG__

export const verifyEmailDataKey = 'MFAVerifyEmail'

const verifyEmailActions = {
  create: `${verifyEmailDataKey}/CREATE`,
}

type verifyEmailState = Record<string, never>

const dataModule = createDataModule<verifyEmailState, SSOAPIError>(
  verifyEmailDataKey,
  `${sso.paths.mfa}/email`,
  api.ssoBase
)

const { selectIsLoading, selectErrors, CREATE_DONE } = dataModule

export {
  selectIsLoading as selectVerifyEmailIsLoading,
  selectErrors as selectVerifyEmailErrors,
}

export const verifyEmail = (id: string, oneTimePassword: any) => ({
  type: verifyEmailActions.create,
  payload: { oneTimePassword },
  urlPath: `/${id}/verify`,
})

function* verifyEmailDoneSaga() {
  const authenticators: MFAAuthenticator[] = yield select(
    selectMFAAuthenticators
  )
  const active = authenticators.filter((auth) => auth.status === 'active')
  if (active.length) {
    yield put(emailAdded(false))
  } else {
    yield put(emailAdded(true))
  }
  yield put(closeVisibleModal())
  yield put(fetchMFA())
  yield put(checkAuth())
  yield put(resetInitiateEmailData())
  yield put(resetMFASetupEmail())
}

function* subscribeToEmailDoneSaga() {
  yield takeEvery(CREATE_DONE, verifyEmailDoneSaga)
}

export function* verifyEmailRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToEmailDoneSaga)])
}

export const verifyEmailReducer = dataModule.reducer
