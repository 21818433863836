import { all, spawn } from 'redux-saga/effects'
import { api } from 'api'

import { MFAType } from 'modules/mfa'
import createDataModule from 'utils/moduleCreator'
import { SSOAPIError } from 'types/types'
import { MFAAuthenticatorStatus } from 'enums'

const {
  api: { sso },
} = __CONFIG__

export const sendEmailDataKey = 'MFASendEmail'

const sendEmailActions = {
  send: `${sendEmailDataKey}/CREATE`,
}

type SendEmailState =
  | {
      email: string
      status: MFAAuthenticatorStatus
      last_success: string | null
      id: string
      created: string
      type: MFAType.email
    }
  | Record<string, never>

const dataModule = createDataModule<SendEmailState, SSOAPIError>(
  sendEmailDataKey,
  `${sso.paths.mfa}/email`,
  api.ssoBase
)

const { selectIsLoading, selectErrors, resetData } = dataModule

export {
  selectIsLoading as selectSendEmailIsLoading,
  selectErrors as selectSendEmailErrors,
  resetData as resetSendEmailData,
}

export const sendMFAEmail = (id: string) => {
  return {
    type: sendEmailActions.send,
    urlPath: `/${id}/send`,
  }
}

export function* sendEmailRootSaga() {
  yield all([spawn(dataModule.rootSaga)])
}

export const sendEmailReducer = dataModule.reducer
