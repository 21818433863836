import React from 'react'

import styled from 'theme/styled'

import { ZendeskComment } from '../requests/types'
import { Comment } from './Comment'
import { FullScaleImageModal } from './FullScaleImage'

type Props = {
  comments: ZendeskComment[]
  isTicketOpen: boolean
  isCCedTicket: boolean
}

export const RequestBody: React.FC<Props> = ({
  comments,
  isTicketOpen,
  isCCedTicket,
}) => {
  return (
    <Wrapper>
      {comments.map((comment, i, array) => {
        const isFirstMessageFromAuthor =
          comment.author_name !== array[i - 1]?.author_name

        return (
          <Comment
            {...comment}
            key={comment.id}
            shouldScroll={i + 1 === comments.length}
            isTicketOpen={isTicketOpen}
            isFirstMessageFromAuthor={isFirstMessageFromAuthor}
            isCCedTicket={isCCedTicket}
          />
        )
      })}
      <FullScaleImageModal />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-height: 600px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.neutral01};
  border-radius: 4px;
`
