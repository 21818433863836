import React, { useMemo } from 'react'

import cloudSvg from 'assets/svgs/cloudConsole.svg'
import fallbackSvg from 'assets/svgs/unknownDevice.svg'
import useDeviceImage from 'utils/useDeviceImage'
import { formatMac } from 'utils/formatMac'

import {
  Detail,
  DeviceImage,
  DeviceInfo,
  DeviceNameWrapper,
  Name,
} from './Backups.styles'
import { DeviceBackupsArchived, DeviceBackupsWithShadow } from './types'

const CLOUD_KEY = 'CLOUD'

export const DeviceName: React.FC<{
  deviceBackups: DeviceBackupsWithShadow | DeviceBackupsArchived
}> = ({ deviceBackups }) => {
  const shortname = deviceBackups.archived
    ? deviceBackups.backups[0].metadata.hardware_shortname.replaceAll(/"/g, '')
    : deviceBackups.shadow.state.reported.hardware.shortname

  const mac = deviceBackups.archived
    ? deviceBackups.backups[0].metadata.mac.replaceAll(/"/g, '')
    : deviceBackups.shadow.state.reported.mac

  const name = deviceBackups.archived
    ? deviceBackups.name
    : deviceBackups.shadow.state.reported.name

  const src = useDeviceImage(shortname)

  const imgSource = useMemo(() => {
    const fallbackUrl = shortname === CLOUD_KEY ? cloudSvg : fallbackSvg

    return src || fallbackUrl
  }, [src, shortname])

  return (
    <DeviceNameWrapper>
      <DeviceImage src={imgSource} />
      <DeviceInfo>
        <Name>{name || shortname}</Name>
        {shortname !== CLOUD_KEY && <Detail>{formatMac(mac)}</Detail>}
      </DeviceInfo>
    </DeviceNameWrapper>
  )
}
