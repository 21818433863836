import {
  all,
  put,
  race,
  select,
  spawn,
  take,
  takeEvery,
} from 'redux-saga/effects'
import { api } from 'api'

import { ReduxAction, SelfData } from 'types/types'
import { checkAuth } from 'features/auth/modules/auth'
import createDataModule from 'utils/moduleCreator'

import {
  SET_PROFILE_DATA,
  selectIsTwoFactorAuthEnabled,
  selectProfileData,
} from './profile'
import { closeVisibleModal } from './modals'

const {
  api: { sso },
} = __CONFIG__

export const dataKey = 'twoFa'

export interface TwoFaState {
  '2fa_uri'?: string
}

interface ErrorType {
  details?: string
}

const TOGGLE_TWO_FA = `${dataKey}/TOGGLE_TWO_FA`
export const toggleTwoFa = (token: string) => ({
  type: TOGGLE_TWO_FA,
  payload: token,
})

const dataModule = createDataModule<TwoFaState, ErrorType>(
  dataKey,
  sso.paths.twoFa,
  api.ssoBase
)

export const {
  fetch: fetchTwoFaUri,
  selectIsLoading: selectIsTwoFaLoading,
  reducer: twoFaReducer,
} = dataModule

export const FETCH_TWO_FA = `${dataKey}/FETCH_TWO_FA`

function* toggleTwoFaSaga({ payload: token }: ReturnType<typeof toggleTwoFa>) {
  const isTwoFactorAuthEnabled: boolean | undefined = yield select(
    selectIsTwoFactorAuthEnabled
  )
  if (isTwoFactorAuthEnabled) {
    yield put(dataModule.remove({ token }))
  } else {
    yield put(dataModule.create({ token }))
  }

  const { error, success } = yield race({
    success: take([dataModule.CREATE_DONE, dataModule.REMOVE_DONE]),
    error: take([dataModule.CREATE_FAILED, dataModule.REMOVE_FAILED]),
  })
  if (error) return

  if (success.type === dataModule.REMOVE_DONE) {
    yield put(fetchTwoFaUri())
  }

  yield put(checkAuth())
  yield put(closeVisibleModal())
}

function* subscribeToToggleTwoFaSaga() {
  yield takeEvery(TOGGLE_TWO_FA, toggleTwoFaSaga)
}

function* fetchTwoFaSaga() {
  const profileData: ReturnType<typeof selectProfileData> = yield select(
    selectProfileData
  )
  if (profileData) {
    if (profileData.twofa_enabled) return
  } else {
    const { payload }: ReduxAction<SelfData> = yield take(SET_PROFILE_DATA)
    if (payload.twofa_enabled) return
  }
  yield put(fetchTwoFaUri())
}

function* subscribeToFetchTwoFaSaga() {
  yield takeEvery(FETCH_TWO_FA, fetchTwoFaSaga)
}

export function* twoFaRootSaga() {
  yield all([
    spawn(dataModule.rootSaga),
    spawn(subscribeToToggleTwoFaSaga),
    spawn(subscribeToFetchTwoFaSaga),
  ])
}
