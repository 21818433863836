import React from 'react'
import { createDividerOption } from '@ubnt/ui-components/Dropdown/Options'
import { DropdownOption } from '@ubnt/ui-components/Dropdown/types'

import { ParsedNCAConsole } from 'features/support/unifi/types'
import { ZDFormValueType } from 'features/support/unifi/config/types'
import { formatMac } from 'utils/formatMac'

import { FormDropdown } from './Dropdown'

export const DeviceDropdown: React.FC<{
  userConsoles: ParsedNCAConsole[]
  otherOption: ZDFormValueType
  handleChange: (option: DropdownOption) => void
}> = ({ userConsoles, otherOption, handleChange }) => {
  const dropdownOptions: DropdownOption[] = userConsoles.map((console) => ({
    value: console.mac || console.id,
    label: console.deviceName || console.label,
    additionalInfo: formatMac(console.mac),
  }))

  const otherDropdownOption = {
    value: otherOption.id,
    label: otherOption.label,
  }
  const optionDivider = createDividerOption()
  return (
    <FormDropdown
      options={
        [
          otherDropdownOption,
          optionDivider,
          ...dropdownOptions,
        ] as DropdownOption[]
      }
      handleChange={handleChange}
    />
  )
}
