import { createSelector } from 'reselect'
import 'intl-pluralrules'
import '@formatjs/intl-relativetimeformat/polyfill'
import { ReduxAction, RootState } from 'types/types'

import enTranslations from './translations/en/common.json'
import caTranslations from './translations/ca/common.json'
import daTranslations from './translations/da/common.json'
import deTranslations from './translations/de/common.json'
import esTranslations from './translations/es/common.json'
import nbTranslations from './translations/nb/common.json'
import nlTranslations from './translations/nl/common.json'
import svTranslations from './translations/sv/common.json'
import trTranslations from './translations/tr/common.json'

export const dataKey = 'locale'
export const DEFAULT_LANGUAGE = 'en'

const SUPPORTED_LANGUAGES = {
  en: 'English',
  ca: 'Catalan',
  da: 'Danish',
  de: 'German',
  es: 'Spanish',
  nb: 'Norwegian',
  nl: 'Dutch',
  sv: 'Swedish',
  tr: 'Turkish',
}

export type SupportedLanguages =
  | 'ca'
  | 'en'
  | 'ca'
  | 'da'
  | 'de'
  | 'es'
  | 'nb'
  | 'nl'
  | 'sv'
  | 'tr'

export interface Messages {
  [key: string]: string
}

interface LocaleMessages {
  [part: string]: Messages
}
type LocaleTranslations = { [lang in SupportedLanguages]?: LocaleMessages }

export interface LocaleState {
  language: string
  translations: LocaleTranslations
}

const mergeWithEnglish = (translation: { [key: string]: string }) => {
  return {
    ...enTranslations,
    ...translation,
  }
}

const ininitalState: LocaleState = {
  language: DEFAULT_LANGUAGE,
  translations: {
    en: {
      common: enTranslations,
    },
    ca: {
      common: mergeWithEnglish(caTranslations),
    },
    da: {
      common: mergeWithEnglish(daTranslations),
    },
    de: {
      common: mergeWithEnglish(deTranslations),
    },
    es: {
      common: mergeWithEnglish(esTranslations),
    },
    nb: {
      common: mergeWithEnglish(nbTranslations),
    },
    nl: {
      common: mergeWithEnglish(nlTranslations),
    },
    sv: {
      common: mergeWithEnglish(svTranslations),
    },
    tr: {
      common: mergeWithEnglish(trTranslations),
    },
  },
}

const CHANGE_LANGUAGE = `${dataKey}/CHANGE_LANGUAGE`

export const changeLanguageAction = (language: SupportedLanguages) => {
  return {
    type: CHANGE_LANGUAGE,
    payload: { language },
  }
}

export const selectLanguage = (state: RootState) =>
  state[dataKey].language as SupportedLanguages

// eslint-disable-next-line
export const selectLanguageOptions = (state: RootState) =>
  Object.keys(SUPPORTED_LANGUAGES).map((value) => ({
    value,
    label: SUPPORTED_LANGUAGES[value as SupportedLanguages],
  }))

export const selectTranslations = (state: RootState) =>
  state[dataKey].translations

export const selectMessages = createSelector(
  [selectLanguage, selectTranslations],
  (language, translations) => {
    const parts = translations[language]
    if (!parts) return {}
    return parts.common
  }
)

export function localeReducer(
  state: LocaleState = ininitalState,
  action: ReduxAction
): LocaleState {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.payload.language,
      }

    default:
      return state
  }
}
