import React, { useContext, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Popover from '@ubnt/ui-components/Popover/Popover'
import SortAscendingIcon from '@ubnt/icons/SortAscendingIcon'
import SortDescendingIcon from '@ubnt/icons/SortDescendingIcon'

import { useOutsideClick } from 'utils/useOutsideClick'

import {
  Divider,
  HeaderTitle,
  HeaderWrapper,
  PageHeader,
  SortContainer,
  StyledPopoverCardMenu,
  TitleWrapper,
} from './Backups.styles'
import { BackupsContext } from './BackupsContext'
import { DeviceBackupsWithShadow, SortType } from './types'
import { BackupsFilter } from './BackupsFilter'
import { getSortOptions } from './getSortOptions'

interface Props {
  displaySort: boolean
  devices: DeviceBackupsWithShadow[]
}

const BackupsHeader: React.FC<Props> = ({ displaySort, devices }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const { selectedSort, setSelectedSort, selectedDevice, setSelectedDevice } =
    useContext(BackupsContext)
  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, () => setIsPopoverOpen(false))

  return (
    <>
      <PageHeader>
        <HeaderWrapper>
          <TitleWrapper>
            <HeaderTitle>
              <FormattedMessage id="COMMON_SIDE_NAV_CONSOLE" />
              {displaySort && (
                <Popover
                  onClick={() => {
                    setIsPopoverOpen(!isPopoverOpen)
                  }}
                  open={isPopoverOpen}
                  classNameCardWrap="sortPopover-wrapper"
                  renderToggle={() => (
                    <SortContainer>
                      {selectedSort.includes('desc-') ? (
                        <SortDescendingIcon isActive size="navigation" />
                      ) : (
                        <SortAscendingIcon isActive size="navigation" />
                      )}
                    </SortContainer>
                  )}
                  align="bottomRight"
                  renderCard={() => (
                    <StyledPopoverCardMenu
                      ref={wrapperRef}
                      items={getSortOptions(selectedSort)}
                      open={isPopoverOpen}
                      onClick={(item) => {
                        if (selectedSort !== item.value) {
                          setSelectedSort?.(
                            (
                              item as {
                                label: JSX.Element
                                value: SortType
                              }
                            ).value
                          )
                          setIsPopoverOpen(false)
                        }
                      }}
                    />
                  )}
                />
              )}
            </HeaderTitle>
          </TitleWrapper>
          {!!devices?.length && (
            <BackupsFilter
              devices={devices}
              selected={selectedDevice}
              onChange={(option: string) => setSelectedDevice?.(option)}
            />
          )}
        </HeaderWrapper>
      </PageHeader>
      <Divider />
    </>
  )
}

export default BackupsHeader
