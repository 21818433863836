import { Button } from '@ubnt/ui-components/Button'
import { Popover } from '@ubnt/ui-components/Popover'
import { typography } from 'theme'
import styled from 'theme/styled'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  padding: 16px 0;
  @media (max-width: ${(p) => p.theme.media.small}) {
    padding: 16px 0;
  }
`

export const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const IconTypeWrapper = styled.div<{ iconBorder: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  border: ${({ iconBorder, theme }) =>
    iconBorder ? `1px solid ${theme.neutral04}` : 'none'};
  border-radius: 11px;
  margin-right: 16px;
`

export const Type = styled.div`
  font-weight: bold;
  font: ${typography['desktop-heading-medium']};
  color: ${({ theme }) => theme.text2};
`

export const InfoContainer = styled.div`
  margin-top: 4px;
  font: ${typography['desktop-typography-caption']};
  color: ${({ theme }) => theme.text3};
  display: flex;
  @media (max-width: ${(props) => props.theme.media.mobileLarge}) {
    flex-direction: column;
  }
`

export const Info = styled.span`
  font: ${typography['desktop-typography-caption']};
  text-align: right;
  color: ${({ theme }) => theme.text3};
  @media (min-width: ${({ theme }) => theme.media.mobileLarge}) {
    text-align: left:
  }
`

export const Badge = styled.div`
  font-size: ${typography['desktop-font-size-xsmall']};
  color: ${({ theme }) => theme.ublue06};
  background: ${({ theme }) => theme.ublue01};
  border-radius: 10px;
  font-weight: bold;
  margin: auto 5px;
  padding: 2px 7px;
  cursor: default;
`

export const PendingBadge = styled(Badge)`
  color: ${({ theme }) => theme.green07};
  background-color: ${({ theme }) => theme.green01};
`

export const DeactivatedBadge = styled(Badge)`
  color: ${(p) => p.theme.red06};
  background-color: ${({ theme }) => theme.red01};
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const Option = styled.div<{ type?: string; disabled?: boolean }>`
  color: ${({ type, theme }) =>
    type === 'delete' ? theme.red06 : theme.text2};
  font: ${typography['desktop-typography-body']};
  width: 100%;
  padding: 15px 20px;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.3;
  `}

  :hover {
    background: ${({ theme }) => theme.neutral01};
  }
`

export const EditSelector = styled(Popover)`
  cursor: pointer;
  float: right;
  font: ${typography['desktop-typography-caption']};
  color: ${({ theme }) => theme.text2};
`

export const StyledImg = styled.img<{ size?: string }>`
  width: ${(p) => p.size ?? '30px'};
  height: ${(p) => p.size ?? '30px'};
  border-radius: ${(p) => (p.size ? '11px' : 'none')};
`

export const Description = styled.div`
  margin-right: 15px;
`
export const StyledButton = styled(Button)`
  font: ${typography['desktop-typography-body']};
  color: ${({ theme }) => theme.text2};
  margin-right: 2px;
`
