import { all, put, spawn, takeEvery } from 'redux-saga/effects'

import { api } from 'api'
import { fetchMFA } from 'modules/mfa'
import createDataModule from 'utils/moduleCreator'
import { SSOAPIError } from 'types/types'
import { closeVisibleModal } from 'modules/modals'

const {
  api: { sso },
} = __CONFIG__

export const updateTOTPDataKey = 'MFAUpdateTOTP'

type UpdateTOTPState = Record<string, never>

const dataModule = createDataModule<UpdateTOTPState, SSOAPIError>(
  updateTOTPDataKey,
  `${sso.paths.mfa}/totp`,
  api.ssoBase
)

const { UPDATE_DONE } = dataModule

function* closeModalSaga() {
  yield put(fetchMFA())
  yield put(closeVisibleModal())
}

function* subscribeToUpdatedTOTPDoneSaga() {
  yield takeEvery(UPDATE_DONE, closeModalSaga)
}

export function* updateTOTPRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToUpdatedTOTPDoneSaga)])
}

export const updateTOTPReducer = dataModule.reducer
