import React, { useRef } from 'react'
import { useIntl } from 'react-intl'
import { FormItem_Default } from '@ubnt/ui-components/aria/FormItem'
import styled from 'theme/styled'
import { FormikErrors } from 'formik'
import { ExpiryDateValueState } from '../modules/types'
import { parseYear } from './utils'

type ExpiryDateProps = {
  value: ExpiryDateValueState
  onChange: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<any>>
  placeholder?: ExpiryDateValueState
  expiryDateError: boolean
  setExpiryDateError: React.Dispatch<React.SetStateAction<boolean>>
}

const MAX_INPUT_CHARS = 2
const MONTHS_IN_YEAR = 12
const MAX_YEAR = 99

const checkIfDateIsPast = ({
  expMonth,
  expYear,
}: {
  expMonth: string
  expYear: string
}): boolean => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()

  const parsedYear = parseInt(`20${expYear}`)
  const parsedMonth = parseInt(expMonth)

  const isPastYear = parsedYear < currentYear
  const isPastDate = parsedYear === currentYear && parsedMonth <= currentMonth

  return isPastYear || isPastDate
}

export const ExpiryDate: React.FC<ExpiryDateProps> = ({
  value,
  onChange,
  placeholder,
  expiryDateError,
  setExpiryDateError,
}) => {
  const monthRef = useRef<HTMLInputElement>(null)
  const yearRef = useRef<HTMLInputElement>(null)

  const intl = useIntl()

  const handleMonthChange = (val: string) => {
    if (parseInt(val) > MONTHS_IN_YEAR) {
      return
    }
    if (val.length === MAX_INPUT_CHARS) {
      yearRef.current?.focus()
    }
    onChange('exp_month', val)
  }

  const handleYearChange = (val: string) => {
    const parsedVal = parseYear(val)

    if (parseInt(parsedVal) > MAX_YEAR) {
      return
    }

    if (parsedVal.length === MAX_INPUT_CHARS) {
      const isDateInPast = checkIfDateIsPast({
        expMonth: value.expMonth!,
        expYear: parsedVal,
      })
      setExpiryDateError(isDateInPast)
    }
    onChange('exp_year', parsedVal)
  }

  const handleKeyDown = ({ key }: React.KeyboardEvent) => {
    if (key === 'Backspace' && !value.expYear?.length) {
      monthRef.current?.focus()
    }
  }

  const handleFocus = () => {
    if (value.expMonth?.length === MAX_INPUT_CHARS) {
      yearRef.current?.focus()
    } else {
      monthRef.current?.focus()
    }
  }

  return (
    <StyledFormItem_Default
      label={intl.formatMessage({
        id: 'SETTINGS_PAYMENTS_LABEL_EXPIRATION_DATE',
      })}
      // this should come from UIC, but there is a known bug https://ubiquiti.atlassian.net/browse/UICMP-1782
      $isInvalid={expiryDateError}
      helpMessage={
        expiryDateError
          ? intl.formatMessage({
              id: 'SETTINGS_PAYMENTS_INVALID_CARD_EXPIRY_PAST',
            })
          : ` `
      }
      width="fill"
    >
      <Wrapper
        // this should come from UIC, but there is a known bug https://ubiquiti.atlassian.net/browse/UICMP-1782
        $isInvalid={expiryDateError}
        onClick={handleFocus}
      >
        <NumericInput
          type="number"
          ref={monthRef}
          value={value.expMonth}
          onChange={(e) => {
            handleMonthChange(e.target.value)
          }}
          placeholder={placeholder?.expMonth}
        />
        <Divider> / </Divider>
        <NumericInput
          type="number"
          ref={yearRef}
          value={parseYear(value.expYear)}
          onChange={(e) => handleYearChange(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={placeholder?.expYear}
        />
      </Wrapper>
    </StyledFormItem_Default>
  )
}

const StyledFormItem_Default = styled(FormItem_Default)<{
  $isInvalid: boolean
}>`
  ${({ $isInvalid, theme }) =>
    $isInvalid &&
    `
      label, span, input {
        color: ${theme.red06} !important;
      } 
     span {
        font-size: 12px !important;
        line-height: 16px !important;
      }
    `}
`

const Wrapper = styled.div<{ $isInvalid: boolean }>`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;

  border-radius: 4px;
  background-color: ${({ theme }) => theme.neutral02};
  padding: 0 8px;

  &:has(input:focus) {
    outline: 1px solid
      ${({ $isInvalid, theme }) => ($isInvalid ? theme.red06 : theme.ublue06)};
    background-color: ${({ theme }) => theme.neutral03};
  }

  &:hover {
    background-color: ${({ theme }) => theme.neutral03};
    cursor: text;
  }
`

const NumericInput = styled.input`
  width: 32px;
  border: none;
  background-color: inherit;
  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
  }
`

const Divider = styled.span`
  padding-right: 12px;
`
