import React, { useEffect, useRef } from 'react'

import { DeviceTile } from 'components/support/DeviceTile'
import { Bubble } from 'components/support/Bubble'
import { TilesWrapper } from 'components/support/Wrappers'

import { BubbleProps } from './types'
import { model } from './config/ConfigOptions'
import { UniFiStateValues } from './config/types'
import { setNextSection } from './handleClicks'

export const ModelBubble: React.FC<BubbleProps> = ({
  handleChange,
  values,
  shouldScroll,
  config,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (shouldScroll) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll])

  return (
    <Bubble question={config.model.fieldTitle} ref={ref}>
      <TilesWrapper>
        {model.map(({ id, label, icon }) => (
          <DeviceTile
            key={id}
            label={label}
            icon={icon}
            onClick={() =>
              setNextSection(
                config,
                id,
                UniFiStateValues.MODEL,
                values,
                handleChange
              )
            }
            isActive={values.model === id}
          />
        ))}
      </TilesWrapper>
    </Bubble>
  )
}
