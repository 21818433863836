import React from 'react'

import cardVisaSVG from 'assets/svgs/cardVisa.svg'
import cardMCSVG from 'assets/svgs/cardMC.svg'
import cardUnionpaySVG from 'assets/svgs/cardUnionpay.svg'
import cardAmexSVG from 'assets/svgs/cardAmex.svg'
import IconWarning from 'assets/svgs/iconWarningRed.svg?react'
import styled from 'theme/styled'

import { getPaymentMethodIcon } from './utils'

const CardProviderOverlay: React.FC<{
  cardBrand: string | undefined
  invalidCard: boolean
  disabled?: boolean
}> = ({ children, cardBrand, invalidCard, disabled }) => {
  const renderCard = () => {
    if (invalidCard) return <StyledIconWarning color="danger" />
    if (!cardBrand || cardBrand === 'unknown')
      return (
        <Wrapper>
          <StyledImg src={cardVisaSVG} />
          <StyledImg src={cardMCSVG} />
          <StyledImg src={cardAmexSVG} />
          <StyledImg src={cardUnionpaySVG} className="lastIcon" />
        </Wrapper>
      )
    else return <StyledImg src={getPaymentMethodIcon(cardBrand)} />
  }
  return (
    <OuterWrapper>
      {children}
      {disabled ? <DisabledCover /> : <></>}
      <SvgContainer>{renderCard()}</SvgContainer>
    </OuterWrapper>
  )
}

export default CardProviderOverlay

const DisabledCover = styled.div`
  position: absolute;
  height: 15px;
  width: 22.5px;
  background-color: #fafbfb;
  opacity: 0.5;
  z-index: 99999999;
  margin-right: 8px;
  top: 9px;
  right: 2px;
  @media (max-width: 380px) {
    .lastIcon {
      display: none;
    }
  }
`

const SvgContainer = styled.div`
  position: absolute;
  top: 9px;
  right: 2px;
  @media (max-width: 380px) {
    .lastIcon {
      display: none;
    }
  }
`
const StyledImg = styled.img`
  height: 15px;
  margin-right: 8px;
`
const Wrapper = styled('div')`
  display: flex;
  flex-direction: row;
  @media (max-width: 315px) {
    display: none;
  }
`
const StyledIconWarning = styled(IconWarning)`
  margin-top: -1px;
  margin-right: 8px;
`
const OuterWrapper = styled('div')`
  position: relative;
`
