import { createSelector } from 'reselect'
import { all, call, put, spawn, takeEvery } from 'redux-saga/effects'

import { RootState } from 'types/types'
import axios, { AxiosError, AxiosResponse } from 'axios'

import { Components, IncidentsStatus, MaintanenceStatus } from './types'

export const statusApiDataKey = 'statusApi'

export const HARDCODED_MAINTENANCE = false

const {
  api: { status },
} = __CONFIG__

const SSO_ID = 'vqmcpp1kx7pf'
const IN_PROGRESS = 'in_progress'

export interface StatusApiState {
  active: MaintanenceStatus | null
  upcoming: MaintanenceStatus | null
  incidents_status: IncidentsStatus | null
  isLoading: boolean
  error?: AxiosError
}

const initialState: StatusApiState = {
  active: null,
  upcoming: null,
  incidents_status: null,
  isLoading: false,
  error: undefined,
}

enum statusApiActionTypes {
  STATUS_API = 'STATUS_API',
  STATUS_API_SET_UPCOMING = 'STATUS_API_SET_UPCOMING',
  STATUS_API_SET_ACTIVE = 'STATUS_API_SET_ACTIVE',
  STATUS_API_SET_INCIDENTS = 'STATUS_API_SET_INCIDENTS',
  STATUS_API_START = 'STATUS_API_START',
  STATUS_API_FAILED = 'STATUS_API_FAILED',
  STATUS_API_DONE = 'STATUS_API_DONE',
}

type Action =
  | {
      type:
        | statusApiActionTypes.STATUS_API
        | statusApiActionTypes.STATUS_API_START
        | statusApiActionTypes.STATUS_API_DONE
    }
  | {
      type: statusApiActionTypes.STATUS_API_FAILED
      payload: AxiosError
    }
  | {
      type:
        | statusApiActionTypes.STATUS_API_SET_UPCOMING
        | statusApiActionTypes.STATUS_API_SET_ACTIVE
        | statusApiActionTypes.STATUS_API_SET_INCIDENTS
      payload: MaintanenceStatus
    }

export const statusApiReducer = (state = initialState, action: Action) => {
  let newState: StatusApiState

  switch (action.type) {
    case statusApiActionTypes.STATUS_API:
      newState = {
        ...state,
        isLoading: false,
        error: undefined,
      }
      return newState
    case statusApiActionTypes.STATUS_API_START:
      newState = {
        ...state,
        isLoading: true,
        error: undefined,
      }
      return newState
    case statusApiActionTypes.STATUS_API_FAILED:
      newState = {
        ...state,
        isLoading: false,
        error: action.payload,
      }
      return newState
    case statusApiActionTypes.STATUS_API_DONE:
      newState = {
        ...state,
        isLoading: false,
        error: undefined,
      }
      return newState
    case statusApiActionTypes.STATUS_API_SET_UPCOMING:
      newState = {
        ...state,
        upcoming: action.payload,
      }
      return newState
    case statusApiActionTypes.STATUS_API_SET_ACTIVE:
      newState = {
        ...state,
        active: action.payload,
      }
      return newState
    case statusApiActionTypes.STATUS_API_SET_INCIDENTS:
      newState = {
        ...state,
        incidents_status: action.payload as IncidentsStatus,
      }
      return newState

    default:
      return state
  }
}

/**
 * Actions
 */

export const statusApi = (): Action => ({
  type: statusApiActionTypes.STATUS_API,
})

const statusApiSetUpcoming = (payload: MaintanenceStatus): Action => ({
  type: statusApiActionTypes.STATUS_API_SET_UPCOMING,
  payload,
})

const statusApiSetActive = (payload: MaintanenceStatus): Action => ({
  type: statusApiActionTypes.STATUS_API_SET_ACTIVE,
  payload,
})

const statusApiSetIncidents = (payload: MaintanenceStatus): Action => ({
  type: statusApiActionTypes.STATUS_API_SET_INCIDENTS,
  payload,
})

const statusApiStart = (): Action => ({
  type: statusApiActionTypes.STATUS_API_START,
})

const statusApiFailed = (error: AxiosError): Action => ({
  type: statusApiActionTypes.STATUS_API_FAILED,
  payload: error,
})

const statusApiDone = (): Action => ({
  type: statusApiActionTypes.STATUS_API_DONE,
})

/**
 * Selectors
 */

export const selectStatusApi = (state: RootState) => state[statusApiDataKey]

const filterComponents = (components: Components[]) =>
  components.find(({ id }) => id === SSO_ID)

export const selectUpcomingMaintenance = createSelector(
  selectStatusApi,
  ({ upcoming }): { start: string; end: string } | null => {
    try {
      const maintenance = upcoming?.scheduled_maintenances?.find(
        ({ components }) => filterComponents(components)
      )

      // hardcoded test values
      // return {
      //   start: '2023-04-13T23:00:00.000-06:00',
      // }

      if (!maintenance) return null

      return {
        start: maintenance.scheduled_for,
        end: maintenance.scheduled_until,
      }
    } catch (error) {
      console.error(error)
    }

    return null
  }
)

export const selectActiveMaintenance = createSelector(
  selectStatusApi,
  ({ active }): boolean => {
    try {
      const maintenance = active?.scheduled_maintenances?.find(
        ({ components }) => filterComponents(components)
      )

      // hardcoded test value
      // return true

      return maintenance?.status === IN_PROGRESS
    } catch (error) {
      console.error(error)
    }
    return false
  }
)

export const selectStatusUiIncidents = createSelector(
  selectStatusApi,
  ({ incidents_status }) => {
    const incidents = incidents_status?.incidents || []

    if (!incidents?.length) {
      return null
    }

    return incidents[0].name
  }
)

function* statusApiSaga() {
  yield put(statusApiStart())

  const getStatusUpcoming = () =>
    axios.get(`${status.base}/${status.paths.upcoming}`)
  const getStatusActive = () =>
    axios.get(`${status.base}/${status.paths.active}`)
  const getStatusIncidents = () =>
    axios.get(`${status.base}/${status.paths.incidents}`)

  try {
    const response: AxiosResponse<any> = yield call(getStatusActive)
    yield put(statusApiSetActive(response.data))
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(statusApiFailed(error))
    }
  }

  try {
    const response: AxiosResponse<any> = yield call(getStatusIncidents)
    yield put(statusApiSetIncidents(response.data))
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(statusApiFailed(error))
    }
  }

  try {
    const response: AxiosResponse<any> = yield call(getStatusUpcoming)
    yield put(statusApiSetUpcoming(response.data))
  } catch (error) {
    if (axios.isAxiosError(error)) {
      yield put(statusApiFailed(error))
    }
    return
  }

  yield put(statusApiDone())
}

function* subscribeToStatusApiSaga() {
  yield takeEvery(statusApiActionTypes.STATUS_API, statusApiSaga)
}

export function* statusApiRootSaga() {
  yield all([spawn(subscribeToStatusApiSaga)])
}
