import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Loader } from '@ubnt/ui-components'
import { useHistory } from 'react-router'

import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import styled from 'theme/styled'
import {
  fetchTalkInstalls,
  resetTalkInstallsErrors,
  selectTalkInstallsData,
  selectTalkInstallsErrors,
  selectTalkInstallsIsDone,
  selectTalkInstallsIsLoading,
} from 'features/subscriptions/module/deviceTalkInstalls'
import {
  cancelTalk,
  resetTalkCancelErrors,
  selectCancelTalkErrors,
  selectCancelTalkLoading,
} from 'features/subscriptions/module/deviceTalkCancel'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/fullSubscriptions'
import { GenericModal } from 'components/generic-modal/GenericModal'

interface Props extends ModalProps {
  fullSubscription?: FullSubscription
}

const CancelSubscriptionTalkModal: React.FC<Props> = ({
  isOpen,
  onClose,
  fullSubscription,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const talkInstallsData = useSelector(selectTalkInstallsData)
  const talkInstallsErrors = useSelector(selectTalkInstallsErrors)
  const talkCancelErrors = useSelector(selectCancelTalkErrors)
  const talkInstallsIsLoading = useSelector(selectTalkInstallsIsLoading)
  const talkInstallsIsDone = useSelector(selectTalkInstallsIsDone)
  const talkCancelIsLoading = useSelector(selectCancelTalkLoading)
  const resetCancelErrors = useSelector(resetTalkCancelErrors)
  const resetFetchErrors = useSelector(resetTalkInstallsErrors)

  const foundInstall = useMemo(
    () =>
      fullSubscription &&
      talkInstallsData &&
      Array.isArray(talkInstallsData) &&
      talkInstallsData.find(
        ({ subscription }: { subscription: string }) =>
          subscription === fullSubscription.id
      ),
    [fullSubscription, talkInstallsData]
  )

  const error = useMemo(() => {
    if (
      typeof talkInstallsErrors === 'string' ||
      (talkInstallsIsDone && !foundInstall)
    ) {
      return <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_FETCH_ERROR" />
    } else if (typeof talkCancelErrors === 'string') {
      return <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_ERROR" />
    } else {
      return null
    }
  }, [talkInstallsErrors, talkCancelErrors, foundInstall, talkInstallsIsDone])

  const renderContent = useMemo(() => {
    if (error) {
      return <ErrorMessage>{error}</ErrorMessage>
    } else if (talkInstallsIsLoading) {
      return (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )
    } else {
      return (
        <FormattedMessage
          id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_MESSAGE_TALK"
          tagName="div"
        />
      )
    }
  }, [error, talkInstallsIsLoading])

  const onConfirm = useCallback(() => {
    if (error) {
      history.push('/supportform?formtype=billing')
    } else if (foundInstall) {
      dispatch(
        cancelTalk({
          install: foundInstall.id,
        })
      )
    }
  }, [error, foundInstall, history, dispatch])

  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterOpen={() => dispatch(fetchTalkInstalls())}
      title={<Title />}
      size="small"
      onAfterClose={() => {
        dispatch(resetCancelErrors)
        dispatch(resetFetchErrors)
      }}
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CANCEL" />,
          onClick: onClose,
        },
        {
          text: error ? (
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CONTACT_SUPPORT" />
          ) : (
            <FormattedMessage id="COMMON_ACTION_CONFIRM" />
          ),
          variant: 'primary',
          onClick: onConfirm,
          loader:
            (!error && talkInstallsIsLoading) || talkCancelIsLoading
              ? 'dots'
              : undefined,
          type: 'submit',
          disabled: (!error && talkInstallsIsLoading) || talkCancelIsLoading,
        },
      ]}
    >
      {renderContent}
    </GenericModal>
  )
}

export const CANCEL_SUBSCRIPTION_TALK_MODAL_ID =
  'CANCEL_SUBSCRIPTION_TALK_MODAL_ID'

const WrappedCancelSubscriptionTalkModal = () => (
  <ModalWrapper modalId={CANCEL_SUBSCRIPTION_TALK_MODAL_ID}>
    <CancelSubscriptionTalkModal />
  </ModalWrapper>
)

export default WrappedCancelSubscriptionTalkModal

const Title = () => (
  <TitleWrapper>
    <FormattedMessage
      id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION"
      tagName="div"
    />
  </TitleWrapper>
)

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const ErrorMessage = styled.div`
  color: ${(p) => p.theme.red06};
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`
