import React, { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { UnknownVendorLogo } from '@ubnt/ui-components'
import { Avatar_Default } from '@ubnt/ui-components/aria'

import styled from 'theme/styled'
import UiLogo from 'assets/svgs/uiIconRoundedNew.svg'
import UserImage from 'components/avatar/UserImage'

import { CommentUserRole, ZendeskComment } from '../requests/types'
import { CommentWrapper, FirstRow, StyledSecondaryText } from './styles'
import { formatDate, parseAttachmentUrl } from './utils'
import CommentMessage from './CommentMessage'

type Props = ZendeskComment & {
  shouldScroll: boolean
  isTicketOpen: boolean
  isFirstMessageFromAuthor: boolean
  isCCedTicket: boolean
}

export const Comment: React.FC<Props> = ({
  body,
  created_at,
  shouldScroll,
  isTicketOpen,
  isFirstMessageFromAuthor,
  isCCedTicket,
  author_type,
  author_name,
  html_body,
}) => {
  const intl = useIntl()
  const commentRef = useRef<HTMLDivElement>(null)

  const isUserComment = author_type === CommentUserRole.SELF
  const isAgentComment = author_type === CommentUserRole.AGENT
  const isCCedComment = author_type === CommentUserRole.END_USER
  const showAuthorName = isFirstMessageFromAuthor && isCCedTicket

  useEffect(() => {
    if (shouldScroll) {
      commentRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll])

  return (
    <CommentWrapper ref={commentRef} $isUserComment={isUserComment}>
      <FirstRow $isUserComment={isUserComment}>
        {isAgentComment && <UiAvatar image={UiLogo} />}
        {isCCedComment && <CcedAvatar name={author_name} />}

        <CommentMessage
          html_body={html_body}
          attachmentUrl={parseAttachmentUrl(body)}
          author_name={author_name}
          showAuthorName={showAuthorName}
          isTicketOpen={isTicketOpen}
          isUserComment={isUserComment}
        />

        {isUserComment && <UserImage dimensions={20} />}
      </FirstRow>
      <StyledSecondaryText isUserComment={isUserComment}>
        {formatDate(created_at, intl)}
      </StyledSecondaryText>
    </CommentWrapper>
  )
}

const UiAvatar = styled(Avatar_Default)`
  width: 20px;
  height: 20px;
`

const CcedAvatar = styled(UnknownVendorLogo)`
  width: 20px;
  height: 20px;
  margin: 0;
`
