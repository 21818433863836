import React, { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'theme/styled'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/fullSubscriptions'
import { GenericModal } from 'components/generic-modal/GenericModal'
import {
  removeSiteSupport,
  resetSiteSupportErrors,
  selectIsSiteSupportLoading,
  selectSiteSupportErrors,
} from 'features/subscriptions/module/deviceSiteSupportCancel'
import { useHistory } from 'react-router'

interface Props extends ModalProps {
  fullSubscription?: FullSubscription
}

const CancelSubscriptionSiteSupportModal: React.FC<Props> = ({
  isOpen,
  onClose,
  fullSubscription,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const cancelErrors = useSelector(selectSiteSupportErrors)
  const isLoading = useSelector(selectIsSiteSupportLoading)
  const resetErrors = useSelector(resetSiteSupportErrors)

  const error = useMemo(() => {
    if (
      Object.values(cancelErrors).length ||
      typeof cancelErrors === 'string'
    ) {
      return <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_ERROR" />
    } else if (!fullSubscription) {
      return <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_FETCH_ERROR" />
    } else {
      return null
    }
  }, [cancelErrors, fullSubscription])

  const onConfirm = useCallback(() => {
    if (error) {
      history.push('/supportform')
    } else {
      dispatch(
        removeSiteSupport({
          id: fullSubscription?.id,
        })
      )
    }
  }, [error, history, dispatch, fullSubscription?.id])

  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={<Title />}
      size="small"
      onAfterClose={() => dispatch(resetErrors)}
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CANCEL" />,
          onClick: onClose,
        },
        {
          text: error ? (
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CONTACT_SUPPORT" />
          ) : (
            <FormattedMessage id="COMMON_ACTION_CONFIRM" />
          ),
          variant: 'primary',
          onClick: onConfirm,
          loader: isLoading ? 'dots' : undefined,
          type: 'submit',
          disabled: isLoading,
        },
      ]}
    >
      {error ? (
        <ErrorMessage>{error}</ErrorMessage>
      ) : (
        <FormattedMessage
          id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_MESSAGE"
          values={{ name: fullSubscription?.name }}
          tagName="div"
        />
      )}
    </GenericModal>
  )
}

export const CANCEL_SUBSCRIPTION_SITE_SUPPORT_MODAL_ID =
  'CANCEL_SUBSCRIPTION_SITE_SUPPORT_MODAL_ID'

const WrappedCancelSubscriptionSiteSupportModal = () => (
  <ModalWrapper modalId={CANCEL_SUBSCRIPTION_SITE_SUPPORT_MODAL_ID}>
    <CancelSubscriptionSiteSupportModal />
  </ModalWrapper>
)

export default WrappedCancelSubscriptionSiteSupportModal

const Title = () => (
  <TitleWrapper>
    <FormattedMessage
      id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION"
      tagName="div"
    />
  </TitleWrapper>
)

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const ErrorMessage = styled.div`
  color: ${(p) => p.theme.red06};
`
