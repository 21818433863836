import { api } from 'api'
import axios from 'axios'
import { ZDCustomFields } from './types'

export const handleCreateRequestError = async (
  error: unknown,
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>,
  formattedRequest: {
    ticket_form_id: number
    requester?: {
      email?: string
      name?: string
    }
    tags: string[]
    subject?: string
    comment: { body?: string }
    custom_fields?: ZDCustomFields
  },
  setIsFormSubmitted: React.Dispatch<React.SetStateAction<boolean>>
) => {
  // In case of submitting again for users to be able to see the toast again
  setErrorMessage('')
  if (!axios.isAxiosError(error)) throw error

  //   // set error message for zendesk rate-limit
  if (error.response?.status === 429) {
    return setErrorMessage('SUPPORT_SUBMIT_FAILED_TOAST_DESCRIPTION_RATE_LIMIT')
  }
  //    Check if the browser blocked the request due to strict mode
  //    in such cases, a Network Error message is received and no we get no error response
  //    as the request is prevented from reaching its destination so it never made it.
  //    */
  if (error?.message === 'Network Error' && !error?.response) {
    return setErrorMessage(
      'SUPPORT_SUBMIT_FAILED_TOAST_DESCRIPTION_STRICT_MODE'
    )
  }

  if (error?.response?.status === 422) {
    /*
     422 errors happen when the user information does not exist in
     Zendesk or does not match the information in SSO 
    */
    await api.createOrUpdateZendeskUser()
    await api.createZendeskRequest(formattedRequest)
    setIsFormSubmitted(true)
  } else {
    throw error
  }
}
