import { all, put, select, spawn, takeEvery } from 'redux-saga/effects'

import { api } from 'api'
import createDataModule from 'utils/moduleCreator'
import { SSOAPIError } from 'types/types'
import { closeVisibleModal } from 'modules/modals'
import {
  MFAAuthenticator,
  fetchMFA,
  selectMFAAuthenticators,
} from 'modules/mfa'
import { resetMFASetupSMS } from 'modules/mfaSetup'
import { checkAuth } from 'features/auth/modules/auth'
import { smsAdded } from 'modules/mfaAdded'

import { resetInitiateMFASMSData } from './initiateMFASMS'

const {
  api: { sso },
} = __CONFIG__

export const verifyMFASMSDataKey = 'verifyMFASMS'

const VerifyMFASMSActions = {
  create: `${verifyMFASMSDataKey}/CREATE`,
}

export type VerifyMFASMSState = Record<string, never>

const dataModule = createDataModule<VerifyMFASMSState, SSOAPIError>(
  verifyMFASMSDataKey,
  `${sso.paths.mfa}/sms`,
  api.ssoBase
)

const { selectIsLoading, selectErrors, CREATE_DONE } = dataModule

export {
  selectIsLoading as selectVerifyMFASMSIsLoading,
  selectErrors as selectVerifyMFASMSErrors,
}

export const verifyMFASMS = (
  id: string,
  phoneNumber: string,
  token: string
) => {
  return {
    type: VerifyMFASMSActions.create,
    payload: { phone_number: phoneNumber, oneTimePassword: token },
    urlPath: `/${id}/verify`,
  }
}

function* verifySMSDoneSaga() {
  const authenticators: MFAAuthenticator[] = yield select(
    selectMFAAuthenticators
  )
  const active = authenticators.filter((auth) => auth.status === 'active')
  if (active.length) {
    yield put(smsAdded(false))
  } else {
    yield put(smsAdded(true))
  }
  yield put(closeVisibleModal())
  yield put(resetInitiateMFASMSData())
  yield put(resetMFASetupSMS())
  yield put(fetchMFA())
  yield put(checkAuth())
}

function* subscribeToVerifySMSDoneSaga() {
  yield takeEvery(CREATE_DONE, verifySMSDoneSaga)
}

export function* verifyMFASMSRootSaga() {
  yield all([spawn(dataModule.rootSaga), spawn(subscribeToVerifySMSDoneSaga)])
}

export const verifyMFASMSReducer = dataModule.reducer
