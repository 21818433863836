import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import {
  all,
  call,
  put,
  race,
  spawn,
  take,
  takeEvery,
} from 'redux-saga/effects'
import { pick } from 'lodash-es'

import { ModuleMeta, ReduxAction } from 'types/types'
import axiosErrorInterceptor from './axiosErrorInterceptor'
import { isAxiosError } from './isAxiosError'

interface DefaultError {
  failed: 'Something went wrong'
}

const defaultError: DefaultError = { failed: 'Something went wrong' }

export interface ModuleState<DataType = any, ErrorType = any> {
  isFetchLoading: number
  isCreateLoading: boolean
  isUpdateLoading: boolean
  isUpdatePartialLoading: boolean
  isRemoveLoading: boolean
  isFetchDone: boolean
  hasFetchedOnce: boolean
  isCreateDone: boolean
  isUpdateDone: boolean
  isRemoveDone: boolean
  data: DataType
  errors: ErrorType & DefaultError
}

export const defaultInitialState: ModuleState = {
  isFetchLoading: 0,
  isCreateLoading: false,
  isUpdateLoading: false,
  isUpdatePartialLoading: false,
  isRemoveLoading: false,
  isFetchDone: false,
  hasFetchedOnce: false,
  isCreateDone: false,
  isUpdateDone: false,
  isRemoveDone: false,
  data: {},
  errors: {},
}

export default function createDataModule<DataType = any, ErrorType = any>(
  prefix: string,
  url: string,
  baseUrl?: string,
  initialState: ModuleState<DataType, ErrorType> = defaultInitialState,
  getUrl: (origUrl: string, data: { id?: string } & any) => string = (
    origUrl
  ) => origUrl,
  mergeFn: (data: any, prevData?: any) => any = (data: DataType) => data,
  isLongPoll = false,
  headers?: any
) {
  let ax: AxiosInstance
  if (baseUrl) {
    ax = axios.create({
      headers,
      withCredentials: true,
      baseURL: baseUrl,
    })
  } else {
    ax = axios.create({
      headers,
      withCredentials: true,
    })
  }

  axiosErrorInterceptor(ax)

  /**
   * ACTIONS
   */

  const FETCH = `${prefix}/FETCH`
  const FETCH_FAILED = `${prefix}/FETCH_FAILED`
  const FETCH_DONE = `${prefix}/FETCH_DONE`
  const FETCH_CANCEL = `${prefix}/FETCH_CANCEL`

  const FETCH_ALL = `${prefix}/FETCH_ALL`
  const FETCH_ALL_FAILED = `${prefix}/FETCH_ALL_FAILED`
  const FETCH_ALL_DONE = `${prefix}/FETCH_ALL_DONE`

  const MERGE_FETCH = `${prefix}/MERGE_FETCH`
  const MERGE_FETCH_DONE = `${prefix}/MERGE_FETCH_DONE`

  const CREATE = `${prefix}/CREATE`
  const CREATE_FAILED = `${prefix}/CREATE_FAILED`
  const CREATE_DONE = `${prefix}/CREATE_DONE`

  const UPDATE = `${prefix}/UPDATE`
  const UPDATE_FAILED = `${prefix}/UPDATE_FAILED`
  const UPDATE_DONE = `${prefix}/UPDATE_DONE`

  const UPDATE_PARTIAL = `${prefix}/UPDATE_PARTIAL`
  const UPDATE_PARTIAL_FAILED = `${prefix}/UPDATE_PARTIAL_FAILED`
  const UPDATE_PARTIAL_DONE = `${prefix}/UPDATE_PARTIAL_DONE`

  const REMOVE = `${prefix}/REMOVE`
  const REMOVE_DONE = `${prefix}/REMOVE_DONE`
  const REMOVE_FAILED = `${prefix}/REMOVE_FAILED`

  const SET_DATA = `${prefix}/SET_DATA`
  const RESET_DATA = `${prefix}/RESET_DATA`
  const RESET_ERRORS = `${prefix}/RESET_ERRORS`

  const SET_IS_FETCH_LOADING = `${prefix}/SET_IS_FETCH_LOADING`
  const SET_IS_REMOVE_LOADING = `${prefix}/SET_IS_REMOVE_LOADING`

  /**
   * ACTION CREATORS
   */

  const fetch = (payload?: any, meta?: ModuleMeta) => {
    return {
      type: FETCH,
      payload,
      meta,
    }
  }
  const fetchCancel = () => {
    return {
      type: FETCH_CANCEL,
    }
  }
  const fetchFailed = <Message = any>(
    validationMessage: Message,
    meta?: ModuleMeta
  ) => ({
    type: FETCH_FAILED,
    payload: validationMessage || defaultError,
    meta,
  })
  const fetchDone = (data?: DataType, meta?: ModuleMeta): ReduxAction => ({
    type: FETCH_DONE,
    payload: data,
    meta,
  })

  const mergeFetch = (payload?: any, meta?: ModuleMeta) => ({
    type: MERGE_FETCH,
    payload,
    meta,
  })
  const mergeFetchDone = (data?: DataType, meta?: ModuleMeta): ReduxAction => ({
    type: MERGE_FETCH_DONE,
    payload: data,
    meta,
  })

  const fetchAll = (payload?: any, meta?: ModuleMeta) => ({
    type: FETCH_ALL,
    payload,
    meta,
  })
  const fetchAllDone = (data?: DataType, meta?: ModuleMeta): ReduxAction => ({
    type: FETCH_ALL_DONE,
    payload: data,
    meta,
  })

  const create = <Attributes = any>(
    attributes: Attributes,
    meta?: ModuleMeta
  ) => ({
    type: CREATE,
    payload: attributes,
    meta,
  })
  const createFailed = <Message = any>(
    validationMessage?: Message,
    meta?: ModuleMeta
  ) => ({
    type: CREATE_FAILED,
    payload: validationMessage || defaultError,
    meta,
  })
  const createDone = <Attributes = any>(
    attributes?: Attributes,
    meta?: ModuleMeta
  ) => ({
    type: CREATE_DONE,
    payload: attributes,
    meta,
  })

  const update = <Attributes = any>(
    attributes: Attributes,
    meta?: ModuleMeta
  ) => ({
    type: UPDATE,
    payload: attributes,
    meta,
  })
  const updateFailed = <Message = any>(
    validationMessage: Message,
    meta?: ModuleMeta
  ) => ({
    type: UPDATE_FAILED,
    payload: validationMessage || defaultError,
    meta,
  })
  const updateDone = <Attributes = any>(
    attributes?: Attributes,
    meta?: ModuleMeta
  ) => ({
    type: UPDATE_DONE,
    payload: attributes,
    meta,
  })

  const updatePartial = <Attributes = any>(
    attributes: Attributes,
    meta?: ModuleMeta,
    uriParams?: Record<string, string>
  ) => ({
    type: UPDATE_PARTIAL,
    payload: attributes,
    meta,
    uriParams,
  })
  const updatePartialFailed = <Message = any>(
    validationMessage: Message,
    meta?: ModuleMeta
  ) => ({
    type: UPDATE_PARTIAL_FAILED,
    payload: validationMessage || defaultError,
    meta,
  })
  const updatePartialDone = <Attributes = any>(
    attributes?: Attributes,
    meta?: ModuleMeta
  ) => ({
    type: UPDATE_PARTIAL_DONE,
    payload: attributes,
    meta,
  })

  const remove = <Attributes = any>(
    attributes?: Attributes,
    meta?: ModuleMeta
  ) => ({
    type: REMOVE,
    payload: attributes,
    meta,
  })
  const removeFailed = <Message = any>(
    validationMessage: Message,
    meta?: ModuleMeta
  ) => ({
    type: REMOVE_FAILED,
    payload: validationMessage || defaultError,
    meta,
  })
  const removeDone = <Attributes = any>(
    attributes?: Attributes,
    meta?: ModuleMeta
  ) => ({
    type: REMOVE_DONE,
    payload: attributes,
    meta,
  })

  const setData = (data: DataType, meta?: ModuleMeta) => ({
    type: SET_DATA,
    payload: data,
    meta,
  })
  const resetData = (meta?: ModuleMeta) => ({
    type: RESET_DATA,
    meta,
  })

  const resetErrors = (meta?: ModuleMeta) => ({
    type: RESET_ERRORS,
    meta,
  })

  const setIsFetchLoading = (meta?: ModuleMeta) => ({
    type: SET_IS_FETCH_LOADING,
    meta,
  })
  const setIsRemoveLoading = (payload: boolean) => ({
    type: SET_IS_REMOVE_LOADING,
    payload,
  })

  /**
   * SAGAS
   */

  function* fetchSaga(action: ReduxAction<DataType>): any {
    const { meta = {}, urlPath = '' }: ReduxAction<any> = action
    try {
      const cancelSource = axios.CancelToken.source()
      const { response, cancel } = yield race({
        response: call(ax.get, getUrl(`${url}${urlPath}`, action.payload), {
          cancelToken: cancelSource.token,
        }),
        cancel: take(FETCH_CANCEL),
      })

      if (cancel && cancel.type) {
        yield call(cancelSource.cancel)
      }

      if (response.status === 202 && isLongPoll) {
        yield put({
          type: FETCH,
          urlPath: action.urlPath,
        })

        return
      }

      if (response.status === 204) {
        yield put(fetchDone(undefined, meta))
      } else if (isLongPoll && response.status === 202) {
        return
      } else if (isLongPoll && response.status === 401) {
        yield put(fetchFailed(response.data, meta))
        return
      } else {
        const json = response.data
        yield put(fetchDone(json, meta))
      }
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError
        const data = error.response?.data
        yield put(fetchFailed(data, meta))
      }
      return
    }
  }

  function* subscribeToFetchSaga() {
    yield takeEvery(FETCH, fetchSaga)
  }

  function* mergeFetchSaga(
    action: ReduxAction<DataType>
  ): Generator<any, any, any> {
    const { meta = {}, urlPath = '' }: ReduxAction<any> = action
    try {
      const { response, cancel } = yield race({
        response: yield call(
          ax.get,
          getUrl(`${url}${urlPath}`, action.payload)
        ),
        cancel: take(MERGE_FETCH),
      })

      if (cancel && cancel.type) return

      if (response.status === 204) {
        yield put(mergeFetchDone(undefined, meta))
      } else {
        const json = response.data

        yield put(mergeFetchDone(json, meta))
      }
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError
        const data = error.response?.data
        yield put(fetchFailed(data, meta))
      }
      return
    }
  }

  function* subscribeToMergeFetchSaga() {
    yield takeEvery(MERGE_FETCH, mergeFetchSaga)
  }

  function* fetchAllSaga(action: ReduxAction<DataType>) {
    const { meta = {}, urlPath = '' }: ReduxAction<any> = action
    try {
      const { response, cancel } = yield race({
        response: call(ax.get, getUrl(`${url}${urlPath}`, action.payload)),
        cancel: take(FETCH),
      })

      if (cancel && cancel.type) return

      if (response.status === 204) {
        yield put(fetchDone(undefined, meta))
      } else {
        const json = response.data

        yield put(fetchAllDone(json, meta))

        const regions = Object.keys(json)

        for (let i = 0; i < regions.length; i++) {
          const isInvoiceLines = /\/lines/.test(json[`${regions[i]}`].url)
          if (json[`${regions[i]}`].has_more && !isInvoiceLines) {
            yield put(
              fetchAll(
                {
                  ...action.payload,
                  starting_after:
                    json[`${regions[i]}`].data[
                      json[`${regions[i]}`].data.length - 1
                    ].id,
                  region: regions[i],
                },
                meta
              )
            )
            return
          }
          if (json[`${regions[i]}`].has_more && isInvoiceLines) {
            const invoiceId = json[`${regions[i]}`].url.split('/')[3]
            yield put(
              fetchAll(
                {
                  invoiceId,
                  region: regions[i],
                  starting_after:
                    json[`${regions[i]}`].data[
                      json[`${regions[i]}`].data.length - 1
                    ].id,
                },
                meta
              )
            )
            return
          }
        }
      }
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError
        const data = error.response?.data
        yield put(fetchFailed(data, meta))
      }
      return
    }
  }

  function* subscribeToFetchAllSaga() {
    yield takeEvery(FETCH_ALL, fetchAllSaga)
  }

  function* createSaga(action: ReduxAction): any {
    const { payload, meta = {}, urlPath = '' }: ReduxAction<any> = action
    let response: AxiosResponse
    try {
      response = yield call(
        ax.post,
        getUrl(`${url}${urlPath}`, action.payload),
        meta.pick ? pick(payload, meta.pick) : payload
      )

      if (response.status === 204) {
        yield put(createDone(undefined, meta))
      } else {
        yield put(createDone(response.data, meta))
      }
      meta.onSuccess && meta.onSuccess(response ? response.data : undefined)
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError
        const data = error.response?.data
        yield put(createFailed(data, meta))
        meta.onError?.(data)
      }
      return
    }
  }

  function* subscribeToCreateSaga() {
    yield takeEvery(CREATE, createSaga)
  }

  function* removeSaga(action: ReduxAction) {
    const { payload, meta = {}, urlPath = '' }: ReduxAction<any> = action
    let response: AxiosResponse
    try {
      response = yield call(ax.delete, getUrl(`${url}${urlPath}`, payload), {
        data: payload,
      })

      if (response.status === 204) {
        yield put(removeDone(undefined, meta))
      } else {
        yield put(removeDone(undefined, meta))
      }
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError
        yield put(
          removeFailed(error.response ? error.response.data : null, meta)
        )
      }
      return
    }
  }

  function* subscribeToRemoveSaga() {
    yield takeEvery(REMOVE, removeSaga)
  }

  function* updateSaga(action: ReduxAction) {
    const { payload, meta, urlPath = '' }: ReduxAction<any> = action
    let response: AxiosResponse
    try {
      response = yield call(
        ax.put,
        getUrl(`${url}${urlPath}`, action.payload),
        payload
      )

      if (response.status === 204) {
        yield put(updateDone(undefined, meta))
      } else {
        yield put(updateDone(response.data, meta))
      }
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError
        yield put(updateFailed(error.response?.data, meta))
      }
      return
    }
  }

  function* subscribeToUpdateSaga() {
    yield takeEvery(UPDATE, updateSaga)
  }

  function* updatePartialSaga(action: ReduxAction) {
    const { payload, meta, urlPath = '' }: ReduxAction<any> = action
    let response: AxiosResponse
    try {
      response = yield call(
        ax.patch,
        getUrl(`${url}${urlPath}`, payload),
        meta?.pick ? pick(payload, meta.pick) : payload
      )

      if (response.status === 204) {
        yield put(updatePartialDone(undefined, meta))
      } else {
        yield put(updatePartialDone(response.data, meta))
      }
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError
        yield put(updatePartialFailed(error.response?.data, meta))
      }
      return
    }
  }

  function* subscribeToUpdatePartialSaga() {
    yield takeEvery(UPDATE_PARTIAL, updatePartialSaga)
  }

  function* rootSaga() {
    yield all([
      spawn(subscribeToFetchSaga),
      spawn(subscribeToCreateSaga),
      spawn(subscribeToRemoveSaga),
      spawn(subscribeToMergeFetchSaga),
      spawn(subscribeToFetchAllSaga),
      spawn(subscribeToUpdateSaga),
      spawn(subscribeToUpdatePartialSaga),
    ])
  }

  /**
   * REDUCER
   */

  const reducer = (state = initialState, action: ReduxAction) => {
    const { type, payload } = action
    switch (type) {
      case FETCH:
        return {
          ...state,
          isFetchLoading: state.isFetchLoading + 1,
          isFetchDone: false,
        }
      case FETCH_ALL_FAILED:
      case FETCH_FAILED:
        return {
          ...state,
          errors: payload ? payload : { failed: true },
          isFetchLoading:
            state.isFetchLoading - 1 ? state.isFetchLoading - 1 : 0,
          isFetchDone: true,
          hasFetchedOnce: true,
        }
      case FETCH_DONE: {
        return {
          ...state,
          data: payload,
          isFetchLoading:
            state.isFetchLoading - 1 ? state.isFetchLoading - 1 : 0,
          isFetchDone: true,
          hasFetchedOnce: true,
          errors: {},
        }
      }
      case FETCH_CANCEL: {
        return {
          ...state,
          isFetchLoading:
            state.isFetchLoading - 1 ? state.isFetchLoading - 1 : 0,
        }
      }
      case FETCH_ALL:
      case MERGE_FETCH:
        return {
          ...state,
          isFetchLoading: state.isFetchLoading + 1,
          isFetchDone: false,
        }
      case MERGE_FETCH_DONE:
      case FETCH_ALL_DONE: {
        const prevData = state.data
        const newData = mergeFn(payload, prevData)
        return {
          ...state,
          data: { ...newData },
          isFetchLoading:
            state.isFetchLoading - 1 ? state.isFetchLoading - 1 : 0,
          isFetchDone: true,
          hasFetchedOnce: true,
        }
      }

      case CREATE:
        return {
          ...state,
          errors: {},
          isCreateLoading: true,
          isCreateDone: false,
        }
      case CREATE_FAILED:
        return {
          ...state,
          errors: payload,
          isCreateLoading: false,
          isCreateDone: true,
        }
      case CREATE_DONE: {
        if (payload && Object.keys(payload).length) {
          return {
            ...state,
            data: payload,
            errors: {},
            isCreateLoading: false,
            isCreateDone: true,
          }
        }
        return {
          ...state,
          errors: {},
          isCreateLoading: false,
          isCreateDone: true,
        }
      }
      case UPDATE:
      case UPDATE_PARTIAL:
        return {
          ...state,
          errors: {},
          isUpdateLoading: true,
          isUpdateDone: false,
        }
      case UPDATE_FAILED:
      case UPDATE_PARTIAL_FAILED:
        return {
          ...state,
          errors: payload,
          isUpdateLoading: false,
          isUpdateDone: true,
        }
      case UPDATE_DONE:
      case UPDATE_PARTIAL_DONE: {
        if (payload && Object.keys(payload).length) {
          return {
            ...state,
            data: payload,
            errors: {},
            isUpdateLoading: false,
            isUpdateDone: true,
          }
        }
        return {
          ...state,
          errors: {},
          isUpdateLoading: false,
          isUpdateDone: true,
        }
      }

      case REMOVE:
        return {
          ...state,
          errors: {},
          isRemoveLoading: true,
          isRemoveDone: false,
        }
      case REMOVE_FAILED:
        return {
          ...state,
          errors: payload,
          isRemoveLoading: false,
          isRemoveDone: true,
        }
      case REMOVE_DONE: {
        return {
          ...state,
          data: payload,
          errors: {},
          isRemoveLoading: false,
          isRemoveDone: true,
        }
      }
      case SET_DATA:
        return { ...state, data: payload }
      case RESET_DATA:
        return { ...initialState }
      case RESET_ERRORS:
        return { ...state, errors: {} }
      case SET_IS_FETCH_LOADING:
        return { ...state, isFetchLoading: state.isFetchLoading + 1 }
      case SET_IS_REMOVE_LOADING:
        return { ...state, isRemoveLoading: payload }
      default:
        return { ...state }
    }
  }

  /**
   * SELECTORS
   */

  const selectData = (state: any): DataType => state[prefix].data
  const selectIsLoading = (state: any): boolean => {
    const moduleState = state[prefix] as ModuleState<DataType, ErrorType>
    return (
      !!moduleState.isFetchLoading ||
      moduleState.isCreateLoading ||
      moduleState.isUpdateLoading ||
      moduleState.isUpdatePartialLoading ||
      moduleState.isRemoveLoading
    )
  }
  const selectIsRemoveLoading = (state: any): boolean => {
    const moduleState = state[prefix] as ModuleState<DataType, ErrorType>
    return !!moduleState.isRemoveLoading
  }
  const selectIsFetchLoading = (state: any): boolean => {
    const moduleState = state[prefix] as ModuleState<DataType, ErrorType>
    return !!moduleState.isFetchLoading
  }

  const selectErrors = (state: any): ErrorType => state[prefix].errors
  const selectIsError = (state: any): boolean =>
    Object.keys(state[prefix].errors).length > 0 ? true : false
  const selectIsDone = (state: any): boolean => {
    const { isFetchDone, isCreateDone, isUpdateDone, isRemoveDone } =
      state[prefix]
    return isFetchDone || isCreateDone || isUpdateDone || isRemoveDone
  }
  const selectHasFetchedOnce = (state: any): boolean => {
    const moduleState = state[prefix] as ModuleState<DataType, ErrorType>
    return !!moduleState.hasFetchedOnce
  }

  return {
    FETCH,
    FETCH_FAILED,
    FETCH_DONE,

    FETCH_ALL,
    FETCH_ALL_FAILED,
    FETCH_ALL_DONE,

    CREATE,
    CREATE_FAILED,
    CREATE_DONE,

    UPDATE,
    UPDATE_FAILED,
    UPDATE_DONE,

    UPDATE_PARTIAL,
    UPDATE_PARTIAL_FAILED,
    UPDATE_PARTIAL_DONE,

    REMOVE,
    REMOVE_FAILED,
    REMOVE_DONE,

    SET_DATA,

    fetch,
    fetchFailed,
    fetchDone,
    fetchCancel,

    fetchAll,
    fetchAllDone,

    mergeFetch,
    mergeFetchDone,

    create,
    createFailed,
    createDone,

    update,
    updateFailed,
    updateDone,

    updatePartial,
    updatePartialFailed,
    updatePartialDone,

    remove,
    removeFailed,
    removeDone,

    setData,
    resetData,
    resetErrors,

    setIsFetchLoading,
    setIsRemoveLoading,

    selectData,
    selectIsLoading,
    selectIsRemoveLoading,
    selectIsFetchLoading,
    selectErrors,
    selectIsError,
    selectIsDone,
    selectHasFetchedOnce,

    initialState,
    rootSaga,
    reducer,
  }
}
