import React from 'react'
import styled from '@emotion/styled'
import { AndroidIconL, LogoAppleIconL } from '@ubnt/icons'
import { typography } from 'theme'
import { getIsAndroid, getIsIOS } from 'utils/getOS'

export const StoreButtons = () => {
  const isIOS = getIsIOS(navigator.userAgent)
  const isAndroid = getIsAndroid(navigator.userAgent)
  const isNotIOSorAndroid = !isAndroid && !isIOS
  const showIOSButton = isIOS || isNotIOSorAndroid
  const showAndroidButton = isAndroid || isNotIOSorAndroid

  const iosAppLink = 'https://apps.apple.com/app/ui-verify/id1499320261'
  const androidAppLink =
    'https://play.google.com/store/apps/details?id=com.ui.uid.authenticator'

  return (
    <>
      {showIOSButton && (
        <a href={iosAppLink} target="_blank" rel="noreferrer">
          <IconWrapper>
            <StyledAppleIcon
              variant="fill"
              $highlighted={isIOS}
              size={isIOS ? 'large' : undefined}
            />
            <LogoText $highlighted={isIOS}>iOS</LogoText>
          </IconWrapper>
        </a>
      )}
      {showAndroidButton && (
        <a href={androidAppLink} target="_blank" rel="noreferrer">
          <IconWrapper>
            <StyledAndroidIcon
              variant="fill"
              $highlighted={isAndroid}
              size={isAndroid ? 'large' : undefined}
            />
            <LogoText $highlighted={isAndroid}>Android</LogoText>
          </IconWrapper>
        </a>
      )}
    </>
  )
}

const LogoText = styled.div<{ $highlighted?: boolean }>`
  font: ${typography['desktop-typography-caption']};
  color: ${({ theme, $highlighted }) =>
    $highlighted ? theme.ublue06 : theme.neutral06};
  margin-top: 4px;
  ${({ $highlighted }) =>
    $highlighted &&
    `
  font-size: 14px;
`};
`

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
`

const StyledAppleIcon = styled(LogoAppleIconL)<{ $highlighted?: boolean }>`
  color: ${({ theme, $highlighted }) =>
    $highlighted ? theme.ublue06 : theme.neutral06};
`

const StyledAndroidIcon = styled(AndroidIconL)<{ $highlighted?: boolean }>`
  color: ${({ theme, $highlighted }) =>
    $highlighted ? theme.ublue06 : theme.neutral06};
`
