import React from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { selectMFASetup } from 'modules/mfaSetup'
import { MFAType } from 'modules/mfa'

import { GenericConfirmRemoveMFAModal } from '../RemoveModal/GenericConfirmRemoveMFAModal'

export const EMAIL_REMOVE_MODAL_ID = 'EMAIL_REMOVE_MODAL_ID'

const EmailRemoveModal: React.FC<ModalProps & WrappedComponentProps> = ({
  intl,
  ...restProps
}) => {
  const { removeEmail } = useSelector(selectMFASetup)

  return (
    <GenericConfirmRemoveMFAModal
      title={intl.formatMessage({
        id: 'SETTINGS_MFA_EMAIL_REMOVE_MODAL_TITLE',
      })}
      removeId={removeEmail.id}
      type={MFAType.email}
      {...restProps}
    />
  )
}

const EmailRemoveModalConnected = injectIntl(EmailRemoveModal)

export const WrappedEmailRemoveModal = () => (
  <ModalWrapper modalId={EMAIL_REMOVE_MODAL_ID}>
    <EmailRemoveModalConnected />
  </ModalWrapper>
)
