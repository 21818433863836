import React from 'react'

import { FullSubscription } from 'features/subscriptions/fullSubscriptions/fullSubscriptions'
import { showSubscriptionFailedPaymentButtons } from 'features/subscriptions/utils'

import { useSubscriptionCancel } from '../../hooks/useSubscriptionCancel'
import MorePopover from '../../MorePopover'
import { useSubscriptionPaymentMethods } from '../../hooks/useSubscriptionPaymentMethod'

export const SubscriptionMorePopover: React.FC<{
  fullSubscription: FullSubscription
  morePopoverOpen: number | null
  setMorePopoverOpen: React.Dispatch<React.SetStateAction<number | null>>
  setCardPopoverOpen: React.Dispatch<React.SetStateAction<number | null>>
  cardPopoverOpen: number | null
  modalId: string
  id: number
  isTalk?: boolean
}> = ({
  fullSubscription,
  morePopoverOpen,
  setMorePopoverOpen,
  setCardPopoverOpen,
  cardPopoverOpen,
  id,
  modalId,
  isTalk,
}) => {
  const { onCancelSubscription } = useSubscriptionCancel()
  const { openChoosePaymentMethodModal } = useSubscriptionPaymentMethods()

  return (
    <MorePopover
      fullSubscription={fullSubscription}
      onCancelSubscription={() =>
        onCancelSubscription(modalId, fullSubscription)
      }
      popoverOpen={morePopoverOpen}
      setPopoverOpen={setMorePopoverOpen}
      setCardPopoverOpen={setCardPopoverOpen}
      cardPopoverOpen={cardPopoverOpen}
      showFailedSubActionButtons={showSubscriptionFailedPaymentButtons(
        fullSubscription
      )}
      openChoosePaymentMethodModal={openChoosePaymentMethodModal}
      id={id}
      isTalk={isTalk}
    />
  )
}
