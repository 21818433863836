import { MFAType } from 'modules/mfa'
import { BACKUP_CODES_MODAL_ID } from 'pages/security/components/BackupCodes/BackupCodesModal'
import { EMAIL_INPUT_EMAIL_MODAL_ID } from 'pages/security/components/Email'
import { SMS_INPUT_MOBILE_PHONE_NUMBER_MODAL_ID } from 'pages/security/components/SMS/InputMobilePhoneNumberModal'
import { PASSKEY_ADD_KEY_NAME_MODAL_ID } from 'pages/security/components/Passkey/AddKeyNameModal'
import { PASSKEY_NO_BROWSER_SUPPORT_MODAL_ID } from 'pages/security/components/Passkey/NoBrowserSupportModal'
import {
  TOTP_QR_CODE_MODAL_ID,
  TOTP_SECRET_CODE_MODAL_ID,
} from 'pages/security/components/TOTP'

export const getNextModalId = (
  value: MFAType,
  isMobileDevice?: boolean
): string => {
  switch (value) {
    case MFAType.totp:
      return isMobileDevice ? TOTP_SECRET_CODE_MODAL_ID : TOTP_QR_CODE_MODAL_ID
    case MFAType.email:
      return EMAIL_INPUT_EMAIL_MODAL_ID
    case MFAType.sms:
      return SMS_INPUT_MOBILE_PHONE_NUMBER_MODAL_ID
    case MFAType.webAuthn:
      return window.PublicKeyCredential
        ? PASSKEY_ADD_KEY_NAME_MODAL_ID
        : PASSKEY_NO_BROWSER_SUPPORT_MODAL_ID
    default:
      return BACKUP_CODES_MODAL_ID
  }
}
