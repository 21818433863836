import { unionBy } from 'lodash-es'
import { createSelector } from 'reselect'

import createDataModule from 'utils/moduleCreator'
import { StripeRegionCode } from 'features/stripe/ui/types'

import { InvoiceLines, InvoiceLinesData } from './types'

interface UrlParams {
  region: StripeRegionCode
  invoiceId: string
  starting_after?: string
}

const {
  api: { billing },
} = __CONFIG__

const getUrl = (
  url: string,
  { invoiceId, region, starting_after }: UrlParams
) =>
  `${url.replace('{invoiceId}', invoiceId)}?region=${region}&limit=100${
    starting_after ? `&starting_after=${starting_after}` : ''
  }`

const mergeFn = (data: InvoiceLinesData, prevData: InvoiceLines) => {
  const newData: InvoiceLines = {
    us: [],
    ca: [],
    gb: [],
    eu: [],
    au: [],
    ae: [],
    sg: [],
    row: [],
  }

  try {
    const regions = Object.keys(data) as StripeRegionCode[]
    regions.forEach((region) => {
      newData[`${region as keyof InvoiceLines}`] = unionBy(
        prevData[`${region as keyof InvoiceLines}`] ?? [],
        data[`${region as keyof InvoiceLines}`].data ?? [],
        'id'
      )
    })
    return newData
  } catch (e) {
    console.error(e)
  }
}

export const invoiceLinesDataKey = 'invoiceLines'

export const dataModule = createDataModule<InvoiceLines>(
  invoiceLinesDataKey,
  billing.paths.invoiceLines,
  billing.base,
  undefined,
  getUrl,
  mergeFn
)

export const {
  fetchAll: fetchInvoiceLines,
  selectIsLoading: selectIsInvoiceLinesLoading,
  resetData: resetInvoiceLinesData,
  reducer: invoiceLinesReducer,
  rootSaga: invoiceLinesRootSaga,
} = dataModule

export const selectInvoiceLines = createSelector(
  dataModule.selectData,
  (invoicesData) => {
    const regions = Object.keys(invoicesData)

    const invoiceLines = regions
      .map((region) => {
        return (
          invoicesData[region as keyof InvoiceLines]?.map((invoice) => {
            return invoice
          }) ?? []
        )
      })
      .flat()

    return invoiceLines
  }
)
