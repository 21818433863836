import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { GenericModal } from 'components/generic-modal/GenericModal'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectInvoices,
  selectIsInvoicesLoading,
} from 'features/subscriptions/module/invoices'
import { selectFullSubscriptions } from 'features/subscriptions/fullSubscriptions/fullSubscriptions'
import { selectIsSubscriptionsFetching } from 'features/subscriptions/module/subscriptions'

import { setVisibleModal } from 'modules/modals'
import { CONFIRM_DELETE_ACCOUNT_MODAL } from './ConfirmDeleteAccountModal'
import { getPendingItemsType } from '../utils'
import { DeleteAccountModalBody } from './DeleteAccountModalBody'

export const DeleteAccountModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch()
  const invoices = useSelector(selectInvoices)
  const isInvoicesLoading = useSelector(selectIsInvoicesLoading)

  const fullSubscriptions = useSelector(selectFullSubscriptions)
  const isSubscriptionsLoading = useSelector(selectIsSubscriptionsFetching)

  const isLoading = useMemo(() => {
    const areInvoicesLoading = !invoices.length && isInvoicesLoading
    const areSubscriptionsLoading =
      !fullSubscriptions.length && isSubscriptionsLoading

    return areInvoicesLoading || areSubscriptionsLoading
  }, [invoices, isInvoicesLoading, isSubscriptionsLoading, fullSubscriptions])

  const pendingItems = getPendingItemsType(invoices, fullSubscriptions)

  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={<FormattedMessage id="SETTINGS_SECURITY_DELETE" />}
      size="small"
      actions={
        pendingItems
          ? []
          : [
              {
                text: <FormattedMessage id="COMMON_ACTION_CANCEL" />,
                onClick: onClose,
                variant: 'tertiary',
              },
              {
                text: <FormattedMessage id="SETTINGS_SECURITY_DELETE_BUTTON" />,
                variant: 'primary',
                color: 'danger',
                onClick: () =>
                  dispatch(setVisibleModal(CONFIRM_DELETE_ACCOUNT_MODAL)),
                disabled: isLoading || !!pendingItems,
                loader: isLoading ? 'dots' : undefined,
                type: 'submit',
              },
            ]
      }
      footer={pendingItems ? <></> : undefined}
    >
      <DeleteAccountModalBody
        isLoading={isLoading}
        pendingItems={pendingItems}
      />
    </GenericModal>
  )
}

export const DELETE_ACCOUNT_MODAL = 'DELETE_ACCOUNT_MODAL'

export const WrappedDeleteAccountModal = () => (
  <ModalWrapper modalId={DELETE_ACCOUNT_MODAL}>
    <DeleteAccountModal />
  </ModalWrapper>
)
